import React, { useEffect } from 'react';
import { getRemoteConfig, fetchAndActivate, getValue, RemoteConfig, getAll } from "firebase/remote-config";
import { useFirebase } from 'contexts/Firebase';
import * as Sentry from "@sentry/react";

const RemoteConfigContext = React.createContext<{
  loading: boolean,
  override: any,
  remoteConfig?: RemoteConfig,
  setOverride: React.Dispatch<React.SetStateAction<object>>
}>({
  loading: true,
  override: {},
  setOverride: () => { }
});


const stringToBoolean = (stringValue: string) => {
  switch (stringValue?.toLowerCase()?.trim()) {
    case "true":
    case "yes":
    case "1":
      return true;

    case "false":
    case "no":
    case "0":
    case null:
    case undefined:
      return false;

    default:
      return JSON.parse(stringValue);
  }
}

const RemoteConfigProvider = ({ children }: { children: any }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [override, setOverride] = React.useState<object>({});
  const [remoteConfig, setRemoteConfig] = React.useState<RemoteConfig>()
  const { app } = useFirebase();
  useEffect(() => {
    const remoteConfig = getRemoteConfig(app);
    // cache for 1 hour then check again on next reload
    remoteConfig.settings.minimumFetchIntervalMillis = 60 * 1000;
    let rcDefaults = require('../../remote_config_defaults.json');
    remoteConfig.defaultConfig = rcDefaults;
    console.log("remoteConfig setRemoteConfig")
    setRemoteConfig(remoteConfig);
  }, [app])

  useEffect(() => {
    if (!remoteConfig)
      return;
    if (!loading) {
      //save changes to local storage
      //convert override to key:value
      const newOverride: any = {};
      Object.keys(override).forEach(key => {
        console.log("save remoteConfig set", key, (override as any)[key].value)
        newOverride[key] = (override as any)[key].value;
      });
      console.log("save remoteConfig", newOverride)
      localStorage.setItem('remoteConfigOverride', JSON.stringify(newOverride));
    }

    const all: any = getAll(remoteConfig);
    Object.keys(override).forEach(key => {
      all[key] = { _source: "browserOverride", _value: (override as any)[key].value };
    }
    )
    Sentry.setExtra("remoteConfig", all);
    console.log("remoteConfig all", all)
  }, [loading, override, remoteConfig])


  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => {
    console.log("remoteConfig useEffect check url params")
    if (!remoteConfig)
      return;
    //check url params for override
    const params = new URLSearchParams(window.location.search);
    if (params.has('debug')) {
      setOverride((override) => {
        return {
          ...override, debug: {
            value: params.get('debug'),
            asBoolean: (): boolean => stringToBoolean(params.get('debug')!),
            asString: (): string | null => params.get('debug'),
            asNumber: (): number => Number(params.get('debug')),
            getSource: (): string => "override"
          }
        }
      });
    }
    if (params.has("overrides")) {
      setOverride((override) => {
        const overridesParam = params.get('overrides');
        if (overridesParam) {
          const overrides = overridesParam.split(',').map(pair => pair.split('|'));
          const newOverrides: any = {};
          const saveOverrides: any = localStorage.getItem('remoteConfigOverride') ? JSON.parse(localStorage.getItem('remoteConfigOverride')!) : {};
          overrides.forEach(([key, value]) => {
            saveOverrides[key] = value;
            newOverrides[key] = {
              value,
              asBoolean: (): boolean => stringToBoolean(value),
              asString: (): string | null => value,
              asNumber: (): number => Number(value),
              getSource: (): string => "override"
            };
          });
          Object.keys(override).forEach((key, value) => {
            if (!saveOverrides[key]) {
              saveOverrides[key] = (override as any)[key].value;
            }
          })
          console.log("save remoteConfig from url change", saveOverrides)
          localStorage.setItem('remoteConfigOverride', JSON.stringify(
            saveOverrides
          ));
          return {
            ...override,
            ...newOverrides
          };
        }
        return override;
      });
    }
    fetchAndActivate(remoteConfig)
      .then(activated => {
        if (!activated) console.log('remote config not activated');
        else console.log("remote config activated");
      })
      .catch(error => console.error("remote config error", error))
      .finally(() => {
        //set override from local storage
        const storedOverride = JSON.parse(localStorage.getItem('remoteConfigOverride') || '{}');
        //set override from remote config individually
        console.log("storedOverride", storedOverride);
        Object.keys(storedOverride).forEach(key => {
          setOverride(prevOverride => ({
            ...prevOverride,
            [key]: {
              value: storedOverride[key],
              asBoolean: () => stringToBoolean(storedOverride[key]),
              asString: () => storedOverride[key],
              asNumber: () => Number(storedOverride[key]),
              getSource: () => "remoteConfig"
            }
          }))
        });
        setLoading(false);
      });
  }, [remoteConfig]);

  return (
    <RemoteConfigContext.Provider value={{ loading, override, remoteConfig, setOverride }}>{children}</RemoteConfigContext.Provider>
  );
};

export default RemoteConfigProvider;

export const useRemoteConfig = (key: string) => {
  const context = React.useContext(RemoteConfigContext);
  if (!context.remoteConfig)
    return {};
  if (key === "overrides") return {
    overrides: context.override,
    defaults: context.remoteConfig.defaultConfig,
    setOverride: (key: string, value: any) => {
      context.setOverride(prevOverride => ({
        ...prevOverride,
        [key]: {
          value: value,
          asBoolean: () => stringToBoolean(value),
          asString: () => value.toString ? value.toString() : "",
          asNumber: () => Number(value),
          getSource: () => "remoteConfig"
        }
      }))
    },
    deleteOverride: (key: string, value: string) => {
      context.setOverride(prevOverride => {
        const newOverride: any = { ...prevOverride };
        delete newOverride[key];
        return newOverride;
      })
    }
  };
  // eslint-disable-next-line no-prototype-builtins
  if (context.override.hasOwnProperty(key)) {
    console.log("using remote config override", context.override[key], context.override[key].asBoolean())
    return context.override[key];
  }
  return getValue(context.remoteConfig, key);
}
export const useRemoteConfigFull = (): RemoteConfigs => {
  return {
    "debug": useRemoteConfig("debug").asBoolean(),
    "quotit": useRemoteConfig("quotit").asBoolean(),
    "quotitACA": useRemoteConfig("quotitACA").asBoolean(),
    "documents": useRemoteConfig("documents").asBoolean(),
    "services": useRemoteConfig("services").asBoolean(),
    "resources": useRemoteConfig("resources").asBoolean(),
    "retirement": useRemoteConfig("retirement").asBoolean(),
    "whenBenefit": useRemoteConfig("whenBenefit").asBoolean(),
    "aiScore": useRemoteConfig("aiScore").asBoolean(),
    "unifiedJamie": useRemoteConfig("unifiedJamie").asBoolean(),
  }
}
export type RemoteConfigs = {
  "debug": boolean,
  "quotit": boolean,
  "quotitACA": boolean,
  "documents": boolean,
  "services": boolean,
  "resources": boolean,
  "retirement": boolean,
  "whenBenefit": boolean,
  "aiScore": boolean,
  "unifiedJamie": boolean,
}
export const useRemoteConfigLoading = () => {
  const context = React.useContext(RemoteConfigContext);
  return context.loading;
}