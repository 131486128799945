import { Typography, Button, Dialog, Card } from "@mui/material"
import { Box } from "@mui/system"
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import { useEffect, useState } from "react";
//import { useNavigate } from "react-router-dom";
import { useAuth } from "contexts/User";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { collection, doc, orderBy, query, updateDoc } from "firebase/firestore";
import { QuotitHealthPlanDetails } from "services/Interfaces";
import { QuotitPlanConverter } from "services/Firestore/Plan";
import { useRemoteConfig } from "contexts/RemoteConfig";
import moment from "moment";
import Household from "./Household";
import InsuranceDetails from "./InsuranceDetails";
import { useFirebase } from "contexts/Firebase";
import RecommendedPlans from "./RecommendedPlans";


function FindYourNextPlanQuotit() {
    const { firestore } = useFirebase();
    const ideon = useRemoteConfig("ideon").asBoolean();
    //const [openPreferences, setOpenPreferences] = useState<boolean>(false);
    const [missingDetails, setMissingDetails] = useState<Array<string>>([]);
    const [householdOpen, setHouseholdOpen] = useState<boolean>(false);
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
    const [cobraCost, setCobraCost] = useState<number | undefined>()

    const quotitACA = useRemoteConfig("quotitACA").asBoolean();
    const { user, answers } = useAuth();
    // const theme = useTheme();
    // const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    // const navigate = useNavigate();
    //const { modalDispatch } = useContext(ModalContext);

    useEffect(() => {
        if (answers && answers.exists()) {
            // check if all required data is set
            let details = []
            const members = answers?.get("insuranceDetails.household.members");
            const insuranceDetails = answers?.get("insuranceDetails");

            if (members === undefined || Object.keys(members).length === 0) {
                // need to set household
                details.push("members");
            }
            if (insuranceDetails === undefined || insuranceDetails.county === undefined || insuranceDetails.income === undefined || insuranceDetails.type === undefined) {
                details.push("details");
            }
            setMissingDetails(details);
        }
    }, [answers])
    async function saveHousehold() {
        console.log("loadRates");
        // update quotit
        const effectiveDate = answers?.get("insuranceDetails.effectiveDate");
        loadRates({ variables: { effectiveDate, source: ideon ? "ideon" : "quotit" }, fetchPolicy: 'network-only' });
        setHouseholdOpen(false);
    }
    async function saveDetails() {
        const effectiveDate = answers?.get("insuranceDetails.effectiveDate");
        loadRates({ variables: { effectiveDate, source: ideon ? "ideon" : "quotit" }, fetchPolicy: 'network-only' });
        setDetailsOpen(false);
    }
    // function goto(uri: string) {
    //     navigate(uri);
    // }
    const [loadRates, rateQuery] = useLazyQuery<{ total: number }, { effectiveDate: string, source: string }>(Queries.getRates, {
        notifyOnNetworkStatusChange: true,
    });
    const [loadAiRecommendation, aiRecommendation] = useLazyQuery<{ aiScore: number }, { effectiveDate: string, planId: string }>(Queries.aiRecommendation, {
        notifyOnNetworkStatusChange: true,
    });
    //get plans from firestore users/uid/effectiveDate/date/plans
    // const [plans, plansLoading, plansError] = useCollection<QuotitHealthPlanDetails>(
    //     collection(firestore, `users/${user?.uid}/effectiveDate/${effectiveDate}/plans`).withConverter(QuotitPlanConverter)
    // );

    const [plans, plansLoading, plansError] = useCollection<QuotitHealthPlanDetails>(
        query<QuotitHealthPlanDetails>(
            collection(firestore, `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate")}/plans`).withConverter(QuotitPlanConverter),
            // where("contractStatus", "==", "Contracted"),
            orderBy("recommendation.internalScore", "desc"),
            // limit(3)
        )
        , {
            snapshotListenOptions: {
                // includeMetadataChanges: true
            },
        });

    const [userCobraPlan, ,] = useDocument(
        user
            ? doc(firestore, `users/${user?.uid}/settings/insurance`)
            : null,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );

    const [topPlans, setTopPlans] = useState<Array<QuotitHealthPlanDetails>>([]);
    useEffect(() => {
        if (plans && plans.docs.length === 0 && !plansLoading && !plansError) {
            //hit graphql to load plans
            console.log('no plans found, loading plans');
            // loadRates({
            //     variables: {
            //         effectiveDate: answers?.get("insuranceDetails?.effectiveDate")
            //     }
            // })
        }
        if (rateQuery.error) {
            console.error(rateQuery.error);
        }
        if (aiRecommendation.error) {
            console.error("ai recommendation error", aiRecommendation.error);
        }
    }, [loadAiRecommendation, loadRates, plans, plansLoading, rateQuery, aiRecommendation, plansError, answers]);

    useEffect(() => {
        const updateEffectiveDate = async () => {
            const effectiveDate = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
            await updateDoc(doc(firestore, `users/${user?.uid}/answers/${user?.uid}`), {
                "insuranceDetails.effectiveDate": effectiveDate
            });
            loadRates({ variables: { effectiveDate, source: ideon ? "ideon" : "quotit" }, fetchPolicy: 'network-only' });
        }
        if (answers && answers?.get("insuranceDetails.effectiveDate") && moment(answers?.get("insuranceDetails.effectiveDate")) < moment().endOf('month')) {
            // effective date is in the past make it the next month
            updateEffectiveDate();
        }
        setCobraCost(answers?.get("cobra")?.estimate ? answers?.get("cobra")?.estimate : 0)
    }, [answers, user, loadRates, firestore, ideon]);

    useEffect(() => {
        //for top 3 plans, check if disclaimers exist
        if (plans && plans.docs.length > 0) {
            //prioritize on exchange
            const exchangePlans =
                quotitACA ?
                    plans.docs.filter((plan) => plan.get('insuranceType') === "HealthOnExchange") :
                    [];
            //if no exchange plans, get off exchange
            if (exchangePlans.length > 3) {
                setTopPlans(exchangePlans.slice(0, 3).map((plan) => plan.data()));
            } else {
                setTopPlans(
                    plans.docs
                        .filter((plan) => plan.get('insuranceType') !== "HealthOnExchange")
                        .slice(0, 3)
                        .map((plan) => plan.data()));
            }
            // console.log("set disclaimers", plans.docs[0].get('disclaimer'), plans.docs[1].get('disclaimer'), plans.docs[2].get('disclaimer'));
            // addDisclaimer(plans.docs[0].get("carrierId"), plans.docs[0].get('disclaimer'));
            // addDisclaimer(plans.docs[1].get("carrierId"), plans.docs[1].get('disclaimer'));
            // addDisclaimer(plans.docs[2].get("carrierId"), plans.docs[2].get('disclaimer'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plans]);
    if (plansError) {
        return (
            <Box>
                <Typography>Error loading plans</Typography>
                <Typography>{JSON.stringify(plansError)}</Typography>
            </Box>
        )
    }
    if (missingDetails && missingDetails.length > 0) {
        return (
            <Box sx={{
            }}>
                <Box sx={{
                    textAlign: "center",
                    maxWidth: "540px",
                    mx: "auto"
                }}>
                    <Typography variant="h2" color="primary">Find your next <Box component={"span"} fontWeight={600}>plan</Box></Typography>
                    <Typography variant="body2">Your next Health Insurance Plan is just clicks away. Jamie, your Health Insurance Advocate, is able to help find insurance policies and can even help you enroll in certain policies!</Typography>
                </Box>
                <Box sx={{
                    border: "solid",
                    borderColor: "redOrange.main",
                    borderRadius: "10px",
                    p: 4
                }}>
                    <Typography>It looks like you are missing some key information to get health insurance plans.</Typography><br />
                    <Box sx={{
                        display: "flex",
                        gap: 2
                    }}>
                        {missingDetails.includes("members") &&
                            <Button onClick={() => { setHouseholdOpen(true) }} variant="contained" color="primary">Add Household Members</Button>
                        }
                        {missingDetails.includes("details") &&
                            <Button onClick={() => { setDetailsOpen(true) }} variant="contained" color="primary">Add Household Details</Button>
                        }
                    </Box>
                </Box>
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={householdOpen}
                    onClose={() => { setHouseholdOpen(false) }}
                >
                    <Card sx={{
                        p: 4
                    }}>
                        <Household cancel={setHouseholdOpen} saved={saveHousehold}></Household>
                    </Card>

                </Dialog>
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={detailsOpen}
                    onClose={() => { setDetailsOpen(false) }}
                >
                    <Card sx={{
                        p: 4
                    }}>
                        <InsuranceDetails cancel={setDetailsOpen} saved={saveDetails}></InsuranceDetails>
                    </Card>

                </Dialog>
            </Box >

        )
    }

    if ((!plans || !(plans.docs.length > 2)) && !userCobraPlan) {
        return (
            <Box>
                <Typography>Loading plans</Typography>
            </Box>
        )
    }
    console.log('plans loaded');
    return (
        <RecommendedPlans cobraCost={cobraCost} recommendedPlan={topPlans} plansLoading={plansLoading} />
        //     <Box sx={{
        //     display: "flex",
        //     flexDirection: "column",
        //     gap: 4,
        //     width: "100%",
        //     alignItems: "center"
        // }}>
        //     <Box sx={{
        //         textAlign: "center",
        //         maxWidth: "540px",
        //     }}>
        //         <Typography variant="h2" color="primary">Find your next <Box component={"span"} fontWeight={600}>plan</Box></Typography>
        //         <Typography variant="body2">Your next Health Insurance Plan is just clicks away. Jamie, your Health Insurance Advocate, is able to help find insurance policies and can even help you enroll in certain policies!</Typography>
        //     </Box>
        //     <Box sx={{
        //         position: "relative",
        //         minHeight: "700px",
        //         minWidth: "70%"
        //     }}>
        //         <Backdrop sx={{
        //             position: "absolute",
        //             zIndex: 100,
        //             height: "100%"
        //         }} open={plansLoading}>
        //             <CircularProgress color="brightOrange" />
        //         </Backdrop>
        //         {topPlans && topPlans.length > 0 && <Box sx={{
        //             display: "flex",
        //             flexDirection: { xs: "column", sm: "row" },
        //             gap: 4,
        //             justifyContent: "space-between",
        //             alignItems: "center"
        //         }}>
        //             <Box sx={{ height: "auto" }}><PlanSummaryCardQuotit plan={topPlans[1]} title=""></PlanSummaryCardQuotit></Box>
        //             <Box sx={{ height: "100%" }}><PlanSummaryCardQuotit variant="primary" plan={topPlans[0]} title="Highly Recommended"></PlanSummaryCardQuotit></Box>
        //             <Box sx={{ height: "auto" }}><PlanSummaryCardQuotit plan={topPlans[2]} title=""></PlanSummaryCardQuotit></Box>
        //         </Box>
        //         }
        //     </Box>
        //     <Box sx={{
        //         display: "flex",
        //         flexDirection: "column",
        //         alignItems: "center",
        //         gap: 1
        //     }}>

        //         {plans?.docs.length !== undefined &&
        //             <Typography variant="body2">Jamie found {plans.docs.length} Health Insurance options based on your preferences.</Typography>
        //         }
        //         <Button variant="text" onClick={() => { setOpenPreferences(true) }}>Click here to update your preferences</Button>
        //         {/* <Button variant="contained" color="primary" onClick={() => { goto("/account/insurance-plans") }}>See all your options</Button> */}
        //         <Button variant="contained" color="primary" onClick={() => {
        //             if (user?.isAnonymous) {
        //                 modalDispatch({ type: "createAccount" })
        //             } else {
        //                 goto("/account/insurance-plans")
        //             }
        //         }}>
        //             See all your options
        //             {user?.isAnonymous && <Lock sx={{ height: "16px", mt: "-4px" }} />}
        //         </Button>
        //     </Box>
        //     <Dialog
        //         open={openPreferences}
        //         onClose={() => { setOpenPreferences(false); }}
        //         fullScreen={isSm ? false : true}
        //         fullWidth
        //         maxWidth="lg"
        //         aria-labelledby="preferences-modal-title"
        //     >
        //         <PreferencesModal close={() => { setOpenPreferences(false); }}></PreferencesModal>
        //     </Dialog>
        // </Box>
    )
}
export default FindYourNextPlanQuotit;