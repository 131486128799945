import ETradeBaseTile from "./ETradeBaseTile";

function ETradeFinancialPlanningTile() {
    return (
      <ETradeBaseTile
        title="Investing with E*TRADE from Morgan Stanley may help you get where you want to go. "
        type="FinancialPlanning"
        imageUrl="/etrade-financial-planning.svg"
        body="Keep moving toward your financial goals with user-friendly tools, a full range of investment options, and $0 commissions for online US-listed stocks, options, ETFs, and mutual funds.
Other fees may apply."
        buttonText="Learn more"
        buttonUrl="https://us.etrade.com/what-we-offer?cid=pa_employercampaign_etwhatweoffer_when"
      />
    );
}

export default ETradeFinancialPlanningTile;