import { PaletteColorOptions, createTheme } from "@mui/material/styles";


declare module '@mui/material/styles' {
  interface CustomPalette {
    beige: PaletteColorOptions;
    white: PaletteColorOptions;
    black: PaletteColorOptions;
    brightOrange: PaletteColorOptions;
    redOrange: PaletteColorOptions;
    periwinkle: PaletteColorOptions;
    peach: PaletteColorOptions;
  }
  interface Palette extends CustomPalette { }
  interface PaletteOptions extends CustomPalette { }

  interface TypographyVariants {
    superscript: React.CSSProperties;
    "body1-bold": React.CSSProperties;
    "body2-bold": React.CSSProperties;
    footer: React.CSSProperties;
  }
  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    superscript?: React.CSSProperties;
    "body1-bold"?: React.CSSProperties;
    "body2-bold"?: React.CSSProperties;
    footer?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    superscript: true;
    "body1-bold": true;
    "body2-bold": true;
    footer: true;
  }

}
declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    white: true,
    brightOrange: true
  }
}
declare module '@mui/material/InputBase' {
  interface InputBasePropsColorOverrides {
    white: true,
  }
}

declare module '@mui/material/FormLabel' {
  interface FormLabelPropsColorOverrides {
    white: true
  }
}
declare module '@mui/material/FormControl' {
  interface FormControlPropsColorOverrides {
    white: true
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    navigation: true;
  }
  interface ButtonPropsColorOverrides {
    white: true;
  }
}

let theme = createTheme();
const { augmentColor } = theme.palette;
const createColor = (mainColor: string, name: string) => augmentColor({ color: { main: mainColor }, name });
theme = createTheme(theme, {
  shape: {
    borderRadius: 8,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'navigation' },
          style: () => {
            return {
              // background: `linear-gradient(-72.47deg,rgb(${theme.palette.primary.main}) 22.16%,rgba(var(--v-global-theme-primary),.7) 76.47%)!important`,
              borderRadius: "40px",
            }
          },

        },
      ],
      styleOverrides: {
        root: {
          // fontWeight: 600,
          // borderRadius: 40
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0 4px 5px -2px rgba(0, 0, 0, 0.05),0 2px 10px 1px rgba(0, 0, 0, 0.05),0 2px 16px 1px rgba(0, 0, 0, 0.05)",
        }
      }
    },
    MuiFormControl: {
      variants: [
        {
          props: { color: 'white' },
          style: {
            color: "#ffffff",
            "& .MuiSelect-icon": {
              color: "#ffffff"
            },
            '& .MuiInputLabel-root': {
              color: "#ffffff"
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: "#ffffff"
            },
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: "#ffffff"
            }
          }
        }
      ]
    },
    MuiSelect: {
      variants: [
        {
          props: { color: 'white' },
          style: {
            color: "#ffffff",
            "& .MuiSelect-icon": {
              color: "#ffffff"
            },
            '& .MuiInputBase-input': {
              borderColor: "#ffffff"
            },
          }
        }
      ]
    }
  },
  palette: {
    background: {
      default: "#F4F5FA"
    },
    primary: createColor('#194142', "primary"),
    white: createColor("#FFFFFF", "white"),
    secondary: createColor("#ff9100", "secondary"),
    gray: {
      main: '#707070',
    },
    beige: createColor('#fef2df', "beige"),
    black: {
      main: '#0f0f0f'
    },
    brightOrange: createColor("#ff9100", "brightOrange"),
    redOrange: createColor('#ff5a28', "redOrange"),
    periwinkle: createColor('#afc3ff', "periwinkle"),
    peach: createColor('#ffbe82', "peach"),

  },
  typography: {
    fontFamily: `Friends, sans-serif`,
    button: {
      textTransform: "none"
    },
    superscript: {
      fontSize: "50%",
      verticalAlign: "super",
    },
    body1: {
      color: "#4F4F4F"
    },
    "body1-bold": {
      fontWeight: 600,
      color: "#4F4F4F"
    },
    body2: {
      color: "black"
    },
    "body2-bold": {
      fontWeight: 600,
      color: "black"
    },
    h1: {
      color: "primary.main",
      fontSize: "2.5rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.5rem",
    },
    h4: {
      fontSize: "1.3rem"
    },
    h5: {
      fontSize: "1.2rem"
    },
    h6: {
      fontSize: "1.1rem"
    },
    footer: {
      color: "white"
    }
  }
});
export default theme;