/** @jsxImportSource @emotion/react */
import {
  Typography,
  Box,
  Card,
  Button,
  Grid,
  Dialog,
  Select,
  MenuItem,
  Chip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { Link, useLocation } from 'react-router-dom';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { ref as storageRef } from 'firebase/storage';

import { AttachMoney, Business, Cancel, Info, Person, Phone, Shield, TextSnippet, Work, WorkHistory } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { Company, HIPAAViewReasons, HIPAAHumanReasons, HIPAAViewReasonsType, Case, SeveranceBenefitType, CaseSeveranceBenefit, ReimbursementRequest, SharedStatus, CaseStatus } from "services/Interfaces";
import { TabContext, TabPanel } from "@mui/lab";
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { collection, collectionGroup, doc, DocumentReference, or, orderBy, query, QueryDocumentSnapshot, where } from "firebase/firestore";
import { CaseConverter, CaseSeveranceBenefitConverter, CompanyConverter } from "services/Firestore";
import { useFirebase } from "contexts/Firebase";
import moment from "moment";
import WhenIcon from "components/common/WhenIcon";
import { FormattedNumber } from "react-intl";
import { WhenBenefitConverter, WhenReimbursementRequestConverter } from "services/Firestore/User";
import InsuranceAdmin from "./CaseComponents/InsuranceAdmin";
import { useNotifications } from "contexts/Notification";
import { useAuth } from "contexts/User";
import AdminCaseEditDialog from "components/Admin/AdminCaseEditDialog";
import AdminSharedWithEmployeeDialog from "components/Admin/AdminSharedWithEmployeeDialog";
import AdjudicateRequest from "components/WhenBenefit/AdjudicateRequest";
import ReleaseBenefitDialog from "components/Company/ReleaseBenefitDialog";
import { Mutations, useMutation } from "services/apollo";

function CasePage({ caseIdIn, companyIdIn }: { caseIdIn?: string, companyIdIn?: string }) {
  const { hash } = useParams<{ hash: string }>();
  const { notifications } = useNotifications();
  const { user } = useAuth();
  const navigate = useNavigate();
  const caseId = caseIdIn || hash?.split("-").pop();
  const companyId = companyIdIn || hash?.split("-").slice(0, -1).join("-");
  console.log("CasePage", caseId, companyId);
  const { firestore } = useFirebase();
  const [caseDoc, setCaseDoc] = useState<DocumentReference<Case> | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [reasonDialogOpen, setReasonDialogOpen] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get('tab');
  const [releaseOpen, setReleaseOpen] = useState(false);
  const [reason, setReason] = useState<HIPAAViewReasonsType | "">("");
  const [noReasonSelectedMessage, setNoReasonSelectedMessage] = useState<string>("");
  const [setReleaseCaseBenefit] = useMutation(Mutations.releaseCaseBenefit);
  const [hasManagedHealth, setHasManagedHealth] = useState(false);
  const [whenBenefitSeverance, setWhenBenefitSeverance] = useState<CaseSeveranceBenefit | null>(null);

  const [getCase, caseAccess] = useLazyQuery(Queries.adminGetCase, {
    fetchPolicy: "network-only",
  })

  const [theCase, caseLoading, caseError] = useDocument<Case>(
    caseDoc,
    {
      snapshotListenOptions: { includeMetadataChanges: true, },
    });
  const [hipaaRecord, hippaRecordLoading,] = useDocument(
    doc(firestore, "hipaa", "access", "hipaa_cases", caseId || "", "hipaa_access", user?.uid || ""),
  );

  // get the case benefits
  const [caseBenefits, ,] = useCollection<CaseSeveranceBenefit>(
    companyId ? query(
      collection(firestore, `companies/${companyId}/cases/${caseId}/benefits`).withConverter(CaseSeveranceBenefitConverter),
      where("active", "!=", false)
    ) : null
    , {
      snapshotListenOptions: { includeMetadataChanges: true },
    });

  useEffect(() => {
    console.log("hipaaRecord", hipaaRecord?.data());
  }, [hipaaRecord])
  const [activity, activityLoading, activityError] = useCollection(
    theCase && theCase?.get("syncUser.id") ? query(
      collectionGroup(firestore, `activity`),
      or(
        where("caseId", "==", theCase.id),
        where("userId", "==", theCase?.get("syncUser.id")),
      ),
      orderBy("timestamp", "desc")
    ) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  )
  useEffect(() => {
    if (caseAccess.data?.adminGetCase && moment(caseAccess.data?.adminGetCase.expiration, 'x').isAfter(moment())) {
      // reload the page
      console.log("reload the page");
      setCaseDoc(doc(firestore, 'companies', caseAccess.data?.adminGetCase?.companyId,
        `cases`, caseAccess.data?.adminGetCase?.caseId).withConverter(CaseConverter))
      window.location.reload();
    } else if (caseId && companyId) {
      setCaseDoc(doc(firestore, 'companies', companyId,
        `cases`, caseId).withConverter(CaseConverter));
    }
  }, [
    caseAccess.data?.adminGetCase?.caseId,
    caseAccess.data?.adminGetCase?.companyId,
    firestore,
    caseId,
    companyId,
    caseAccess.data?.adminGetCase,
    caseError
  ]);

  useEffect(() => {
    console.log("theCase", theCase?.data(), theCase?.get("syncUser.id"), caseLoading, caseError, companyId, caseId, caseAccess.data, activityLoading, activityError)
  })
  const [company, ,] = useDocument<Company>(
    caseAccess?.data ? doc(firestore, `companies`, caseAccess?.data?.adminGetCase.companyId || "").withConverter(CompanyConverter) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    });
  const [dataTab, setDataTab] = useState(tab === "benefit" ? "benefit" : "activity"); // controls the page tab
  const createUser = async () => {
    if (theCase?.data()?.syncUser) {
      notifications.error("Case already has a user attached");
    } else {
      // open pop up to create user
    }
  }

  useEffect(() => {
    if (theCase?.data()?.sharedStatus === SharedStatus.SUCCESS) {
      const hasManagedHealth = caseBenefits?.docs.some((benefit) => {
        setWhenBenefitSeverance(benefit?.data());
        let type = benefit?.data()?.type;
        return type === SeveranceBenefitType.MANAGED_HEALTH;
      });
      setHasManagedHealth(!!hasManagedHealth)
    }
  }, [theCase, caseBenefits]);

  const setReleaseBenefit = async (values: boolean) => {
    if (!companyId || !caseId)
      return;
    setReleaseOpen(true);

    if (values === true) {
      try {
        const releaseSet = await setReleaseCaseBenefit({
          variables: {
            companyId: companyId,
            caseId: caseId
          }
        });

        if (releaseSet?.data?.releaseCaseBenefit) {
          setReleaseOpen(false);
          notifications.success("Benefit released successfully");
        } else {
          notifications.error("Benefit not released, something went wrong.");
        }
      } catch (error) {
        console.error(`Benefit not released, error: ${error}`);
      }
    }
  };

  const isCaseShared = theCase?.data()?.shared !== undefined;
  const toggleShareDialog = () => setShareDialogOpen(!shareDialogOpen);
  const toggleEditDialog = () => setEditDialogOpen(!editDialogOpen);

  const caseData = theCase?.data();
  const isV1Case = !!(caseData?.dataVersion && caseData?.dataVersion === "1.0");

  const userName = isV1Case ? `${caseData?.contact?.firstName} ${caseData?.contact?.lastName}` : caseData?.employee?.name;
  const userPhone = isV1Case ? caseData?.contact?.phone : caseData?.employee?.phone;
  const userEmail = isV1Case ? caseData?.contact?.email : caseData?.employee?.email;
  const userDateOfBirth = isV1Case ? caseData?.contact?.dateOfBirth : caseData?.employee?.dateOfBirth;
  const userStreet1 = isV1Case ? caseData?.contact?.address?.street1 : caseData?.employee?.home?.street1;
  const userStreet2 = isV1Case ? caseData?.contact?.address?.street2 : caseData?.employee?.home?.street2;
  const userCity = isV1Case ? caseData?.contact?.address?.city : caseData?.employee?.home?.city;
  const userState = isV1Case ? caseData?.contact?.address?.state : caseData?.employee?.home?.state;
  const userZip = isV1Case ? caseData?.contact?.address?.zip : caseData?.employee?.home?.zip;

  if (!caseId || !companyId || caseAccess.error) {
    return (
      <Box>
        <Typography variant="h1">Something went wrong</Typography>
        <Typography>{caseError?.message}</Typography>
        <Typography>{caseAccess?.error?.message}</Typography>
      </Box>
    )
  }
  if (hippaRecordLoading) {
    return <Box>
      <Typography>Loading HIPAA Record</Typography>
    </Box>
  }
  if (!hipaaRecord?.exists() || (hipaaRecord?.exists() && hipaaRecord?.get("expiration") && moment(hipaaRecord?.get("expiration")).isBefore(moment()))) {
    return (
      <Dialog maxWidth="md" fullWidth open={reasonDialogOpen}>
        <Card>
          <Box sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            position: "relative"
          }}>
            <Typography variant="h2">Please select your reason for viewing this case.</Typography>
            <Select value={reason} displayEmpty onChange={(e) => { setReason(e.target.value as HIPAAViewReasonsType) }}>
              <MenuItem disabled value={""} selected><em>Select a reason</em></MenuItem>
              {
                Object.keys(HIPAAViewReasons).map((key) => {
                  return <MenuItem key={key} value={key}>{HIPAAHumanReasons(key)}</MenuItem>
                })
              }

            </Select>
            {noReasonSelectedMessage && !reason && <Typography color="error">{noReasonSelectedMessage}</Typography>}
            <Button onClick={() => {
              if (reason) {
                getCase({ variables: { caseId, companyId, reason } })
              } else {
                setNoReasonSelectedMessage("Please select reason to view this case")
              }
            }
            }>Submit</Button>
            <Cancel onClick={() => setReasonDialogOpen(false)} color="inherit" sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              cursor: "pointer"
            }} />
          </Box>

        </Card>
      </Dialog>
    )
  }
  if (!theCase?.exists()) {
    return (
      <Box>
        <Typography variant="h1">Loading Case</Typography>
      </Box>
    )
  }
  return (
    <Grid
      container
      spacing={3}
      sx={{
        width: "100%",
      }}
    >
      <Grid item xs={12} lg={4}>
        <Card
          sx={{
            width: "100%",
            p: 2,
            pt: 4,
            mb: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          {company?.get("logo") && <Box
            component={"img"}
            src={company.get("logo")}
            width={"200px"}
            sx={{
              mx: "auto",
            }}
          ></Box>
          }
          {!company?.get("logo") && <Box sx={{

            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}>
            <Box
              sx={{
                backgroundColor: "periwinkle.main",
                display: "flex",
                mx: "auto",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.12),0px 2px 2px 0px rgba(0, 0, 0, 0.12),0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
              }}
            >
              <Work sx={{ color: "white.main" }} />
            </Box>
            <Typography textAlign={"center"} variant="h2">
              {userName}
            </Typography>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              flexWrap: "wrap",
            }}>
              <Chip
                label={<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}><Business /> <Typography>{theCase.get("companyId")}</Typography></Box>} />
              {theCase.data().childCompany?.name && <Chip label={<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}><Business /> <Typography>{theCase.data().childCompany?.name}</Typography></Box>} />}
              {theCase.data().syncUser && <Chip onClick={() => { navigate(`/admin/users/${theCase.data().syncUser?.id}`) }} label={<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}><Person />User Profile</Box>} />}
              {!theCase.data().syncUser && <Chip label={<Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}><Person />No User</Box>} />}
              <Chip
                label={<Box sx={{
                  color:
                    ["active", "open", "completed"].includes(theCase.get("status")) ? "success.main" : "error.main",
                  display: "flex", flexDirection: "row", alignItems: "center", gap: 1
                }}>
                  <Info />
                  <Typography>{theCase.get("status")}</Typography>
                </Box>} />
            </Box>
          </Box>
          }
          <Box sx={{
            py: 2
          }}>
            <Box sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1
            }}>
              <Typography><strong>Phone:</strong> {userPhone}</Typography>
              <Typography><strong>Contact Email:</strong> {userEmail}</Typography>
              <Typography><strong>Termination Date:</strong> {caseData?.terminationDate}</Typography>
              <Typography><strong>Last Day of Work:</strong> {caseData?.lastDay}</Typography>
              <Typography><strong>Reason:</strong> {caseData?.reason?.toLowerCase().replace(/\b(\w)/g, (s: any) => s.toUpperCase())}</Typography>
              <Typography><strong>Type:</strong> {caseData?.terminationType?.toLowerCase().replace(/\b(\w)/g, (s: any) => s.toUpperCase())}</Typography>
              <Typography><strong>Shared On: </strong>
                {theCase.get("shared")}
              </Typography>
              <Typography><strong>UID: </strong> {theCase.get("syncUser.id")}</Typography>
              <Typography><strong>Date of Birth: </strong> {userDateOfBirth}</Typography>
              <Typography><strong>Address: </strong> <br />{userStreet1}<br />
                {userStreet2 && <>{userStreet2}<br /></>}
                {userCity}, {userState} {userZip}
              </Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                <Button variant="contained" color="inherit" sx={{ mt: 2 }} onClick={toggleEditDialog}>
                  Edit Case
                </Button>
                <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={toggleShareDialog}>
                  {isCaseShared ? "Re-share Case" : "Share Case"}
                </Button>
                {theCase.data()?.sharedStatus === SharedStatus.SUCCESS && hasManagedHealth && !theCase.data()?.released && theCase.data()?.status !== CaseStatus.COMPLETED &&
                  <Button variant="contained" color="primary" sx={{ mt: 2 }}
                    onClick={() => { setReleaseOpen(true) }}>Release Benefit</Button>}
              </Box>
            </Box>
            <ReleaseBenefitDialog
              releaseOpen={releaseOpen}
              setReleaseOpen={setReleaseOpen}
              setReleaseBenefit={setReleaseBenefit}
            />
          </Box>
        </Card>
      </Grid>
      <TabContext
        value={dataTab}
      >
        <Grid item xs={12} sm={8}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1,
            pb: 2
          }}>
            <Button startIcon={<WorkHistory />} variant={dataTab === "activity" ? "contained" : undefined} sx={{ px: 2 }} onClick={() => { setDataTab("activity") }}>Activity</Button>
            <Button startIcon={<WhenIcon />} variant={dataTab === "benefit" ? "contained" : undefined} sx={{ px: 2 }} onClick={() => { setDataTab("benefit") }}>Benefit</Button>
            <Button startIcon={<AttachMoney />} variant={dataTab === "severance" ? "contained" : undefined} sx={{ px: 2 }} onClick={() => { setDataTab("severance") }}>Severance Packages</Button>
            <Button startIcon={<TextSnippet />} variant={dataTab === "documents" ? "contained" : undefined} sx={{ px: 2 }} onClick={() => { setDataTab("documents") }}>Documents</Button>
            <Button startIcon={<Shield />} variant={dataTab === "insurance" ? "contained" : undefined} sx={{ px: 2 }} onClick={() => { setDataTab("insurance") }}>Insurance</Button>
            <Button startIcon={<Phone />} variant={dataTab === "contact" ? "contained" : undefined} sx={{ px: 2 }} onClick={() => { setDataTab("contact") }}>Communications</Button>
          </Box>
          <AdminCaseEditDialog editCaseOpen={editDialogOpen} setEditCaseOpen={toggleEditDialog} theCase={theCase} caseId={theCase.data().id} />
          <AdminSharedWithEmployeeDialog sharedDialogOpen={shareDialogOpen} toggleSharedDialog={toggleShareDialog} theCase={theCase} caseId={theCase.data().id} isCaseShared={isCaseShared} />
          <TabPanel value="benefit" sx={{ p: 0 }}>
            <CaseBenefit theCase={theCase.data()} whenBenefitSeverance={whenBenefitSeverance} />
          </TabPanel>
          <TabPanel value="insurance" sx={{ p: 0 }}>
            {
              theCase.data().syncUser && <InsuranceAdmin theCase={theCase.data()} userId={theCase.data().syncUser!.id} />
            }
            {
              !theCase.data().syncUser && <Button variant="contained" color="primary" onClick={createUser}>Create User on behalf of customer</Button>
            }
          </TabPanel>
          <TabPanel value="documents" sx={{ p: 0 }}>
            <Typography>To be added</Typography>
          </TabPanel>
          <TabPanel value="severance" sx={{ p: 0, display: "flex", flexDirection: "column", gap: 4 }}>
            <Typography>To be added</Typography>
          </TabPanel>
          <TabPanel value="activity" sx={{ p: 0 }}>
            {activity?.docs?.map(act => {
              const data = act.data();
              if (data.timestamp && data.timestamp.toDate) {
                data.timestamp = moment(data.timestamp.toDate());
              } else if (data.timestamp && typeof data.timestamp === "string") {
                data.timestamp = moment(data.timestamp);
              }
              return data;
            }).sort((a, b) => b.timestamp.diff(a.timestamp))
              .map((activity) => {
                return (
                  <Accordion key={activity.id} sx={{ mb: 1 }}>
                    <AccordionSummary>
                      <Box>
                        <Typography variant="h3" color="primary">{activity.activity}</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}><Typography variant="subtitle1">user:  </Typography><UserName uid={activity.userId} /></Box>
                        <Typography variant="subtitle1">time: {activity.timestamp.format && activity.timestamp.format("MM/DD/YYYY H:mm:ss A")}</Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      {activity.message ?
                        <Typography variant="body1">message: {activity.message}</Typography> : null}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
          </TabPanel>
        </Grid>
      </TabContext>
    </Grid>
  );
}
function UserName({ uid }: { uid: string }) {
  const { firestore } = useFirebase();
  const [user, ,] = useDocument(
    doc(firestore, "users", uid)
  );
  if (!user || !user.get("name.display")) {
    return <Typography>{uid}</Typography>
  }
  return <Typography>{user.get("name.display")}</Typography>
}
function CaseBenefit({ theCase, whenBenefitSeverance }: { theCase: Case, whenBenefitSeverance: CaseSeveranceBenefit | null }) {

  const { firestore } = useFirebase();
  const { userClaims } = useAuth();

  const [userBenefit, ,] = useDocument(
    doc(firestore, "users", theCase.syncUser?.id || "0", "whenBenefits", theCase.id || "")
      .withConverter(WhenBenefitConverter),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );
  const [reimbursementRequests, ,] = useCollection(
    query(
      collection(firestore, "users", theCase.syncUser?.id || "0", "whenBenefits", theCase.id || "0", "reimbursementRequests"),
    ).withConverter(WhenReimbursementRequestConverter),
    { snapshotListenOptions: { includeMetadataChanges: true } }
  );
  const [bankInfo, bankInfoData] = useLazyQuery(Queries.getUserBankInfo);
  const showBankInfo = async () => {
    bankInfo({ variables: { userId: theCase.syncUser?.id || "" } })
  }

  if (!theCase) {
    return <Box>
      <Typography>Case not found</Typography>
    </Box>
  }
  if (!whenBenefitSeverance) {
    return <Box>
      <Typography>No When Benefit found</Typography>
    </Box>
  }
  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      gap: 2

    }}>
      <Card>
        <Box sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1

        }}>
          <Typography variant="h2">When Benefit Provided</Typography>
          <Typography>Amount: <FormattedNumber value={whenBenefitSeverance.amount} style={`currency`} currency="USD" /></Typography>
          <Typography>Active: {whenBenefitSeverance.active ? "true" : "false"}</Typography>
        </Box>
      </Card>
      <Card>
        <Box sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1

        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1

          }}>
            <Typography variant="h2">User&apos;s When Benefit</Typography>
            {userClaims.roles?.reimbursements?.editor && <Button variant="contained" color="primary" onClick={showBankInfo} fullWidth={false}>View Bank Info</Button>}
          </Box>
          {bankInfoData.data && <Typography>Bank Account Number: {bankInfoData.data?.getUserBankInfo?.accountNumber} </Typography>}
          {bankInfoData.data && <Typography>Bank Routing Number: {bankInfoData.data?.getUserBankInfo?.routingNumber} </Typography>}
          {bankInfoData.data && <Typography>Bank Name on Account: {bankInfoData.data?.getUserBankInfo?.nameOnAccount} </Typography>}
          {bankInfoData.error && <Typography>Bank Info: Not Set</Typography>}
          {!userBenefit?.exists() && <Typography>No When Benefit found</Typography>}
          {userBenefit?.exists() && <>
            <Typography>Max Benefit: <FormattedNumber value={userBenefit?.data()?.maxBenefit || -1} style={`currency`} currency="USD" /></Typography>
            <Typography>Remaining Benefit: <FormattedNumber value={userBenefit?.data()?.remainingBenefit || -1} style={`currency`} currency="USD" /></Typography>
            <Typography>Status: {userBenefit?.data()?.status}</Typography>
            <Typography>First request must be before: {userBenefit?.data()?.terminationDate.add(userBenefit?.data()?.time.firstRequest.value, userBenefit?.data()?.time.firstRequest.measure).format("MM/DD/YYYY")}</Typography>
            <Typography>Max time between requests: {userBenefit?.data()?.time.firstRequest.value} {userBenefit?.data()?.time.firstRequest.measure}</Typography>
          </>}
        </Box>
      </Card>
      <Typography variant="h2">Pending Reimbursement Requests</Typography>
      {reimbursementRequests?.docs
        ?.filter((request) => request.data().status === "Pending" || request.data().status === "Additional Information Requested")
        .map((request) =>
          <RequestFiles key={request.id} request={request} firstName={theCase?.contact?.firstName} />
        )
      }
      {reimbursementRequests?.docs
        ?.filter((request) => request.data().status === "Pending" || request.data().status === "Additional Information Requested").length === 0 &&
        <Typography>No pending requests</Typography>
      }
      <Typography variant="h2">Processed Reimbursement Requests</Typography>
      {reimbursementRequests?.docs
        ?.filter((request) => request.data().status !== "Pending" && request.data().status !== "Additional Information Requested")
        .map((request) =>
          <RequestFiles key={request.id} request={request} firstName={theCase?.contact?.firstName} />
        )
      }
      {reimbursementRequests?.docs
        ?.filter((request) => request.data().status !== "Pending" && request.data().status !== "Additional Information Requested").length === 0 &&
        <Typography>No processed requests</Typography>
      }
    </Box>
  );
}
function DownloadFile({ file }: { file: { name: string, type: string, path: string } }) {
  const { storage } = useFirebase();
  const [downloadURL, loading, error] = useDownloadURL(storageRef(storage, file.path));
  if (loading) {
    return <Typography variant="body1">{file.name}</Typography>
  }
  if (error) {
    return <Typography variant="body1">Error: {file.name}: {error.message}</Typography>
  }
  if (!downloadURL) return <Typography variant="body1">{file.name}</Typography>
  return (
    <Link to={downloadURL} target="_blank" download>{file.name}</Link>
  );
}
function RequestFiles({ request, firstName }: { request: QueryDocumentSnapshot<ReimbursementRequest>, firstName: string }) {
  const [showFiles, setShowFiles] = useState(false);
  const [adjudicateRequest, setAdjudicateRequest] = useState(false);

  function modifyToOID(firstCh: any, userId: any) {
    let modifiedID = userId.slice(0, 8);

    if (modifiedID.charAt(7) === '9') {
      modifiedID = modifiedID.substring(0, 7) + 'a' + modifiedID.substring(8);
    }
    if (modifiedID.charAt(7) === 'z') {
      modifiedID = modifiedID.substring(0, 7) + 'A' + modifiedID.substring(8);
    }
    if (modifiedID.slice(-1) === 'Z') {
      const secondToLastChar = modifiedID.charAt(6);
      const incrementedChar = String.fromCharCode(secondToLastChar.charCodeAt(0) + 1);
      modifiedID = modifiedID.substring(0, 6) + incrementedChar;
    }

    return `${firstCh}.${modifiedID}`;
  }
  return (
    <Card key={request.id}>
      <Box sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1
      }}>
        <Typography variant="h3">Request {request.id}</Typography>
        <Typography variant="h4">Coverage: {request.data().coverageStartDate?.format("MM/DD/YYYY")} - {request.data().coverageEndDate?.format("MM/DD/YYYY")}</Typography>
        <Typography>Amount: <FormattedNumber value={request.data().amount} style={`currency`} currency="USD" /></Typography>
        <Typography>Status: {request.data().status}</Typography>
        {
          (request.data().status === "Approved" ||
            request.data().status === "Partially Approved") &&
          <>
            {request.data()?.approvedBy && <Typography>Approved By: {request.data()?.approvedBy}</Typography>}
            {request.data()?.approvedOn && moment(request.data()?.approvedOn).isValid() && <Typography>Approved On: {request.data()?.approvedOn?.format("MM/DD/YYYY")}</Typography>}
            {request.data()?.approvedAmount && <Typography>Approved Amount: <FormattedNumber value={request.data().approvedAmount || -1} style={`currency`} currency="USD" /></Typography>}
          </>
        }
        <Typography>Files: <Button onClick={() => { setShowFiles(!showFiles) }}>{showFiles ? "Hide" : "Show"}</Button></Typography>
        <Box sx={{
          display: showFiles ? "flex" : "none",
          flexDirection: "column",
          gap: 1

        }}>
          {request?.data().files.map((file) => {
            return <DownloadFile key={file.name} file={file} />
          })}
        </Box>
        {
          (request.data().status === "Pending") &&
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2

          }}>
            <Button variant="contained" color="primary" onClick={() => { setAdjudicateRequest(true) }}>Review</Button>
          </Box>
        }
        {
          (request.data().status === "Additional info needed") &&
          <>
            <Button variant="contained" color="primary" onClick={() => setAdjudicateRequest(true)}>Make Decision</Button>
          </>
        }
      </Box>
      <Dialog open={adjudicateRequest} onClose={() => { setAdjudicateRequest(false) }}> {/* Render AdjudicateRequest dialog */}
        <Card sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          overflowY: "scroll",
        }}>
          <AdjudicateRequest reimbursementRequest={request.data()} onClose={() => { setAdjudicateRequest(false); }} modifyToOID={modifyToOID} firstName={firstName} />
        </Card>
      </Dialog>
    </Card>
  );
}
export default CasePage;