import { Button, CircularProgress, Divider, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FormattedNumber } from "react-intl";
import { useEffect, useState } from "react";
import { CobraPlan } from "services/Interfaces";
import { useAuth } from "contexts/User";
import { doc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useFirebase } from "contexts/Firebase";
import UpdateCobraPlanModal from "./UpdateCobraPlanModal";

function CobraPlanSummary(props: { plan?: any, isMobile?: boolean, userCobraPlanLoading: boolean }) {
  const [deductable, setInsuranceType] = useState<number>();
  const [outOfPocket, setOutOfPocket] = useState<number>();
  const [cobraPlan, setCobraPlan] = useState<CobraPlan | undefined>()
  const [cobraCost, setCobraCost] = useState<number>()
  const [openUpdatePlanModal, setOpenUpdatePlanModal] = useState<boolean>(false)
  const { firestore } = useFirebase();
  const { user } = useAuth();
  const userAnswersDoc = user ? doc(firestore, 'users', user?.uid!, "answers", user?.uid!) : null;
  const [answers, answersLoading] = useDocument(
    userAnswersDoc,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  useEffect(() => {
    if (!answersLoading) {
      setCobraCost(answers?.get("cobra")?.estimate)
      const insuranceType = answers?.get("insuranceDetails")?.type;
      const plan = cobraPlan?.cobra?.planComparison
      setInsuranceType(insuranceType === 'individual' ? plan?.individualDeductible : plan?.familyDeductible);
      setOutOfPocket(insuranceType === 'individual' ? plan?.individualOutOfPocketMaximum : plan?.familyOutOfPocketMaximum);
    }
  }, [answers, answersLoading, cobraPlan])

  useEffect(() => {
    setCobraPlan(props?.plan)
  }, [props.plan])
  if (!props.plan && !cobraCost) {
    return <></>
  }

  return (
    <>
      {
        props.userCobraPlanLoading ?
          <Box padding={2} justifyContent={'center'} display={'flex'}><CircularProgress color="primary" /> </Box>
          :
          (props.plan &&
            <>
              < Box sx={{ pt: 3 }
              }>
                <Typography variant="h2" fontWeight={600} color={"primary.main"}>
                  Your current plan
                </Typography>
                <Typography variant="h6" color="#000">
                  Your current monthly COBRA cost :
                  <Box component="span" fontWeight={600} sx={{ m: 1 }}>
                    <FormattedNumber style={`currency`} currency="USD" value={cobraCost ? cobraCost : 0} />
                  </Box>
                </Typography>
              </Box >
              <Paper
                sx={{
                  borderRadius: 2,
                  padding: 0,
                  border: '1px solid #DEDEDE',
                  background: '#fff',
                  boxShadow: 'none',
                  mt: 2,
                  overflowX: 'auto',
                }}
              >
                <Grid container sx={{ backgroundColor: props.isMobile ? '#fff' : '#F1F1F1', padding: props.isMobile ? 0 : 2 }} >
                  <Grid item xs={12} sm={2} padding={0} sx={{ minWidth: '20%' }}>
                    <Box
                      sx={{
                        overflowWrap: 'anywhere',
                        gap: 1,
                        padding: 2,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRight: props.isMobile ? 'none' : '1px solid #ddd',
                        borderBottom: !props.isMobile ? 'none' : '1px solid #ddd',
                        backgroundColor: props.isMobile ? 'primary.main' : '#F1F1F1'
                      }}
                    >
                      <Typography sx={{ color: props.isMobile ? '#ECECEC' : '#000', }} fontSize={14}>Name</Typography>
                      <Tooltip title={cobraPlan?.cobra?.planComparison?.name} arrow>
                        <Typography
                          sx={{
                            color: props.isMobile ? '#fff' : '#000',
                            fontSize: 16,
                            fontWeight: 600,
                            cursor: 'pointer',
                            display: '-webkit-box',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            whiteSpace: 'break-spaces',
                          }}
                        >
                          {cobraPlan?.cobra?.planComparison?.name}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} padding={0} sx={{ minWidth: '20%' }}>
                    <Box
                      sx={{
                        overflowWrap: 'anywhere',
                        gap: 1,
                        padding: 2,
                        height: '100%',
                        display: 'flex',
                        flexDirection: props.isMobile ? 'row' : 'column',
                        borderRight: !props.isMobile ? '1px solid #ddd' : 'none',
                        borderBottom: !props.isMobile ? 'none' : '1px solid #ddd',
                        backgroundColor: props.isMobile ? '#fff' : 'white', // Change background color for the first row in mobile view to red
                      }}
                    >
                      <Box width='100%' >
                        <Typography fontSize={14}>Plan Type</Typography>
                        <Typography fontSize={16} color="#000" fontWeight={600}>{cobraPlan?.cobra?.planComparison?.planNetworkType}</Typography>
                      </Box>  <Box width='100%' >
                        <Typography fontSize={14}>Insurance Type</Typography>
                        <Typography fontSize={16} color="#000" fontWeight={600}>COBRA</Typography>
                      </Box> </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} padding={0} sx={{ minWidth: '20%' }}>
                    <Box
                      sx={{
                        overflowWrap: 'anywhere',
                        gap: 1,
                        padding: 2,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRight: props.isMobile ? 'none' : '1px solid #ddd',
                        borderBottom: !props.isMobile ? 'none' : '1px solid #ddd',
                        backgroundColor: 'white',

                      }}
                    >
                      <Typography fontSize={14}>Monthly premium</Typography>
                      <Typography fontSize={16} color="#000" fontWeight={600}>
                        <FormattedNumber value={cobraCost ? cobraCost : 0} style={`currency`} currency="USD" />
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} padding={0} sx={{ minWidth: '20%' }}>
                    <Box
                      sx={{
                        overflowWrap: 'anywhere',
                        gap: 1,
                        padding: 2,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        borderBottom: !props.isMobile ? 'none' : '1px solid #ddd',
                        borderRight: !props.isMobile ? '1px solid #ddd' : 'none',
                        backgroundColor: 'white',

                      }}
                    >
                      <Typography fontSize={14}>Insurer</Typography>
                      <Typography fontSize={16} color="#000" fontWeight={600}>{cobraPlan?.cobra?.planComparison?.carrier}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2} padding={0} sx={{ minWidth: '20%' }}>
                    <Box
                      sx={{
                        overflowWrap: 'anywhere',
                        gap: 1,
                        padding: 2,
                        height: '100%',
                        display: 'flex',
                        flexDirection: props.isMobile ? 'row' : 'column',
                        // borderRight: !props.isMobile ? '1px solid #ddd' : 'none',
                        borderBottom: !props.isMobile ? 'none' : '1px solid #ddd',
                        backgroundColor: 'white',

                      }}
                    >
                      <Box width='100%' >
                        <Typography fontSize={14}>Deductible</Typography>
                        <Typography fontSize={16} color="#000" fontWeight={600}>
                          <FormattedNumber value={deductable ? deductable : 0} style={`currency`} currency="USD" />
                        </Typography>
                      </Box>  <Box width='100%' >
                        <Typography fontSize={14}>Max Out of Pocket</Typography>
                        <Typography fontSize={16} color="#000" fontWeight={600}>
                          <FormattedNumber value={outOfPocket ? outOfPocket : 0} style={`currency`} currency="USD" />
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Divider sx={{ width: '100%' }} />

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    p: '4px',
                    backgroundColor: "#FCFBFB",
                    justifyContent: "end",
                  }}
                >
                  <Button variant="outlined" onClick={() => setOpenUpdatePlanModal(true)}>Update Plan</Button>
                </Box>
                <UpdateCobraPlanModal openUpdateCobraPlan={openUpdatePlanModal} setOpenUpdateCobraPlan={setOpenUpdatePlanModal} setCobraPlan={setCobraPlan} />
              </Paper>
            </>)
      }
    </>
  )
}
export default CobraPlanSummary