/** @jsxImportSource @emotion/react */
import { AccountNavBar, Navbar, Footer, Debug } from "./";
import Routing from "../../Routing";
import { Box, Typography, IconButton, Dialog } from "@mui/material";
import { LoginForm } from "../User";
import { useAuth } from "../../contexts/User";
import { Suspense, useContext, useEffect } from "react";
import { ModalContext } from "../../contexts/Modal";
import { useRemoteConfigLoading, useRemoteConfig } from 'contexts/RemoteConfig';
import { CloseOutlined } from "@mui/icons-material";
import CreateAccount from "components/User/CreateAccount";

function Content() {
  const { modalState, modalDispatch } = useContext(ModalContext);
  const { loading, user, isAuthed } = useAuth();
  const debug = useRemoteConfig('debug').asBoolean();
  const remoteConfigLoading = useRemoteConfigLoading();
  useEffect(() => {
    //if query param debug is set, set debug to true
    // if (window.location.search.includes("debug")) {

    // }
    console.log("Content Rewrite", loading, user?.uid);
  }, [loading, user]);
  if (loading || remoteConfigLoading) {
    console.log("CONTENT LOADING SCREEN");
    return (
      <Box sx={{
      }}>
        <Typography>Loading</Typography>
      </Box>
    );
  }
  return (
    <Box>
      {isAuthed() && <AccountNavBar></AccountNavBar>}
      {!isAuthed() && <Navbar></Navbar>}
      <Box sx={{
        minHeight: "100vh",
      }}
        id="test">
        <Suspense fallback={<Typography>Loading...</Typography>}>
          <Routing />
        </Suspense>
      </Box>
      <footer>
        <Footer />
      </footer>
      {debug &&
        <Box sx={{
          pt: 8
        }}>
          <Debug />
        </Box>
      }
      <Dialog
        open={modalState.login}
        onClose={() => modalDispatch({ type: "login" })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <IconButton onClick={() => modalDispatch({ type: "login" })} sx={{
          position: "absolute",
          top: 0,
          right: 0
        }}>
          <CloseOutlined></CloseOutlined>
        </IconButton>
        <LoginForm></LoginForm>
      </Dialog>
      <Dialog
        open={modalState.createAccount}
        onClose={() => { modalDispatch({ type: "createAccount" }) }}
      >
        <IconButton onClick={() => modalDispatch({ type: "createAccount" })} sx={{
          position: "absolute",
          top: 0,
          right: 0
        }}></IconButton>
        <CreateAccount></CreateAccount>
      </Dialog>
    </Box>
  );
}
export default Content;
