import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function WhenIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path
                fill="inherit"
                d="m 0,13.666052 c 0,5.596722 4.8846442,10.168019 10.954641,10.333777 0.185283,0.0058 0.339014,-0.134878 0.339014,-0.304476 V 10.206739 C 11.350463,4.7083671 6.3121483,0.16591291 0.33897744,1.720824e-4 0.15369777,-0.00560958 0,0.13507734 0,0.3046726 Z"
            />
            <path
                fill="inherit"
                d="m 24,13.666052 c 0,5.596722 -4.884635,10.168019 -10.954641,10.333777 -0.185283,0.0058 -0.339014,-0.134878 -0.339014,-0.304476 V 10.206739 C 12.649537,4.7083671 17.687904,0.16591291 23.661073,1.720824e-4 23.846355,-0.00560958 24,0.13507734 24,0.3046726 Z"
            />
        </SvgIcon>

    )
}
export default WhenIcon;