/** @jsxImportSource @emotion/react */
import { Box, TextField, Button, Typography, Grid, Card, Container, CardContent, CardHeader, Dialog, Divider, ButtonBase, InputAdornment, IconButton } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import React from "react";
import { useAuth } from "../../contexts/User";
import { useLocation, useNavigate } from "react-router-dom";
import { ModalContext } from "../../contexts/Modal";
import mixpanel from 'mixpanel-browser';
import ForgotPassword from "./ForgotPassword";
import signInWithGoogleImage from "./btn_google_signin_light_normal_web.png";
import { addDoc, collection } from "firebase/firestore";
import { UserActivityTypes } from "services/Interfaces";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OtpVerificationDialog from "./loginOtpVerifyDialog";
import { useFirebase } from "contexts/Firebase";

function LoginForm(props: any) {
  const { firestore } = useFirebase();
  const { afterLogin } = props;
  let navigate = useNavigate();
  const { authed, login, loading, signInWithGoogle, generateOtpforSMSmfa } = useAuth();
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loginLoading, setLoading] = useState<boolean>(false);
  const onEmailChange = (e: any) => setEmail(e.target.value);
  const onPasswordChange = (e: any) => setPassword(e.target.value);
  const { modalState, modalDispatch } = useContext(ModalContext);
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [verificationId, setVerificationId] = useState<string>("");
  const [openOtpVerificationDialog, setOpenOtpVerificationDialog] = useState<boolean>(false);

  const [mfaError, setMfaError] = useState()
  interface CustomizedState {
    path: string
  }
  function defaultAfterLogin() {
    mixpanel.track("Login");
    //go to dashboard
    const state = location.state as CustomizedState; // Type Casting, then you can get the params passed via router
    console.log("login path redirect", state?.path);
    navigate(state?.path || "/account/dashboard");
    //if modal is open, close it
    if (modalState.login) {
      modalDispatch({ type: 'login' });
    }
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    if (email === "") {
      setError("Please enter your email");
      return;
    }
    if (password === "") {
      setError("Please enter your password");
      return;
    }
    login(email, password).then(async ({ user, userClaims }) => {
      afterLogin ? afterLogin() : defaultAfterLogin();
      try {
        const userActivityCollectionRef = collection(firestore, `users/${user.uid}/activity`);
        await addDoc(userActivityCollectionRef, {
          activity: UserActivityTypes.LOGGEDIN,
          type: "user",
          userId: user.uid,
          timestamp: new Date(),
        });
      } catch (error) {
        console.error("Error adding document:", error);
      }
    }).catch(err => {
      const errorCode = err.code;
      if (errorCode === 'auth/wrong-password' || errorCode === 'auth/user-not-found' || errorCode === 'auth/invalid-email') {
        setError("Invalid email or password");
      } if (errorCode === 'auth/too-many-requests') {
        setError("Too many login attempts, your account is temporarily locked. Please try again later");
      } else {
        setError("Please add valid Username and Password");
      }
      if (errorCode === "auth/multi-factor-auth-required") {
        setError("")
        generateOtpforSMSmfa(err)
          .then((result) => {
            if (result !== null && result !== undefined) {
              setVerificationId(result);
              setOpenOtpVerificationDialog(true);
              setMfaError(err)
            }
          })
          .catch((err) => {
            setError("An error occurred. Please try again later.");
          });

      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (authed) {
      navigate("/account/dashboard")
    }
  }, [authed, navigate])

  if (loading) {
    return (
      <Box></Box>
    );
  }
  return (
    <Container id="loginForm" maxWidth="lg" sx={{
      textAlign: "center",
      justifyContent: "center",
      py: { xs: 4 }
    }}>
      <Typography variant="body2">Protecting Your Access to Care</Typography>
      <Typography variant="h4" sx={{
        paddingBottom: "2rem",
      }}>Sign In to Your When Benefit Account</Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={5}>
          <Card color="primary" sx={{
            backgroundColor: "primary.main",
            color: "white.main",
            textAlign: "left",
          }}>
            <CardHeader title="Don't Have an Account?"></CardHeader>
            <CardContent sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3
            }}>
              <Typography color="white.main" variant="body1">Jamie, your Health Insurance Advocate, is built to find health insurance policies for people in every stage of their lives. Whether you fell of your parents plan, recently lost your job, or are considering joining the gig-economy. Jamie is able to help match you with the plans you need.</Typography>
              <Button id="getStarted" variant="contained" color="secondary" onClick={() => { navigate("/jamie") }}>Get Started With Jamie!</Button>
              {/* <br /><br />
              <Typography color="white.main" variant="body1">If you enrolled via your employer, click the link below to locate your employer policy and create your account.</Typography>
              <br />
              <Link underline="none" color="secondary" href="/lookup">Find My Employer Policy</Link> */}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <form onSubmit={handleSubmit}>
            <Box sx={{
              display: authed ? "none" : "flex",
              flexDirection: "column",
              gap: "1rem",
            }}>
              <TextField data-testid="emailTextField" id="email" color="secondary" sx={{ borderRadius: "10px" }} label="Email Address" onChange={onEmailChange} value={email} required></TextField>

              <TextField
                data-testid="passwordTextField"
                color="secondary"
                sx={{ borderRadius: "10px" }}
                label="Password"
                type={showPassword ? 'text' : 'password'}
                onChange={onPasswordChange}
                value={password}
                id="password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton data-testid="togglePasswordButton"
                        aria-label="toggle password visibility"
                        onClick={() => { setShowPassword(!showPassword); }}
                        onMouseDown={(e) => { e.preventDefault() }}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required />
              <Typography variant="caption" data-testid="signErrorMessage" color="error" >{error}</Typography>


              <Button variant="contained"
                data-testid="loginButton"
                id="loginButton"
                type="submit"
                sx={{
                  maxWidth: { sm: "220px" }
                }}
                color="secondary"
                disabled={loginLoading}>Sign In</Button>

              <Button variant="text"
                // id="forgotPasswordButton"
                data-testid="forgotPasswordButton"
                sx={{
                  maxWidth: { sm: "220px" }
                }}
                color="secondary"
                onClick={() => { setForgotPassword(true) }}
                disabled={loginLoading}>
                I forgot my password
              </Button>
              <Divider />
              <ButtonBase
                id="signInWithGoogleButton"
                onClick={() => {
                  signInWithGoogle(email).then(() => {
                    afterLogin ? afterLogin() : defaultAfterLogin();
                  })
                }}>
                <img src={signInWithGoogleImage} alt="Sign in with Google" />
              </ButtonBase>
            </Box>
          </form>
        </Grid>
      </Grid>
      <Dialog sx={{ px: 2 }} open={forgotPassword} onClose={() => setForgotPassword(false)}>
        <ForgotPassword close={() => { setForgotPassword(false) }} />
      </Dialog>
      {(verificationId !== "") && openOtpVerificationDialog &&
        <OtpVerificationDialog
          isOpen={openOtpVerificationDialog}
          onClose={() => { setOpenOtpVerificationDialog(false) }}
          verificationId={verificationId}
          mfaError={mfaError}
          afterLogin={afterLogin ? afterLogin : defaultAfterLogin} />}
    </Container>
  );
}
export default LoginForm;
