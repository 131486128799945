export const validateEmailFormat = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateZipCode = (zipCode: string) => {
  const zipCodePattern = /^\d{5}(-\d{4})?$/;
  return zipCodePattern.test(zipCode);
};

export const phonePattern = /^\+1\d{10}$/; // Validates a 10-digit phone number with +1 country code
