/** @jsxImportSource @emotion/react */
import { Container } from "@mui/material";
import { LoginForm } from "../components/User";



function Login() {

  return (
    <Container sx={{
      paddingTop: "53px",
    }}>
      <LoginForm />
    </Container>
  );
}
export default Login;
