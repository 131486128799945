import { doc, updateDoc } from "@firebase/firestore";
import { Box, Typography, Card, CardContent, Button, Backdrop, CircularProgress, Grid, Slider } from "@mui/material";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import { setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { Queries, useLazyQuery } from "services/apollo";

function PreferencesModal(props: { close: Function, saveOverride?: (preferences: any) => any, userId?: string }) {
  const { firestore } = useFirebase();
  const [loading, setLoading] = useState<boolean>(false);
  let [preferencesInitialized, setPreferencesInitialized] = useState<boolean>(false);


  const { user } = useAuth();

  const [reScorePlans] = useLazyQuery<{ total: number }, { effectiveDate: string, uid: string }>(Queries.reScorePlans, {
    notifyOnNetworkStatusChange: true,
  });
  const userAnswersDoc = user ? doc(firestore, 'users', props.userId || user?.uid!, "answers", props.userId || user?.uid!) : null;
  const [answers, ,] = useDocument(
    userAnswersDoc,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [preferences, setPreferences] = useState<any>();
  const userPreferencesDoc = user ? doc(firestore, 'users', props.userId || user?.uid!, "answers", "preferences") : null;
  const [userPreferences, preferencesLoading, preferencesError] = useDocument(
    userPreferencesDoc,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  useEffect(() => {
    console.log('preferences', userPreferences?.data())
    setPreferences(userPreferences?.data());
  }, [userPreferences]);

  async function close() {
    if (typeof props.saveOverride === "function") {
      await props.saveOverride(preferences);
    } else {
      try {
        await updateDoc(userPreferencesDoc!,
          preferences
        );
      } catch (err: any) {
        console.error(err);
      }
    }
    await reScore();
  }
  async function reScore() {
    //trigger a rescoring call
    try {
      setLoading(true);
      console.log("reScorePlans", answers?.get("insuranceDetails.effectivedate"), props.userId || user?.uid || "")
      await reScorePlans({
        variables: {
          effectiveDate: answers?.get("insuranceDetails.effectivedate"),
          uid: props.userId || user?.uid || ""
        },
        fetchPolicy: "network-only"
      })
    } catch (err) {
      //do nothing
    }
    props.close();
  }
  useEffect(() => {
    console.log("preferences", userPreferences?.exists(), userPreferencesDoc?.path);
    if (userPreferences?.exists() === false && userPreferencesDoc) {
      console.log("initiate preferences")
      if (preferencesInitialized === false) {
        setPreferencesInitialized(true);
        setDoc(userPreferencesDoc, {
          lowPremium: 0.5
        });
      }
    }
  }, [userPreferences, userPreferencesDoc, preferencesInitialized])
  useEffect(() => {
    if (preferencesError)
      console.error(preferencesError);
  }, [preferencesError])
  if (preferencesLoading || !userPreferences?.exists || !userPreferences || !preferences) {
    return (<></>);
  }
  return (
    <Box sx={{

    }}>
      <Card sx={{
        backgroundColor: "white.main",
        height: { xs: "100vh", sm: "auto" },
        p: 4,
      }}>
        <CardContent sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: "33px",
          padding: 0
        }}>

          <Box>
            <Typography id="preferences-modal-title" variant="h1" fontWeight={600} align="center" color={"primary.main"}>Health Plan Preferences</Typography>
            <Typography align="center">This information is used to provide health plan recommendations.</Typography>
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: 4
          }}>
            <Box width={"100%"} minHeight={"300px"} position={"relative"}>
              <Backdrop open={preferencesLoading || loading} sx={{ position: "absolute" }}>
                <CircularProgress color="brightOrange"></CircularProgress>
              </Backdrop>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                mx: "auto",
                maxWidth: "800px",
              }}>
                <Grid container rowGap={2}>
                  <Grid item xs={12} >
                    <Typography color={"primary"} variant="h2" fontWeight={600} align="center">What Type of Plans do you Prefer?</Typography>
                  </Grid>
                  <Grid item xs={3}>
                  </Grid>
                  <Grid item xs={9} sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}>
                    <Typography color={"primary"} fontWeight={600} align="left">Not Interested</Typography>
                    <Typography color={"primary"} fontWeight={600} align="center">Somewhat Interested</Typography>
                    <Typography color={"primary"} fontWeight={600} align="right">Strongly Prefer</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color={"primary"} fontWeight={600} px={2}>HMO</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Slider
                      aria-label="hmo plan type"
                      defaultValue={0.5}
                      value={preferences.planType?.hmo || 0.5}
                      onChange={(e, v) => {
                        console.log("update", v)
                        setPreferences({
                          ...preferences, planType: { ...preferences.planType, hmo: v }
                        })
                      }}
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color={"primary"} fontWeight={600} px={2}>PPO</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Slider
                      aria-label="ppo plan type"
                      defaultValue={0.5}
                      value={preferences && preferences.planType?.ppo !== undefined ? preferences.planType?.ppo : 0.5}
                      onChange={(e, v) => {
                        setPreferences({
                          ...preferences, planType: { ...preferences.planType, ppo: v }
                        })
                      }}
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color={"primary"} fontWeight={600} px={2}>Short Term Medical</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Slider
                      aria-label="short term plan type"
                      defaultValue={0.5}
                      value={preferences.planType?.shortterm || 0.5}
                      onChange={(e, v) => {
                        setPreferences({
                          ...preferences, planType: { ...preferences.planType, shortterm: v }
                        })
                      }}
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <Typography color={"primary"} variant="h2" fontWeight={600} align="center">How important are these to you?</Typography>
                  </Grid>
                  <Grid item xs={3}>
                  </Grid>
                  <Grid item xs={9} sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}>
                    <Typography color={"primary"} fontWeight={600} align="left">Not Important</Typography>
                    <Typography color={"primary"} fontWeight={600} align="center">Indifferent</Typography>
                    <Typography color={"primary"} fontWeight={600} align="right">Very Important</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color={"primary"} fontWeight={600} px={2}>Low Premiums</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Slider
                      aria-label="low premiums"
                      defaultValue={0.5}
                      value={preferences?.lowPremium || 0.5}
                      onChange={(e, v) => {
                        setPreferences({
                          ...preferences, lowPremium: v
                        })
                      }}
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color={"primary"} fontWeight={600} px={2}>Low Deductible</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Slider
                      aria-label="low deductible"
                      defaultValue={0.5}
                      value={preferences?.lowDeductible || 0.5}
                      onChange={(e, v) => {
                        setPreferences({
                          ...preferences, lowDeductible: v
                        })
                      }}
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color={"primary"} fontWeight={600} px={2}>Comprehensive Coverage</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Slider
                      aria-label="comprehensive coverage"
                      defaultValue={0.5}
                      value={preferences?.comprehensiveCoverage || 0.5}
                      onChange={(e, v) => {
                        setPreferences({
                          ...preferences, comprehensiveCoverage: v
                        })
                      }}
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color={"primary"} fontWeight={600} px={2}>Network Size</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Slider
                      aria-label="network size"
                      defaultValue={0.5}
                      value={preferences?.networkSize || 0.5}
                      onChange={(e, v) => {
                        setPreferences({
                          ...preferences, networkSize: v
                        })
                      }}
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color={"primary"} fontWeight={600} px={2}>Mental Health</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Slider
                      aria-label="mental health"
                      defaultValue={0.5}
                      value={preferences?.mentalHealth || 0.5}
                      onChange={(e, v) => {
                        setPreferences({
                          ...preferences, mentalHealth: v
                        })
                      }}
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color={"primary"} fontWeight={600} px={2}>Dental</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Slider
                      aria-label="dental"
                      defaultValue={0.5}
                      value={preferences?.dental || 0.5}
                      onChange={(e, v) => {
                        setPreferences({
                          ...preferences, dental: v
                        })
                      }}
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography color={"primary"} fontWeight={600} px={2}>Vision</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Slider
                      aria-label="vision"
                      defaultValue={0.5}
                      value={preferences?.vision || 0.5}
                      onChange={(e, v) => {
                        setPreferences({
                          ...preferences, vision: v
                        })
                      }}
                      step={0.1}
                      marks
                      min={0}
                      max={1}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box sx={{ mx: "auto" }}>
            <Button variant="outlined" disabled={loading} onClick={close}>Close</Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
export default PreferencesModal;