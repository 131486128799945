import React from "react"
import { reducer, initialState } from "./reducer"

export const ModalContext = React.createContext<any>({
  modalState: initialState,
  modalDispatch: () => {},
})

export const ModalProvider = ({ children }:{children:any}) => {
  const [modalState, modalDispatch] = React.useReducer(reducer, initialState)

  return (
    <ModalContext.Provider value={ {modalState, modalDispatch }}>
      { children }
    </ModalContext.Provider>
  )
}