import { Typography, Backdrop, CircularProgress, Button, Dialog, useMediaQuery } from "@mui/material"
import { Box, useTheme } from "@mui/system"
import PlanSummaryCard from "./PlanSummaryCard"
import { useQuery } from "@apollo/client";
import { Interfaces, Queries } from "services/apollo";
import PreferencesModal from "./PreferencesModal";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "contexts/User";
import { ModalContext } from "contexts/Modal";
import { Lock } from "@mui/icons-material";


function FindYourNextPlan(props: { refreshPlans: number }) {
    const [openPreferences, setOpenPreferences] = useState<boolean>(false);
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const navigate = useNavigate();
    const { user } = useAuth();
    const { modalDispatch } = useContext(ModalContext);
    function goto(uri: string) {
        navigate(uri);
    }
    const searchResults = useQuery<{ recommendPlans: Interfaces.RecommendPlansData }, Interfaces.RecommendPlansVariables>(Queries.recommendPlans, {
        variables: {
        },
        notifyOnNetworkStatusChange: true,
    });
    useEffect(() => {
        if (props.refreshPlans) {
            searchResults.refetch();
        }
    }, [props.refreshPlans, searchResults]);
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            width: "100%",
            alignItems: "center"
        }}>
            <Box sx={{
                textAlign: "center",
                maxWidth: "540px",
            }}>
                <Typography variant="h2" color="primary">Find your next <Box component={"span"} fontWeight={600}>plan</Box></Typography>
                <Typography>Your next Health Insurance Plan is just clicks away. Jamie, your Health Insurance Advocate, is able to help find insurance policies and can even help you enroll in certain policies!</Typography>
            </Box>
            <Box sx={{
                position: "relative",
                minHeight: "700px",
                minWidth: "70%"
            }}>
                <Backdrop sx={{
                    position: "absolute",
                    zIndex: 100,
                    height: "100%"
                }} open={searchResults.loading}>
                    <CircularProgress color="brightOrange" />
                </Backdrop>
                {searchResults?.data?.recommendPlans.total && <Box sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 4,
                    justifyContent: "space-between",
                    alignItems: "center"
                }}>
                    <Box sx={{ height: "auto" }}><PlanSummaryCard plan={searchResults?.data?.recommendPlans.plans.lowestDeductible} title="Lowest Deductible"></PlanSummaryCard></Box>
                    <Box sx={{ height: "100%" }}><PlanSummaryCard variant="primary" plan={searchResults?.data?.recommendPlans.plans.lowestOutOfPocket} title="Most Affordable"></PlanSummaryCard></Box>
                    <Box sx={{ height: "auto" }}><PlanSummaryCard plan={searchResults?.data?.recommendPlans.plans.lowestPremium} title="Lowest Premium"></PlanSummaryCard></Box>
                </Box>}
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1
            }}>

                {searchResults?.data?.recommendPlans?.total !== undefined &&
                    <Typography>Jamie found {searchResults?.data?.recommendPlans.total} Health Insurance options based on your preferences.</Typography>
                }
                <Button variant="text" onClick={() => { setOpenPreferences(true) }}>Click here to update your preferences</Button>
                {/* <Button variant="contained" color="primary" onClick={() => { goto("/account/insurance-plans") }}>See all your options</Button> */}
                <Button variant="contained" color="primary" onClick={() => {
                    if (user?.isAnonymous) {
                        modalDispatch({ type: "createAccount" })
                    } else {
                        goto("/account/insurance-plans")
                    }
                }}>
                    See all your options
                    {user?.isAnonymous && <Lock sx={{ height: "16px", mt: "-4px" }} />}
                </Button>
            </Box>
            <Dialog
                open={openPreferences}
                onClose={() => { searchResults.refetch(); setOpenPreferences(false); }}
                fullScreen={isSm ? false : true}
                fullWidth
                maxWidth="lg"
            >
                <PreferencesModal close={() => { searchResults.refetch(); setOpenPreferences(false); }}></PreferencesModal>
            </Dialog>
        </Box>
    )
}
export default FindYourNextPlan;