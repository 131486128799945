import { createContext, useContext, useEffect, useState } from 'react';
import { FirebaseApp, initializeApp } from "firebase/app";
import { connectFirestoreEmulator, initializeFirestore, FirestoreSettings, Firestore } from "firebase/firestore";
import { Auth, connectAuthEmulator, getAuth } from "firebase/auth";
import { FirebasePerformance, getPerformance } from "firebase/performance";
import { FirebaseStorage, connectStorageEmulator, getStorage } from "firebase/storage";
import { AppCheck, initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from "firebase/app-check";
import { FirebaseError } from 'firebase/app';

const FirebaseContext = createContext<{
  loaded: boolean,
  app?: FirebaseApp,
  appCheck?: AppCheck,
  auth?: Auth,
  firestore?: Firestore,
  perf?: FirebasePerformance,
  storage?: FirebaseStorage,
  error?: string,
  refreshAppCheckToken?: () => Promise<void>
}>({
  loaded: false,
});

declare global {
  interface Window {
    env: any
  }
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}


export const FirebaseProvider = ({ children }: { children: any }) => {

  const [loaded, setLoaded] = useState<boolean>(false);
  const [app, setApp] = useState<FirebaseApp>();
  const [appCheck, setAppCheck] = useState<AppCheck>();
  const [auth, setAuth] = useState<Auth>();
  const [firestore, setFirestore] = useState<Firestore>();
  const [perf, setPerf] = useState<FirebasePerformance>();
  const [storage, setStorage] = useState<FirebaseStorage>();
  const [error, setError] = useState<string | undefined>();
  const refreshAppCheckToken = async () => {
    if (!appCheck) return;
    try {
      const tokenResult = await getToken(appCheck, /* forceRefresh= */ true);
      console.log("New App Check token generated:", tokenResult.token);
    } catch (error) {
      console.error("Failed to regenerate App Check token:", error);
      setError("Failed to regenerate App Check token");
    }
  };

  const handleFirebaseError = (error: FirebaseError) => {
    if (error.code === "auth/firebase-app-check-token-is-invalid") {
      console.error("Firebase App Check token is invalid. Regenerating...");
      refreshAppCheckToken().catch(err => {
        console.error("Failed to refresh App Check token:", err);
        setError("Firebase App Check token is invalid. Please try again.");
      });
    } else {
      console.error("Firebase error:", error);
      setError(error.message);
    }
  };

  useEffect(() => {
    if (app || loaded)
      return;
    console.log("configure firebase")
    if (window?.env?.configLoaded === true) {
      try {
        console.log(window.env);
        //configure firebase
        const firebaseConfig = {
          apiKey: window.env.REACT_APP_FIREBASE_KEY,
          authDomain: window.env.REACT_APP_FIREBASE_DOMAIN.startsWith('https://') ? window.env.REACT_APP_FIREBASE_DOMAIN.substring(8) : window.env.REACT_APP_FIREBASE_DOMAIN,
          projectId: window.env.REACT_APP_FIREBASE_PROJECT_ID,
          storageBucket: window.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
          messagingSenderId: window.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
          appId: window.env.REACT_APP_FIREBASE_APP_ID,
          measurementId: window.env.REACT_APP_FIREBASE_MEASUREMENT_ID
        };
        // Initialize Firebase
        const newApp = initializeApp(firebaseConfig);
        setApp(newApp);

        //initialize auth
        const auth = getAuth(newApp);
        setAuth(auth);

        //initialize firestore
        let firestoreSettings: FirestoreSettings = {
          experimentalForceLongPolling: true,
          ignoreUndefinedProperties: true
        };
        window.FIREBASE_APPCHECK_DEBUG_TOKEN = window.env.FIREBASE_APPCHECK_DEBUG_TOKEN;
        const appCheck = initializeAppCheck(newApp, {
          provider: new ReCaptchaEnterpriseProvider(window.env.RECAPTCHA_ENTERPRICE_SITE_KEY || ''),
          isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
        });
        setAppCheck(appCheck);
        if (window.env.NODE_ENV !== 'production' || window.env.REACT_APP_FIREBASE_EMULATE) {
          //this is to fix cypress tests, they capture network requests
          //in a way that breaks firestores constant connection
          // firestoreSettings.experimentalAutoDetectLongPolling = true;

          firestoreSettings.experimentalForceLongPolling = true;
        }
        const firestore = initializeFirestore(newApp, firestoreSettings);
        setFirestore(firestore);
        let perf;
        const storage = getStorage(newApp)

        // handle emulator environment differently
        if (process.env.NODE_ENV === 'production' && !window.env.REACT_APP_FIREBASE_EMULATE) {
          perf = getPerformance(newApp);
        } else if (window.env.REACT_APP_FIREBASE_EMULATE) {
          console.log("emulating firebase");
          console.log(window.env);
          connectFirestoreEmulator(firestore, 'localhost', 8080);
          connectStorageEmulator(storage, 'localhost', 9199);
          connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
        }

        setPerf(perf);
        setStorage(storage);
        setLoaded(true)
      } catch (err) {
        console.error("error configuring firebase", err)
        if (err instanceof FirebaseError) {
          handleFirebaseError(err);
        } else {
          console.error("error configuring firebase:", err);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app, loaded]);


  if (!loaded) {
    return <div>Loading...</div>
  }
  return (
    <FirebaseContext.Provider value={{ loaded, app, appCheck, auth, perf, storage, firestore, error, refreshAppCheckToken }}>
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {children}
    </FirebaseContext.Provider>
  );
};


export const useFirebase = (): {
  loaded: boolean,
  app: FirebaseApp,
  auth: Auth,
  firestore: Firestore,
  perf: FirebasePerformance,
  storage: FirebaseStorage,
  error?: string,
  refreshAppCheckToken: () => Promise<void>
} => {
  const context = useContext(FirebaseContext);
  return {
    loaded: context.loaded,
    app: context.app!,
    auth: context.auth!,
    firestore: context.firestore!,
    perf: context.perf!,
    storage: context.storage!,
    error: context.error,
    refreshAppCheckToken: context.refreshAppCheckToken!
  };
}