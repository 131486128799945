export const reducer = (state: any, action: any) => {
  // console.log("disclaimer context", action);
  switch (action.type) {
    case "addDisclaimer": {
      state = {
        ...state,
        [action.carrierId]: action.disclaimer
      }
      // console.log("new state", state)
      return state;
    }
    case "clearDisclaimers":
      return {
      }
    case "getDisclaimers":
      return Object.keys(state).map((key: string) => {
        return state[key];
      })
    case "removeDisclaimer":
      return {
        ...state,
        [action.carrierId]: null
      }
    default:
      return state
  }
}

export const initialState = {

}