import { Button, Dialog, Divider, IconButton, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { Box } from "@mui/system"

const CancelReimbursementDialog = ({ openCancelReimbursement, setOpenCancelReimbursement, cancelReimbursement }: { openCancelReimbursement: boolean, setOpenCancelReimbursement: Function, cancelReimbursement: Function }) => {
  return (
    <>
      <Dialog open={openCancelReimbursement} onClose={() => { setOpenCancelReimbursement(false) }}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          margin: 3,
        }}>
          <Typography variant="h3" sx={{ pb: 2, fontSize: { xs: "1.2rem", sm: "1.4rem", ms: "1.8rem" }, width: "85%" }}>Cancel Reimbursement Request</Typography>
          <IconButton sx={{ position: 'absolute', right: '30px', color: 'primary', p: 0, width: "15%" }} onClick={() => { setOpenCancelReimbursement(false) }}>
            <CloseIcon />
          </IconButton>
          <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
        </Box>
        <Box sx={{ marginX: { xs: 1, ms: 2 }, p: { xs: 1, sm: 2 } }}>
          <Typography>Cancelling this request will stop it from processing. Are you sure you want to cancel this reimbursement request?</Typography>
        </Box>
        <Box sx={{ marginX: { xs: 1, ms: 2 }, marginY: { xs: 1.5, sm: 3 }, p: { xs: 1, ms: 2 }, display: "flex", justifyContent: 'space-between' }}>
          <Button variant="contained" color="primary" sx={{ width: "45%", padding: { xs: 1, sm: 1.5 }, fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "0.875rem" } }} onClick={() => { cancelReimbursement() }} >Yes, cancel request</Button>
          <Button variant="contained" color="secondary" sx={{ width: "45%", padding: { xs: 1, sm: 1.5 }, fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "0.875rem" } }} onClick={() => { setOpenCancelReimbursement(false) }}>No, keep request</Button>
        </Box>
      </Dialog>
    </>
  )
}
export default CancelReimbursementDialog