/** @jsxImportSource @emotion/react */
import { Navbar, Debug } from "./";
import Routing from "../../Routing";
import { Box, Typography, Modal, Paper, IconButton, Dialog, Button, Collapse, Drawer, useMediaQuery, useTheme, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { LoginForm } from "../User";
import { useAuth } from "../../contexts/User";
import { Suspense, useContext, useEffect, useState } from "react";
import { ModalContext } from "../../contexts/Modal";
import { useRemoteConfigLoading, useRemoteConfig } from 'contexts/RemoteConfig';
import { ArrowDropDown, CircleOutlined, Close, CloseOutlined, Home, Person, Settings, Task, Work } from "@mui/icons-material";
import CreateAccount from "components/User/CreateAccount";
import { useCompany } from "contexts/Company";
import { useNavigate } from "react-router-dom";
import CompanyAppBar from "./CompanyAppBar";

const drawerWidth = "260px";
function CompanyContent() {
  const navigate = useNavigate();
  const [expandSettings, setExpandSettings] = useState(false);
  const { modalState, modalDispatch } = useContext(ModalContext);
  const { company, setCompanyId, companies } = useCompany();
  const { loading, user, isAuthed } = useAuth();
  const debug = useRemoteConfig('debug').asBoolean();
  const remoteConfigLoading = useRemoteConfigLoading();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    //if query param debug is set, set debug to true
    // if (window.location.search.includes("debug")) {

    // }
    console.log("Content Rewrite", loading, user?.uid, remoteConfigLoading, company?.get("name"));
  }, [loading, user, remoteConfigLoading, company]);
  if (loading || remoteConfigLoading || !company) {
    console.log("company CONTENT LOADING SCREEN", loading, remoteConfigLoading, company);
    return (
      <Box sx={{
      }}>
        <Typography>Loading</Typography>
        {!loading && <Routing />}
      </Box>
    );
  }
  console.log("company CONTENT", user)
  return (
    <Box sx={{ display: "flex" }}>

      {!isAuthed() && <Navbar></Navbar>}
      <Drawer

        open={modalState.navigation}
        onClose={() => { modalDispatch({ type: "navigation" }) }}
        variant={!isMd ? "temporary" : "permanent"}
        sx={{
          "& .MuiDrawer-paper": {
            borderWidth: 0,
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: "primary.main",
          },
          position: "relative",
          width: drawerWidth,
        }}>
        <Box sx={{
          width: drawerWidth,
          minHeight: "100vh",
          height: "auto",
          backgroundColor: "primary.main",
          display: "flex",
          flexDirection: "column",
          px: 2,
          py: 2,
          gap: 0.5,
          '&::-webkit-scrollbar': {
            width: '2.4em'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
          }
        }}>
          {!isMd &&
            <IconButton sx={{
              position: "absolute",
              top: 2,
              right: 8,
              zIndex: 100
            }}
              onClick={() => { modalDispatch({ type: "navigation" }) }}
              id="nav-close"
            >
              <Close />
            </IconButton>
          }
          <Box sx={{
            pt: 1,
            pb: 2
          }}>
            <img id="main-logo" src="/when-logo-reversed.png" width="120px" alt="When" />
          </Box>
          {/* <Typography sx={{ mb: 2, borderBottom: "1px solid", borderColor: 'primary.main' }} color={"white.main"} variant="h4" align="left">{company.get("name")}</Typography> */}
          <Button startIcon={<Home sx={{ height: 16 }} />} color="white" variant={window.location.pathname === "/company" ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname === "/company" ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/company") }}>Dashboard</Button>
          <Button startIcon={<Task sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/company/tasks") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/company/tasks") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/company/tasks") }}>Tasks</Button>
          <Button startIcon={<Person sx={{ height: 16 }} />} data-testid="companyEmployeesNavigationButton" color="white" variant={window.location.pathname.startsWith("/company/employees") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/company/employees") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/company/employees") }}>Employees</Button>
          <Button startIcon={<Work sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/company/cases") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/company/cases") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("/company/cases") }}>Cases</Button>
          {/* <Button>Reports</Button> */}
          <Button startIcon={<Settings sx={{ height: 16 }} />} color="white" data-testid="companySettingNavigationButton" variant={window.location.pathname.startsWith("/company/settings") ? "contained" : undefined} sx={{ justifyContent: "start", backgroundColor: window.location.pathname.startsWith("/company/settings") ? "rgba(0, 0, 0, .2)" : undefined, color: window.location.pathname.startsWith("/company/settings") ? "white.main" : undefined, borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { setExpandSettings(!expandSettings) }}>Settings <ArrowDropDown></ArrowDropDown></Button>
          <Collapse in={expandSettings}>
            <Box sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1
            }}>
              <Button startIcon={<CircleOutlined sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/company/settings/users") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/company/settings/users") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("company/settings/users") }}>Users</Button>
              <Button startIcon={<CircleOutlined sx={{ height: 16 }} />} color="white" data-testid="checkListNavigation" variant={window.location.pathname.startsWith("/company/settings/checklists") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/company/settings/checklists") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("company/settings/checklists") }}>Checklists</Button>
              <Button startIcon={<CircleOutlined sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/company/settings/documents") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/company/settings/documents") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("company/settings/documents") }}>Documents</Button>
              <Button startIcon={<CircleOutlined sx={{ height: 16 }} />} color="white" data-testid="companySettingsSeveranceNavigationButton" variant={window.location.pathname.startsWith("/company/settings/severance") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/company/settings/severance") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("company/settings/severance-packages") }}>Severance Packages</Button>
              <Button startIcon={<CircleOutlined sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/company/settings/benefits") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/company/settings/benefits") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("company/settings/benefits") }}>Benefits</Button>
              <Button startIcon={<CircleOutlined sx={{ height: 16 }} />} color="white" variant={window.location.pathname.startsWith("/company/settings/system") ? "contained" : undefined} sx={{ justifyContent: "start", background: window.location.pathname.startsWith("/company/settings/system") ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined, color: "white.main", borderRadius: 40, pl: 8, ml: -6 }} size="large" onClick={() => { navigate("company/settings/system") }}>System</Button>
            </Box>
          </Collapse>
          {companies && companies.length > 1 &&
            <Box sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 1,
              mt: "auto"
            }}>
              <FormControl color="white">
                <InputLabel id="roles-label">Company</InputLabel>
                <Select color="white" labelId="roles-label" label="Company" value={company.id} onChange={(e) => {
                  setCompanyId(e.target.value)
                }}>
                  {companies &&
                    companies.map((company) => {
                      return (
                        <MenuItem key={company.id} value={company.id}>{company.data().name}</MenuItem>
                      )
                    }
                    )}

                </Select>
              </FormControl>
            </Box>
          }
        </Box>
      </Drawer>
      <Box sx={{
        minHeight: "100vh",
        width: { sm: `calc(100% - ${drawerWidth})`, xs: "100%" },
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        // ml: drawerWidth
      }}>
        {isAuthed() && <CompanyAppBar sx={{
          boxShadow: "0 4px 10px -4px rgba(0, 0, 0, 0.05),0 4px 8px -4px rgba(0, 0, 0, 0.05),0 4px 8px -4px rgba(0, 0, 0, 0.05)",
          backgroundColor: "background.default",
        }}></CompanyAppBar>}
        <Box sx={{
          p: 4,
        }}>
          <Suspense fallback={<Typography>Loading...</Typography>}>
            <Routing />
          </Suspense>
          <footer>
          </footer>
          {debug &&
            <Box sx={{
              pt: 8
            }}>
              <Debug />
            </Box>
          }
        </Box>
      </Box>
      <Modal
        open={modalState.login}
        onClose={() => modalDispatch({ type: "login" })}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={{
          backgroundColor: "white.main",
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: "300px",
          width: { xs: "100vw", sm: "auto" },
          height: { xs: "100vh", sm: "auto" },
          transform: "translate(-50%, -50%)",
          padding: "20px",
          overflowY: "scroll"
        }}>
          <IconButton onClick={() => modalDispatch({ type: "login" })} sx={{
            position: "absolute",
            top: 0,
            right: 0
          }}
          >
            <CloseOutlined></CloseOutlined>
          </IconButton>
          <LoginForm></LoginForm>
        </Paper>
      </Modal>
      <Dialog
        open={modalState.createAccount}
        onClose={() => { modalDispatch({ type: "createAccount" }) }}
      >
        <IconButton onClick={() => modalDispatch({ type: "createAccount" })} sx={{
          position: "absolute",
          top: "1%",
          right: "1%"
        }}></IconButton>
        <CreateAccount></CreateAccount>
      </Dialog>

    </Box >
  );
}
export default CompanyContent;
