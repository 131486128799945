/** @jsxImportSource @emotion/react */
import { Box } from "@mui/material";
import { useEffect } from "react";
import { useAuth } from "../contexts/User";
import { Mutations, useMutation } from 'services/apollo';

function Logout() {
  const { logout } = useAuth();
  const [userSignOut] = useMutation(Mutations.userSignOut);
  const { user } = useAuth();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        // Call your userSignOut mutation
        await userSignOut({
          variables: {
            userId: user?.uid!,
          }
        });
        await logout().then(() => window.location.href = "/login");
      } catch (error) {
        // if something goes wrong at least trigger the firebase logout
        await logout().then(() => window.location.href = "/login");
        console.error("Error during logout:", error);
      }
    };

    if (user) handleLogout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box>Logging out</Box>;
}

export default Logout;
