/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/system';
import { Divider, FormControl, IconButton, Typography } from '@mui/material';
import { useAuth } from 'contexts/User';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { UserDataConverter } from 'services/Firestore/User';
import { Edit } from '@mui/icons-material';
import { useNotifications } from 'contexts/Notification';
import { UserActivityTypes } from 'services/Interfaces';
import ConfirmSubmissionDialog from './ConfirmSubmissionDialog';
import { useFirebase } from 'contexts/Firebase';
import MuiPhoneNumber from 'mui-phone-number';


interface Field {
  label: string;
  value: string;
  field: string;
}
interface ContactInfo {
  email: string;
  phoneNumber?: string;
}

function ContactInformation() {
  const { firestore } = useFirebase();
  const { userData } = useAuth();
  const initialFields: Field[] = [
    { field: 'email', label: 'Email Address', value: "" },
    { field: 'number', label: 'Phone Number', value: "" },
  ];


  const [fields, setFields] = useState<Field[]>(initialFields);
  const [isOpen, setIsOpen] = useState<boolean>(false); // triggers for edit modal
  const [openConfirmSubmission, setOpenConfirmSubmission] = useState(false)
  const [validationObject, setValidationObject] = useState<any>({
    email: ""
  });
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    email: "",
    phoneNumber: ""
  });
  const { notifications } = useNotifications() // for showing notifications
  useEffect(() => {
    setFields(
      [
        { field: 'email', label: 'Email Address ( username )', value: userData?.data()?.email || "" },
        { field: 'number', label: 'Phone Number', value: userData?.data()?.phoneNumber || "" },
      ]
    )
  }, [userData])
  const handleEdit = () => {
    // Initialize edited fields with current values
    setContactInfo({
      email: userData?.data()?.email || "",
      phoneNumber: userData?.data()?.phoneNumber || ""
    })
    setValidationObject({})
    setIsOpen(true);
  };

  const handleSave = async () => {
    if (Object.values(validationObject).length === 0) {
      // update contact information of user
      await setDoc(doc(firestore, `users/${userData?.id}`).withConverter(UserDataConverter), contactInfo, { merge: true })
      const userActivityCollectionRef = collection(firestore, `users/${userData?.id}/activity`);
      await addDoc(userActivityCollectionRef, {
        activity: UserActivityTypes.UPDATEDSETTINGS,
        type: "user",
        userId: userData?.id,
        timestamp: new Date(),
      });
      setIsOpen(false);
      setOpenConfirmSubmission(false)
      setValidationObject({});
      notifications.success("Contact information edited successfully")
    }
  };
  // close the edit modal
  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleChangeForm = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setContactInfo({ ...contactInfo, [name]: event.target.value });
    // email regex for valid email
    // eslint-disable-next-line no-useless-escape
    const emailRegex = "^[\\wa-z0-9!#$%&'*+\\/=?^_‘{|}~-]+(?:\\.[\\wa-z0-9!#$%&'*+\\/=?^_‘{|}~-]+)*@(?:[\\wa-z0-9](?:[\\wa-z0-9-]*[\\wa-z0-9])?\\.)+[\\wa-z0-9](?:[\\wa-z0-9-]*[\\wa-z0-9])?$"
    if (!event.target.value && name !== "phoneNumber") {
      setValidationObject({
        ...validationObject,
        [name]: `${name?.charAt(0).toUpperCase()}${name?.slice(1)} cannot be empty`
      })
    } else if (name === "email" && event.target.value && !event.target.value.match(emailRegex)) {
      setValidationObject({
        ...validationObject,
        [name]: `Invalid ${name?.charAt(0).toUpperCase()}${name?.slice(1)} Address`
      })
    } else {
      delete validationObject[name]
      setValidationObject(validationObject)
    }
  };

  const handleConfirmation = (e: any) => {
    e.preventDefault();
    if (
      (contactInfo.email !== (userData?.data()?.email || "")) ||
      (contactInfo.phoneNumber !== (userData?.data()?.phoneNumber || ""))
    ) {
      const validation: any = {};
      // email regex for valid email
      const emailRegex = "^[\\wa-z0-9!#$%&'*+\\/=?^_‘{|}~-]+(?:\\.[\\wa-z0-9!#$%&'*+\\/=?^_‘{|}~-]+)*@(?:[\\wa-z0-9](?:[\\wa-z0-9-]*[\\wa-z0-9])?\\.)+[\\wa-z0-9](?:[\\wa-z0-9-]*[\\wa-z0-9])?$"
      if (!contactInfo?.email) {
        validation['email'] = 'Email cannot be empty'
        setValidationObject(validation)
        return;
      }
      if (contactInfo?.email && !contactInfo?.email?.match(emailRegex)) {
        validation['email'] = 'Invalid Email Address'
        setValidationObject(validation)
        return;
      }
      if (Object.values(validationObject).length === 0) {
        setOpenConfirmSubmission(true)
      }
    } else {
      handleCancel()
    }
  }

  return (
    <Box sx={{ px: { xs: 2, sm: 4 } }}>
      <Typography variant="h3" color="primary" fontWeight={600}>Contact Information<IconButton color='primary' sx={{ mb: 1 }} onClick={handleEdit} ><Edit /></IconButton></Typography>
      <Divider />
      <Grid container spacing={2} sx={{ py: 2 }}>
        {fields.map((field) => (
          <Grid item xs={12} key={field.label} sx={{ wordBreak: "break-word" }}>
            <Typography variant='body1'><strong>{field.label}:</strong> {field.value}</Typography>
          </Grid>
        ))}
      </Grid>

      {/* Edit Modal */}
      <Dialog open={isOpen} onClose={handleCancel} sx={{ "& .MuiPaper-root": { width: { sm: "50%", xs: "70%" } } }}>
        <DialogContent sx={{ p: { xs: 2, sm: 4 } }}>
          <Box component='form' onSubmit={handleConfirmation} sx={{
            display: "flex",
            flexDirection: "column",
            gap: { sm: 3, xs: 2 }
          }}>
            <Typography sx={{ fontSize: { xs: '1.3rem', sm: '1.8rem' } }}>Edit Contact Information</Typography>
            <FormControl>
              <TextField
                label={"Email Address"}
                variant="outlined"
                type={'email'}
                error={Boolean(validationObject['email'])}
                value={contactInfo?.email}
                onChange={handleChangeForm("email")}
                fullWidth
              />
              {validationObject['email'] && <Typography component={"div"} color={"error"} variant="caption">{validationObject['email']}</Typography>}
            </FormControl>
            <FormControl>
              {/* <TextField
                label={"Phone number"}
                variant="outlined"
                type={'number'}
                error={Boolean(validationObject['phoneNumber'])}
                value={contactInfo?.phoneNumber}
                onChange={handleChangeForm("phoneNumber")}
                fullWidth
                sx={{
                  '& input[type=number]': {
                    '-moz-appearance': 'textfield'
                  },
                  '& input[type=number]::-webkit-outer-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  },
                  '& input[type=number]::-webkit-inner-spin-button': {
                    '-webkit-appearance': 'none',
                    margin: 0
                  },
                }}
              /> */}
              <MuiPhoneNumber
                autoFormat={false}
                sx={{ xs: '100%', sm: '50%' }}
                onChange={(value) => {
                  setContactInfo(prevState => ({
                    ...prevState,
                    phoneNumber: value as string
                  }));
                }}
                variant="outlined"
                onlyCountries={['us']}
                defaultCountry={'us'}
                disableAreaCodes={true}
                countryCodeEditable={false}
                value={contactInfo.phoneNumber}
              />
              {validationObject['phoneNumber'] && <Typography component={"div"} color={"error"} variant="caption">{validationObject['phoneNumber']}</Typography>}
            </FormControl>
            <Button type='submit' variant="contained" color="primary" >Save</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Box>
        </DialogContent>
      </Dialog>
      {openConfirmSubmission && <ConfirmSubmissionDialog confirmSubmit={handleSave} openConfirmSubmission={openConfirmSubmission} setOpenConfirmSubmission={setOpenConfirmSubmission} />}
    </Box>
  );
}
export default ContactInformation;