import React, { useCallback, useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  setPersistence,
  updateProfile,
  signInWithEmailAndPassword,
  browserLocalPersistence,
  confirmPasswordReset,
  sendPasswordResetEmail,
  reauthenticateWithCredential,
  signInAnonymously,
  linkWithCredential,
  User,
  signOut,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "@firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  DocumentData,
  DocumentSnapshot,
  FirestoreError,
  addDoc,
  collection,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import mixpanel from "mixpanel-browser";
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  SAMLAuthProvider,
  getMultiFactorResolver,
  getRedirectResult,
  linkWithPopup,
  multiFactor,
  sendEmailVerification,
} from "firebase/auth";
import { useDocument } from "react-firebase-hooks/firestore";
import { UserActivityTypes, UserData } from "services/Interfaces";
import { UserDataConverter } from "services/Firestore/User";
import Cookies from "js-cookie";
import { useFirebase } from "contexts/Firebase";
import { useSettings } from "contexts/Settings";

export interface UserState {
  user: User | null | undefined;
  userData: DocumentSnapshot<UserData> | undefined;
  userDataLoading: boolean;
  userDataError?: FirestoreError;
  userClaims: UserClaims;
  loaded: boolean;
  loading: boolean;
  error?: Error;
  setClaims: Function;
  answers?: DocumentSnapshot<DocumentData>;
  answersLoading: boolean;
  answersError?: FirestoreError;
  preferences?: DocumentSnapshot<DocumentData>;
  hasMFA: boolean;
}

export const UserContext = React.createContext<UserState>({
  user: undefined,
  userDataLoading: true,
  userDataError: undefined,
  answersLoading: true,
  answersError: undefined,
  userData: undefined,
  userClaims: { notSet: true },
  loaded: false,
  loading: true,
  error: undefined,
  hasMFA: false,
  setClaims: () => { },
});

export interface UserClaims {
  notSet?: boolean;
  admin?: boolean;
  companyAdmin?: boolean;
  roles?: {
    admin?: {
      viewer?: boolean;
      editor?: boolean;
    };
    support?: {
      viewer?: boolean;
      editor?: boolean;
    };
    reimbursements?: {
      viewer?: boolean;
      editor?: boolean;
    };
    companies?: {
      viewer?: boolean;
      editor?: boolean;
    };
    insurance?: {
      viewer?: boolean;
      editor?: boolean;
    };
  };
}

export function useAuth() {
  const { auth, firestore } = useFirebase();
  const { settings } = useSettings();
  const { user, userClaims, loaded, userData, error, setClaims, answers, preferences, hasMFA } =
    React.useContext(UserContext);

  const handleTokenRevocation = useCallback(async (user: User) => {
    try {
      await user.getIdToken(true);
    } catch (error: any) {
      if (error.code === "auth/id-token-revoked") {
        await signOut(auth);
      }
    }
  }, [auth]); // Include dependencies of handleTokenRevocation

  useEffect(() => {
    if (user) {
      handleTokenRevocation(user);
    }
  }, [user, handleTokenRevocation]);

  return {
    user,
    userData,
    answers,
    preferences,
    userClaims,
    loading: !loaded,
    error,
    hasMFA,
    getRedirectResult: async () => {
      return getRedirectResult(auth);
    },
    authed: user && !user?.isAnonymous ? true : false,
    isAuthed: function () {
      return user && !user?.isAnonymous ? true : false;
    },
    async signInWithGoogle(email?: string, merge: boolean = false) {
      try {
        const provider = new GoogleAuthProvider();
        provider.addScope("email");
        const currentUser = auth.currentUser;
        if (email) {
          provider.setCustomParameters({
            login_hint: email,
            prompt: "select_account",
          });
        } else {
          provider.setCustomParameters({
            prompt: "select_account",
          });
        }
        let result = undefined;
        if (currentUser && currentUser.isAnonymous && merge) {
          result = await linkWithPopup(currentUser, provider);
        } else {
          result = await signInWithPopup(auth, provider);
        }
        const credential = GoogleAuthProvider.credentialFromResult(result);
        if (credential) {
          const user = result.user;
          const userDoc = doc(firestore, "users", user.uid);
          const userAnswersDoc = doc(firestore, "users", user.uid, "answers", user.uid);
          mixpanel.identify(user?.uid);
          Sentry.setContext("user", {
            id: user?.uid,
            email: user?.email,
            method: "signInWithGoogle",
          });
          if (Cookies.get("employerLanding")) {
            mixpanel.people.set("employer", Cookies.get("employerLanding"));
          }
          mixpanel.people.set("$name", user?.displayName);
          mixpanel.people.set("$email", user.providerData[0].email);
          await setDoc(
            userDoc,
            {
              email: user.email,
              uid: user.uid,
              lastLogin: moment().toISOString(),
              created: moment().toISOString(),
            },
            { merge: true }
          );
          await setDoc(userAnswersDoc, { uid: user.uid }, { merge: true });
          return user;
        }
      } catch (error: any) {
        if (error.code === "auth/popup-blocked") {
          alert(
            "Please enable pop-ups in your browser settings to sign in with Google."
          );
        } else {
          console.error(error);
        }
      }
    },
    async emailExists(email: string) {
      try {
        await signInWithEmailAndPassword(auth, email, "pass");
      } catch (err: any) {
        if (err.message.includes("user-not-found")) {
          return false;
        } else if (err.message.includes("wrong-password")) {
          return true;
        }
        return false;
      }
      return null;
    },
    async confirmPasswordReset(code: string, password: string) {
      confirmPasswordReset(auth, code, password);
    },
    async sendPasswordResetEmail(email: string) {
      const actionCodeSettings = {
        url: `${settings.REACT_APP_WHEN_APP_URL}/login`,
        handleCodeInApp: false,
      };
      try {
        await sendPasswordResetEmail(auth, email, actionCodeSettings);
        return true;
      } catch (err) {
        return false;
      }
    },
    async signup(email: string, password: string, firstName: string = "", lastName: string = "", phoneNumber: string = "") {
      if (auth.currentUser && auth.currentUser.isAnonymous) {
        const uid = auth.currentUser.uid;
        const credential = EmailAuthProvider.credential(email, password);
        let usercred = await linkWithCredential(auth.currentUser, credential);
        await reauthenticateWithCredential(auth.currentUser, credential);
        await usercred.user.getIdToken(true);
        const user = usercred.user;
        await sendEmailVerification(user);
        updateProfile(user, {
          displayName: `${firstName} ${lastName}`,
        });
        const userDoc = doc(firestore, "users", uid);
        const userAnswersDoc = doc(firestore, "users", user.uid, "answers", user.uid);
        mixpanel.identify(user?.uid);
        Sentry.setContext("user", {
          id: user?.uid,
          email: user?.email,
          method: "signupAnonymous",
        });
        if (Cookies.get("employerLanding")) {
          mixpanel.people.set("employer", Cookies.get("employerLanding"));
        }
        mixpanel.people.set("$name", `${firstName} ${lastName}`);
        mixpanel.people.set("$email", email);
        await setDoc(
          userDoc,
          {
            email: email,
            name: {
              first: firstName,
              last: lastName,
              display: `${firstName} ${lastName}`,
              lowercase: `${firstName.toLowerCase()} ${lastName.toLowerCase()}`,
            },
            ...(phoneNumber && { phoneNumber }),
            uid: uid,
            lastLogin: moment().toISOString(),
            created: moment().toISOString(),
          },
          { merge: true }
        );
        await setDoc(
          userAnswersDoc,
          {
            personalInfo: {
              firstName: firstName,
              lastName: lastName,
              email: email,
              ...(phoneNumber && { phoneNumber }),
            },
            uid: user.uid,
          },
          { merge: true }
        );
        return user;
      } else {
        const credentials = await createUserWithEmailAndPassword(auth, email, password);
        const user = credentials.user;
        await sendEmailVerification(user);
        updateProfile(user, {
          displayName: `${firstName} ${lastName}`,
        });
        const userDoc = doc(firestore, "users", user.uid);
        const userAnswersDoc = doc(firestore, "users", user.uid, "answers", user.uid);
        mixpanel.identify(user?.uid);
        Sentry.setContext("user", {
          id: user?.uid,
          email: user?.email,
          method: "signup",
        });
        if (Cookies.get("employerLanding")) {
          mixpanel.people.set("employer", Cookies.get("employerLanding"));
        }
        mixpanel.people.set("$name", `${firstName} ${lastName}`);
        mixpanel.people.set("$email", email);
        await setDoc(
          userDoc,
          {
            email: email,
            name: {
              first: firstName,
              last: lastName,
              display: `${firstName} ${lastName}`,
              lowercase: `${firstName.toLowerCase()} ${lastName.toLowerCase()}`,
            },
            ...(phoneNumber && { phoneNumber }),
            uid: user.uid,
            lastLogin: moment().toISOString(),
            created: moment().toISOString(),
            acceptedPrivacyPolicy: moment().toISOString(),
            acceptedTermsOfService: moment().toISOString(),
          },
          { merge: true }
        );
        await setDoc(
          userAnswersDoc,
          {
            uid: user.uid,
            personalInfo: {
              firstName: firstName,
              lastName: lastName,
              email: email,
              ...(phoneNumber && { phoneNumber }),
            },
          },
          { merge: true }
        );
        return user;
      }
    },
    async loginSSO(email: string) {
      const emailDomain = email.split("@")[1];
      const provider = new SAMLAuthProvider(`saml.${emailDomain}`);
      return signInWithPopup(auth, provider);
    },
    async login(email: string, password: string) {
      try {
        await setPersistence(auth, browserLocalPersistence);
        let userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        let tokenResult = await user.getIdTokenResult();
        let userClaims = tokenResult.claims as UserClaims;
        setClaims(userClaims);
        const userDoc = doc(firestore, "users", user.uid);
        await updateDoc(userDoc, {
          lastLogin: moment().toISOString(),
        });
        mixpanel.identify(user?.uid);
        Sentry.setContext("user", {
          id: user?.uid,
          email: user?.email,
          method: "login",
        });
        return { user: user, userClaims };
      } catch (error) {
        console.error("Error signing in:", error);
        throw error;
      }
    },
    async loginAnonymously() {
      await setPersistence(auth, browserLocalPersistence);
      const credentials = await signInAnonymously(auth);
      const user = credentials.user;
      await user.getIdToken(true);
      const userDoc = doc(firestore, "users", user.uid);
      const userAnswersDoc = doc(firestore, "users", user.uid, "answers", user.uid);
      await setDoc(
        userDoc,
        {
          uid: user.uid,
          lastLogin: moment().toISOString(),
          created: moment().toISOString(),
        },
        { merge: true }
      );
      await setDoc(userAnswersDoc, { uid: user.uid }, { merge: true });
      return user;
    },
    async logout() {
      const uidBeforeSignOut = auth.currentUser ? auth.currentUser.uid : null;
      if (uidBeforeSignOut) {
        const userActivityCollectionRef = collection(firestore, `users/${uidBeforeSignOut}/activity`);
        await addDoc(userActivityCollectionRef, {
          activity: UserActivityTypes.LOGGEDOUT,
          type: "user",
          userId: uidBeforeSignOut,
          timestamp: new Date(),
        });
      }
      await signOut(auth);
      return true;
    },
    async generateOtpforSMSmfa(error: any) {
      const recaptchaVerifier = new RecaptchaVerifier("loginButton", { size: "invisible" }, auth);

      try {
        const resolver = getMultiFactorResolver(auth, error);

        if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session,
          };

          const phoneAuthProvider = new PhoneAuthProvider(auth);

          return await phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier
          );
        }
      } catch (error) {
        console.error("Error in generateOtpForSmsMfa:", error);
        return null;
      }
    },
    async verfiyOtpForSMSmfa(verificationId: string, otp: string, mfaError: any) {
      const cred = PhoneAuthProvider.credential(
        verificationId,
        otp
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const resolver = getMultiFactorResolver(auth, mfaError);
      const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
      const user = userCredential.user;
      let tokenResult = await user.getIdTokenResult();
      let userClaims = tokenResult.claims as UserClaims;
      setClaims(userClaims);
      const userDoc = doc(firestore, "users", user.uid);
      await updateDoc(userDoc, {
        lastLogin: moment().toISOString(),
      });
      mixpanel.identify(user?.uid);
      Sentry.setContext("user", {
        id: user?.uid,
        email: user?.email,
        method: "login",
      });
      return { user: user, userClaims };
    },
    async reAuthenticateUser(password: string, email: string) {
      const user = auth.currentUser;
      if (user && email) {
        try {
          const credential = EmailAuthProvider.credential(email, password);
          await reauthenticateWithCredential(user, credential);
          return true;
        } catch (error) {
          return false;
        }
      }
    },
  };
}

export const UserProvider = ({ children }: { children: any }) => {
  const { auth, firestore } = useFirebase();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [hasMFA, setHasMFA] = React.useState<boolean>(false);
  const [user, loading, error] = useAuthState(auth, {
    onUserChanged: async (user) => {
      let idTokenResult = await user?.getIdTokenResult();
      setClaims(idTokenResult?.claims as UserClaims || {});
      if (user && user.uid) {
        mixpanel.identify(user?.uid);
        Sentry.setContext("user", {
          id: user?.uid,
          email: user?.email,
          method: "login",
        });
      }
      if (Cookies.get("employerLanding")) {
        mixpanel.people.set("employer", Cookies.get("employerLanding"));
      }
      mixpanel.people.set("$name", user?.displayName);
      if (user?.email) {
        mixpanel.people.set("$email", user?.email);
      } else if (user?.providerData[0].email) {
        mixpanel.people.set("$email", user?.providerData[0].email);
      }
    },
  });
  const [userClaims, setClaims] = React.useState<UserClaims>({ notSet: true });

  useEffect(() => {
    if (!userClaims.notSet && !loading && !loaded) {
      setLoaded(true);
    }
  }, [userClaims, loaded, loading]);

  useEffect(() => {
    if (user && user.uid) {
      const mfa = multiFactor(user);
      if (mfa.enrolledFactors.length > 0) {
        setHasMFA(true);
      }
    }
    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "when-local") {
      setHasMFA(true);
    }
  }, [user]);

  const [userData, userDataLoading, userDataError] = useDocument<UserData>(
    user ? doc(firestore, "users", user.uid).withConverter(UserDataConverter) : null
  );
  const [answers, answersLoading, answersError] = useDocument(
    user ? doc(firestore, "users", user.uid, "answers", user.uid) : null
  );
  const [preferences] = useDocument(
    user ? doc(firestore, "users", user.uid, "answers", "preferences") : null
  );

  return (
    <UserContext.Provider
      value={{
        loaded,
        loading,
        user,
        hasMFA,
        userData,
        userDataLoading,
        userDataError,
        answersLoading,
        answersError,
        answers,
        preferences,
        userClaims,
        error,
        setClaims,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default function AuthConsumer() {
  return React.useContext(UserContext);
}

