import { useEffect, useContext, createContext, useState } from 'react';

const SettingsContext = createContext<{
  loaded: boolean,
  settings: any,
}>({
  loaded: false,
  settings: {}
});


const SettingsProvider = ({ children }: { children: any }) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [settings, setSettings] = useState<object>({});
  useEffect(() => {
    if (window.env.configLoaded) {
      console.log("SettingsProvider Loaded", window.env)
      //override settings with window.env
      setSettings(() => {
        setLoaded(true);
        return { ...process.env, ...window.env }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.env])

  return (
    <SettingsContext.Provider value={{ loaded, settings }}>
      {loaded ? children : <div>Loading settings</div>}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;

export const useSettings = () => {
  const context = useContext(SettingsContext);
  return {
    loaded: context.loaded,
    settings: context.settings,
  }
}