import { useMutation } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Button, Card, Dialog, TextField, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useNotifications } from 'contexts/Notification'
import React, { useState } from 'react'
import { Mutations } from 'services/apollo'
import { ReimbursementRequest } from 'services/Interfaces'

function AdditionalInfoRequest({ openAdditionalInfoDialog, setOpenAdditionalInfoDialog, reimbursementRequest, firstName }: { openAdditionalInfoDialog: Boolean, setOpenAdditionalInfoDialog: Function, reimbursementRequest: ReimbursementRequest, firstName?: string }) {
    const { notifications } = useNotifications()
    const [additionalInfoRequest, { loading }] = useMutation(Mutations.additionalInfoRequest);
    const [additionalInfoText, setAdditionalInfoText] = useState(`Hi ${firstName || ""}, `)
    const [error, setError] = useState<string>("")

    const RequestAdditionalInfo = async () => {
        if (!additionalInfoText) {
            setError("Please enter massage");
            return
        }
        try {
            await additionalInfoRequest({
                variables: {
                    requestUserId: reimbursementRequest?.userId || "",
                    requestId: reimbursementRequest?.id || "",
                    requestBenefitId: reimbursementRequest?.whenBenefitId || "",
                    msg: additionalInfoText,
                    status: "Additional info needed"
                },
            });
            notifications.success('Information requested successfully.');
        }
        catch (error) {
            console.error('Error requesting additional information from user:', error);
            notifications.error('An error occurred while requesting additional information from user.');
        } finally {
            setOpenAdditionalInfoDialog(null)
        }
    }
    const changeStatus = async () => {
        try {
            await additionalInfoRequest({
                variables: {
                    requestUserId: reimbursementRequest?.userId || "",
                    requestId: reimbursementRequest?.id || "",
                    requestBenefitId: reimbursementRequest?.whenBenefitId || "",
                    status: "Additional info needed"
                },
            });
            notifications.success('Status updated successfully.');
        } catch (error) {
            console.error('Error updating status for reimbursement:', error);
            notifications.error('An error occurred while updating status for reimbursement.');
        } finally {
            setOpenAdditionalInfoDialog(null)
        }
    }
    return (
        <Dialog fullWidth open={!!openAdditionalInfoDialog} onClose={() => { setOpenAdditionalInfoDialog("") }}> {/* open Additional dialog */}
            <Card sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2,
            }}>
                <TextField error={!!error} multiline rows={5} value={additionalInfoText} onChange={(e) => {
                    setAdditionalInfoText(e.target.value)
                    if (!e.target.value) {
                        setError("Please enter massage")
                    } else {
                        setError("")
                    }
                }} />
                {error && <Typography color="error">{error}</Typography>}
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <LoadingButton loading={loading} onClick={() => RequestAdditionalInfo()} variant="contained" color="primary">
                        Send
                    </LoadingButton>
                    <Tooltip title="Change status without send an email to user">
                        <Button onClick={() => changeStatus()} variant="outlined" color="primary">
                            Change status only
                        </Button>
                    </Tooltip>
                    <Button color="primary" onClick={() => { setOpenAdditionalInfoDialog("") }}>
                        Cancel
                    </Button>
                </Box>
            </Card>
        </Dialog>
    )
}

export default AdditionalInfoRequest