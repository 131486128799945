import { Warning } from '@mui/icons-material';
import { Button, Dialog, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react'

export const ConfirmationModal = ({ open, onClose, onSave, headingText, bodyText, btnText }: { open: boolean, onClose: Function, onSave: Function, headingText: string, bodyText: string, btnText: string }) => {
    return (
        <Dialog
            open={open}
            onClose={() => onClose()}
        >
            <Box sx={{
                width: 500,
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 2

            }}>
                <Typography variant="h2">{headingText}</Typography>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center"
                }}>
                    <Warning color="warning" />
                    <Typography>{bodyText}</Typography>
                </Box>
                <Button variant="contained" color="primary" onClick={() => onSave()}>{btnText}</Button>
                <Button onClick={() => onClose()}>Cancel</Button>
            </Box>
        </Dialog >
    )
}