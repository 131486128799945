/** @jsxImportSource @emotion/react */
import { Typography, Box, Container, Grid, Link, Button, TextField, Select, MenuItem, FormControl, InputLabel, Modal, Paper, Snackbar, Menu, Fade, Dialog, Slider } from "@mui/material";
import { useAuth } from "contexts/User";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ReactComponent as JamieImage } from "images/Jamie.svg";
import { ReactComponent as InfoIcon } from "images/InfoIcon.svg";
import Cookies from "js-cookie";
import { doc, updateDoc, addDoc, collection } from "@firebase/firestore";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { limit, orderBy, query, setDoc, where } from "firebase/firestore";
import moment from "moment";
import { Queries, useMutation, useApolloClient, Mutations, useLazyQuery } from "services/apollo";
import { useNavigate } from "react-router";
import mixpanel from "mixpanel-browser";
import ReCAPTCHA from "react-google-recaptcha";
import { Check, KeyboardArrowDown } from "@mui/icons-material";
import { ModalContext } from "contexts/Modal";
import * as Sentry from "@sentry/react";
import { useRemoteConfig } from "contexts/RemoteConfig";
import { UserActivityTypes, formatPhoneNumber } from "services/Interfaces";
import { useFirebase } from "contexts/Firebase";
import { useSettings } from "contexts/Settings";
import { phonePattern } from "services/validation";
import MuiPhoneNumber from 'mui-phone-number';
import PlanOptionsJamie from "./PlanOptionsJamie";

interface Message {
  text: string;
  id: string;
  type: string;
  saveTo?: string | Function;
  input?: string;
  validation?: string | Function;
  autoComplete?: string;
  default?: string;
  min?: number;
  max?: number;
  step?: number;
  aria?: {
    label?: string;
  }
  headers?: {
    low?: string;
    high?: string;
    mid?: string;
  }
  options?: Array<{
    value: string;
    text: string;
    color?: "primary" | "error" | "inherit" | "secondary" | "info" | "success" | "warning" | undefined; //button
    variant?: "text" | "contained" | "outlined" | undefined; //button
  }>
}
let assistant: any = {
  settings: {
    name: "Jamie",
    typeSpeed: 6//process.env.REACT_APP_TA_SPEED || 12
  },
  variables: {
    section: "initiate",
    step: "",
  },
  lookup: {
    auth: null,
    answers: null,
    apollo: null,
    conciergePhone: window.env.REACT_APP_CONCIERGE_PHONE,
    invalidStates: {
      "CA": {
        exchangeUrl: "https://www.coveredca.com/",
        name: "California"
      },
      "CO": {
        exchangeUrl: "http://www.connectforhealthco.com/",
        name: "Colorado"
      },
      "CT": {
        exchangeUrl: "http://www.accesshealthct.com/",
        name: "Connecticut"
      },
      "DC": {
        exchangeUrl: "https://dchealthlink.com/",
        name: "District of Columbia"
      },
      "ID": {
        exchangeUrl: "http://www.yourhealthidaho.org/",
        name: "Idaho"
      },
      "KY": {
        exchangeUrl: "https://kynect.ky.gov/s/?language=en_US",
        name: "Kentucky"
      },
      "ME": {
        exchangeUrl: "https://coverme.gov/",
        name: "Maine"
      },
      "MD": {
        exchangeUrl: "http://www.marylandhealthconnection.gov/",
        name: "Maryland"
      },
      "MA": {
        exchangeUrl: "https://www.mahealthconnector.org/",
        name: "Massachusetts"
      },
      "MN": {
        exchangeUrl: "http://mn.gov/hix/",
        name: "Minnesota"
      },
      "NV": {
        exchangeUrl: "https://www.nevadahealthlink.com/",
        name: "Nevada"
      },
      "NJ": {
        exchangeUrl: "https://www.getcovered.nj.gov/",
        name: "New Jersey"
      },
      "NM": {
        exchangeUrl: "http://www.bewellnm.com/",
        name: "New Mexico"
      },
      "NY": {
        exchangeUrl: "http://nystateofhealth.ny.gov/",
        name: "New York"
      },
      "PA": {
        exchangeUrl: "https://www.pennie.com/",
        name: "Pennsylvania"
      },
      "RI": {
        exchangeUrl: "http://www.healthsourceri.com/",
        name: "Rhode Island"
      },
      "VT": {
        exchangeUrl: "http://healthconnect.vermont.gov/",
        name: "Vermont"
      },
      "WA": {
        exchangeUrl: "http://www.wahealthplanfinder.org/",
        name: "Washington"
      }
    }
  },
  conversation: {
    initiate: {
      start: "checkAuthed",
      next: "introduction",
      steps: {
        checkAuthed: {
          type: "lookup",
          saveTo: "userAuthed",
          lookup: async function () {
            console.log(`userAuthed lookup`, assistant.lookup.auth)
            return assistant.lookup.auth.authed;
          },
          next: undefined
        },
      }
    },
    introduction: {
      start: "intro1",
      helper: function () {
        return (
          <Box>
            <Typography color={"white.main"}>Meet Jamie, your Health Insurance Advocate!</Typography>
          </Box>
        )
      },
      steps: {
        intro1: {
          type: "text",
          text: "Hi. I am Jamie, Your Health Insurance Advocate.",
          next: "reasonText"
        },
        reasonText: {
          type: "text",
          text: async (assistant: any) => {
            if (assistant.lookup.answers.get("currentSituation") === "jobLoss") {
              return "I am sorry to hear that you recently lost your job."
            } else {
              return "It looks like you recently left your job."
            }
          },
          next: "intro2"
        },
        intro2: {
          type: "text",
          text: "I am here to guide you through the complex world of health insurance and help you find the right plan for your specific needs, budget, and location. With personalized recommendations and expert support, I am your ally in navigating the healthcare system.",
          next: undefined
        },
      },
      next: "personal"
    },
    personal: {
      start: "intro",
      next: "household",
      helper: function () {
        return (
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography color="white.main">Personal information is collected so that I can identify you and provide you personalized results!</Typography>
            <Typography color="white.main">Don&apos;t worry, I won&apos;t send your personal information to our carrier partners until you pick a plan to enroll in!</Typography>
          </Box>
        )
      },
      steps: {
        intro: {
          type: "text",
          text: "I was able to access some of your information from your employer. Please confirm that the following information is correct.",
          next: "firstName",
        },
        firstName: {
          type: "text",
          text: "First Name",
          next: "firstNameQ"
        },
        firstNameQ: {
          type: "question",
          saveTo: "personalInfo.firstName",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("personalInfo.firstName")
          },
          autoComplete: "given-name",
          validation: '[\\w]{2,}',
          aria: {
            label: "First Name",
          },
          input: "text",
          next: "zipcode"
        },
        zipcode: {
          type: "text",
          text: "Zipcode",
          next: "zipcodeQ"
        },
        zipcodeQ: {
          type: "question",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("insuranceDetails.zipcode")
          },
          saveTo: "insuranceDetails.zipcode",
          validation: '(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)',
          autoComplete: "postal-code",
          input: "text",

          aria: {
            label: "Zip Code",
          },
          next: "countyLookup",
          helper: function () {
            return (
              <Box>
                <Typography color="white.main">Your zipcode is used to find policies available in your area.</Typography>
              </Box>
            )
          },
        },
        countyLookup: {
          type: "lookup",
          saveTo: "insuranceDetails.counties",
          async: true,
          next: "email",
          lookup: async () => {
            try {
              // query counties
              console.log('get counties');
              const counties = await assistant.lookup.apollo.query({
                query: Queries.GET_GEOGRAPHY,
                variables: {
                  zipcode: assistant.lookup.answers.get('insuranceDetails.zipcode')
                }
              })
              console.log(`counties `, counties.data)
              await assistant.updateDoc("personalInfo.state", counties.data.geographyByZip[0].state);
              console.log(`saved state ${counties.data.geographyByZip[0].state} as ${assistant.lookup.answers.get("personalInfo.state")}`);
              return counties.data.geographyByZip.map((county: { fips: string, name: string, state: string, zipcode: string }) => {
                return { text: county.name, value: county }
              });
            } catch (err) {
              console.log("personal.countyLookup error", err);
            }
          }
        },
        email: {
          type: "text",
          text: "Email",
          next: "emailQ"
        },
        emailQ: {
          type: "question",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("personalInfo.email")
          },
          validation: "^[\\wa-z0-9!#$%&'*+\\/=?^_‘{|}~-]+(?:\\.[\\wa-z0-9!#$%&'*+\\/=?^_‘{|}~-]+)*@(?:[\\wa-z0-9](?:[\\wa-z0-9-]*[\\wa-z0-9])?\\.)+[\\wa-z0-9](?:[\\wa-z0-9-]*[\\wa-z0-9])?$",
          saveTo: "personalInfo.email",
          autoComplete: "email",
          input: "text",

          aria: {
            label: "Email",
          },
          next: "phoneNumber"
        },
        phoneNumber: {
          type: "text",
          text: "Phone Number",
          next: "phoneQ"
        },
        phoneQ: {
          type: "question",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("personalInfo.phone")
          },
          validation: phonePattern.toString().slice(1, -1),
          saveTo: "personalInfo.phone",
          autoComplete: "tel",
          input: "text",
          aria: {
            label: "Phone Number",
          },
          next: "checkStateLoaded"
        },
        checkStateLoaded: {
          type: "if",
          variable: () => {
            return assistant.lookup.answers.get("personalInfo.state");
          },
          check: "==",
          value: undefined,
          ifTrue: "reloadState",
          ifFalse: "checkInvalidState"
        },
        reloadState: {
          type: "text",
          text: "Please wait while I make sure we have coverage options in your state.",
          next: "stateLookup"
        },
        stateLookup: {
          type: "lookup",
          saveTo: "insuranceDetails.counties",
          async: false,
          next: "checkInvalidState",
          lookup: async () => {
            try {
              // query counties
              console.log('get counties');
              const counties = await assistant.lookup.apollo.query({
                query: Queries.GET_GEOGRAPHY,
                variables: {
                  zipcode: assistant.lookup.answers.get('insuranceDetails.zipcode')
                }
              })
              console.log(`counties `, counties.data)
              await assistant.updateDoc("personalInfo.state", counties.data.geographyByZip[0].state);
              console.log(`saved state ${counties.data.geographyByZip[0].state} as ${assistant.lookup.answers.get("personalInfo.state")}`);
              return counties.data.geographyByZip.map((county: { fips: string, name: string, state: string, zipcode: string }) => {
                return { text: county.name, value: county }
              });
            } catch (err) {
              console.log("stateLookup error", err);
            }
          }
        },
        checkInvalidState: {
          type: "if",
          variable: () => {
            if (assistant.remoteConfig.quotit) {
              // with quotit all states are valid
              return false;
            }
            return Object.keys(assistant.lookup.invalidStates).includes(assistant.lookup.answers.get("personalInfo.state"));
          },
          check: "==",
          value: true,
          ifTrue: "invalidStateNotice",
          ifFalse: "validState"
        },
        validState: {
          type: "text",
          text: "Great, you live in a state that I am able to offer health insurance plans for!",
          next: undefined
        },
        invalidStateNotice: {
          type: "text",
          text: (assistant: any) => {
            const state = assistant.lookup.answers.get("personalInfo.state");
            if (assistant.lookup.invalidStates[state].exchangeUrl) {
              return `Unfortunately ${assistant.lookup.invalidStates[state].name} is not on the federal healthcare exchange, so I cannot find you policies online at the moment. I have a team of When Licensed Insurance Agents available that can help you find plans over the phone. Please call ${assistant.lookup.conciergePhone} to speak with a When Licensed Insurance Agent. In the meantime you may also visit ${assistant.lookup.invalidStates[state].exchangeUrl} to find policies on your state exchange.`
            } else {
              return `Unfortunately ${assistant.lookup.invalidStates[state].name} is not on the federal healthcare exchange, so I cannot find you policies online at the moment. I have a team of When Licensed Insurance Agents available that can help you find plans over the phone. Please call ${assistant.lookup.conciergePhone} to speak with a When Licensed Insurance Agent.`
            }
          }
        }
      },
    },
    household: {
      start: "planType",
      next: "cobra",
      helper: function () {
        return (
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography color="white.main">Information about your household is required to build health insurance plans and to get you rates that accurately reflect what you need.</Typography>
            <Typography color="white.main">The information you provide here will be shared with our carrier partners should you choose to enroll in one of their plans.</Typography>
            <Typography color="white.main">If you need protection for your and your family be sure to indicate that here so that we can collect information about each member of your household. Plans for yourself do not allow you to add additional members.</Typography>
            <Typography color="white.main">The name of additional members in your household is only used to help you identify these members when making changes in the future. This information is not shared with partners, and you can use &apos;Child 1&apos; in lieu of actual names.</Typography>
          </Box>
        )
      },
      steps: {
        planType: {
          type: "text",
          text: "Would you like me to help you find an individual plan just for yourself, or a plan that covers you and other members of your household?",
          next: "planTypeQuestion"
        },
        planTypeQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "insuranceDetails.type",
          next: "effectiveDate",
          options: [
            { value: "individual", text: "Just myself" },
            { value: "family", text: "My family" }
          ]
        },
        effectiveDate: {
          type: "text",
          text: "When would you like your coverage to start?",
          next: "effectiveDateQ"
        },
        effectiveDateQ: {
          type: "question",
          input: "select",
          saveTo: "insuranceDetails.effectiveDate",
          next: "self",
          options: [
            //generate 4 months of dates starting on the first of the month
            ...Array.from(Array(4).keys()).map((i) => {
              const date = moment().add(i + 1, "months").startOf("month");
              return {
                value: date.format("YYYY-MM-DD"),
                text: date.format("MMMM D")
              }
            })
          ]
        },
        self: {
          type: "text",
          text: "Great, now let's start by getting information about you",
          next: "selfDOB"
        },
        selfDOB: {
          type: "text",
          text: "What is your Date of Birth?",
          next: "selfDOBQ"
        },
        selfDOBQ: {
          type: "question",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("personalInfo.dateOfBirth")
          },
          input: "date",
          saveTo: "insuranceDetails.household.members.self.dob",
          next: "selfName",
          aria: {
            label: "SELF-DOB"
          }
        },
        selfName: {
          type: "lookup",
          saveTo: "insuranceDetails.household.members.self.name",
          lookup: async () => {
            return "self"
          },
          next: "selfRelationship"
        },
        selfRelationship: {
          type: "lookup",
          saveTo: "insuranceDetails.household.members.self.relationship",
          lookup: async () => {
            return "self"
          },
          next: "checkAge"
        },
        checkAge: {
          type: "if",
          variable: () => {
            const today = new Date();
            const birthDate = new Date(assistant.lookup.answers.get("personalInfo.dateOfBirth"));

            // Calculate the age
            let age = today.getFullYear() - birthDate.getFullYear();
            const monthDiff = today.getMonth() - birthDate.getMonth();
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
              age--;
            }
            return age >= 65;
          },
          check: "==",
          value: true,
          ifTrue: "over65Description",
          ifFalse: "selfGender"
        },
        over65Description: {
          type: "text",
          text: `As someone over 65, you may be eligible for Medicare. When’s licensed and helpful agents can offer a variety of Medicare Supplement and Medicare Advantage plans over the phone, but we are unable to offer these plans online. If you’d like to explore your Medicare Supplement or Medicare Advantage plan options, call us at ${process.env.REACT_APP_CONCIERGE_PHONE}. Or, if you’d like to proceed online, I can help you find a plan from the ACA marketplace or with one of our private insurance carrier partners.`,
          next: "over65Description2"
        },
        over65Description2: {
          type: "text",
          text: "Would you like to proceed?",
          next: "over65Q"
        },
        over65Q: {
          type: "question",
          input: "buttons",
          saveTo: "over65Proceed",
          next: "over65Proceed",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]
        },
        over65Proceed: {
          type: "if",
          variable: "over65Proceed",
          check: "==",
          value: true,
          ifTrue: "selfGender",
          ifFalse: "over65NotProceed"
        },
        over65NotProceed: {
          type: "text",
          text: `No problem. If you’d like to get live support to find a health insurance plan, including Medicare Supplement and Medicare Advantage plans, don’t hesitate to give us a call at ${process.env.REACT_APP_CONCIERGE_PHONE}. We’re here to help!`,
          next: "goToDashboard"
        },
        goToDashboard: {
          type: "question",
          saveTo: "goToDashboard",
          input: "buttons",
          options: [
            { value: true, text: "Go to your Dashboard" }
          ],
          next: "redirectToDashboard"
        },
        redirectToDashboard: {
          type: "redirect",
          url: "/account/dashboard"
        },
        selfGender: {
          type: "text",
          text: "What is your gender?",
          next: "selfGenderQ"
        },
        selfGenderQ: {
          type: "question",
          input: "buttons",
          saveTo: "insuranceDetails.household.members.self.gender",
          next: "selfIfPregnant",
          options: [
            { value: "male", text: "Male" },
            { value: "female", text: "Female" }
          ]
        },
        selfIfPregnant: {
          type: "if",
          variable: "insuranceDetails.household.members.self.gender",
          check: "==",
          value: "female",
          ifTrue: "selfPregnant",
          ifFalse: "selfSmokes"
        },
        selfPregnant: {
          type: "text",
          text: "Are you pregnant?",
          next: "selfPregnantQuestion"
        },
        selfPregnantQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "insuranceDetails.household.members.self.pregnant",
          next: "selfSmokes",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]
        },
        selfSmokes: {
          type: "text",
          text: "Do you use tobacco products? (e.g. cigarettes, vape pens, Zyns, etc.)",
          next: "selfSmokesQuestion"
        },
        selfSmokesQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "insuranceDetails.household.members.self.tobacco",
          next: "ifFamilyPlan",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]
        },
        ifFamilyPlan: {
          type: "if",
          variable: "insuranceDetails.type",
          check: "==",
          value: "family",
          ifTrue: "familyStart",
          ifFalse: "checkCounty"
        },
        familyStart: {
          type: "text",
          text: "Now we will collect information about the members of your household",
          next: "addMember"
        },
        moreHousehold: {
          type: "text",
          text: "Do you need to add anymore people to your household?",
          next: "moreHouseholdQuestion"
        },
        moreHouseholdQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "moreHousehold",
          next: "ifMoreHousehold",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]
        },
        ifMoreHousehold: {
          type: "if",
          variable: "moreHousehold",
          check: "==",
          value: true,
          ifTrue: "addMember",
          ifFalse: "checkCounty"
        },
        addMember: {
          type: "text",
          text: "What is the name of this household member?",
          next: "addMemberQ"
        },
        addMemberQ: {
          type: "question",
          input: "text",
          validation: '[\\w]{2,}',
          aria: {
            label: "Household member name",
          },
          saveTo: async (value: string) => {
            console.log("addMember", value)
            await assistant.updateDoc(`householdMemberName`, value);
            await assistant.updateDoc(`insuranceDetails.household.members.${value}.name`, value)
          },
          next: "memberGender"
        },
        memberGender: {
          type: "text",
          text: (assistant: any) => {
            return `What gender is ${assistant.lookup.answers.get('householdMemberName')}?`;
          },
          next: "memberGenderQ",
        },
        memberGenderQ: {
          type: "question",
          input: "buttons",
          next: "memberDOB",
          saveTo: async (value: string) => {
            await assistant.updateDoc(`insuranceDetails.household.members.${assistant.lookup.answers.get('householdMemberName')}.gender`, value)
          },
          options: [
            { value: "male", text: "Male" },
            { value: "female", text: "Female" }
          ]
        },
        memberDOB: {
          type: "text",
          text: (assistant: any) => {
            return `What is ${assistant.lookup.answers.get('householdMemberName')} date of birth?`;
          },
          next: "memberDOBQ",
        },
        memberDOBQ: {
          type: "question",
          input: "date",
          next: "memberIfPregnant",
          saveTo: async (value: string) => {
            await assistant.updateDoc(`insuranceDetails.household.members.${assistant.lookup.answers.get('householdMemberName')}.dob`, value)
          },
        },
        memberIfPregnant: {
          type: "if",
          variable: () => { return assistant.lookup.answers.get(`insuranceDetails.household.members.${assistant.lookup.answers.get('householdMemberName')}.gender`) },
          check: "==",
          value: "female",
          ifTrue: "memberPregnant",
          ifFalse: "memberSmokes"
        },
        memberPregnant: {
          type: "text",
          text: (assistant: any) => {
            return `Is ${assistant.lookup.answers.get('householdMemberName')} pregnant?`;
          },
          next: "memberPregnantQuestion"
        },
        memberPregnantQuestion: {
          type: "question",
          input: "buttons",
          next: "memberSmokes",
          saveTo: async (value: string) => {
            await assistant.updateDoc(`insuranceDetails.household.members.${assistant.lookup.answers.get('householdMemberName')}.pregnant`, value)
          },
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]
        },
        memberSmokes: {
          type: "text",
          text: (assistant: any) => {
            return `Does ${assistant.lookup.answers.get('householdMemberName')} smoke?`;
          },
          next: "memberSmokesQuestion"
        },
        memberSmokesQuestion: {
          type: "question",
          input: "buttons",
          next: "memberRelationship",
          saveTo: async (value: string) => {
            await assistant.updateDoc(`insuranceDetails.household.members.${assistant.lookup.answers.get('householdMemberName')}.tobacco`, value)
          },
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]
        },
        memberRelationship: {
          type: "question",
          input: "select",
          text: (assistant: any) => {
            return `How is ${assistant.lookup.answers.get('householdMemberName')} related to you?`;
          },
          aria: {
            label: "memberRelationship",
          },
          next: "moreHousehold",
          saveTo: async (value: string) => {
            await assistant.updateDoc(`insuranceDetails.household.members.${assistant.lookup.answers.get('householdMemberName')}.relationship`, value)
          },
          options: [
            { value: "spouse", text: "Spouse" },
            { value: "child", text: "Child" }
          ]
        },
        checkCounty: {
          type: "if",
          variable: () => {
            return assistant.lookup.answers.get('insuranceDetails.counties') ? assistant.lookup.answers.get('insuranceDetails.counties').length : 0;
          },
          check: ">",
          value: 0,
          ifTrue: "county",
          ifFalse: "countyLookupText"
        },
        countyLookupText: {
          type: "text",
          text: "Please give me a minute while I lookup your county.",
          next: "countyLookup"
        },
        countyLookup: {
          type: "lookup",
          saveTo: "insuranceDetails.counties",
          next: "county",
          lookup: async () => {
            // query counties
            try {
              console.log('get counties');
              const counties = await assistant.lookup.apollo.query({
                query: Queries.GET_GEOGRAPHY,
                variables: {
                  zipcode: assistant.lookup.answers.get('insuranceDetails.zipcode')
                }
              })
              console.log(`counties `, counties.data)
              return counties.data.geographyByZip.map((county: { fips: string, name: string, state: string, zipcode: string }) => {
                return { text: county.name, value: county }
              });
            } catch (err) {
              console.log(`household.countyLookup error`, err)
            }
          }
        },
        county: {
          type: "text",
          text: "Select your county",
          next: "countyQ"
        },
        countyQ: {
          type: "question",
          input: "buttons",
          options: "insuranceDetails.counties",
          saveTo: "insuranceDetails.county",
          next: "policyLookup"
        },
        policyLookup: {
          type: "lookup",
          saveTo: "searchPlans",
          async: true,
          next: "subsidyEligibility",
          lookup: async () => {
            if (assistant.remoteConfig.quotit) {
              // use getRates
              let plans = await assistant.lookup.apollo.query({
                query: Queries.getRates,
                variables: {
                  source: assistant.remoteConfig.ideon ? "ideon" : "quotit"
                }
              });
              return plans.data.getRates;
            } else {

              let plans = await assistant.lookup.apollo.query({
                query: Queries.SEARCH_PLANS
              });
              return plans.data.searchPlans;
            }
          }
        },
        subsidyEligibility: {
          type: "text",
          text: "Would you like to check your eligibility for potential subsidies that can reduce the cost of health insurance plans through the federal and state exchanges, such as healthcare.gov and Covered California?",
          next: "subsidyEligibilityQ"
        },
        subsidyEligibilityQ: {
          type: "question",
          input: "buttons",
          saveTo: "checkSubsidy",
          next: "checkSubsidy",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]
        },
        checkSubsidy: {
          type: "if",
          variable: "checkSubsidy",
          check: "==",
          value: true,
          ifTrue: "taxFiling",
          ifFalse: undefined
        },
        taxFiling: {
          type: "text",
          text: "How do you file your taxes?",
          next: "taxFilingQ"
        },
        taxFilingQ: {
          type: "question",
          input: "select",
          default: async (assistant: any) => {
            return assistant.lookup.answers.get("insuranceDetails.taxFilingStatus")
          },
          saveTo: "insuranceDetails.taxFilingStatus",
          options: [
            {
              value: "Single",
              text: "Single"
            },
            {
              value: "HeadOfHousehold",
              text: "Head of household"
            },
            {
              value: "QualifyingWidower",
              text: "Qualifying widower"
            },
            {
              value: "MarriedFilingJointly",
              text: "Married filing jointly"
            },
            {
              value: "MarriedFilingSeparately",
              text: "Married filing separately"
            }
          ],
          next: "income"
        },
        income: {
          type: "text",
          text: "What is your estimated income of your entire household for this year? This is used to determine subsidies for the ACA.",
          helpText: "Income should be based on what you will report to the IRS for taxes. ACA subsidies use this value",
          next: "incomeQuestion"
        },
        incomeQuestion: {
          type: "question",
          input: "number",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("insuranceDetails.income")
          },
          saveTo: "insuranceDetails.income",
          next: "isNativeAmerican"
        },
        isNativeAmerican: {
          type: "text",
          text: "Are you a member of a federally recognized Native American Tribe?",
          next: "isNativeAmericanQ"
        },
        isNativeAmericanQ: {
          type: "question",
          input: "buttons",
          next: "subsidiesCheckLookup",
          saveTo: "insuranceDetails.isNativeAmarican",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]
        },
        subsidiesCheckLookup: {
          type: "lookup",
          async: true,
          saveTo: "insuranceDetails.federalSubsidyAmount",
          next: undefined,
          lookup: async () => {
            await assistant.lookup.apollo.query({
              query: Queries.subsidyCheck,
              variables: {
                uid: assistant.currentUser.uid
              }
            })
            return;
          }
        },
      }
    },
    policies: {
      start: "lookup",
      next: "cobra",
      helper: function () {
        return (
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography color="white.main">When is partnered with Healthcare.gov, private medical providers, and short term medical providers to provide you with the most robust offering of health insurance products available.</Typography>
            {assistant.lookup.answers.get("searchPlans.total") > 0 &&
              <Typography color="white.main">I was able to find {assistant.lookup.answers.get("searchPlans.total")} health insurance plans based on the information you&apos;ve provided!</Typography>
            }
            <Typography color="white.main">I only send non-identifiable information to our carrier partners to get a list of health insurance policies and rates.</Typography>
            <Typography color="white.main">I highly recommend completing the recommendation process so that I can find the most suitable plans for you.</Typography>
          </Box>
        )
      },
      steps: {
        checkPolicyCount: {
          type: "if",
          variable: "searchPlans.total",
          check: ">",
          value: 0,
          ifTrue: "stateCheck",
          ifFalse: "lookupText"
        },
        lookupText: {
          type: "text",
          text: "Please give me a second while I see how many policies I can find for you.",
          next: "lookup"
        },
        lookup: {
          type: "lookup",
          saveTo: "searchPlans",
          next: "stateCheck",
          lookup: async () => {
            if (assistant.remoteConfig.quotit) {
              // use getRates
              let plans = await assistant.lookup.apollo.query({
                query: Queries.getRates,
                variables: {
                  source: assistant.remoteConfig.ideon ? "ideon" : "quotit"
                }
              });
              return plans.data.getRates;
            } else {

              let plans = await assistant.lookup.apollo.query({
                query: Queries.SEARCH_PLANS
              });
              return plans.data.searchPlans;
            }
          }
        },
        stateCheck: {
          type: "if",
          variable: "searchPlans.total",
          check: "==",
          value: 0,
          ifTrue: "searchError",
          ifFalse: "intro"
        },
        searchError: {
          type: "text",
          text: (assistant: any) => {
            return `Unfortunately, I’m having trouble finding plans at the moment. Please give our Member Services team a call at +1 (831) 288-6412 and we'll find plans that are available to you. Our team is available Monday through Friday 9am to 7pm Central Time or anytime at jamie@forwhen.com.`;
          },
        },
        intro: {
          type: "text",
          text: (assistant: any) => {
            return `I found ${assistant.lookup.answers.get("searchPlans.total")} health insurance policies for you. May I ask more questions to help me better understand your healthcare needs, budget, and preferences?`;
          },
          next: "askToRefineQuestion"
        },
        askToRefineQuestion: {
          type: "question",
          input: "buttons",
          next: "ifRefine",
          saveTo: "refineQuestions",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" }
          ]
        },
        ifRefine: {
          type: "if",
          variable: "refineQuestions",
          check: "==",
          value: true,
          ifTrue: undefined,
          ifFalse: "notRefining"
        },
        notRefining: {
          type: "text",
          text: "Okay, I will always be here to help!",
          next: "goToPolicies"//"checkAuth"
        },
        checkAuth: {
          type: "if",
          variable: "userAuthed",
          check: "==",
          value: true,
          ifTrue: "goToPolicies",
          ifFalse: "createAccountPrompt"
        },
        createAccountPrompt: {
          type: "text",
          text: "In order to secure and protect your data lets create a password for your account.",
          next: "createPassword"
        },
        createPassword: {
          type: "question",
          input: "password",
          saveTo: async (value: string) => {
            return await assistant.lookup.auth.signup(
              assistant.lookup.answers.get("personalInfo.email"),
              value,
              assistant.lookup.answers.get("personalInfo.firstName"),
              assistant.lookup.answers.get("personalInfo.lastName")
            );
          },
          next: "goToPolicies"
        },
        goToPolicies: {
          type: "question",
          saveTo: "goToPolicies",
          input: "buttons",
          options: [
            { value: true, text: "View your policies" }
          ],
          next: "redirectToPolicies"
        },
        redirectToPolicies: {
          type: "redirect",
          url: "/jamie/recommended-plans"
        }
      }
    },
    cobra: {
      start: "ifCobraPlan",
      next: "preferences",
      helper: function () {
        return (
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography color="white.main">COBRA (Consolidated Omnibus Budget Reconciliation Act) is a federal law that allows certain individuals to continue their employer-sponsored health insurance coverage after they leave their job or experience a qualifying event, such as a reduction in hours or a divorce. COBRA applies to employer group health plans that cover 20 or more employees.</Typography>
            <Typography color="white.main">I gather information about your COBRA policy to help both of us compare your new plan options with your COBRA option.</Typography>
            <Typography color="white.main">Don&apos;t worry if you don&apos;t have access to a COBRA policy, these are generally very expensive and I can find you plenty of alternatives.</Typography>
          </Box>
        )
      },
      steps: {
        intro: {
          type: "text",
          text: "COBRA is a temporary solution for individuals who have lost their job and need to maintain their health insurance coverage. It can be a valuable option, but it is often more expensive than other health insurance options.",
          next: "check"
        },
        check: {
          type: "if",
          variable: "cobra.estimate",
          check: ">",
          value: 0,
          ifTrue: "explainCost",
          ifFalse: "checkJobLoss"
        },
        explainCost: {
          type: "text",
          text: async (assistant: any) => {
            const estimate = await assistant.lookup.answers.get("cobra.estimate");
            const formattedEstimate = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(estimate);

            const estimatedContribution = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format(await assistant.lookup.answers.get("insuranceInfo.premium") || 0);
            return `You have been paying ${estimatedContribution} for your medical insurance, however COBRA will cost you approximately ${formattedEstimate} per month based on the plans you were enrolled in. This is because you are now responsible for the full premium plus a 2% administration fee.`;
          },
          next: "cobraEnrollment"
        },
        checkJobLoss: {
          type: "if",
          variable: "currentSituation",
          check: "==",
          value: "jobLoss",
          ifTrue: "jobLossNotice",
          ifFalse: "askAboutJobLoss"
        },
        jobLossNotice: {
          type: "text",
          text: "Earlier you mentioned you had lost your job. Did your previous employer provide you information about COBRA?",
          next: "cobraQ"
        },
        askAboutJobLoss: {
          type: "text",
          text: "If you recently lost your job, you may be eligible to use COBRA to continue your coverage. Do you have access to a COBRA plan?",
          next: "cobraQ"
        },
        cobraQ: {
          type: "question",
          input: "buttons",
          saveTo: "cobra.access",
          next: "checkCobraAccess",
          options: [
            { value: true, text: "Yes" },
            { value: false, text: "No" },
          ]
        },
        checkCobraAccess: {
          type: "if",
          variable: "cobra.access",
          check: "==",
          value: true,
          ifTrue: "cobraPremium",
          ifFalse: undefined
        },
        cobraPremium: {
          type: "text",
          text: "How much, per month, is your COBRA premium?",
          next: "cobraPremiumQ"
        },
        cobraPremiumQ: {
          type: "question",
          input: "text",

          aria: {
            label: "Cobra Premium",
          },
          saveTo: "cobra.premium",
          next: "cobraEnrollment"
        },
        ifCobraPlan: {
          type: "text",
          text: "If you were enrolled in a health plan through your employer, your employer will likely offer you COBRA. COBRA is a temporary solution for individuals who have lost their job and need to maintain their health insurance coverage. It can be a valuable option, but it is often more expensive than other health insurance options.",
          next: "cobraPlanConfirmation"
        },
        cobraPlanConfirmation: {
          type: "text",
          text: async (assistant: any) => {
            const employerName = assistant.currentUser.companies?.length
              ? assistant.currentUser.companies[0]
              : "your employer";
            if (assistant.planComparison?.name) {
              return `Our records indicate that you were enrolled in the ${assistant.planComparison?.name} health insurance plan while you were covered by ${employerName}. Please confirm this is correct, or select the plan you were enrolled in.`
            }
            else {
              return `Please select which plan you were enrolled in while you were covered by ${employerName}`
            }
          },
          next: "cobraPlanDropdown"
        },
        cobraPlanDropdown: {
          type: "question",
          input: "select",
          options: [{
            text: "I was not enrolled in a health insurance plan from my employer", value: "none"
          }],
          default: async (assistant: any) => {
            return assistant.planComparison?.name ? assistant.planComparison.name : null
          },
          aria: {
            label: "cobraPlan"
          },
          next: "explainCobraCost",
        },
        explainCobraCost: {
          type: "text",
          text: async (assistant: any) => {
            const estimate = await assistant.lookup.answers.get("cobra.estimate");
            const formattedEstimate = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(estimate);

            const estimatedContribution = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
              .format(await assistant.lookup.answers.get("insuranceInfo.premium") || 0);
            const employerName = assistant.currentUser.companies?.length
              ? assistant.currentUser.companies[0]
              : "your employer";
            if (assistant.planComparison?.name) {
              return `In the ${assistant.planComparison.name} plan, you were paying ${estimatedContribution} per month. If you were to enroll in COBRA, your monthly cost for this plan would be ${formattedEstimate}.`
            }
            else {
              return `Even if you didn’t have health insurance from ${employerName} , When can still help you find affordable health insurance.`
            }
          },
          next: "ifLookAtPlans",
        },
        ifLookAtPlans: {
          type: "if",
          variable: () => {
            return assistant.planComparison?.name ? true : false
          },
          check: "==",
          value: true,
          ifTrue: "cobraEnrollment",
          ifFalse: "lookAtPlans"
        },
        cobraEnrollment: {
          type: "text",
          text: "Have you already enrolled in COBRA?",
          next: "cobraEnrollmentQuestion"
        },
        cobraEnrollmentQuestion: {
          type: "question",
          input: "buttons",
          saveTo: "cobra.enrolled",
          options: [
            {
              value: true,
              text: "Yes"
            }, {
              value: false,
              text: "No"
            }
          ],
          next: "enrollmentReason"
        },
        enrollmentReason: {
          type: "if",
          variable: "cobra.enrolled",
          check: "==",
          value: true,
          ifTrue: "reasonEnrolled",
          ifFalse: "cobraTotalPlan"
        },
        reasonEnrolled: {
          type: "text",
          text: "Why did you choose to enroll COBRA?",
          next: "reasonEnrolledQ",
        },
        reasonEnrolledQ: {
          type: "question",
          input: "select",
          saveTo: "cobra.reasonEnrolled",
          options: [
            {
              value: "keepPlan",
              text: "I wanted to keep my existing plan"
            },
            {
              value: "noOptions",
              text: "I didn't know there were other options"
            },
            {
              value: "employerPaid",
              text: "My employer is paying for it"
            },
            {
              value: "other",
              text: "Other"
            }
          ],
          next: "checkReasonEnrolledOther"
        },
        checkReasonEnrolledOther: {
          type: "if",
          variable: "cobra.reasonEnrolled",
          check: "==",
          value: "other",
          ifTrue: "reasonEnrolledOther",
          ifFalse: "explorePlans"
        },
        reasonEnrolledOther: {
          type: "text",
          text: "What was the reason you enrolled in COBRA?",
          next: "reasonEnrolledOtherQ"
        },
        reasonEnrolledOtherQ: {
          type: "question",
          input: "text",
          aria: {
            label: "Reason",
          },
          saveTo: "cobra.reasonEnrolledOther",
          next: "explorePlans"
        },
        explorePlans: {
          type: "text",
          text: "Even though you’ve already enrolled in COBRA, you can still explore plans the individual market plans that When offers. Would you like to continue anyway?",
          next: "explorPlansQ"
        },
        explorPlansQ: {
          type: "question",
          input: "buttons",
          saveTo: "explorePlans",
          options: [
            {
              value: true,
              text: "Yes"
            }, {
              value: false,
              text: "No"
            }
          ],
          next: "explorePlansAns"
        },
        explorePlansAns: {
          type: "if",
          variable: "explorePlans",
          check: "==",
          value: true,
          ifTrue: "lookAtPlans",
          ifFalse: "notLookAtPlans"
        },
        notLookAtPlans: {
          type: "text",
          text: "Okay! If you ever need assistance finding a new health insurance plan, don’t hesitate to visit us again or contact the When team!",
          next: "goToPolicies"
        },
        lookup: {
          type: "lookup",
          saveTo: "searchPlans",
          next: "cobraTotalPlan",
          lookup: async () => {
            if (assistant.remoteConfig.quotit) {
              // use getRates
              let plans = await assistant.lookup.apollo.query({
                query: Queries.getRates,
                variables: {
                  source: assistant.remoteConfig.ideon ? "ideon" : "quotit"
                }
              });
              return plans.data.getRates;
            } else {

              let plans = await assistant.lookup.apollo.query({
                query: Queries.SEARCH_PLANS
              });
              return plans.data.searchPlans;
            }
          }
        },
        cobraTotalPlan: {
          type: "text",
          text: async (assistant: any) => {
            return `Before you enroll in COBRA, let’s take a look at some other health insurance plans that could be a better fit for you than COBRA.
            We found ${assistant.lookup.answers.get("searchPlans.total")} plans that are more affordable than your COBRA option.`
          },
          next: "lookAtPlans"
        },
        lookAtPlans: {
          type: "text",
          text: "Let’s take a look at some health insurance options that could be a good fit for you.",
          next: "choosePlanStructure",
        },
        choosePlanStructure: {
          type: "lookup",
          saveTo: "TablePlanStructure",
          next: "ConfirmPlanStructure",
          lookup: async () => {
            return assistant.remoteConfig.jamiePlanPreviewTable
          }
        },
        ConfirmPlanStructure: {
          type: "if",
          variable: "TablePlanStructure",
          check: "==",
          value: true,
          ifTrue: "PlanOptionsTable",
          ifFalse: "plan1Description"
        },
        PlanOptionsTable: {
          type: "planOptionTable",
          next: "refineSearch",
        },
        plan1Description: {
          type: "text",
          text: async (assistant: any) => {
            const plan = assistant.plans[0]
            let deductible, outOfPocketMax;
            plan.benefits.forEach((benefit: any) => {
              if (benefit.enum === "ACA1MedicalDrugDeductible") {

                deductible = benefit.inNetwork;
              }
              if (benefit.enum === "ACA1EHBDrugOOPMax") {

                outOfPocketMax = benefit.inNetwork
              }
            })
            let difference = plan.rate - assistant.lookup.answers.get("cobra.estimate");
            let lessOrMore;
            if (difference > 0) {
              lessOrMore = "more";
            } else if (difference < 0) {
              lessOrMore = "less";
              difference = Math.abs(difference);
            }
            const formattedDifference = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(difference)
            const premium = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(plan.rate)
            return `The ${plan.name} plan is a ${plan.planType} plan with a ${assistant.lookup.answers.get("insuranceDetails.type")} deductible of ${!deductible ? "$0" : deductible} and out-of-pocket max of ${!outOfPocketMax ? "$0" : outOfPocketMax}. This plan costs ${premium} per month, which is ${formattedDifference} ${lessOrMore} than your COBRA plan option.`
          },
          next: "plan2Description",
        },
        plan2Description: {
          type: "text",
          text: async (assistant: any) => {
            const plan = assistant.plans[1]
            let deductible, outOfPocketMax;
            plan.benefits.forEach((benefit: any) => {
              if (benefit.enum === "ACA1MedicalDrugDeductible") {
                deductible = benefit.inNetwork;
              }
              if (benefit.enum === "ACA1EHBDrugOOPMax") {
                outOfPocketMax = benefit.inNetwork
              }
            })
            let difference = plan.rate - assistant.lookup.answers.get("cobra.estimate");
            let lessOrMore;
            if (difference > 0) {
              lessOrMore = "more";
            } else if (difference < 0) {
              lessOrMore = "less";
              difference = Math.abs(difference);
            }
            const formattedDifference = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(difference)
            const premium = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(plan.rate)
            return `The ${plan.name} plan is a ${plan.planType} plan with a ${assistant.lookup.answers.get("insuranceDetails.type")} deductible of ${!deductible ? "$0" : deductible} and out-of-pocket max of ${!outOfPocketMax ? "$0" : outOfPocketMax}. This plan costs ${premium} per month, which is ${formattedDifference} ${lessOrMore} than your COBRA plan option.`
          },
          next: "ifShortTerm"
        },
        ifShortTerm: {
          type: "if",
          variable: () => {
            if (assistant.shortTermPlan.length === 0) {
              return false;
            } else {
              return true;
            }
          },
          check: "==",
          value: true,
          ifTrue: "shortTermPlanDescription",
          ifFalse: "plan3Description"
        },
        shortTermPlanDescription: {
          type: "text",
          text: async (assistant: any) => {
            const plan = assistant.shortTermPlan[0]
            let policyMax, deductible;
            plan.benefits.forEach((benefit: any) => {
              if (benefit.enum === "AnnualDeductible") {

                deductible = benefit.inNetwork
              }
              if (benefit.enum === "PolicyMaximum") {

                policyMax = benefit.inNetwork
              }
            })
            return `When can also provide Short-Term, Limited Duration Health Insurance (STLDI) plans. The ${plan.name} plan provides coverage for emergency room admissions and inpatient hospital stays for up to ${policyMax} after a ${deductible}. This plan only costs $${plan.rate} per month, but excludes many types of care that are usually covered by traditional medical plans.`
          },
          next: "shortTermPlanInfo"
        },
        shortTermPlanInfo: {
          type: "text",
          text: "Short-term plans are temporary health insurance solutions designed to protect individuals against emergencies during coverage gaps. They offer coverage for under one year (less in some states) and are often more affordable than traditional health insurance plans. However, they often exclude routine care, pre-existing conditions, and have coverage limitations. You should carefully consider your health needs and the potential risks before opting for a short-term plan, as they do not meet the comprehensive standards set by the ACA.",
          next: "refineSearch"
        },
        plan3Description: {
          type: "text",
          text: async (assistant: any) => {
            const plan = assistant.plans[2]
            let deductible, outOfPocketMax;
            plan.benefits.forEach((benefit: any) => {
              if (benefit.enum === "ACA1MedicalDrugDeductible") {

                deductible = benefit.inNetwork;
              }
              if (benefit.enum === "ACA1EHBDrugOOPMax") {

                outOfPocketMax = benefit.inNetwork
              }
            })
            let difference = plan.rate - assistant.lookup.answers.get("cobra.estimate");
            let lessOrMore;
            if (difference > 0) {
              lessOrMore = "more";
            } else if (difference < 0) {
              lessOrMore = "less";
              difference = Math.abs(difference);
            }
            const formattedDifference = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(difference)
            return `The ${plan.name} plan is a ${plan.planType} plan with a ${assistant.lookup.answers.get("insuranceDetails.type")} deductible of ${!deductible ? "$0" : deductible} and out-of-pocket max of ${!outOfPocketMax ? "$0" : outOfPocketMax}. This plan costs $${plan.rate} per month, which is ${formattedDifference} ${lessOrMore} than your COBRA plan option.`
          },
          next: "refineSearch"
        },
        refineSearch: {
          type: "text",
          text: "If you are interested in these plans, you can view them here. Or, I can provide more tailored recommendations if you’ll answer a few short questions about your health plan preferences. May I ask more questions to help me better understand your healthcare needs, budget, and preferences?",
          next: "refineSearchQ"
        },
        refineSearchQ: {
          type: "question",
          input: "buttons",
          saveTo: "refineSeach",
          options: [
            {
              value: true,
              text: "Yes, refine my search"
            }, {
              value: false,
              text: "No, take me to my plans"
            }
          ],
          next: "refineSearchAns"
        },
        refineSearchAns: {
          type: "if",
          variable: "refineSeach",
          check: "==",
          value: true,
          ifTrue: undefined,
          ifFalse: "goToPolicies"
        },
        goToPolicies: {
          type: "question",
          saveTo: "goToPolicies",
          input: "buttons",
          options: [
            { value: true, text: "See plans" }
          ],
          next: "redirectToPolicies"
        },
        redirectToPolicies: {
          type: "redirect",
          url: "/jamie/recommended-plans"
        }
      }
    },
    // account: {
    //   start: "checkAuthed",
    //   steps: {
    //     checkAuthed: {
    //       type: "lookup",
    //       saveTo: "userAuthed",
    //       lookup: async function () {
    //         console.log(`userAuthed lookup`, assistant.lookup.auth)
    //         return assistant.lookup.auth.authed;
    //       },
    //       next: "ifAuthed"
    //     },
    //     ifAuthed: {
    //       type: "if",
    //       variable: "userAuthed",
    //       check: "==",
    //       value: "true",
    //       ifTrue: "alreadyLoggedIn",
    //       ifFalse: "checkEmail",
    //     },
    //     alreadyLoggedIn: {
    //       type: "text",
    //       text: "Thanks for already being logged in!"
    //     },
    //     checkEmail: {
    //       type: "lookup",
    //       saveTo: "emailUsed",
    //       lookup: async function () {
    //         // "auth().getUserByEmail(personalInfo.email).then(()=>true).catch(()=>false)",
    //         const emailExists = await assistant.lookup.auth.emailExists(assistant.lookup.answers.get("personalInfo.email"));
    //         console.log(`emailUsed lookup`, emailExists)
    //         return emailExists;
    //       },
    //       next: "ifEmailUsed"
    //     },
    //     ifEmailUsed: {
    //       type: "if",
    //       variable: "emailUsed",
    //       check: "==",
    //       value: "true",
    //       ifTrue: "emailUsedText",
    //       ifFalse: "askCreateText"
    //     },
    //     emailUsedText: {
    //       type: "text",
    //       text: "It looks like your email is already associated with an account"
    //     },
    //     askCreateText: {
    //       type: "text",
    //       text: "It looks like you don't have an account with us, I recommend you create one so you don't lose access to your data.",
    //       next: "askCreateQuestion"
    //     },
    //     askCreateQuestion: {
    //       type: "question",
    //       input: "buttons",
    //       saveTo: "createAccount",
    //       options: [
    //         {
    //           text: "Yes",
    //           value: true,
    //           variant: "contained",
    //           color: "primary"
    //         },
    //         {
    //           text: "Not yet",
    //           variant: "outlined",
    //           color: "warning",
    //           value: false
    //         }
    //       ],
    //       next: "ifCreateNow"
    //     },
    //     ifCreateNow: {
    //       type: "if",
    //       variable: "createAccount",
    //       check: "==",
    //       value: "true",
    //       ifTrue: "askPassword",
    //       ifFalse: "notCreatingAccount"
    //     },
    //     notCreatingAccount: {
    //       type: "text",
    //       text: "Ok, we'll worry about that later."
    //     },
    //     askPassword: {
    //       type: "question",
    //       text: "Provide a password",
    //       saveTo: async (value: string) => {
    //         return await assistant.lookup.auth.signup(assistant.lookup.answers.get("personalInfo.email"), value);
    //       },
    //       input: "password",
    //       next: "accountThanks"
    //     },
    //     accountThanks: {
    //       type: "text",
    //       text: "Thank you for creating an account!"
    //     }

    //   },
    //   next: "insurance"
    // },
    insurance: {
      start: "intro",
      next: "preferences",
      helper: function () {
        return (
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography color="white.main">If you currently or previously had insurance coverage I want to know about it.</Typography>
            <Typography color="white.main">The data I gather here will be used to indicate plans that are similar to your current or previous coverage, but may be less expensive.</Typography>
          </Box>
        )
      },
      steps: {
        intro: {
          type: "text",
          text: "Now I am going to ask you some questions about your current, or previous, insurance coverage.",
          next: "checkPremium"
        },
        // terminationDate: {
        //   type: "question",
        //   input: "date",
        //   saveTo: "formerEmployer.terminationDate",
        //   text: "When was your last day of employment?",
        //   next: "employerInsurance"
        // },
        // employerInsurance: {
        //   type: "text",
        //   text: "Did you have health insurance from your employer?",
        //   next: "employerInsuranceQuestion"
        // },
        // employerInsuranceQuestion: {
        //   type: "question",
        //   input: "buttons",
        //   saveTo: "formerEmployer.providedInsurance",
        //   options: [
        //     {
        //       value: true,
        //       text: "Yes"
        //     },
        //     {
        //       value: false,
        //       text: "No"
        //     }
        //   ],
        //   next: "checkEmployerInsurance"
        // },
        // checkEmployerInsurance: {
        //   type: "if",
        //   variable: "formerEmployer.providedInsurance",
        //   check: "==",
        //   value: true,
        //   ifTrue: "monthlyPremium",
        //   ifFalse: undefined
        // },
        checkPremium: {
          type: "if",
          variable: "insuranceInfo.premium",
          check: ">",
          value: 0,
          ifTrue: "planType",
          ifFalse: "monthlyPremium"
        },
        monthlyPremium: {
          type: "text",
          text: "Approximately, how much did you pay each month for health insurance premium at your last job?",
          next: "monthlyPremiumQ"
        },
        monthlyPremiumQ: {
          type: "question",
          default: (assistant: any) => {
            return assistant.lookup.answers.get("insuranceInfo.premium")
          },
          saveTo: "insuranceInfo.premium",
          input: "text",
          next: "planType"
        },
        planType: {
          type: "text",
          text: "What type of plan did you have?",
          next: "planTypeQ"
        },
        planTypeQ: {
          type: "question",
          saveTo: "insuranceInfo.type",
          input: "buttons",
          next: "coverageType",
          options: [
            {
              value: "hmo",
              text: "HMO"
            }, {
              value: "ppo",
              text: "PPO"
            }, {
              value: "pos",
              text: "POS"
            }, {
              value: "hdhp",
              text: "HDHP"
            }, {
              value: "eop",
              text: "EOP"
            }
          ]
        },
        coverageType: {
          type: "text",
          text: "What kind of coverage did you have?",
          next: "coverageTypeQ"
        },
        coverageTypeQ: {
          type: "question",
          saveTo: "insuranceInfo.coverageType",
          input: "buttons",
          options: [
            {
              value: "individual",
              text: "Individual"
            }, {
              value: "family",
              text: "Family"
            }
          ],
          next: undefined
        },
        // postEmployment: {
        //   type: "text",
        //   text: "Did your former employer provide you with any post-employment health insurance?",
        //   next: "postEmploymentQuestion"
        // },
        // postEmploymentQuestion: {
        //   type: "question",
        //   input: "buttons",
        //   saveTo: "formerEmployer.providedPostEmploymentCoverage",
        //   options: [
        //     {
        //       value: true,
        //       text: "Yes"
        //     },
        //     {
        //       value: false,
        //       text: "No"
        //     }
        //   ],
        //   next: "checkPostEmploymentProvided"
        // },
        // checkPostEmploymentProvided: {
        //   type: "if",
        //   variable: "formerEmployer.providedPostEmploymentCoverage",
        //   check: "==",
        //   value: true,
        //   ifTrue: "postEmploymentProvided",
        //   ifFalse: undefined
        // },
        // postEmploymentProvided: {
        //   type: "question",
        //   input: "select",
        //   text: "How many months of coverage did your employer provide?",
        //   saveTo: "formerEmployer.monthsOfPostEmploymentCoverage",
        //   options: [
        //     {
        //       value: "endOfMonth",
        //       text: "End of the month"
        //     },
        //     {
        //       value: 1,
        //       text: "1"
        //     },
        //     {
        //       value: 2,
        //       text: "2"
        //     },
        //     {
        //       value: 3,
        //       text: "3"
        //     },
        //     {
        //       value: 4,
        //       text: "4"
        //     },
        //     {
        //       value: 5,
        //       text: "5"
        //     },
        //     {
        //       value: 6,
        //       text: "6"
        //     },
        //     {
        //       value: 7,
        //       text: "7"
        //     },
        //     {
        //       value: 8,
        //       text: "8"
        //     },
        //     {
        //       value: 9,
        //       text: "9"
        //     },
        //     {
        //       value: "10+",
        //       text: "10+"
        //     }
        //   ]
        // }
      }
    },
    preferences: {
      start: "planType",
      next: "finalAccount",
      helper: function () {
        return (
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography color="white.main">Your health insurance preferences are important to me!</Typography>
            <Typography color="white.main">I will use this information to help pre-sort policies and make recommendations that most resemble your preferences.</Typography>
            <Typography color="white.main">While I can&apos;t know everything about you and your family this information gives me a good jump-start to finding the right health insurance plan for you.</Typography>
          </Box>
        )
      },
      steps: {
        intro: {
          type: "text",
          text: "Now I want to learn more about the what kind of insurance plan you are looking for now.",
          next: "planType"
        },
        planType: {
          type: "text",
          text: "There are three main types of health insurance networks to pick from. A PPO, which typically has a higher premium but more flexibility in choosing doctors. An HMO, which has a lower premium but less flexibility in choosing doctors. And a short term medical plan which is a temporary plan that is cheaper but has less coverage. Please tell me your preferences for each of these types of plans.",
          next: "ppoQ"
        },
        ppoQ: {
          type: "question",
          saveTo: "$preferences.planType.ppo",
          input: "slider",
          text: "PPO",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Interested",
            mid: "Somewhat Interested",
            high: "Strongly Prefer"
          },
          next: "hmoQ"
        },
        hmoQ: {
          type: "question",
          saveTo: "$preferences.planType.hmo",
          input: "slider",
          text: "HMO",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Interested",
            mid: "Somewhat Interested",
            high: "Strongly Prefer"
          },
          next: "shortTermQ"
        },
        shortTermQ: {
          type: "question",
          saveTo: "$preferences.planType.shortterm",
          input: "slider",
          text: "Short Term",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Interested",
            mid: "Somewhat Interested",
            high: "Strongly Prefer"
          },
          next: "otherPreferences"
        },
        otherPreferences: {
          type: "text",
          text: "How important are each of these to you?",
          next: "lowPremiumQ"
        },
        lowPremiumQ: {
          type: "question",
          saveTo: "$preferences.lowPremium",
          input: "slider",
          text: "Low Premium",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important"
          },
          next: "lowDeductibleQ"
        },
        lowDeductibleQ: {
          type: "question",
          saveTo: "$preferences.lowDeductible",
          input: "slider",
          text: "Low Deductible",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important"
          },
          next: "comprehensiveCoverageQ"
        },
        comprehensiveCoverageQ: {
          type: "question",
          saveTo: "$preferences.comprehensiveCoverage",
          input: "slider",
          text: "Comprehensive Coverage",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important"
          },
          next: "networkSizeQ"
        },
        networkSizeQ: {
          type: "question",
          saveTo: "$preferences.networkSize",
          input: "slider",
          text: "Network Size",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important"
          },
          next: "mentalHealthQ"
        },
        mentalHealthQ: {
          type: "question",
          saveTo: "$preferences.mentalHealth",
          input: "slider",
          text: "Mental Health Coverage",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important"
          },
          next: "dentalQ"
        },
        dentalQ: {
          type: "question",
          saveTo: "$preferences.dental",
          input: "slider",
          text: "Dental Coverage",
          min: 0,
          max: 1,
          default: 0.5,
          step: 0.1,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important"
          },
          next: "visionQ"
        },
        visionQ: {
          type: "question",
          saveTo: "$preferences.vision",
          input: "slider",
          text: "Vision Coverage",
          min: 0,
          max: 1,
          step: 0.1,
          default: 0.5,
          headers: {
            low: "Not Important",
            mid: "Somewhat Important",
            high: "Very Important"
          },
          next: "scorePlans"
        },
        scorePlans: {
          type: "action",
          async: true,
          action: async () => {
            // call score plans
            assistant.reScore({
              fetchPolicy: "network-only"
            });
          },
          next: "pcpUsage"
        },
        pcpUsage: {
          type: "text",
          text: "About many times in the past year did someone on your plan visit your Primary Care Physician?",
          next: "pcpUsageQ"
        },
        pcpUsageQ: {
          type: "question",
          input: "number",
          saveTo: "preferences.pcpUsage",
          next: "specialistUsage"
        },
        specialistUsage: {
          type: "text",
          text: "About many times in the past year did someone on your plan visit a specialist?",
          next: "specialistUsageQ"
        },
        specialistUsageQ: {
          type: "question",
          input: "number",
          saveTo: "preferences.specialistUsage",
          next: "prescriptionUsage"
        },
        prescriptionUsage: {
          type: "text",
          text: "About how many prescription drugs does your household take?",
          next: "prescriptionUsageQ"
        },
        prescriptionUsageQ: {
          type: "question",
          input: "number",
          saveTo: "preferences.prescriptionUsage",
          next: undefined
        },
        shortTermInfo: {
          type: "text",
          text: "Short-term health insurance is a great option for people who are between jobs, waiting for employer coverage to start, or waiting for Medicare coverage to start. Short-term plans are not ACA compliant and do not cover pre-existing conditions. These are very affordable options for people who are healthy and need coverage for a short period of time.",
          next: "shortTerm"
        },
        shortTerm: {
          type: "text",
          text: "If available to you, how many months of short-term coverage would you be interested in?",
          next: "shortTermLengthQ"
        },
        shortTermLengthQ: {
          type: "question",
          input: "select",
          saveTo: "insuranceDetails.shortTerm",
          next: undefined,
          options: [
            {
              value: 1,
              text: "1 Month"
            },
            {
              value: 2,
              text: "2 Months"
            },
            {
              value: 3,
              text: "3 Months"
            },
            {
              value: 4,
              text: "4 Months"
            },
            {
              value: 5,
              text: "5 Months",
            },
            {
              value: 6,
              text: "6 Months"
            }
          ]
        },
      }
    },
    // job: {
    //   start: "intro",
    //   next: "policies",
    //   steps: {
    //     intro: {
    //       type: "text",
    //       text: "I would like to collect some information about your former employer to help us develop better tools to assist people in the future.",
    //       next: "privacy"
    //     },
    //     privacy: {
    //       type: "text",
    //       text: "We take privacy seriously and will not reach out to your former employer without permission. Please review our privacy policy if you want more details.",
    //       next: "yearsEmployed"
    //     },
    //     yearsEmployed: {
    //       type: "question",
    //       input: "select",
    //       text: "How many years were you employed?",
    //       saveTo: "formerEmployer.yearsEmployed",
    //       next: "industry",
    //       options: [
    //         { value: 1, text: "1" },
    //         { value: 2, text: "2" },
    //         { value: 3, text: "3" },
    //         { value: 4, text: "4" },
    //         { value: 5, text: "5" },
    //         { value: 6, text: "6" },
    //         { value: 7, text: "7" },
    //         { value: 8, text: "8" },
    //         { value: 9, text: "9" },
    //         { value: "10+", text: "10+" }
    //       ]
    //     },
    //     industry: {
    //       type: "question",
    //       input: "select",
    //       text: "What industry were you working in?",
    //       saveTo: "formerEmployer.industry",
    //       next: "jobLevel",
    //       options: [
    //         { value: "technology", text: "Technology" },
    //         { value: "hospitality", text: "Hospitality" }
    //       ]
    //     },
    //     jobLevel: {
    //       type: "question",
    //       input: "select",
    //       text: "What was your previous job level?",
    //       saveTo: "formerEmployer.jobLevel",
    //       next: "title",
    //       options: [
    //         { value: "entry", text: "Entry" },
    //         { value: "intermediate", text: "Intermediate" },
    //         { value: "experienced", text: "Experienced" },
    //         { value: "managemenet", text: "Management" },
    //         { value: "middle management", text: "Middle Management" },
    //         { value: "upper management", text: "Upper Management" },
    //         { value: "executive", text: "Executive" },
    //       ]
    //     },
    //     title: {
    //       type: "question",
    //       input: "text",
    //       text: "What was your job title?",
    //       saveTo: "formerEmployer.jobTitle",
    //       next: "employer"
    //     },
    //     employer: {
    //       type: "question",
    //       input: "text",
    //       text: "Who was your former employer?",
    //       saveto: "formerEmployer.name",
    //     }
    //   }
    // },
    finalAccount: {// final create account step if not already created
      start: "intro",
      helper: function () {
        return (
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography color="white.main">You&apos;ve made it to the end! Let me create an account for you so that you can continue to view and enroll in policies.</Typography>
          </Box>
        )
      },
      steps: {
        intro: {
          type: "text",
          text: "That's all I need for now!",
          next: "checkAuth"
        },
        checkAuth: {
          type: "if",
          variable: "userAuthed",
          check: "==",
          value: true,
          ifTrue: "goToPolicies",
          ifFalse: "goToPolicies"//"createAccountPrompt"
        },
        createAccountPrompt: {
          type: "text",
          text: "In order to secure and protect your data lets create a password for your account.",
          next: "createPassword"
        },
        createPassword: {
          type: "question",
          input: "password",
          saveTo: async (value: string) => {
            return await assistant.lookup.auth.signup(
              assistant.lookup.answers.get("personalInfo.email"),
              value,
              assistant.lookup.answers.get("personalInfo.firstName"),
              assistant.lookup.answers.get("personalInfo.lastName")
            );
          },
          next: "goToPolicies"
        },
        goToPolicies: {
          type: "question",
          saveTo: "goToPolicies",
          input: "buttons",
          options: [
            { value: true, text: "View your policies" }
          ],
          next: "redirectToPolicies"
        },
        redirectToPolicies: {
          type: "redirect",
          url: "/jamie/recommended-plans"
        }
      }
    }
  },
  step: async (section: string, step: string, progress: boolean = false) => {
    if (!section)
      section = assistant.variables.section;
    let current: any = assistant.conversation[section];
    if (!step || !Object.keys(current.steps).includes(step))
      step = current.start;
    console.log(`step, ${section} - ${step}`);
    if (progress) {
      const nextStep = current.steps[step].next;
      console.log(`progress to ${nextStep}`);
      if (nextStep === undefined) {
        //go to next section
        section = current.next;
        // if no more sections return undefined
        if (section === undefined) {
          return undefined;
        }
        current = assistant.conversation[section];
        step = current.start;
      } else {
        step = nextStep;
      }
    }
    if (step === "STOP") {
      return;
    }
    const currentStep = current.steps[step];
    mixpanel.track(`TA.${section}.${step}`);
    Sentry.setContext("TransitionAssistant", {
      section,
      step,
    });
    // handle look ups and branching internally
    if (currentStep.type === "action") {
      if (currentStep.async) {
        currentStep.action().then(() => {

        }).catch((err: Error) => {
          console.error(err);
        })
      } else {
        try {
          await currentStep.action();
        } catch (err) {
          console.error(err);
        }
      }
      return await assistant.step(section, step, true);
    } else if (currentStep.type === "lookup") {
      //handle a lookup
      if (currentStep.async) {
        const saveTo = currentStep.saveTo;
        currentStep.lookup().then(async (result: any) => {
          console.log(`lookup results`, result)
          // save results for future lookup
          // assistant.variables[currentStep.saveTo] = result;
          await assistant.updateDoc(saveTo, result);
        }).catch((err: any) => {
          console.error("lookup async error", err);
        });
        return await assistant.step(section, step, true);
      } else {
        assistant.setLookupLoading(true);
        const result = await currentStep.lookup();
        console.log(`lookup results`, result)
        // save results for future lookup
        // assistant.variables[currentStep.saveTo] = result;
        try {
          await assistant.updateDoc(currentStep.saveTo, result);
          assistant.setLookupLoading(false);
          return await assistant.step(section, step, true);
        } catch (err) {
          console.error("lookup error", err);
          assistant.setLookupLoading(false);
        }
      }

    } else if (currentStep.type === "if") {
      let variable;
      // handle an if branch
      if (typeof currentStep.variable === "function") {
        variable = currentStep.variable();
      } else {
        variable = assistant.lookup.answers.get(currentStep.variable);
      }
      console.log(`evaluating ${variable} ${currentStep.check} ${currentStep.value}`);
      // eslint-disable-next-line no-eval
      const check = eval(`variable ${currentStep.check} currentStep.value`);
      console.log(`evaluated ${check}`)
      let nextStep = "";
      if (check) {
        console.log(`proceed to ${currentStep.ifTrue}`);
        nextStep = currentStep.ifTrue;
      } else {
        console.log(`proceed to ${currentStep.ifFalse}`);
        nextStep = currentStep.ifFalse;
      }
      if (nextStep === undefined) {
        section = current.next;
        if (section === undefined) {
          return undefined;
        }
      }
      return await assistant.step(section, nextStep);
      // const check = eval(`${assistant.variables[currentStep.variable]} ${currentStep.check} ${currentStep.value}`);
      // console.log(`evaluating ${currentStep.variable}: ${assistant.variables[currentStep.variable]} ${currentStep.check} ${currentStep.value}`, check);
      // if (check) {
      //   console.log(`proceed to ${currentStep.ifTrue}`);
      //   return assistant.step(section, currentStep.ifTrue);
      // } else {
      //   console.log(`proceed to ${currentStep.ifFalse}`);
      //   return assistant.step(section, currentStep.ifFalse);
      // }
    } else if (currentStep.type === 'question') {

      if (currentStep.options && typeof currentStep.options === 'string') {
        // do a lookup
        currentStep.options = assistant.lookup.answers.get(currentStep.options);
      }
    } else if (currentStep.type === 'redirect') {
      assistant.navigate(currentStep.url);
    } else if (currentStep.type === "gotoSection") {
      return await assistant.step(currentStep.section, currentStep.step);
    }
    let final = { ...currentStep };
    if (currentStep.default && typeof currentStep.default === "function") {
      final.default = await currentStep.default(assistant);
    }
    if (currentStep.text && typeof currentStep.text === "function") {
      final.text = await currentStep.text(assistant);
    }
    return {
      section,
      step,
      helper: current.steps[step].helper || current.helper || function () { return (<></>) },
      response: final
    };
  },
  updateDoc: undefined,
  navigate: undefined,
  setLookupLoading: undefined,
  remoteConfig: {},
  reScore: undefined
}


function TransitionAssistant() {
  const { settings } = useSettings();
  const { firestore } = useFirebase();
  const quotit = useRemoteConfig('quotit').asBoolean();
  const ideon = useRemoteConfig('ideon').asBoolean();
  assistant.remoteConfig.ideon = ideon;
  assistant.remoteConfig.quotit = quotit;
  const jamiePlanPreviewTable = useRemoteConfig('jamiePlanPreviewTable').asBoolean();
  assistant.remoteConfig.jamiePlanPreviewTable = jamiePlanPreviewTable;
  const auth = useRef(useAuth());
  const { user } = useAuth();
  const [error, setError] = useState<string>("");
  const [lookupLoading, setLookupLoading] = useState<boolean>(false);
  assistant.setLookupLoading = setLookupLoading;
  const [helpForm, setHelpForm] = useState<any>({});
  const { modalDispatch } = useContext(ModalContext);
  assistant.modalDispatch = modalDispatch;
  const [helpfulInfoAnchorEl, setHelpfulInfoAnchorEl] = React.useState<null | HTMLElement>(null);
  const helpfulInfoOpen = Boolean(helpfulInfoAnchorEl);
  const handleClickHelpfulInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHelpfulInfoAnchorEl(event.currentTarget);
  };
  const handleCloseHelpfulInfo = () => {
    setHelpfulInfoAnchorEl(null);
  };
  const [submitContactUs] = useMutation(Mutations.contactUs);
  const recaptchaRef = React.createRef<any>();
  // const jamieBox = useRef<any>(null)
  const [started, setStarted] = useState<boolean>(false);
  const [askForHelpModal, setAskForHelpModal] = useState<boolean>(false);
  const [contactSubmitted, setContactSubmitted] = useState<boolean>(false);
  const apollo = useApolloClient();
  const [section, setSection] = useState<string>("");
  const [step, setStep] = useState<string>("");
  const [currentMessage, setCurrentMessage] = useState<{ response: Message, helper: any, section: string, step: string }>();
  // const [blurEffect, setBlurEffect] = useState<any>({
  //   filter: `blur(8px)`
  // });
  // const [getStartedCSS, setGetStartedCSS] = useState<any>({
  // });
  const [messageThread, setMessageThread] = useState<Message[]>([]);
  const navigate = useNavigate();
  const basePath = settings.REACT_APP_WHEN_URL;
  const ref = useChatScroll(messageThread);
  const userDocRef = user ? doc(firestore, 'users', user?.uid!) : null;
  const [userDoc, userDocLoading, userDocError] = useDocument(
    userDocRef,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const userAnswersDoc = user ? doc(firestore, 'users', user?.uid!, "answers", user?.uid!) : null;
  const [answers, answersLoading, firestoreError] = useDocument(
    userAnswersDoc,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [currentUserSnapshot] = useDocument(
    user ? doc(firestore, 'users', user.uid) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const currentUserData = currentUserSnapshot?.data();
  assistant.currentUser = currentUserData;
  const userId = currentUserData?.uid;
  const syncEmployeeId = currentUserData?.syncEmployeeId;
  const [cobraPlanSnapshot] = useDocument(
    userId && syncEmployeeId ? doc(firestore, 'users', userId, "formerEmployments", syncEmployeeId) : null,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  assistant.cobraPlans = cobraPlanSnapshot?.data()?.cobraPlans;
  const [insuranceSnapshot] = useDocument(
    userId ? doc(firestore, "users", userId, "settings", "insurance") : null
  );
  if (insuranceSnapshot && insuranceSnapshot.data()?.cobra?.planComparison) {
    assistant.planComparison = insuranceSnapshot.data()?.cobra?.planComparison;
  }

  const [plans] = useCollection(
    query(
      collection(firestore, `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate")}/plans`),
      orderBy("insuranceType"),
      orderBy("recommendation.internalScore", "desc"),
      where("insuranceType", "!=", "ShortTerm"),
      limit(3)
    )
  );

  assistant.plans = [];
  plans?.docs.map((plan) => {
    return assistant.plans.push(plan.data())
  })

  const [shortTermPlan] = useCollection(
    query(
      collection(firestore, `users/${user?.uid}/effectiveDate/${answers?.get("insuranceDetails.effectiveDate")}/plans`),
      orderBy("insuranceType"),
      orderBy("recommendation.internalScore", "desc"),
      where("insuranceType", "==", "ShortTerm"),
      limit(1)
    )
  );

  assistant.shortTermPlan = []
  shortTermPlan?.docs.map((plan) => {
    return assistant.shortTermPlan.push(plan.data())
  })

  assistant.updateDoc = async (variable: string, value: any) => {
    if (userAnswersDoc) {
      try {
        console.log('assistant updateDoc', variable, value)
        if (variable[0] === "$") {
          // this saves into a subcollection
          // parse $subcollection out of $subcollection.document.field
          const [subcollectionIndicator, ...field] = variable.split(".");
          const subcollection = subcollectionIndicator.slice(1);
          console.log("save to subcollection", subcollection, "field", field.join("."))
          if (subcollection === "answers") {
            await updateDoc(userAnswersDoc, {
              [field.join(".")]: value
            })
          } else {
            const subCollectionRef = doc(firestore, 'users', user?.uid!, "answers", subcollection);
            try {
              await updateDoc(subCollectionRef, {
                [field.join(".")]: value
              })
            } catch (err) {
              await setDoc(subCollectionRef, { uid: user?.uid! });
              await updateDoc(subCollectionRef, {
                [field.join(".")]: value
              })
            }
          }
        } else {
          await updateDoc(userAnswersDoc, {
            [variable]: value
          });
        }

      } catch (err) {
        console.error("assistant.updateDoc error", err);
        setError("Failed to save to the database");
        throw err;
      }
      // console.log('assistant reloadAnswers')
      // await reloadAnswers();
      // console.log(JSON.stringify(answers?.data()));
    }
  }
  assistant.lookup.answers = answers;
  assistant.lookup.auth = auth.current;
  assistant.lookup.firebase = auth;
  assistant.lookup.apollo = apollo;
  assistant.navigate = navigate;
  const [reScorePlans] = useLazyQuery<{ total: number }, { effectiveDate: string }>(Queries.reScorePlans, {
    notifyOnNetworkStatusChange: true,
  });
  assistant.reScore = reScorePlans;
  let writeMessage = async (message: Message) => {
    console.log("writeMessage", message)
    setMessageThread(
      [
        ...messageThread,
        message
      ]
    );
  };
  let doneWriting = async (value: any) => {
    console.log(`doneWriting ${currentMessage?.section} - ${currentMessage?.step}`)
    if (currentMessage?.response?.aria !== undefined && currentMessage?.response?.aria.label === "cobraPlan") {
      if (value === "none") {
        assistant.planComparison = []
      } else {
        const selectedPlan = assistant.cobraPlans.find((plan: any) => plan.name === value);
        if (selectedPlan) {
          assistant.planComparison = selectedPlan;
        }
      }
      await setDoc(
        doc(firestore, "users", userId, "settings", "insurance"),
        { cobra: { ...insuranceSnapshot?.data()?.cobra, planComparison: assistant.planComparison } },
        { merge: true }
      );
    }
    // if current message is a question validate and save the answer
    if (currentMessage?.response.type === "question" && currentMessage.response.saveTo !== undefined) {
      console.log(`save ${value} into ${currentMessage.response.saveTo}`)
      if (currentMessage.response.saveTo instanceof Function) {
        //custom saveTo function
        try {
          await currentMessage.response.saveTo(value);
        } catch (err) {
          console.error("doneWriting saveTo error", err);
        }
      } else {
        try {
          await assistant.updateDoc(currentMessage.response.saveTo, value);
          if (currentMessage.response.aria?.label === "SELF-DOB") {
            await assistant.updateDoc("personalInfo.dateOfBirth", value)
          }
        } catch (err) {
          console.error("doneWriting updateDoc error", err);
        }
        // await reloadAnswers();
      }
      console.log('done saving')
    }
    //figure out what to do next
    try {
      const result = await assistant.step(section, step, true);
      if (result !== undefined) {
        setCurrentMessage(result);
        console.log(`stepped into message ${result}`)
        setSection(result.section);
        setStep(result.step);
        writeMessage({ ...result.response, id: messageThread.length.toString() });
      }
    } catch (err) {
      console.error("doneWriting step error", err);
    }
  };
  useEffect(() => {
    //check Cookies for employerLanding and set mixpanel
    if (Cookies.get("employerLanding")) {
      console.log("set employer", Cookies.get("employerLanding"));
      mixpanel.people.set("employer", Cookies.get("employerLanding"));
    }
  }, []);
  // const getStarted = async () => {
  //   setGetStartedCSS({
  //     display: "none"
  //   })
  //   setBlurEffect({
  //   })
  //   window.scroll({
  //     top: jamieBox.current.getBoundingClientRect().top + window.scrollY - 80,
  //     behavior: "smooth"
  //   })
  //   //jamieBox?.current?.scrollIntoView({ block: 'center' });
  //   // now for the fun stuff
  // }
  useEffect(() => {
    if (firestoreError)
      console.error(`firestoreError error`, firestoreError);
    if (userDocError)
      console.error(`userDocError error`, userDocError);

    if (user && !answersLoading && !userDocLoading && userAnswersDoc && userDocRef) {
      //check if firestore user answers doesn't exist
      if (!userDoc?.exists()) {
        setDoc(userDocRef, {}).then(() => {
          if (!answers?.exists()) {
            console.log("create answers")
            setDoc(userAnswersDoc, {}).then(() => {
              console.log("user answers created");
            }).catch((err) => {
              setError("Error connecting to database");

            });
          }
        });
      } else
        if (!answers?.exists()) {
          console.log("create answers")
          setDoc(userAnswersDoc, {}).then(() => {
            console.log("user answers created");
          }).catch((err) => {
            setError("Error connecting to database");

          });
        }
    }
  })
  useEffect(() => {
    if (currentMessage === undefined && !started && user && answers?.exists()) {
      setStarted(true);
      // check query params if section is set
      const urlParams = new URLSearchParams(window.location.search);
      const sectionParam = urlParams.get('section');
      if (sectionParam) {
        assistant.variables.section = sectionParam;
      }
      console.log("Start assistant");
      assistant.step().then((result: any) => {
        if (result !== undefined) {
          setCurrentMessage(result);
          console.log(`stepped into message ${result}`);
          setSection(result.section);
          setStep(result.step);
          writeMessage({ ...result.response, id: messageThread.length.toString() });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [started, writeMessage, messageThread.length])
  // function askForHelp() {
  //   // open modal with contact form
  //   setAskForHelpModal(true);
  // }

  const [members, setMembers] = useState()
  useEffect(() => {
    if (!answersLoading || !firestoreError) {
      setMembers(answers?.data()?.insuranceDetails?.household?.members)
    }
  }, [answers, answersLoading, firestoreError])
  async function submitHelp(e: any) {
    e.preventDefault();
    const result = await submitContactUs({
      variables: {
        name: helpForm.name,
        email: helpForm.email,
        phone: helpForm.phone,
        subject: helpForm.subject,
        description: helpForm.description
      }
    })
    if (result.errors) {
      console.error(result.errors);
    }
    setContactSubmitted(true);
    setHelpForm({});
    handleClose();
  }
  function handleClose() {
    setAskForHelpModal(false);
  }
  const addActivity = useCallback(async (user: any) => {
    const userActivityCollectionRef = collection(firestore, `users/${user?.uid}/activity`);
    await addDoc(userActivityCollectionRef, {
      activity: UserActivityTypes.USEDJAMIE,
      type: "user",
      userId: user?.uid,
      timestamp: new Date(),
    });
  }, [firestore]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await user?.getIdToken(true);
        await addActivity(user);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [user, addActivity]);

  useEffect(() => {
    if (lookupLoading) {
      autoScroll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lookupLoading])
  if (answersLoading || !user) {
    return (
      <Box>Loading</Box>
    );
  }
  function autoScroll() {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }
  return (
    <Box>
      <Box sx={{
        py: 4
      }}>

        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={7}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4
              }}>

                <Typography color={"primary"} variant="h1">Meet <Box component={"span"} fontWeight={600}>{assistant.settings.name}</Box> - Your Health Insurance Advocate</Typography>
                <Typography>{assistant.settings.name} has been specially crafted to help you navigate healthcare with a personal touch. {assistant.settings.name} is able to take the information you provide and analyze it to help you find the right healthcare solution for <Box component={"span"} color={"primary"} fontWeight={600}>you</Box>.</Typography>
                <Typography color={"primary"} variant="h2">We&apos;ll let {assistant.settings.name} take it from here.</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5} sx={{
              maxHeight: "400px",
              display: { xs: "none", sm: "block" }
            }}>
              <JamieImage height="100%" width="100%" />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box sx={{
        py: 4
      }}
        id="jamie"
      >
        <Container>
          <Box sx={{
            backgroundColor: "white.main",
            borderRadius: "12px"
          }}>
            <Box sx={{
              backgroundColor: "beige.dark",
              borderRadius: "12px 12px 0px 0px",
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: "center",
              padding: "19px 16px",
              gap: "10px"
            }}>
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "21px",
                ml: { xs: "0px", sm: "54px" }
              }}>
                <JamieImage width="40px" height="40px"></JamieImage>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "3px"
                }}>
                  <Typography color={"primary"}>Jamie, Health Insurance Advocate</Typography>
                  <Box sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px"
                  }}>
                    <Box sx={{
                      backgroundColor: "#26BE00",
                      borderRadius: "100%",
                      width: "8px",
                      height: "8px"
                    }}></Box>
                    <Typography fontSize="11px">Online</Typography>
                  </Box>
                </Box>
              </Box>
              <Button onClick={handleClickHelpfulInfo} disableRipple sx={{
                backgroundColor: "primary.main",
                borderRadius: "12px",
                padding: "6px 20px",
                display: "flex",
                maxWidth: "277px",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
                "&:hover": {
                  backgroundColor: "primary.main",

                }
              }}>
                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
                >
                  <InfoIcon width="16px"></InfoIcon>
                  <Typography color={"white.main"} sx={{ pl: "10px" }}>Helpful <Box component={"span"} fontWeight={600}>Information</Box></Typography>
                </Box>
                <KeyboardArrowDown color="secondary"></KeyboardArrowDown>
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={helpfulInfoAnchorEl}
                open={helpfulInfoOpen}
                onClose={handleCloseHelpfulInfo}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                  sx: { py: 0 }
                }}
                TransitionComponent={Fade}
                PaperProps={{
                  sx: {
                    borderRadius: "0px 0px 12px 12px",
                    mt: "-10px"
                  }
                }}
              >
                <Box sx={{
                  backgroundColor: "primary.main",
                  px: "20px",
                  py: "24px",
                  maxWidth: "277px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 2
                }}>
                  {currentMessage?.helper()}
                  <Typography color="white.main">If you are worried about sharing your information with {assistant.settings.name} please <Link href={`${basePath}/privacy`} fontWeight={600} color={"white.main"}>review our privacy policy</Link></Typography>

                </Box>
              </Menu>
            </Box>
            <Box>
              <Box sx={{

                height: "65vh",
                maxHeight: "65vh",
                overflowY: "scroll",
                display: "flex",
                flexDirection: "column",
                gap: "14px",
                px: { xs: "12px", md: "140px" },
                py: "20px"
              }} ref={ref}>
                <Typography fontSize={"11px"}>Jamie</Typography>
                {messageThread.map((message, index) => {
                  if (message.type === "question") {
                    // console.log("output a question")
                    return (
                      <Box key={message.id}>
                        <QuestionMessage message={message} speed={assistant.settings.typeSpeed} finished={doneWriting} members={members} />
                      </Box>
                    );
                  } else if (message.type === "text") {
                    return (
                      <Box key={message.id}>
                        <TypedMessage autoScroll={autoScroll} className={`${index === messageThread.length ? "last-line" : ""}`} text={message.text} speed={assistant.settings.typeSpeed} finished={doneWriting} />
                      </Box>
                    );
                  } else if (message.type === "planOptionTable") {
                    return (
                      <Box key={message.id}>
                        <PlanOptionsJamie effectiveDate={answers?.get("insuranceDetails.effectiveDate") || ""} autoScroll={autoScroll} speed={assistant.settings.typeSpeed} finished={doneWriting} cobraEstimate={assistant.lookup.answers.get("cobra.estimate") || 0} />
                      </Box>
                    )
                  }
                  else {
                    return (
                      <Box key={message.id}>

                      </Box>
                    )
                  }
                })
                }
                {lookupLoading && <Box sx={{
                  display: "flex",
                  justifyContent: "start"
                }}>
                  <Box sx={{
                    padding: 1,
                    backgroundColor: "gray.light",
                    borderRadius: "17px 17px 17px 2px",
                    maxWidth: { xs: "100%", sm: "50%" }
                  }}>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 2,
                        "& > div": {
                          width: "10px",
                          height: "10px",
                          marginX: 0.5,
                          borderRadius: "50%",
                          backgroundColor: "gray.main",
                          opacity: 1,
                          animation: "bouncing-loader 0.6s infinite alternate",
                          "&:nth-child(2)": {
                            animationDelay: "0.2s",
                          },
                          "&:nth-child(3)": {
                            animationDelay: "0.4s",
                          },
                        },
                        "@keyframes bouncing-loader": {
                          to: {
                            opacity: 0.1,
                            transform: "translateY(-16px)",
                          },
                        },
                      }}
                    >
                      <Box />
                      <Box />
                      <Box />
                    </Box>
                  </Box>
                </Box>
                }
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      <Modal
        open={askForHelpModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={{
          backgroundColor: "white.main",
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: "400px",
          transform: "translate(-50%, -50%)",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Contact Us
          </Typography>
          <TextField label="Name" value={helpForm.name} onChange={(e) => { setHelpForm({ ...helpForm, name: e.target.value }) }}></TextField>
          <TextField label="Email" value={helpForm.email} onChange={(e) => { setHelpForm({ ...helpForm, email: e.target.value }) }}></TextField>
          <TextField label="Phone" value={helpForm.phone} onChange={(e) => { setHelpForm({ ...helpForm, phone: e.target.value }) }}></TextField>
          <TextField label="Subject" value={helpForm.subject} onChange={(e) => { setHelpForm({ ...helpForm, subject: e.target.value }) }}></TextField>
          <TextField rows={3} multiline label="Description" value={helpForm.description} onChange={(e) => { setHelpForm({ ...helpForm, description: e.target.value }) }}></TextField>
          <div className="g-recaptcha" data-sitekey=""></div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={settings.REACT_APP_RECAPTCHA || ""}
          />
          <Button onClick={submitHelp} variant="contained">Submit</Button>
        </Paper>
      </Modal>
      <Dialog open={error !== ""} onClose={() => { setError("") }}>
        <Box sx={{
          p: 4
        }}>
          <Typography color="error">Error: {error}</Typography>
          <Button onClick={() => { setError("") }}>Close</Button>
        </Box>
      </Dialog>
      <Snackbar
        open={contactSubmitted}
        autoHideDuration={6000}
        onClose={() => { setContactSubmitted(false) }}
        message="Thank you for contacting us, someone will reach out soon."
      />
    </Box >
  );
}

function TypedMessage({ text, speed, finished, className, autoScroll }: { text: string, speed: number, finished: Function, className?: string, autoScroll?: Function }) {
  const [message, setMessage] = useState<string>(text[0]);
  const [interval, setNewInterval] = useState<any>();
  const [complete, setComplete] = useState<boolean>(false);
  const ref = useRef<string>("");
  ref.current = message;
  const scrollSpan = useRef<any>();

  useEffect(() => {
    if (complete) {
      setMessage(text);
    }
    if (!interval) {
      let newInterval = setInterval(() => {
        // console.log(`currently ${message} ref ${ref.current}`)
        if (ref.current.length < text.length)
          setMessage(ref.current + text[ref.current.length])
        if (ref.current.length >= text.length) {
          setComplete(true);
          finished();
          clearInterval(newInterval);
        }
      }, speed);
      setNewInterval(newInterval);
    }
  }, [complete, finished, interval, message, speed, text])
  useEffect(() => {
    if (scrollSpan.current) {
      //overflowDiv.current.scrollTop = 
      if (typeof autoScroll === "function") {
        autoScroll();
      }
    }
    //scrollSpan.current.scrollIntoView({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);
  return (
    <Box>
      <Typography className={className} sx={{
        padding: "10px 15px",
        backgroundColor: "#F1F1F1",
        borderRadius: "17px 17px 17px 2px",
        width: "fit-content",
        maxWidth: { xs: "100%", sm: "50%" },
      }} variant="body1">{message} </Typography>
      <span ref={scrollSpan}></span>
    </Box>
  );
}


function QuestionMessage({ message, speed, finished, members }: { message: Message, speed: number, finished: Function, members?: Object }) {
  const [answer, setAnswer] = useState<string | number>(message.default || "");
  const [finalText, setFinalText] = useState<string | number>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [validationText, setValidationText] = useState<string>("");
  function validate(value: string | number) {
    if (typeof value === "string") {
      if (message.input === "date") {
        if (message?.aria?.label === "SELF-DOB") {
          const currentDate = new Date();
          if (currentDate < new Date(value)) {
            return false
          }
        }
        return value.match("^[0-9]{2}/[0-9]{2}/[0-9]{4}$");
      } else if (typeof message.validation === "string") {
        return value.match(message.validation);
      } else if (typeof message.validation === "function") {
        return message.validation(value);
      }
    }
    return true;
  }
  function clickButton(value: any) {
    if (validate(value)) {
      console.log(`button clicked ${value}`)
      finished(value);
      setDisabled(true);
      setValidationText("");
      message.options?.forEach((option => {
        if (option.value === value) {
          setFinalText(option.text);
        }
      }))
    } else {
      if (message.input === "date") {
        setValidationText("Please provide a date with the format MM/DD/YYYY");
      } else {
        setValidationText("Please provide a valid value");
      }
    }
  }
  function submit() {
    try {
      setLoading(true)
      if (validate(answer)) {
        finished(answer);
        setDisabled(true);
        setValidationText("");
        if (message.options) {
          message.options?.forEach((option => {
            if (option.value === answer) {
              setFinalText(option.text);
            }
          }))
        } else {
          if (message.input === "password" && typeof answer === "string") {
            setFinalText("*".repeat(answer.length));
          } else {
            setFinalText(answer);
          }
        }
      } else {
        if (message.input === "date") {
          setValidationText("Please provide a date with the format MM/DD/YYYY");
        } else {
          setValidationText("Please provide a valid value");
        }
      }
    } catch (error) {
      //handle error
    } finally {
      setLoading(false)
    }
  }
  if (loading) {
    return (
      <>
        <Box sx={{
          display: "flex",
          justifyContent: "start"
        }}>
          <Box sx={{
            padding: 1,
            backgroundColor: "gray.light",
            borderRadius: "17px 17px 17px 2px",
            maxWidth: { xs: "100%", sm: "50%" }
          }}>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
                "& > div": {
                  width: "10px",
                  height: "10px",
                  marginX: 0.5,
                  borderRadius: "50%",
                  backgroundColor: "gray.main",
                  opacity: 1,
                  animation: "bouncing-loader 0.6s infinite alternate",
                  "&:nth-child(2)": {
                    animationDelay: "0.2s",
                  },
                  "&:nth-child(3)": {
                    animationDelay: "0.4s",
                  },
                },
                "@keyframes bouncing-loader": {
                  to: {
                    opacity: 0.1,
                    transform: "translateY(-16px)",
                  },
                },
              }}
            >
              <Box />
              <Box />
              <Box />
            </Box>
          </Box>
        </Box>
      </>
    );
  }
  if (finalText !== "" && message.input !== 'slider') {
    return (
      <Box sx={{
        display: "flex",
        justifyContent: "end"
      }}>
        <Box sx={{
          padding: "10px 15px",
          backgroundColor: "primary.main",
          borderRadius: "17px 17px 2px 17px",
          maxWidth: { xs: "100%", sm: "50%" }
        }}>
          <Typography color="white.main">{message.aria?.label === 'Phone Number' ? formatPhoneNumber(finalText) : finalText}</Typography>
        </Box>
      </Box>
    );
  }
  if (message.input === 'select' && message.options) {
    if (message?.aria?.label === "cobraPlan" && assistant.cobraPlans) {
      let options = []
      assistant.cobraPlans.map((plan: any) => {
        return options.push({ text: plan.name, value: plan.name })
      })
      options.push({ text: "I was not enrolled in a health insurance plan from my employer", value: "none" })
      message.options = options
    }
    if (message.aria?.label === "memberRelationship") {
      let spouseCount = 0;
      const words = message.text.split(" ")
      const name = words[2]
      if (members) {
        Object.values(members).forEach((member: any) => {
          if (member?.relationship === "spouse" && member.name !== name) {
            spouseCount++;
          }
        });

        if (spouseCount >= 1) {
          message.options = message.options.filter((option) => option.value !== "spouse");
        }
      }
    }
    if (!answer) {
      //default to first answer
      setAnswer(message.options[0].value);
    }
    return (
      <Box key={message.id} component="form" onSubmit={(e: any) => { e.preventDefault(); submit(); }} sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        gap: "5px",
        maxWidth: { xs: "100%", sm: "50%" }
      }}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label">{message.text}</InputLabel>
          <Select disabled={disabled} label={message.text} value={answer} onChange={(e) => { setAnswer(e.target.value) }} sx={{ width: "100%" }}>
            {message.options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>{option.text}</MenuItem>
              )
            })
            }
          </Select>
        </FormControl>
        <Button aria-label="save" color="primary" variant="contained" type="submit" disabled={disabled} sx={{ borderRadius: "12px", width: "54px", height: "54px", minWidth: "54px", maxWidth: "54px", maxHeight: "54px", p: "0px" }}><Check sx={{ color: "white.main" }} /></Button>
      </Box>
    );
  } else if (message.input === "password") {
    return (
      <Box key={message.id} component="form" onSubmit={(e: any) => { e.preventDefault(); submit(); }} sx={{
        display: "flex",
        flexDirection: "row",
        maxWidth: { xs: "100%", sm: "50%" },
        gap: "5px"
      }}>
        <TextField autoComplete="new-password" type="password" label={message.text} disabled={disabled} value={answer} sx={{ width: "100%" }} onChange={(e) => { setAnswer(e.target.value) }} />
        <Button aria-label="save" color="primary" variant="contained" type="submit" disabled={disabled} sx={{ borderRadius: "12px", width: "54px", height: "54px", minWidth: "54px", maxWidth: "54px", maxHeight: "54px", p: "0px" }}><Check sx={{ color: "white.main" }} /></Button>
      </Box>
    );
  } else if (message.input === "date") {
    return (
      <>
        <Box key={message.id} component="form" onSubmit={(e: any) => { e.preventDefault(); submit(); }} sx={{
          display: "flex",
          flexDirection: "row",
          width: { xs: "100%", sm: "50%" },
          gap: "5px"
        }}>
          <DesktopDatePicker
            label={message.text}
            maxDate={message.aria?.label === "DOB" ? moment() : undefined}
            format="MM/DD/YYYY"
            onChange={(value) => {
              if (value)
                setAnswer(value.format("MM/DD/YYYY"))
            }}
            onError={(error) => {
              if (error) {
                setValidationText("Please provide a date with the following format MM/DD/YYYY");
              } else {
                setValidationText("")
              }
            }}
            disabled={disabled}
            value={answer !== "" ? moment(answer) : null}
          ></DesktopDatePicker>
          <Button aria-label="save" color="primary" variant="contained" type="submit" disabled={disabled} sx={{ borderRadius: "12px", width: "54px", height: "54px", minWidth: "54px", maxWidth: "54px", maxHeight: "54px", p: "0px" }}><Check sx={{ color: "white.main" }} /></Button>
        </Box>
        {validationText && <Typography color="error">{validationText}</Typography>}
      </>
    );
  } else if (message.input === 'buttons' && message.options) {
    return (
      <Box key={message.id} sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "end",
        gap: "5px"
      }}>
        {message.options.map(option => {
          return (
            <Button aria-label={option.text} key={option.value} type="submit" variant={option.variant || "outlined"} color={option.color} disabled={disabled} onClick={() => { clickButton(option.value); }}>{option.text}</Button>
          );
        })}
      </Box>
    );
  } else if (message.input === 'slider') {
    return (
      <Box key={message.id} component="form" onSubmit={(e: any) => { e.preventDefault(); submit(); }} sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        justifyContent: "end",
        alignItems: "center",
        gap: 4
      }}>
        <Typography fontWeight={600} color={"primary"}>{message.text}</Typography>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%"
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between"
          }}>
            <Typography>{message?.headers?.low}</Typography>
            <Typography>{message?.headers?.mid}</Typography>
            <Typography>{message?.headers?.high}</Typography>
          </Box>
          <Slider
            aria-label="ppo plan type"
            disabled={disabled}
            defaultValue={0.5}
            value={typeof answer === "number" ? answer : 0.5}
            onChange={(e, v) => {
              if (typeof v === "number") {
                setAnswer(v)
              } else {
                //do nothing
              }
            }}
            step={message.step || 0.1}
            marks
            min={message.min || 0}
            max={message.max || 1}
          />
        </Box>
        <Button aria-label="save" color="primary" variant="contained" type="submit" disabled={disabled} sx={{ borderRadius: "12px", width: "54px", height: "54px", minWidth: "54px", maxWidth: "54px", maxHeight: "54px", p: "0px" }}><Check sx={{ color: "white.main" }} /></Button>
      </Box>
    );
  } else {
    console.log("message", message)
    return (
      <Box key={message.id} component="form" onSubmit={(e: any) => { e.preventDefault(); submit(); }} sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
        gap: "5px"
      }}>
        <InputLabel sx={{ display: "none" }} hidden={true} aria-hidden={false} htmlFor={`input-${message.id}`}>{message.aria?.label}</InputLabel>
        {message.aria?.label === 'Phone Number' ? (
          <MuiPhoneNumber
            aria-label={message.aria?.label}
            id={`input-${message.id}`}
            autoComplete={message.autoComplete}
            error={validationText !== ""}
            helperText={validationText}
            type={message.input || ""}
            label={message.text}
            disabled={disabled}
            value={answer}
            autoFormat={false}
            sx={{
              xs: '100%', sm: '50%'
            }}
            onChange={(value) => {
              if (typeof value === "string" || typeof value === "number") setAnswer(value);
            }}
            variant="outlined"
            onlyCountries={['us']}
            defaultCountry={'us'}
            disableAreaCodes={true}
            countryCodeEditable={false}
          />
        ) :
          <TextField aria-label={message.aria?.label} id={`input-${message.id}`} autoComplete={message.autoComplete} error={validationText !== ""} helperText={validationText} type={message.input || ""} label={message.text} disabled={disabled} value={answer} sx={{
            xs: '100%', sm: '50%'
          }} onChange={(e) => { setAnswer(e.target.value) }} />
        }
        <Button aria-label="save" color="primary" variant="contained" type="submit" disabled={disabled} sx={{ borderRadius: "12px", width: "54px", height: "54px", minWidth: "54px", maxWidth: "54px", maxHeight: "54px", p: "0px" }}><Check sx={{ color: "white.main" }} /></Button>
      </Box>
    );
  }
}

// function ButtonMessage({message, speed, finished}: {message: Message, speed: number, finished: Function }) {
//   const [answer, setAnswer] = useState<string>("");
//   const [disabled, setDisabled] = useState<boolean>(false);
//   function submit(value: any) {
//     finished(value);
//     setDisabled(true);
//   }
//   if (!message.options)
//     return (<></>);
//   return (
//     <Box key={message.id} sx={{
//       display: "flex",
//       flexDirection: "row"
//     }}>
//       {message.options.map(option => {
//         return (
//           <Button key={option.value} type="submit" disabled={disabled} onClick={() => { submit(option.value); }}>{option.text}</Button>
//         );
//       })}
//     </Box>
//   );
// }
function useChatScroll<T>(dep: T): React.MutableRefObject<HTMLDivElement | undefined> {
  const ref = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    if (ref.current) {
      // ref.current.scroll({ top: ref.current.scrollTop, behavior: "smooth" })
      ref.current.scrollTop = ref.current.scrollHeight + ref.current.offsetHeight;
    }
  }, [dep]);
  return ref;
}
export default Sentry.withProfiler(TransitionAssistant);
