import React, { useEffect, useState } from 'react';
import { Typography, Box, Button, TextField, FormControl, Divider, Dialog, DialogContent } from "@mui/material";
import { useAuth } from 'contexts/User';
import ConfirmSubmissionDialog from 'pages/MyAccount/ConfirmSubmissionDialog';
import { addDoc, collection } from 'firebase/firestore';
import { useMutation } from '@apollo/client';
import { useFirebase } from 'contexts/Firebase';
import { Mutations } from 'services/apollo';
import { UserActivityTypes } from 'services/Interfaces';
import { useNotifications } from 'contexts/Notification';

interface AccountInfo {
    nameOnAccount?: string;
    accountNumber: string;
    confirmAccountNumber?: string;
    routingNumber?: string;
}

function BankAccountModal({ headerText, bodyText, submitButtonText, cancelButtonText, open, onClose }: { headerText: string, bodyText?: string, submitButtonText: string, cancelButtonText: string, open: boolean, onClose: () => void }) {
    const { user } = useAuth();
    const [validationObject, setValidationObject] = useState<any>({});
    const { notifications } = useNotifications(); // for showing notifications
    const [setUserDirectDeposit] = useMutation(Mutations.setUserDirectDeposit);
    const [accountInfo, setAccountInfo] = useState<AccountInfo>({
        nameOnAccount: "",
        accountNumber: "",
        confirmAccountNumber: "",
        routingNumber: "",
    });
    useEffect(() => {
        setValidationObject({});
        setAccountInfo({
            nameOnAccount: "",
            accountNumber: "",
            confirmAccountNumber: "",
            routingNumber: ""
        })

    }, [open])
    const { firestore } = useFirebase();
    const [openConfirmSubmission, setOpenConfirmSubmission] = useState(false);

    const handleConfirmation = (e: React.FormEvent) => {
        e.preventDefault();
        const validation: any = {};
        const accountNumberRegex = /^[0-9]{6,17}$/;

        if (!accountInfo.accountNumber.match(accountNumberRegex)) {
            validation.accountNumber = 'Please enter a valid account number';
        }

        if (accountInfo.accountNumber !== accountInfo.confirmAccountNumber) {
            validation.confirmAccountNumber = 'Account numbers do not match';
        }

        Object.keys(accountInfo).forEach(key => {
            if (!accountInfo[key as keyof AccountInfo]) {
                validation[key] = `${key.charAt(0).toUpperCase()}${key.slice(1)} cannot be empty`;
            }
        });

        setValidationObject(validation);

        if (Object.keys(validation).length === 0) {
            setOpenConfirmSubmission(true);
        }
    };

    const handleChangeForm = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setAccountInfo({ ...accountInfo, [name]: value });

        const validation = { ...validationObject };
        if (!value) {
            validation[name] = `${name.charAt(0).toUpperCase()}${name.slice(1)} cannot be empty`;
        } else {
            delete validation[name];
        }

        if (name === "confirmAccountNumber" && accountInfo.accountNumber && value !== accountInfo.accountNumber) {
            validation.confirmAccountNumber = 'Account numbers do not match';
        } else if (name === "accountNumber" && accountInfo.confirmAccountNumber && value !== accountInfo.confirmAccountNumber) {
            validation.confirmAccountNumber = 'Account numbers do not match';
        } else {
            delete validation.confirmAccountNumber;
        }

        setValidationObject(validation);
    };

    const handleSave = async () => {
        if (Object.keys(validationObject).length > 0) {
            return;
        }

        try {
            await setUserDirectDeposit({
                variables: {
                    accountNumber: accountInfo.accountNumber || "",
                    nameOnAccount: accountInfo.nameOnAccount || "",
                    routingNumber: accountInfo.routingNumber || "",
                },
            });

            const userActivityCollectionRef = collection(firestore, `users/${user?.uid}/activity`);
            await addDoc(userActivityCollectionRef, {
                activity: UserActivityTypes.UPDATEDSETTINGS,
                type: "user",
                message: "Bank account details added/updated.",
                userId: user?.uid,
                timestamp: new Date(),
            });

            notifications.success("Bank account added successfully");
            setOpenConfirmSubmission(false);
            setValidationObject({});
            onClose();
        } catch (error) {
            console.error("Error saving bank account:", error);
            notifications.error("An error occurred while adding the bank account");
        }
    };

    return (
        <>
            <Dialog data-testid="add-bank-account" open={open} onClose={onClose} sx={{ "& .MuiPaper-root": { width: { sm: "50%", xs: "70%" } } }}>
                <DialogContent sx={{ p: { xs: 2, sm: 4 } }}>
                    <Box component='form' onSubmit={handleConfirmation} sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: { sm: 2, xs: 2 }
                    }}>
                        <Typography variant="h2" sx={{ pb: { xs: 0, sm: 2 }, fontSize: { xs: "1.4rem", sm: "1.8rem" } }}>{headerText}</Typography>
                        {bodyText &&
                            <>
                                <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
                                <Typography data-testid="text-body" variant="body1" sx={{ pb: 2, fontSize: { xs: "0.8rem", sm: "1rem" } }}>{bodyText}</Typography>
                            </>}
                        <FormControl>
                            <TextField
                                label="Name on Account"
                                variant="outlined"
                                type="text"
                                error={Boolean(validationObject.nameOnAccount)}
                                value={accountInfo.nameOnAccount || ""}
                                onChange={handleChangeForm("nameOnAccount")}
                                fullWidth
                            />
                            {validationObject.nameOnAccount && <Typography component="div" color="error" variant="caption">{validationObject.nameOnAccount}</Typography>}
                        </FormControl>
                        <FormControl>
                            <TextField
                                label="Account Number"
                                variant="outlined"
                                error={Boolean(validationObject.accountNumber)}
                                value={accountInfo.accountNumber || ""}
                                onKeyDown={(event) => event.key === 'e' && event.preventDefault()}
                                onChange={handleChangeForm("accountNumber")}
                                fullWidth
                            />
                            {validationObject.accountNumber && <Typography component="div" color="error" variant="caption">{validationObject.accountNumber}</Typography>}
                        </FormControl>
                        <FormControl>
                            <TextField
                                label="Confirm Account Number"
                                variant="outlined"
                                error={Boolean(validationObject.confirmAccountNumber)}
                                onKeyDown={(event) => event.key === 'e' && event.preventDefault()}
                                value={accountInfo.confirmAccountNumber || ""}
                                onChange={handleChangeForm("confirmAccountNumber")}
                                fullWidth
                            />
                            {validationObject.confirmAccountNumber && <Typography component="div" color="error" variant="caption">{validationObject.confirmAccountNumber}</Typography>}
                        </FormControl>
                        <FormControl>
                            <TextField
                                label="Routing Number"
                                variant="outlined"
                                onKeyDown={(event) => event.key === 'e' && event.preventDefault()}
                                error={Boolean(validationObject.routingNumber)}
                                value={accountInfo.routingNumber || ""}
                                onChange={handleChangeForm("routingNumber")}
                                fullWidth
                            />
                            {validationObject.routingNumber && <Typography component="div" color="error" variant="caption">{validationObject.routingNumber}</Typography>}
                        </FormControl>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Button type="submit" variant="contained" color="primary" data-testid="save-button">
                                {submitButtonText}
                            </Button>
                            <Button variant="outlined" onClick={onClose} sx={{ mt: 2 }} data-testid="skip-button">
                                {cancelButtonText}
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            {openConfirmSubmission && <ConfirmSubmissionDialog confirmSubmit={handleSave} openConfirmSubmission={openConfirmSubmission} setOpenConfirmSubmission={setOpenConfirmSubmission} />}
        </>
    );
}

export default BankAccountModal;
