import { Button, Dialog, Divider, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import moment from "moment";
import { FormattedNumber } from "react-intl";
import { ReimbursementRequest } from "services/Interfaces"
import CloseIcon from '@mui/icons-material/Close';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import { getDownloadURL, ref } from "firebase/storage";
import { useState } from "react";
import CancelReimbursementDialog from "./CancelReimbursementDialog";
import { useNotifications } from "contexts/Notification";
import { Mutations } from 'services/apollo';
import { useMutation } from "@apollo/client";
import { useFirebase } from "contexts/Firebase";


const ViewReimbursementDialog = ({ openViewReimbursement, setOpenViewReimbursement, reimbursement }: { openViewReimbursement: boolean, setOpenViewReimbursement: Function, reimbursement: ReimbursementRequest }) => {

  const { storage } = useFirebase();
  const [openCancelReimbursement, setOpenCancelReimbursement] = useState(false)
  const { notifications } = useNotifications();
  const [cancelReimbursementRequest] = useMutation(Mutations.cancelReimbursementRequest);

  const cancelReimbursement = async () => {
    try {
      await cancelReimbursementRequest({
        variables: {
          userId: reimbursement?.userId || "",
          id: reimbursement?.id || "",
          whenBenefitId: reimbursement.whenBenefitId
        }
      })
      setOpenCancelReimbursement(false)
      setOpenViewReimbursement(false)
      notifications.success("Request cancelled successfully.")
    } catch (error) {
      console.log("error while canceling request.", error)
      notifications.error('An error occurred while cancelled the request. Please try again.');
    }
  };

  const viewattachment = (index: number) => {
    const file = reimbursement.files[index]
    const fileRef = ref(storage, file.path)
    getDownloadURL(fileRef)
      .then((downloadURL) => {
        window.open(downloadURL, '_blank');
      })
      .catch((error) => {
        console.error('Error getting download URL:', error);
        notifications.error(`File is not compatible with your browser so it has been downloaded.`)
        downloadAttachement(file)
      });
  }

  const downloadAttachement = async (file: any) => {
    try {
      const storageRef = ref(storage, file.path);
      const url = await getDownloadURL(storageRef);
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(blobUrl);
      };
      xhr.open('GET', url);
      xhr.send();
      notifications.success(`${file.name} has been downloaded successfully.`)
    } catch (error) {
      console.error('Error downloading file:');
    }
  }

  return (
    <>
      <Dialog open={openViewReimbursement} onClose={() => { setOpenViewReimbursement(false) }}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          marginX: 4,
          marginTop: 2,
        }}>
          <Typography variant="h2" sx={{ pb: 2, fontSize: { xs: "1.2rem", sm: "1.4rem", ms: "1.8rem" }, width: "85%" }}>Reimbursement Request</Typography>
          <IconButton sx={{ position: 'absolute', right: '30px', color: 'primary', width: "15%" }} onClick={() => { setOpenViewReimbursement(false) }}>
            <CloseIcon />
          </IconButton>
          <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
        </Box>
        <Box sx={{
          p: 2
        }}>
          <Box sx={{ marginBottom: 1, marginLeft: 2, }}>
            <Typography variant="h3" sx={{ fontSize: { xs: "1rem", sm: "1.2rem", ms: "1.5rem" } }}>Request Details: </Typography>
          </Box>
          <Box sx={{ paddingX: 2, paddingY: 0.5 }} >
            <Box sx={{ margin: { xs: 1, ms: 2 }, display: 'flex', flexDirection: 'row' }}>
              <Typography sx={{ fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" } }} > Request Date:</Typography>
              <Typography sx={{ paddingX: 2, fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" }, fontWeight: 600 }}>{reimbursement?.createdAt?.format('MM/DD/YYYY')}</Typography>
            </Box>
            <Box sx={{ margin: { xs: 1, ms: 2 }, display: 'flex', flexDirection: 'row' }}>
              <Typography sx={{ fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" } }} >Amount Requested:</Typography>
              <Typography sx={{ paddingX: 2, fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" }, fontWeight: 600 }}><FormattedNumber style={`currency`} maximumFractionDigits={2} currency="usd" value={reimbursement?.amount} /></Typography>
            </Box>
            <Box sx={{ margin: { xs: 1, ms: 2 }, display: 'flex', flexDirection: 'row' }}>
              <Typography sx={{ fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" } }} >Amount Approved:</Typography>
              <Typography sx={{ paddingX: 2, fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" }, fontWeight: 600 }}>{reimbursement.approvedAmount ? (<FormattedNumber style={`currency`} maximumFractionDigits={2} currency="usd" value={reimbursement?.approvedAmount} />) : <>--</>}</Typography>
            </Box>
            <Box sx={{ margin: { xs: 1, ms: 2 }, display: 'flex', flexDirection: 'row' }}>
              <Typography sx={{ fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" } }} >Coverage period start date:</Typography>
              <Typography sx={{ paddingX: 2, fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" }, fontWeight: 600 }}>
                {reimbursement?.coverageStartDate ? (<>
                  {moment(reimbursement.coverageStartDate).format('MM/DD/YYYY')}
                </>) : null}
              </Typography>
            </Box>
            <Box sx={{ margin: { xs: 1, ms: 2 }, display: 'flex', flexDirection: 'row' }}>
              <Typography sx={{ fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" } }} >Coverage period end date:</Typography>
              <Typography sx={{ paddingX: 2, fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" }, fontWeight: 600 }}>
                {reimbursement?.coverageEndDate ? (<>
                  {moment(reimbursement.coverageEndDate).format('MM/DD/YYYY')}
                </>) : null}
              </Typography>
            </Box>
            <Box sx={{ margin: { xs: 1, ms: 2 }, display: 'flex', flexDirection: 'row' }}>
              <Typography sx={{ fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" } }} >Status:</Typography>
              <Typography sx={{ paddingX: 2, fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" }, fontWeight: 600 }}>{reimbursement?.status}</Typography>
            </Box>
            {reimbursement.status === "Denied" &&
              <Box sx={{ margin: { xs: 1, ms: 2 }, display: 'flex', flexDirection: 'row' }}>
                <Typography sx={{ fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" } }} >Denial Reason:</Typography>
                <Typography sx={{ paddingX: 2, fontSize: { xs: "0.85rem", sm: "0.9rem", ms: "1rem" }, fontWeight: 600 }}>{reimbursement?.denialReason}</Typography>
              </Box>
            }
          </Box>
          <Box sx={{ margin: 2 }}>
            <Typography variant="h3" sx={{ fontSize: { xs: "1rem", sm: "1.2rem", ms: "1.5rem" } }}>Attachments: </Typography>
            <TableContainer component={Paper} elevation={0}>
              <Table sx={{ border: 'none' }}>
                <TableHead>
                  <TableRow sx={{
                    '& .MuiTableCell-root': {
                      fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "0.875rem" },
                      p: { xs: 1, sm: 1, ms: 2 }
                    }
                  }}>
                    <TableCell>Document#</TableCell>
                    <TableCell>Document name</TableCell>
                    <TableCell>View</TableCell>
                    <TableCell>Download</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reimbursement.files && reimbursement.files.map((file, index) => (
                    <TableRow key={index} sx={{
                      '& .MuiTableCell-root': {
                        fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "0.875rem" },
                        p: { xs: 1, sm: 1, ms: 2 }
                      }
                    }}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{file?.name}</TableCell>
                      <TableCell>
                        <Tooltip title="View Attachment" arrow onClick={() => { viewattachment(index) }}>
                          <IconButton color="primary" ><PageviewOutlinedIcon /></IconButton>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Download Attachment" arrow onClick={() => { downloadAttachement(file) }}>
                          <IconButton color="primary"><DownloadOutlinedIcon /></IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box sx={{ margin: { xs: 1, ms: 2 }, display: "flex", justifyContent: "space-between" }}>
            {(reimbursement.status === 'Pending' || reimbursement.status === 'Additional Information Requested') ? (
              <>
                <Button sx={{ width: "45%", marginTop: 3, marginBottom: 2, padding: { xs: 1, sm: 1.5 }, fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "0.875rem" }, }} variant="contained" color="primary" onClick={() => { setOpenCancelReimbursement(true) }}>Cancel the request</Button>
                <Button sx={{ width: "45%", marginTop: 3, marginBottom: 2, padding: { xs: 1, sm: 1.5 }, fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "0.875rem" }, }} variant="contained" color="secondary" onClick={() => { setOpenViewReimbursement(false) }}>Close</Button>
              </>
            ) : <Button variant="contained" sx={{ width: "100%", marginTop: 3, marginBottom: 2, padding: { xs: 0.5, ms: 1, sm: 1.5 }, fontSize: { xs: "0.7rem", sm: "0.8rem", ms: "0.875rem" } }} color="secondary" onClick={() => { setOpenViewReimbursement(false) }}>Close</Button>}
          </Box>
        </Box>
      </Dialog >
      {openCancelReimbursement && (
        <CancelReimbursementDialog openCancelReimbursement={openCancelReimbursement} setOpenCancelReimbursement={setOpenCancelReimbursement} cancelReimbursement={cancelReimbursement} />
      )}
    </>
  )
}
export default ViewReimbursementDialog