import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { useAuth } from 'contexts/User';

interface LoginOtpVerifyDialogProps {
  isOpen: boolean;
  onClose: () => void;
  verificationId?: string;
  mfaError: any;
  afterLogin: () => void;
}

const LoginOtpVerifyDialog: React.FC<LoginOtpVerifyDialogProps> = ({ isOpen, onClose, verificationId, mfaError, afterLogin }) => {
  const [error, setError] = useState('');
  const { verfiyOtpForSMSmfa, generateOtpforSMSmfa } = useAuth();
  const [timer, setTimer] = useState(30);
  const [otpVerificationId, setOtpVerificationId] = useState(verificationId ? verificationId : "");
  const [otp, setOtp] = useState(Array(6).fill(''));

  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const handleOtpChange = (index: any, value: any) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleOtpInputChange = (index: number, value: string) => {
    handleOtpChange(index, value);
    if (inputRefs.current[index + 1] && value !== '') {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleResendClick = () => {
    try {
      generateOtpforSMSmfa(mfaError).then((verificationId) => {
        if (verificationId !== undefined && verificationId !== null) {
          setOtpVerificationId(verificationId);
          setTimer(30);
          setError('');
        }
      })
    } catch (error) {
      setError('You have reached the maximum number of attempts. Please try again later.');
      onClose()
    }
  };

  const handleVerify = () => {
    if (otp.join('').length !== 6) {
      setError('Enter a 6-digit OTP.');
      return;
    }
    if (timer !== 0) {
      if (verificationId) {
        verfiyOtpForSMSmfa(otpVerificationId, otp.join(''), mfaError).then(() => {
          afterLogin && afterLogin()
        }).catch(() => {
          setError('Invalid code provided')
          setOtp(['', '', '', '', '', ''])
        })
      }
    } else {
      setError('OTP Expired')
      setOtp(['', '', '', '', '', ''])
    }
  };

  useEffect(() => {
    let countdownInterval: any;
    if (timer > 0) {
      countdownInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(countdownInterval);
  }, [timer]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
    <DialogContent sx={{ textAlign: 'center', p: { xs: 2, sm: 4 } }}>

        <Typography variant="h2" fontWeight={600} mb={4} >
          {'MFA required'}
        </Typography>

        <Typography variant="h5" fontWeight={200} mb={4}>
          {'A verification code has been sent to your phone'}
        </Typography>
        <Box sx={{ margin: '20px' }}>
          <Box>
            {otp.length && otp.map((digit, index) => (
              <TextField
                key={index}
                type="text"
                value={digit}
                onChange={(e) => handleOtpInputChange(index, e.target.value)}
                inputRef={(input) => (inputRefs.current[index] = input)}
                variant="outlined"
                margin="dense"
                inputProps={{ maxLength: 1 }}
                sx={{ marginRight: '20px', width: '50px', textAlign: 'center' }}
              />
            ))}
          </Box>
          {error && <Typography component={"div"} color={"error"} variant="caption">{error}</Typography>}
          <button onClick={() => { if (timer === 0) handleResendClick() }}
            style={{
              margin: "20px",
              fontSize: 14,
              border: "none",
              backgroundColor: "white",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            disabled={timer !== 0}
          >
            {`Didn't receive a text? Click here to resend (${timer}s)`}
          </button>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          sx={{ m: 1, fontSize: 16 }}
          onClick={handleVerify}
        >
          Verify MFA
        </Button>
      </DialogContent>
    </Dialog >
  );
};

export default LoginOtpVerifyDialog;
