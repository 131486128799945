/** @jsxImportSource @emotion/react */
import { Button, Container, IconButton, InputAdornment, LinearProgress, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "contexts/User";
import { Visibility, VisibilityOff } from "@mui/icons-material";


function PasswordReset() {
  const navigate = useNavigate();
  const { confirmPasswordReset } = useAuth();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [passwordStrength, setPasswordSStrength] = useState(0)
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  async function changePassword() {
    const code = searchParams.get("oobCode");
    if (!code) {
      setError("Missing password reset code");
      return;
    }
    if (password === "") {
      setError("Please enter your password");
      return;
    }
    if (passwordStrength !== 100) {
      setError("Please enter a password that has uppercase letter, lowercase letter, number, symbol & minimum 8 characters.")
      return;
    }
    if (password !== confirmPassword) {
      setError("The passwords do not match.")
      return;
    }
    try {
      await confirmPasswordReset(code, password);
      setSuccess(true);
    } catch (err: any) {
      if (err.code === "auth/expired-action-code") {
        setError("Password reset has expired, please try again");
      } else if (err.code === "auth/weak-password") {
        setError("Password not strong enough");
      } else {
        setError("Error reseting your password");
      }
    }
  }
  if (success) {
    return (
      <Container sx={{
        paddingTop: "53px",
        display: "flex",
        flexDirection: "column",
        gap: 4
      }}>
        <Typography variant="h1">Reset your password</Typography>
        <Typography>Your password has been reset</Typography>
        <Button onClick={() => { navigate("/login") }}>Login</Button>
      </Container>
    );
  }

  const onPasswordChange = (e: any) => {
    const password = e.target.value;
    const lengthCheck = /.{8,}/.test(password);
    const numberCheck = /\d/.test(password);
    const symbolCheck = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const uppercaseCheck = /[A-Z]/.test(password);
    const lowercaseCheck = /[a-z]/.test(password);

    const checksPassed = [lengthCheck, numberCheck, symbolCheck, uppercaseCheck, lowercaseCheck].filter(Boolean).length;
    const percentage = checksPassed * 20;
    setPasswordSStrength(percentage)
    setPassword(e.target.value)
    if (percentage === 100) {
      setError("")
    } else {
      setError("Please enter a password that has uppercase letter, lowercase letter, number, symbol & minimum 8 characters.")
    }
  };
  return (
    <Container sx={{
      paddingTop: "53px",
      display: "flex",
      flexDirection: "column",
      gap: 4
    }}>
      <Typography variant="h1">Reset your password</Typography>
      <TextField
        label="New password"
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={onPasswordChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }} />
      <TextField
        label="New password"
        type={showConfirmPassword ? 'text' : 'password'}
        value={confirmPassword}
        onChange={(e) => {
          setConfirmPassword(e.target.value);
          if (password !== e.target.value) {
            setError("The passwords do not match.")
          }
          else {
            setError("")
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                onMouseDown={handleMouseDownPassword}
              >
                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }} />
      <Typography color="error">{error}</Typography>
      <Typography>Password Stregth: {passwordStrength === 100 && "strong"}
        {(passwordStrength >= 60 && passwordStrength < 100) && "medium"}
        {(passwordStrength >= 0 && passwordStrength < 60) && "low"}
      </Typography>
      <LinearProgress variant="determinate" sx={{ height: '8px' }} value={passwordStrength} />
      <Button onClick={changePassword}>Reset Password</Button>
    </Container>
  );
}
export default PasswordReset;
