import React from "react";
import { createRoot } from 'react-dom/client';

import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ModalProvider } from "./contexts/Modal";
import { DisclaimerProvider } from "./contexts/Disclaimers";
import { UserProvider } from "./contexts/User";
import RemoteConfigContext from 'contexts/RemoteConfig';
import { CompanyProvider } from "contexts/Company";
import { FirebaseProvider } from "contexts/Firebase";
import SettingsProvider from "contexts/Settings";


const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <SettingsProvider>
    <FirebaseProvider>
      <RemoteConfigContext>
        <BrowserRouter>
          <ModalProvider>
            <UserProvider>
              <CompanyProvider>
                <DisclaimerProvider>
                  <App />
                </DisclaimerProvider>
              </CompanyProvider>
            </UserProvider>
          </ModalProvider>
        </BrowserRouter>
      </RemoteConfigContext>
    </FirebaseProvider>
  </SettingsProvider>
);


