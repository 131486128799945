import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import { Box } from "@mui/system";

const ConfirmSubmissionDialog = ({ confirmSubmit, openConfirmSubmission, setOpenConfirmSubmission }: { confirmSubmit: Function, openConfirmSubmission: boolean, setOpenConfirmSubmission: Function }) => {
  return (
    <>
      <Dialog open={openConfirmSubmission} onClose={() => { setOpenConfirmSubmission(false) }} sx={{ "& .MuiPaper-root": { width: { sm: "50%", xs: "70%" } } }} >
        <DialogContent>
          <Box sx={{
            p: { sm: 2, xs: 0 },
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography sx={{ fontSize: { xs: '1.3rem', sm: '1.8rem' } }}>Confirm Changes</Typography>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center"
            }}>
              <Typography>Are you sure you want to update your personal information? You can always change this under My Account.</Typography>
            </Box>
            <Button variant="contained" color="primary" onClick={() => { confirmSubmit() }}>Confirm</Button>
            <Button onClick={() => { setOpenConfirmSubmission(false) }}>Cancel</Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ConfirmSubmissionDialog;