import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useAuth } from "contexts/User";
import { useState } from "react";

const ReAuthenticateDialog = ({ openReAuthenticateDialog, setOpenReAuthenticateDialog, handleMfaEnable }: { openReAuthenticateDialog: boolean, setOpenReAuthenticateDialog: Function, handleMfaEnable: Function }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState("")
  const { reAuthenticateUser, user } = useAuth();

  const reAuthenticate = () => {
    if (password === "") {
      setError("Please Enter a valid password.")
      return;
    }
    const email = user ? user.email : null;
    reAuthenticateUser(password, email ?? '').then((res) => {
      if (res) {
        setError("")
        setOpenReAuthenticateDialog(false)
        handleMfaEnable(true)
      } else {
        setError("Invalid password")
      }
    })
  }

  return (
    <>
      <Dialog open={openReAuthenticateDialog} onClose={() => { setOpenReAuthenticateDialog(false) }}>
        <DialogContent sx={{ textAlign: 'center', p: { xs: 2, sm: 4 } }}>
          <Box sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography sx={{ fontSize: { xs: '1.3rem', sm: '1.8rem' }, mb: { xs: 1, sm: 4 } }} fontWeight={600}>Re-Authenticate before you enable MFA</Typography>
            <TextField
              id="password"
              color="secondary"
              sx={{ borderRadius: "10px" }}
              label="Password"
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => {
                setPassword(e.target.value);
                if (e.target.value === "") {
                  setError("Please Enter a valid password.")
                  return;
                }
                setError("")
              }}
              value={password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => { setShowPassword(!showPassword); }}
                      onMouseDown={(e) => { e.preventDefault() }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              required />
            {error && <Typography component="div" color="error">{error}</Typography>}
            <Button
              variant="contained"
              color="secondary"
              sx={{ m: 1, fontSize: 16, mt: 2 }}
              onClick={reAuthenticate}
            >
              Continue
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default ReAuthenticateDialog;