/** @jsxImportSource @emotion/react */
import { Typography, Box, Container, Button, Grid, Card, CardContent, CardActions, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { ServiceMarketPlaceType } from "services/Interfaces";
import { CheckBox, CheckBoxOutlineBlank, Phone } from "@mui/icons-material";
import { useDocument } from "react-firebase-hooks/firestore";
import { query, collection, getDocs } from "firebase/firestore";
import { useFirebase } from "contexts/Firebase";
import { useUserData } from "contexts/User/data";
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { ref as storageRef } from 'firebase/storage';
import { useServiceMarketplaceActivity } from "hooks/useServiceMarketplaceActivity";

// TODO: Extract into helper file
function formatPhoneNumber(phoneNumber: string): string {
  const digits = phoneNumber.replace(/\D/g, "");
  if (digits.length === 10) {
    return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
  }
  return phoneNumber;
}

export interface ServiceMarketplaceActivity {
  serviceId: string;
  activity: "clickedServicePhoneButton" | "clickedServiceCTAButton";
  pageUrl: string;
  userId?: string;
  timestamp: Date;
  type: "user";
}

export function ServiceCard({ service }: { service: ServiceMarketPlaceType }) {
    const { storage } = useFirebase();
    const { addServiceMarketplaceActivity } = useServiceMarketplaceActivity();
    const headerPhotoRef = storageRef(storage, service?.headerPhoto?.path);
    const logoFileRef = storageRef(storage, service?.logoFile?.path);
    const [headerPhotoURL] = useDownloadURL(headerPhotoRef);
    const [logoFileURL] = useDownloadURL(logoFileRef);

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        boxShadow: 'none',
        margin: 2,
        overflow: 'hidden',
        backgroundColor: 'white',
        borderRadius: "20px",
        position:"relative",
        paddingBottom:"50px"
      }}
    >
      {/* Image Section */}
      {service && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '16px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '400px',
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
              borderRadius: '10px',
            }}
          >
            {headerPhotoURL && (
              <img
                src={headerPhotoURL}
                alt={service.tileHeader}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '10px',
                }}
              />
            )}
          </Box>
        </Box>
      )}

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '0px 16px 8px 16px',  // Reduced padding for a compact look
        }}
      >
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: 700,
            color: service.fontColor || '#333',  // Fallback color
            margin: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 2,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            textAlign: 'left',
          }}
        >
          {service.tileHeader}
        </Typography>

        <Typography
          sx={{
            fontSize: '16px',
            color: service.fontColor,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 4,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            marginTop: '8px',
          }}
        >
          {service.tileBodyText}
        </Typography>
      </CardContent>

      {service.redeemCode && (
        <Box
          sx={{
            padding: '12px',
            backgroundColor: '#F5F5F5',
            textAlign: 'left',
            margin: '0 16px 16px 16px',
            borderRadius: '10px',
          }}
        >
          <Typography sx={{ color: service.fontColor }}>
            <b>Redeem Code: {service.redeemCode}</b><br />
          </Typography>
        </Box>
      )}

      <CardActions
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          padding: '0 16px 16px 16px',  // Adjust padding for better alignment
          alignItems: 'center',
          justifyContent: 'space-between',  // Ensure even spacing between buttons
          position:"absolute",
          bottom:0,
          width:"inherit"
        }}
      >
        {service.CTAButton && service.CTAButtonURL && (
          <Button
            variant="outlined"
            sx={{
              color: service.fontColor,
              border: '2px solid black',
              borderRadius: '24px',
              textTransform: 'none',
              padding: '8px 16px',
              fontWeight: 600,
            }}
            href={service.CTAButtonURL}
            onClick={async () => {
              await addServiceMarketplaceActivity({
                serviceId: service.id || "",
                activity: "clickedServiceCTAButton",
                pageUrl: window.location.href,
                timestamp: new Date(),
                type: "user",
              });
            }}
            target="_blank"
          >
            <b>{service.CTAButtonText}</b>
          </Button>
        )}

        {service.phoneButton && service.phoneNumber && (
          <Button
            variant="outlined"
            sx={{
              color: service.fontColor,
              border: '2px solid black',
              borderRadius: '24px',
              margin: '0px !important',
            }}
            onClick={async () => {
              await addServiceMarketplaceActivity({
                serviceId: service.id || "",
                activity: "clickedServicePhoneButton",
                pageUrl: window.location.href,
                timestamp: new Date(),
                type: "user",
              });
            }}
            component="a"
            href={`tel:${service.phoneNumber}`}
          >
            <Phone /> {formatPhoneNumber(service.phoneNumber)}
          </Button>
        )}

        {logoFileURL && (
          <Box
            component="img"
            src={logoFileURL}
            alt="Logo"
            sx={{
              width: '75px',
              height: '50px',
              objectFit: 'contain',
              marginLeft: 'auto',
            }}
          />
        )}
      </CardActions>
    </Card>
  );
}

function Services() {
  const { firestore } = useFirebase();
  const [services, setServices] = useState<ServiceMarketPlaceType[] | undefined>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const { userDoc } = useUserData();

  const [userData] = useDocument(userDoc);

  useEffect(() => {
    const fetchServices = async () => {
      const globalServicesQuery = query(
        collection(firestore, "serviceMarketplace")
      );
      const globalServicesSnapshot = await getDocs(globalServicesQuery);
      const globalServicesData = globalServicesSnapshot.docs.map((doc) =>
        doc.data()
      ) as ServiceMarketPlaceType[];

      const servicesOfferedRef = collection(
        firestore,
        `users/${userData?.data()?.uid}/formerEmployments/${userData?.data()?.syncEmployeeId
        }/servicesOffered`
      );

      const servicesOfferedSnapshot = await getDocs(
        servicesOfferedRef
      );

      const companyServicesData = servicesOfferedSnapshot.docs.map((doc) => doc.data()) as ServiceMarketPlaceType[];

      const combinedServices = [
        ...globalServicesData,
        ...companyServicesData,
      ];

      return combinedServices;
    };

    fetchServices();

    const fetchData = async () => {
      const combinedServices = await fetchServices();
      setServices(combinedServices);
    };

    fetchData();
  }, [firestore, userData]);

  useEffect(() => {
    if (services && services.length > 0) {
      console.log("Populating categories...");
      const categorySet = new Set<string>();
      services.forEach((service) => {
        if (service.serviceCategory) {
          if (Array.isArray(service.serviceCategory)) {
            service.serviceCategory.forEach((cat) => categorySet.add(cat));
          } else {
            categorySet.add(service.serviceCategory);
          }
        }
      });
      setCategories(Array.from(categorySet));
    }
  }, [services]);

  const toggleCategory = (categoryIn: string) => {
    if (selectedCategories.includes(categoryIn)) {
      setSelectedCategories(selectedCategories.filter((cat) => cat !== categoryIn));
    } else {
      setSelectedCategories([...selectedCategories, categoryIn]);
    }
  };

  const filteredServices = services?.filter((service) =>
    selectedCategories.length === 0
      ? true // If no categories are selected, show all services
      : selectedCategories.some((cat) =>
        Array.isArray(service.serviceCategory)
          ? service.serviceCategory.includes(cat)
          : service.serviceCategory === cat
      )
  );

  // Move services with tilesVisibility to the beginning of the filtered list
  const sortedServices = filteredServices?.sort((a, b) => {
    if (a.tilesVisibility && !b.tilesVisibility) {
      return -1; // Service a has tilesVisibility, should be placed first
    } else if (!a.tilesVisibility && b.tilesVisibility) {
      return 1; // Service b has tilesVisibility, should be placed first
    }
    return 0; // Both services have or don't have tilesVisibility, maintain order
  });

  return (
    <Box sx={{ backgroundColor: "background.default" }}>
      <Container sx={{
        py: 4, display: "flex", flexDirection: "row", gap: 2, flexWrap: {
          xs: 'wrap',
          md: 'nowrap',
        },
      }}>
        <Box
          sx={{
            maxWidth: "300px",
            width: "300px",
            flexGrow: 1,
            flexShrink: 0,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box>
            <Typography variant="h3" sx={{ fontWeight: "bold", color: "#000" }}>
              Categories
            </Typography>
            <List>
              {categories &&
                categories.map((categoryIn, index) => (
                  <ListItem key={index} sx={{ color: "#000" }} disablePadding>
                    {selectedCategories.includes(categoryIn) ? (
                      <CheckBox />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                    <ListItemButton onClick={() => toggleCategory(categoryIn)}>
                      <ListItemText primary={categoryIn} />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="h3" sx={{ fontWeight: "bold" }}>
            Services
          </Typography>
          <Grid container spacing={2}>
            {sortedServices ? (
              sortedServices?.map((service) =>
                service.isActive ? (
                  <Grid item xs={12} md={6} key={service.id} sx={{ minWidth: 300 }}>
                    <ServiceCard service={service} />
                  </Grid>
                ) : null
              )
            ) : (
              <Grid item>
                <h3> Currently no services are available.</h3>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
export default Services;