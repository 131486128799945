import { DocumentData, SnapshotOptions, FirestoreDataConverter, QueryDocumentSnapshot } from "firebase/firestore";
import moment from "moment";
import { ReimbursementRequest, UserData, WhenBenefit } from "services/Interfaces";

export const UserDataConverter: FirestoreDataConverter<UserData> = {
    toFirestore(userData: UserData): DocumentData {
        return {
            ...userData,
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions
    ): UserData {
        const data = snapshot.data(options);
        return {
            email: data.email,
            name: data.name,
            companies: data.companies,
            admin: data.admin,
            lastLogin: data.lastLogin,
            uid: data.uid,
            id: snapshot.id,
            ref: snapshot.ref,
            phoneNumber: data.phoneNumber,
            dob: data.dob,
            gender: data.gender,
            address: data.address,
            syncEmployeeId: data.syncEmployeeId,
            companyId: data?.companyId,
        };
    },
};
export const WhenBenefitConverter: FirestoreDataConverter<WhenBenefit> = {
    toFirestore(benefit: WhenBenefit): DocumentData {
        return {
            ...benefit,
            createdAt: benefit.createdAt.toDate(),
            updatedAt: benefit.updatedAt.toDate(),
            terminationDate: benefit.terminationDate.toDate(),
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot<WhenBenefit>,
        options: SnapshotOptions
    ): WhenBenefit {
        const data = snapshot.data(options);
        return {
            ...data,
            id: snapshot.id,
            ref: snapshot.ref,
            createdAt: moment(data.createdAt.toDate()),
            updatedAt: moment(data.updatedAt.toDate()),
            terminationDate: data.terminationDate.toDate ? moment(data.terminationDate.toDate()) : moment(data.terminationDate),
        };
    },
};
export const WhenReimbursementRequestConverter: FirestoreDataConverter<ReimbursementRequest> = {
    toFirestore(request: ReimbursementRequest): DocumentData {
        return {
            ...request,
            createdAt: request.createdAt.toDate(),
            updatedAt: request.updatedAt.toDate(),
        };
    },
    fromFirestore(
        snapshot: QueryDocumentSnapshot<ReimbursementRequest>,
        options: SnapshotOptions
    ): ReimbursementRequest {
        const data = snapshot.data(options);
        return {
            ...data,
            id: snapshot.id,
            ref: snapshot.ref,
            coverageEndDate: data.coverageEndDate?.toDate ? moment(data.coverageEndDate?.toDate()) : moment(data.coverageEndDate, 'MM/DD/YYYY'),
            coverageStartDate: data.coverageStartDate?.toDate ? moment(data.coverageStartDate?.toDate()) : moment(data.coverageStartDate, 'MM/DD/YYYY'),
            approvedOn: data.approvedOn?.toDate ? moment(data.approvedOn.toDate()) : (data.approvedOn ? moment(data.approvedOn) : undefined),
            createdAt: data.createdAt.toDate ? moment(data.createdAt.toDate()) : moment(data.createdAt),
            updatedAt: data.updatedAt.toDate ? moment(data.updatedAt.toDate()) : moment(data.updatedAt),
        };
    },
};