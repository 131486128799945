import { Dialog, DialogContent, Typography, TextField, Button } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState, useEffect, useRef } from 'react';

interface OtpVerificationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleClick: () => void;
  otp: string[];
  setOtp: (otp: string[]) => void;
  handleOtpChange: (index: number, value: string) => void;
  handleResendVerification: () => void;
  InvalidOtp: string;
}

const OtpVerificationDialog: React.FC<OtpVerificationDialogProps> = ({
  InvalidOtp,
  handleResendVerification,
  isOpen,
  onClose,
  handleClick,
  otp,
  setOtp,
  handleOtpChange,
}) => {
  const [timer, setTimer] = useState(30);
  const [error, setError] = useState("");
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  useEffect(() => {
    if (isOpen) {
      setOtp(["", "", "", "", "", ""]);
      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    let countdownInterval: any;
    if (timer > 0) {
      countdownInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(countdownInterval);
  }, [timer]);

  useEffect(() => {
    if (isOpen) {
      setTimer(30);
    }
  }, [isOpen])

  const handleResend = () => {
    // Reset the timer when the "Resend" button is clicked
    handleResendVerification();
    setTimer(30);
    setError("");
    setOtp(["", "", "", "", "", ""]);
  };

  const handleVerify = () => {
    if (timer > 0) {
      handleClick();
    } else {
      setError("OTP Expired");
      setOtp(["", "", "", "", "", ""]);
    }
  };

  const handleOtpInputChange = (index: number, value: string) => {
    handleOtpChange(index, value);

    // Move focus to the next input field if not the last one
    if (inputRefs.current[index + 1] && value !== "") {
      inputRefs.current[index + 1]?.focus();
    }
  };

  useEffect(() => {
    if (InvalidOtp) {
      setError(InvalidOtp);
    }
  }, [InvalidOtp]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent sx={{ textAlign: 'center', p: { xs: 2, sm: 4 } }}>
        <Typography sx={{ fontSize: { xs: '1.3rem', sm: '1.8rem' }, mb: { xs: 1, sm: 4 } }} fontWeight={600}>
          {'Setup MFA To Secure Your Account'}
        </Typography>
        <Typography sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }} fontWeight={200} mb={2}>
          Please enter the verification code you received.
        </Typography>
        <Box sx={{ m: { xs: 1, sm: 4 } }}>
          <Box>
            {otp.map((digit: any, index: number) => (
              <TextField
                key={index}
                type="text"
                value={digit}
                onChange={(e) => handleOtpInputChange(index, e.target.value)}
                variant="outlined"
                margin="dense"
                sx={{ marginRight: '2%', width: 'calc(100% / 6 - 2%)', textAlign: 'center', '& input': { textAlign: 'center' } }}
                inputRef={(input) => (inputRefs.current[index] = input)}
                inputProps={{
                  maxLength: 1,
                  sx: { padding: { xs: 1, sm: 2 } }
                }}
              />
            ))}
          </Box>
          {error && (
            <Typography mt={2} component="div" color="error" variant="caption">
              {error}
            </Typography>
          )}
          <button
            onClick={handleResend}
            style={{
              margin: '14px',
              fontSize: 14,
              border: 'none',
              backgroundColor: 'white',
              textDecoration: 'underline',
            }}
            id="phoneVerificationButton"
            disabled={timer > 0}
          >
            {`Didn't receive a text? Click here to resend (${timer}s)`}
          </button>
        </Box>
        <Button variant="contained" color="secondary" sx={{ m: 1, fontSize: 16 }} onClick={handleVerify}>
          Verify MFA
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default OtpVerificationDialog;
