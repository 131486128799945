// EmailVerificationDialog.js
import React from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

interface MfaEnrolledDialogProps {
  isOpen: boolean;
}

const EmailVerificationDialog: React.FC<MfaEnrolledDialogProps> = ({ isOpen }) => (
  <Dialog open={isOpen}>
       <DialogContent sx={{ textAlign: 'center', p: { xs: 2, sm: 4 } }}>
      <Typography variant="h2" fontWeight={600} mb={4} >
        Setup MFA To Secure Your Account
      </Typography>

      <Typography variant="h5" fontWeight={400} mt={2} mb={4} sx={{
        textAlign: 'center',
      }}>
        We have sent a verification email.
      </Typography>

      <Typography variant="h5" fontWeight={400} mb={4} sx={{
        textAlign: 'center',
      }}>
        Please follow the instructions in the email to first verify your email account.
      </Typography>

    </DialogContent>
  </Dialog>
);

export default EmailVerificationDialog;
