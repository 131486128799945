/** @jsxImportSource @emotion/react */
import { Typography, Box, Button, Link } from "@mui/material";
import { useSettings } from "contexts/Settings";
import { useAuth } from "contexts/User";
import { useNavigate } from "react-router";


function NotFoundPage(exitCase: any) {
  const { settings } = useSettings();
  const { user, isAuthed } = useAuth();
  const navigate = useNavigate();
  function goto(uri: string) {
    navigate(uri, { replace: true });
  }

  return (
    <Box sx={{
      textAlign: "center",
      height: "85vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <Box>
        {!exitCase && <Typography>404 Page Not Found</Typography>}
        {exitCase && <Typography>No record found for this URL. If you believe you received this message in error, contact <Link href="mailto:support@forwhen.com">support@forwhen.com</Link></Typography>}
        <Typography sx={{ marginTop: "10px" }}><Button variant="outlined" onClick={() => { window.location.replace(`${settings.REACT_APP_WHEN_URL}`) }}>Go to Home</Button></Typography>
        {!user && !isAuthed() && <Typography sx={{ marginTop: "10px" }}><Button variant="outlined" onClick={() => { goto('/login') }}>Sign in</Button></Typography>}
      </Box>
    </Box>
  );
}
export default NotFoundPage;
