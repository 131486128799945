import { Button, Dialog, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { DenialReason, ReimbursementRequest } from "services/Interfaces"
import CloseIcon from '@mui/icons-material/Close';
import { useMutation } from "@apollo/client";
import { useNotifications } from "contexts/Notification";
import { Mutations } from "services/apollo"

const DenialRequest = ({ openDenialDialog, setOpenDenialDialog, reimbursementRequest, onClose }: { openDenialDialog: boolean, setOpenDenialDialog: Function, reimbursementRequest: ReimbursementRequest, onClose: Function }) => {
  const [denyReimbursementRequest] = useMutation(Mutations.denyReimbursementRequest);
  const [denialReason, setDenialReason] = useState<DenialReason>();
  const [error, setError] = useState<string>()
  const { notifications } = useNotifications();

  const RejectRequest = async () => {
    if (!denialReason) {
      setError("Please enter reason of denying a reimbursement request");
      return
    }
    try {
      await denyReimbursementRequest({
        variables: {
          requestUserId: reimbursementRequest?.userId || "",
          requestId: reimbursementRequest?.id || "",
          requestBenefitId: reimbursementRequest?.whenBenefitId || "",
          denialReason: denialReason,
          notes: reimbursementRequest?.notes || "",
        },
      });
      notifications.success('Reimbursement request Denied successfully.');
    }
    catch (error) {
      console.error('Error denying reimbursement request:', error);
      notifications.error('An error occurred while denying the request. Please try again.');
    } finally {
      setOpenDenialDialog(false)
      onClose()
    }
  }

  return (
    <>
      <Dialog open={openDenialDialog} onClose={() => { setOpenDenialDialog(false) }} >
        <Box sx={{
          width: 500,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: 'center'
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
          }}>
            <Typography variant="h2" sx={{ pb: 2 }} >Denial Request</Typography>
            <IconButton sx={{ position: 'absolute', top: '15px', right: '30px', color: 'primary' }} onClick={() => { setOpenDenialDialog(false) }}>
              <CloseIcon />
            </IconButton>
            <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
          </Box>
          {/* <Typography>Amount Requested : <FormattedNumber style={`currency`} maximumFractionDigits={2} currency="usd" value={reimbursementRequest.amount} /> </Typography> */}
          <FormControl>
            <InputLabel>Denial Reason</InputLabel>
            <Select
              value={denialReason}
              onChange={(event) => {
                if (event.target?.value) setError("");
                setDenialReason(event.target?.value as DenialReason)
              }}
              label={"Denial Reason"}
            >
              <MenuItem disabled value="">
                <em>Select an Option</em>
              </MenuItem>
              {Object.values(DenialReason).map((reason) => {
                return <MenuItem key={reason} value={reason}>{reason}</MenuItem>
              })
              }
            </Select>
          </FormControl>
          {error && (
            <Typography color={"error"}>{error}</Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => { RejectRequest() }}
          >Continue</Button>
          <Button onClick={() => { setOpenDenialDialog(false) }}>Cancel</Button>
        </Box>
      </Dialog>
    </>
  )
}

export default DenialRequest