import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MenuItem, Grid } from '@mui/material';

const countryCodes = [
  { id: 'AF', code: '93', name: 'Afghanistan' },
  { id: 'AL', code: '355', name: 'Albania' },
  { id: 'DZ', code: '213', name: 'Algeria' },
  { id: 'AD', code: '376', name: 'Andorra' },
  { id: 'AO', code: '244', name: 'Angola' },
  { id: 'AG', code: '1-268', name: 'Antigua and Barbuda' },
  { id: 'AR', code: '54', name: 'Argentina' },
  { id: 'AM', code: '374', name: 'Armenia' },
  { id: 'AU', code: '61', name: 'Australia' },
  { id: 'AT', code: '43', name: 'Austria' },
  { id: 'AZ', code: '994', name: 'Azerbaijan' },
  { id: 'BS', code: '1-242', name: 'Bahamas' },
  { id: 'BH', code: '973', name: 'Bahrain' },
  { id: 'BD', code: '880', name: 'Bangladesh' },
  { id: 'BB', code: '1-246', name: 'Barbados' },
  { id: 'BY', code: '375', name: 'Belarus' },
  { id: 'BE', code: '32', name: 'Belgium' },
  { id: 'BZ', code: '501', name: 'Belize' },
  { id: 'BJ', code: '229', name: 'Benin' },
  { id: 'BT', code: '975', name: 'Bhutan' },
  { id: 'BO', code: '591', name: 'Bolivia' },
  { id: 'BA', code: '387', name: 'Bosnia and Herzegovina' },
  { id: 'BW', code: '267', name: 'Botswana' },
  { id: 'BR', code: '55', name: 'Brazil' },
  { id: 'BN', code: '673', name: 'Brunei Darussalam' },
  { id: 'BG', code: '359', name: 'Bulgaria' },
  { id: 'BF', code: '226', name: 'Burkina Faso' },
  { id: 'BI', code: '257', name: 'Burundi' },
  { id: 'KH', code: '855', name: 'Cambodia' },
  { id: 'CM', code: '237', name: 'Cameroon' },
  { id: 'CA', code: '1', name: 'Canada' },
  { id: 'CV', code: '238', name: 'Cabo Verde' },
  { id: 'CF', code: '236', name: 'Central African Republic' },
  { id: 'TD', code: '235', name: 'Chad' },
  { id: 'CL', code: '56', name: 'Chile' },
  { id: 'CN', code: '86', name: 'China' },
  { id: 'CO', code: '57', name: 'Colombia' },
  { id: 'KM', code: '269', name: 'Comoros' },
  { id: 'CG', code: '243', name: 'Congo' },
  { id: 'CR', code: '506', name: 'Costa Rica' },
  { id: 'HR', code: '385', name: 'Croatia' },
  { id: 'CU', code: '53', name: 'Cuba' },
  { id: 'CY', code: '357', name: 'Cyprus' },
  { id: 'CZ', code: '420', name: 'Czech Republic' },
  { id: 'DK', code: '45', name: 'Denmark' },
  { id: 'DJ', code: '253', name: 'Djibouti' },
  { id: 'DM', code: '1-767', name: 'Dominica' },
  { id: 'DO', code: '1-809', name: 'Dominican Republic' },
  { id: 'EC', code: '593', name: 'Ecuador' },
  { id: 'EG', code: '20', name: 'Egypt' },
  { id: 'SV', code: '503', name: 'El Salvador' },
  { id: 'GQ', code: '240', name: 'Equatorial Guinea' },
  { id: 'ER', code: '291', name: 'Eritrea' },
  { id: 'EE', code: '372', name: 'Estonia' },
  { id: 'ET', code: '251', name: 'Ethiopia' },
  { id: 'FJ', code: '679', name: 'Fiji' },
  { id: 'FI', code: '358', name: 'Finland' },
  { id: 'FR', code: '33', name: 'France' },
  { id: 'GA', code: '241', name: 'Gabon' },
  { id: 'GM', code: '220', name: 'Gambia' },
  { id: 'GE', code: '995', name: 'Georgia' },
  { id: 'DE', code: '49', name: 'Germany' },
  { id: 'GH', code: '233', name: 'Ghana' },
  { id: 'GI', code: '350', name: 'Gibraltar' },
  { id: 'GR', code: '30', name: 'Greece' },
  { id: 'GD', code: '1-473', name: 'Grenada' },
  { id: 'GT', code: '502', name: 'Guatemala' },
  { id: 'GN', code: '224', name: 'Guinea' },
  { id: 'GW', code: '245', name: 'Guinea-Bissau' },
  { id: 'GY', code: '592', name: 'Guyana' },
  { id: 'HT', code: '509', name: 'Haiti' },
  { id: 'HN', code: '504', name: 'Honduras' },
  { id: 'HK', code: '852', name: 'Hong Kong' },
  { id: 'HU', code: '36', name: 'Hungary' },
  { id: 'IS', code: '354', name: 'Iceland' },
  { id: 'IN', code: '91', name: 'India' },
  { id: 'ID', code: '62', name: 'Indonesia' },
  { id: 'IR', code: '98', name: 'Iran, Islamic Republic of' },
  { id: 'IQ', code: '964', name: 'Iraq' },
  { id: 'IE', code: '353', name: 'Ireland' },
  { id: 'IM', code: '44', name: 'Isle of Man' },
  { id: 'IL', code: '972', name: 'Israel' },
  { id: 'IT', code: '39', name: 'Italy' },
  { id: 'JM', code: '1-876', name: 'Jamaica' },
  { id: 'JP', code: '81', name: 'Japan' },
  { id: 'JO', code: '962', name: 'Jordan' },
  { id: 'KZ', code: '7', name: 'Kazakhstan' },
  { id: 'KE', code: '254', name: 'Kenya' },
  { id: 'KI', code: '686', name: 'Kiribati' },
  { id: 'KP', code: '850', name: 'Korea, Democratic People\'s Republic of' },
  { id: 'KR', code: '82', name: 'Korea, Republic of' },
  { id: 'KW', code: '965', name: 'Kuwait' },
  { id: 'KG', code: '996', name: 'Kyrgyzstan' },
  { id: 'LA', code: '856', name: 'Lao People\'s Democratic Republic' },
  { id: 'LV', code: '371', name: 'Latvia' },
  { id: 'LB', code: '961', name: 'Lebanon' },
  { id: 'LS', code: '266', name: 'Lesotho' },
  { id: 'LR', code: '231', name: 'Liberia' },
  { id: 'LY', code: '218', name: 'Libyan Arab Jamahiriya' },
  { id: 'LI', code: '423', name: 'Liechtenstein' },
  { id: 'LT', code: '370', name: 'Lithuania' },
  { id: 'LU', code: '352', name: 'Luxembourg' },
  { id: 'MO', code: '853', name: 'Macao' },
  { id: 'MK', code: '389', name: 'Macedonia, The Former Yugoslav Republic of' },
  { id: 'MG', code: '261', name: 'Madagascar' },
  { id: 'MW', code: '265', name: 'Malawi' },
  { id: 'MY', code: '60', name: 'Malaysia' },
  { id: 'MV', code: '960', name: 'Maldives' },
  { id: 'ML', code: '223', name: 'Mali' },
  { id: 'MT', code: '356', name: 'Malta' },
  { id: 'MH', code: '692', name: 'Marshall Islands' },
  { id: 'MR', code: '222', name: 'Mauritania' },
  { id: 'MU', code: '230', name: 'Mauritius' },
  { id: 'MX', code: '52', name: 'Mexico' },
  { id: 'FM', code: '691', name: 'Micronesia, Federated States of' },
  { id: 'MD', code: '373', name: 'Moldova, Republic of' },
  { id: 'MC', code: '377', name: 'Monaco' },
  { id: 'MN', code: '976', name: 'Mongolia' },
  { id: 'ME', code: '382', name: 'Montenegro' },
  { id: 'MS', code: '1-664', name: 'Montserrat' },
  { id: 'MA', code: '212', name: 'Morocco' },
  { id: 'MZ', code: '258', name: 'Mozambique' },
  { id: 'MM', code: '95', name: 'Myanmar' },
  { id: 'NA', code: '264', name: 'Namibia' },
  { id: 'NR', code: '674', name: 'Nauru' },
  { id: 'NP', code: '977', name: 'Nepal' },
  { id: 'NL', code: '31', name: 'Netherlands' },
  { id: 'AN', code: '599', name: 'Netherlands Antilles' },
  { id: 'NC', code: '687', name: 'New Caledonia' },
  { id: 'NZ', code: '64', name: 'New Zealand' },
  { id: 'NI', code: '505', name: 'Nicaragua' },
  { id: 'NE', code: '227', name: 'Niger' },
  { id: 'NG', code: '234', name: 'Nigeria' },
  { id: 'NU', code: '683', name: 'Niue' },
  { id: 'NF', code: '672', name: 'Norfolk Island' },
  { id: 'NO', code: '47', name: 'Norway' },
  { id: 'OM', code: '968', name: 'Oman' },
  { id: 'PK', code: '92', name: 'Pakistan' },
  { id: 'PW', code: '680', name: 'Palau' },
  { id: 'PS', code: '970', name: 'Palestinian Territory, Occupied' },
  { id: 'PA', code: '507', name: 'Panama' },
  { id: 'PG', code: '675', name: 'Papua New Guinea' },
  { id: 'PY', code: '595', name: 'Paraguay' },
  { id: 'PE', code: '51', name: 'Peru' },
  { id: 'PH', code: '63', name: 'Philippines' },
  { id: 'PN', code: '64', name: 'Pitcairn' },
  { id: 'PL', code: '48', name: 'Poland' },
  { id: 'PT', code: '351', name: 'Portugal' },
  { id: 'PR', code: '1-787', name: 'Puerto Rico' },
  { id: 'QA', code: '974', name: 'Qatar' },
  { id: 'KR', code: '82', name: 'Republic of Korea' },
  { id: 'MD', code: '373', name: 'Republic of Moldova' },
  { id: 'RO', code: '40', name: 'Romania' },
  { id: 'RU', code: '7', name: 'Russia' },
  { id: 'RW', code: '250', name: 'Rwanda' },
  { id: 'SH', code: '290', name: 'Saint Helena' },
  { id: 'KN', code: '1-869', name: 'Saint Kitts and Nevis' },
  { id: 'LC', code: '1-758', name: 'Saint Lucia' },
  { id: 'PM', code: '508', name: 'Saint Pierre and Miquelon' },
  { id: 'VC', code: '1-784', name: 'Saint Vincent and the Grenadines' },
  { id: 'WS', code: '685', name: 'Samoa' },
  { id: 'SM', code: '378', name: 'San Marino' },
  { id: 'ST', code: '239', name: 'Sao Tome and Principe' },
  { id: 'SA', code: '966', name: 'Saudi Arabia' },
  { id: 'SN', code: '221', name: 'Senegal' },
  { id: 'RS', code: '381', name: 'Serbia' },
  { id: 'SC', code: '248', name: 'Seychelles' },
  { id: 'SL', code: '232', name: 'Sierra Leone' },
  { id: 'SG', code: '65', name: 'Singapore' },
  { id: 'SK', code: '421', name: 'Slovakia' },
  { id: 'SI', code: '386', name: 'Slovenia' },
  { id: 'SB', code: '677', name: 'Solomon Islands' },
  { id: 'SO', code: '252', name: 'Somalia' },
  { id: 'ZA', code: '27', name: 'South Africa' },
  { id: 'ES', code: '34', name: 'Spain' },
  { id: 'LK', code: '94', name: 'Sri Lanka' },
  { id: 'SD', code: '249', name: 'Sudan' },
  { id: 'SR', code: '597', name: 'Suriname' },
  { id: 'SJ', code: '47', name: 'Svalbard and Jan Mayen Islands' },
  { id: 'SZ', code: '268', name: 'Swaziland' },
  { id: 'SE', code: '46', name: 'Sweden' },
  { id: 'CH', code: '41', name: 'Switzerland' },
  { id: 'SY', code: '963', name: 'Syrian Arab Republic' },
  { id: 'TW', code: '886', name: 'Taiwan' },
  { id: 'TJ', code: '992', name: 'Tajikistan' },
  { id: 'TH', code: '66', name: 'Thailand' },
  { id: 'TG', code: '228', name: 'Togo' },
  { id: 'TK', code: '690', name: 'Tokelau' },
  { id: 'TO', code: '676', name: 'Tonga' },
  { id: 'TT', code: '1-868', name: 'Trinidad and Tobago' },
  { id: 'TN', code: '216', name: 'Tunisia' },
  { id: 'TR', code: '90', name: 'Turkey' },
  { id: 'TM', code: '993', name: 'Turkmenistan' },
  { id: 'TC', code: '1-649', name: 'Turks and Caicos Islands' },
  { id: 'TV', code: '688', name: 'Tuvalu' },
  { id: 'UG', code: '256', name: 'Uganda' },
  { id: 'UA', code: '380', name: 'Ukraine' },
  { id: 'AE', code: '971', name: 'United Arab Emirates' },
  { id: 'GB', code: '44', name: 'United Kingdom' },
  { id: 'US', code: '1', name: 'United States' },
  { id: 'UY', code: '598', name: 'Uruguay' },
  { id: 'UZ', code: '998', name: 'Uzbekistan' },
  { id: 'VU', code: '678', name: 'Vanuatu' },
  { id: 'VE', code: '58', name: 'Venezuela' },
  { id: 'VN', code: '84', name: 'Viet Nam' },
  { id: 'WF', code: '681', name: 'Wallis and Futuna Islands' },
  { id: 'YE', code: '967', name: 'Yemen' },
  { id: 'ZM', code: '260', name: 'Zambia' },
  { id: 'ZW', code: '263', name: 'Zimbabwe' }
];

interface PhoneNumberDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleClick: () => void;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  selectedCountryCode: string;
  setSelectedCountryCode: (selectedCountryCode: string) => void;
  InvalidPhone: string;
  setInvalidPhone: (InvalidPhone: string) => void;
}

const PhoneNumberDialog: React.FC<PhoneNumberDialogProps> = ({
  setInvalidPhone,
  InvalidPhone,
  isOpen,
  onClose,
  handleClick,
  phoneNumber,
  setPhoneNumber,
  selectedCountryCode,
  setSelectedCountryCode,
}) => {

  useEffect(() => {
    if (isOpen) {
      setPhoneNumber('');
      setSelectedCountryCode('1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (

    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent sx={{ textAlign: 'center', p: { xs: 2, sm: 4 } }}>
        <Typography sx={{ fontSize: { xs: '1.3rem', sm: '1.8rem' }, mb: { xs: 1, sm: 4 } }} fontWeight={600}>
          Setup MFA To Secure Your Account
        </Typography>
        <Typography sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }} fontWeight={200} mb={2}>
          Please enter your personal phone number to which you want us to send verification codes. We will send an initial code to confirm your MFA setup.
        </Typography>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={3} p={0}>
            <TextField
              select
              label="Country Code"
              value={selectedCountryCode}
              onChange={(e) => { setSelectedCountryCode(e.target.value) }}
              variant="outlined"
              fullWidth
              margin="normal"
            >
              {countryCodes.map((country, index) => (
                <MenuItem key={`${country.code}-${index}`} value={country.code}>
                  {`+${country.code} (${country.name})`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={9} p={0}>
            <TextField
              label="Phone"
              value={phoneNumber}
              onChange={(e) => { setInvalidPhone(""); setPhoneNumber(e.target.value) }}
              required
              fullWidth
              margin="normal"
              variant="outlined"
              autoComplete="phone"
            />
          </Grid>
        </Grid>
        {(InvalidPhone) && <Typography component="div" color="error" variant="caption">{InvalidPhone}</Typography>}
        <Button
          id="phoneVerificationButton"
          variant="contained"
          color="secondary"
          sx={{ m: 1, fontSize: 16, mt: 2 }}
          onClick={() => {
            handleClick();
          }}
        >
          Continue
        </Button>
      </DialogContent>
    </Dialog >

  );
}

export default PhoneNumberDialog;
