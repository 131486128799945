import React, { ReactNode, createContext, useContext, useState } from "react";
import { Snackbar, Button, Alert, } from "@mui/material";
import { ModalContext } from "contexts/Modal";

function Notification() {
    const { modalState, modalDispatch } = useContext(ModalContext);

    return (
        <Snackbar open={modalState.newVersion} >
            <Alert onClose={() => modalDispatch({ type: "newVersion" })} severity="info" sx={{ width: '100%' }}>
                There is a new version available! <Button variant="text" sx={{ cursor: "pointer" }} onClick={async () => {
                    console.log("refreshing via SW")
                    const registration = await navigator.serviceWorker.getRegistration();
                    registration?.waiting?.addEventListener("statechange", event => {
                        console.log("SW: state change", event);
                        /* if (event?.target?.state === "activated") {
                            console.log("SW: activated");
                            window.location.reload()
                        } */
                    });
                    registration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
                }}>Refresh Now.</Button>
            </Alert>
        </Snackbar>
    );

}

type NotificationType = 'success' | 'error' | 'warning' | 'info';


interface NotificationData {
    message: string;
    type: NotificationType;
}

interface NotificationsContextProps {
    hideNotification: () => void;
    notifications: {
        success: (message: string) => void;
        error: (message: string) => void;
        warn: (message: string) => void;
        info: (message: string) => void;
    };
}

type Background = {
    backgroundColor?: string,
    color?: string
}

const styles = {
    primary: {
        backgroundColor: "#194142",
        color: "white"
    },
    periwinkle: {
        backgroundColor: "#afc3ff",
        color: "black"
    }
};

const NotificationsContext = createContext<NotificationsContextProps | undefined>(undefined);

export const NotificationsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [notification, setNotification] = useState<NotificationData | null>(null);
    const [background, setBackground] = useState<Background | null>(null)

    const notifications = {
        success: (message: string) => {
            setNotification({ message, type: 'success' })
            setBackground(styles.primary)
        },
        error: (message: string) => {
            setNotification({ message, type: 'error' })
            setBackground({})
        },
        warn: (message: string) => {
            setNotification({ message, type: 'warning' })
            setBackground({})
        },
        info: (message: string) => {
            setNotification({ message, type: 'info' })
            setBackground({})
        },
    };

    const hideNotification = () => {
        setNotification(null);
    };

    return (
        <NotificationsContext.Provider value={{ hideNotification, notifications }}>
            {children}
            {notification && (
                <Snackbar
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: '21%',
                        transform: 'translateX(-21%)',
                        zIndex: 9999
                    }}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={!!notification} autoHideDuration={3000} onClose={hideNotification}>
                    <Alert severity={notification.type} onClose={hideNotification} sx={{ width: '100%', ...background }}>
                        {notification.message}
                    </Alert>
                </Snackbar>
            )
            }
        </NotificationsContext.Provider >
    );
};

export const useNotifications = (): NotificationsContextProps => {
    const context = useContext(NotificationsContext);
    if (!context) {
        throw new Error('useNotifications must be used within a NotificationsProvider');
    }
    return context;
};

export default Notification;