import { Box, Button, Card, CardActions, CardContent, Dialog, Divider, FormControl, IconButton, MenuItem, Select, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { useState, useEffect } from "react";
import { BenefitPlan, CoverageTierStructureTypes, Members } from "services/Interfaces";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";

const UpdateCobraPlanModal = ({ openUpdateCobraPlan, setOpenUpdateCobraPlan, setCobraPlan }: { openUpdateCobraPlan: boolean, setOpenUpdateCobraPlan: Function, setCobraPlan: Function }) => {
  const calculatePremium = (totalPremium: number) => {
    return parseFloat((totalPremium * 1.02).toFixed(2));
  };

  const { firestore } = useFirebase();
  const { user, answers } = useAuth();

  const [planComparison, setPlanComparison] = useState<BenefitPlan | { name: string } | null>()
  const [cobraPlans, setCobraPlans] = useState<Array<BenefitPlan>>()
  const [cobraPlansOptions, setCobraPlansOptions] = useState({})

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const userDoc = await getDoc(doc(firestore, `users/${user.uid}`))
        const cobraPlansDoc = await getDoc(doc(firestore, `users/${user?.uid}/formerEmployments/${userDoc.data()?.syncEmployeeId}`));

        if (cobraPlansDoc.data() && cobraPlansDoc?.data()?.cobraPlans) {
          const newOptions = cobraPlansDoc?.data()?.cobraPlans.reduce((options: any, plan: any) => {
            return { ...options, [plan.name]: plan.name };
          }, { ...cobraPlansOptions });

          setCobraPlansOptions({ ...newOptions, "None": "None" });
        } else {
          setCobraPlansOptions({ "None": "None" })
        }

        setCobraPlans(cobraPlansDoc?.data()?.cobraPlans)
        const planComparisonDoc = await getDoc(doc(firestore, `users/${user.uid}/settings/insurance`))

        if (planComparisonDoc?.data()?.cobra?.planComparison !== null && planComparison === undefined) {
          setPlanComparison(planComparisonDoc?.data()?.cobra?.planComparison)
        }
        if (planComparisonDoc?.data()?.cobra?.planComparison === null && planComparison === undefined) {
          setPlanComparison({ name: "None" })
        }
      }
      fetchData()
    }
  }, [cobraPlansOptions, firestore, planComparison, user])

  const setCobraPlanComparison = async () => {
    if (cobraPlans) {
      if (planComparison?.name === "None") {
        console.log("Resetting comparison");
        if (user) {
          const planComparisonDoc = await getDoc(doc(firestore, `users/${user.uid}/settings/insurance`))
          await updateDoc(doc(firestore, "users", user.uid, "settings", "insurance"), { ...planComparisonDoc.data(), cobra: { ...planComparisonDoc?.data()?.cobra, planComparison: null } })
        }
        setPlanComparison({ name: "None" });
        setCobraPlan([])
        setOpenUpdateCobraPlan(false)
      } else {
        cobraPlans.map(async (plan: any) => {
          if (plan.name === planComparison?.name) {
            if (user) {
              const planComparisonDoc = await getDoc(doc(firestore, `users/${user.uid}/settings/insurance`))
              await updateDoc(doc(firestore, "users", user.uid, "settings", "insurance"), { ...planComparisonDoc.data(), cobra: { ...planComparisonDoc?.data()?.cobra, planComparison: plan } })
              setPlanComparison(plan)
              setCobraPlan(plan)
              const members: Members = answers?.get("insuranceDetails.household.members") ? answers?.get("insuranceDetails.household.members") : {};
              let totalPremium;
              if (plan.coverageTierStructure === CoverageTierStructureTypes.ThreeTier) {
                if (answers?.get("insuranceDetails.type") === "individual") {
                  totalPremium = calculatePremium(plan.threeTierPremium.employeeOnly.totalPremium);
                }
                else if (Object.keys(members).length === 2) {
                  totalPremium = calculatePremium(plan.threeTierPremium.employeePlusOneDependent.totalPremium);
                }
                else if (answers?.get("insuranceDetails.type") === "family" || Object.keys(members).length > 2) {
                  totalPremium = calculatePremium(plan.threeTierPremium.employeePlusMoreDependent.totalPremium);
                }
              }
              if (plan.coverageTierStructure === CoverageTierStructureTypes.FourTier) {
                if (answers?.get("insuranceDetails.type") === "individual") {
                  totalPremium = calculatePremium(plan.fourTierPremium.employeeOnly.totalPremium);
                }

                const relationships = Object.values(members).map(member => member.relationship);
                const hasSpouse = relationships.includes("spouse");
                const hasChildren = relationships.some(rel => rel === "child");

                if (hasSpouse && hasChildren) {
                  // Employee + Family rate
                  totalPremium = calculatePremium(plan.fourTierPremium.family.totalPremium);
                } else if (hasSpouse) {
                  // Employee + Spouse rate
                  totalPremium = calculatePremium(plan.fourTierPremium.employeePlusSpouse.totalPremium);
                } else if (hasChildren) {
                  // Employee + Children rate
                  totalPremium = calculatePremium(plan.fourTierPremium.employeePlusChildren.totalPremium);
                }
              }
              if (totalPremium !== undefined) {
                await updateDoc(doc(firestore, 'users', user?.uid!, "answers", user?.uid!), { ...answers?.data(), cobra: { ...answers?.data()?.cobra, estimate: totalPremium } })
              }
            }
            setOpenUpdateCobraPlan(false)
            return
          }
        })
      }
    }
  };

  return (
    <Dialog open={openUpdateCobraPlan} onClose={() => setOpenUpdateCobraPlan(false)} fullWidth>
      <Card sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 4,
      }}>
        <CardContent>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
            <Typography variant="h3" color="primary" fontWeight={600} sx={{ width: "80%", fontSize: { xs: "1.25rem", sm: "1.35rem", ms: "1.5rem" } }}>Update Your Current Plan Information</Typography>
            <IconButton sx={{ position: 'absolute', top: '30px', right: '30px', color: 'primary', marginLeft: 1, fontSize: { xs: "0.75rem", sm: "0.85rem", ms: "1rem" } }} onClick={() => { setOpenUpdateCobraPlan(false) }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography sx={{ marginY: 1 }}>Please select from the dropdown list</Typography>
          <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
          <Box sx={{ marginTop: 2, marginBottom: 7 }}>
            <FormControl fullWidth>
              <Select
                fullWidth
                value={planComparison ? (planComparison as { name: string }).name : ""}
                onChange={(event) => {
                  cobraPlans?.forEach((plan) => {
                    if (event.target.value === "None") {
                      setPlanComparison({ name: "None" });
                    }
                    if (plan.name && plan.name === event.target.value) {
                      setPlanComparison(plan);
                    }
                  });
                }}
              >
                {Object.values(cobraPlansOptions).map((option: any) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </CardContent>
        <CardActions>
          <Box sx={{ p: 2, display: "flex", flexDirection: "row", justifyContent: 'space-between', width: "100%" }}>
            <Button variant="text" color="primary" sx={{ height: "45px", width: "200px" }} onClick={() => { setOpenUpdateCobraPlan(false) }} >Cancel</Button>
            <Button variant="contained" color="primary" sx={{ height: "45px", width: "200px", borderRadius: "4px" }} onClick={setCobraPlanComparison}>Submit</Button>
          </Box>
        </CardActions>
      </Card>
    </Dialog>
  )
}

export default UpdateCobraPlanModal