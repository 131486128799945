import React, { useState } from 'react'
/** @jsxImportSource @emotion/react */
import { Typography, Box, Button, TextField, Divider, styled, InputAdornment, IconButton, Grid, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { ReimbursementRequest, WhenBenefit } from 'services/Interfaces';
import Webcam from "react-webcam";
import { useDownloadURL, useUploadFile } from 'react-firebase-hooks/storage';
import { deleteObject, ref, ref as storageRef } from 'firebase/storage';
import moment from 'moment';
import { useAuth } from 'contexts/User';
import { CameraAlt, Cameraswitch, CloudUpload, DeleteForever, Visibility } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { Mutations } from 'services/apollo';
import { DatePicker } from '@mui/x-date-pickers';
import { useNotifications } from "contexts/Notification";
import { Link } from 'react-router-dom';
import { useFirebase } from 'contexts/Firebase';
import FormattedCurrency from './FormattedCurrency';

function DownloadFile({ file }: { file: { name: string, type: string, path: string } }) {
    const { storage } = useFirebase();
    const [downloadURL, loading, error] = useDownloadURL(storageRef(storage, file.path));
    if (loading) {
        return <Typography variant="body1">{file.name}</Typography>
    }
    if (error) {
        return <Typography variant="body1">Error: {file.name}: {error.message}</Typography>
    }
    if (!downloadURL) return <Typography variant="body1">{file.name}</Typography>
    return (
        <Link style={{ textDecoration: 'none', marginLeft: "10px" }} to={downloadURL} target="_blank" download><IconButton><Visibility /></IconButton></Link>
    );
}

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;
// const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
//     const byteCharacters = atob(b64Data);
//     const byteArrays = [];

//     for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//         const slice = byteCharacters.slice(offset, offset + sliceSize);

//         const byteNumbers = new Array(slice.length);
//         for (let i = 0; i < slice.length; i++) {
//             byteNumbers[i] = slice.charCodeAt(i);
//         }

//         const byteArray = new Uint8Array(byteNumbers);
//         byteArrays.push(byteArray);
//     }

//     const blob = new Blob(byteArrays, { type: contentType });
//     return blob;
// }
function SubmitReimbursementRequest({ hasBankAccount, whenBenefit, remaining, onSave, onCancel }: { hasBankAccount: boolean, whenBenefit: WhenBenefit, remaining: number, onSave: Function, onCancel: Function }) {
    const { storage } = useFirebase();

    const [reimbursement, setReimbursement] = useState<ReimbursementRequest>({
        amount: 0,
        companyId: whenBenefit?.companyId,
        company: whenBenefit?.company,
        name: '',
        status: "Pending",
        whenBenefitId: whenBenefit?.id,
        files: [],
        coverageStartDate: null,
        coverageEndDate: null,
        createdAt: moment(),
        updatedAt: moment(),
    });
    const { user } = useAuth();
    const [facingMode, setFacingMode] = useState(true);
    const [uploadFile, uploading, ,] = useUploadFile();
    const [submitReimbursementRequest] = useMutation(Mutations.submitReimbursementRequest);
    const webcamRef = React.useRef<Webcam>(null);
    const [photo, setPhoto] = useState(false);
    const [validation, setValidationObject] = useState<any>({});
    const { notifications } = useNotifications();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [confirmationChecked, setConfirmationChecked] = useState(false);
    const [understandingChecked, setUnderstandingChecked] = useState(false);

    const takePhoto = async () => {
        console.log(webcamRef.current?.video?.videoWidth, webcamRef.current?.video?.videoHeight)
        // console.log(webcamRef.current?.stream?.getVideoTracks()[0].getConstraints().width, webcamRef.current?.stream?.getVideoTracks()[0].getConstraints().height)
        webcamRef.current?.getCanvas()?.toBlob((blob) => {
            console.log("blob", blob)
            if (!blob)
                return;
            const fileName = `photo-${moment().unix()}.png`;
            const path = `users/${user?.uid}/reimbursementRequests/${fileName}`;
            uploadFile(
                storageRef(storage, path),
                blob,
                {
                    contentType: 'image/png'
                }
            ).then(() => {
                reimbursement.files.push({
                    name: fileName,
                    path: path,
                    type: 'image/png'
                });
                setReimbursement(reimbursement);
            });
            if (reimbursement.files.length !== 0) {
                setValidation("files", "")
            }
            setPhoto(false);
        }, 'image/png', 1);
    }
    const uploadFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files)
            return;
        console.log("files", files);
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileName = `${file.name}`;
            const path = `/users/${user?.uid}/reimbursementRequests/${fileName}`;
            await uploadFile(
                storageRef(storage, path),
                file,
                {
                    contentType: file.type
                }
            );
            reimbursement.files.push({ name: fileName, path: path, type: file.type });
            setReimbursement(reimbursement);
            if (reimbursement.files.length !== 0) {
                setValidation("files", "")
            }
        }
    }
    function deleteFile(fileName: string): void {
        // Create a reference to the file to delete
        const desertRef = ref(storage, `users/${user?.uid}/reimbursementRequests/${fileName}`);

        // Delete the file
        deleteObject(desertRef).then(() => {
            // File deleted successfully
            const filteredFiles = reimbursement?.files?.filter((file) => {
                return file?.name !== fileName
            })
            setReimbursement({
                ...reimbursement, files: filteredFiles
            })
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }
    function setValidation(key: string, value: string) {
        setValidationObject({ ...validation, [key]: value });
    }
    const isMomentValid = (date: moment.Moment | null): boolean => {
        return date !== null && date.isValid();
    };
    const submit = async () => {
        if (isSubmitting || !user) {
            return;
        }
        let validation: any = {};
        if (reimbursement.amount === 0 || reimbursement.amount > remaining) {
            validation["amount"] = "Please enter an amount no more than the remaining benefit amount."
        }
        if (reimbursement.coverageStartDate === null) {
            validation["coverageStartDate"] = "Please enter a coverage period start date."
        }
        if (!isMomentValid(reimbursement.coverageStartDate)) {
            validation['coverageStartDate'] = "Please provide a date with the following format MM/DD/YYYY"
        }
        if (reimbursement.coverageEndDate === null) {
            validation["coverageEndDate"] = "Please enter a coverage period end date."
        }
        if (!isMomentValid(reimbursement.coverageEndDate)) {
            validation['coverageEndDate'] = "Please provide a date with the following format MM/DD/YYYY"
        }
        if (reimbursement.files.length <= 0) {
            validation['files'] = "Please attach one file to continue."
        }
        if (!confirmationChecked) {
            validation['confirmation'] = "Please confirm that the information provided is true and correct."
        }
        if (!hasBankAccount) {
            if (!understandingChecked) {
                validation['understanding'] = "Please acknowledge the bank account requirement."
            }
        }
        if (Object.keys(validation).length > 0) {
            setValidationObject(validation);
            return;
        }
        if (reimbursement.coverageEndDate && reimbursement.coverageStartDate && reimbursement.coverageEndDate?.toDate() < reimbursement.coverageStartDate?.toDate()) {
            return;
        }

        try {
            setIsSubmitting(true);
            await submitReimbursementRequest({
                variables: {
                    whenBenefitId: reimbursement.whenBenefitId,
                    files: reimbursement.files,
                    amount: reimbursement.amount,
                    coverageStartDate: reimbursement.coverageStartDate!.format("MM/DD/YYYY"),
                    coverageEndDate: reimbursement.coverageEndDate!.format("MM/DD/YYYY"),
                    userId: user?.uid,
                },
            });

            notifications.success(`Reimbursement request of ${reimbursement.amount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} submitted successfully.`)
            onSave();
        } catch (error) {
            console.error('Error submitting reimbursement request:', error);
            notifications.error('An error occurred while submitting the request. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    }
    if (!whenBenefit) {
        return (
            <></>
        )
    }
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
        }}>
            <Typography variant="h2" sx={{ pb: { xs: 0, sm: 2 }, fontSize: { xs: "1.4rem", sm: "1.8rem" } }}>Submit a Reimbursement Request</Typography>
            <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
            <Typography variant="body1" sx={{ pb: 2, fontSize: { xs: "0.8rem", sm: "1rem" } }}>You are able to use your When Benefit to get reimbursed for the premium&apos;s for the health insurance policy you select. All you need to do is submit a reimbursement request with proof of payment for any health insurance policy you&apos;ve selected.</Typography>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "center",
            }}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Amount Requested"
                            data-testid="amount-requested"
                            variant="outlined"
                            sx={{
                                width: "100%",
                                '& input[type=number]': {
                                    '-moz-appearance': 'textfield'
                                },
                                '& input[type=number]::-webkit-outer-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                },
                                '& input[type=number]::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                    margin: 0
                                },
                            }}
                            type="number"
                            value={reimbursement.amount ? reimbursement.amount : ""}
                            onChange={(e) => {
                                const parsedValue = parseFloat(e.target.value);
                                const amountValue = parseFloat(parsedValue.toFixed(2));
                                setReimbursement({
                                    ...reimbursement,
                                    amount: isNaN(amountValue) ? 0 : amountValue,
                                });
                                if (isNaN(amountValue) || amountValue === 0 || amountValue > remaining) {
                                    setValidation("amount", "Please enter an amount no more than the remaining benefit amount.");
                                } else {
                                    setValidation("amount", "");
                                }
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                inputProps: {
                                    step: '0.01',
                                    pattern: '\\d*\\.?\\d{0,2}',
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                pb: { xs: 0, sm: 2 }, 
                                paddingLeft: { xs: 0, sm: 2 }, 
                                paddingTop: { xs: 1, sm: 0 } 
                            }}
                        >
                            Remaining Benefit: <FormattedCurrency value={remaining} />
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            {validation.amount && <Typography color={"error"}>{validation.amount}</Typography>}
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "center",
            }}>
                <DatePicker
                    sx={{
                        width: { xs: "100%", sm: "50%" },
                        whiteSpace: 'normal'
                    }}
                    label="Coverage period start date"
                    format="MM/DD/YYYY"
                    value={reimbursement.coverageStartDate}
                    onError={(error) => {
                        setValidation("coverageStartDate", error ? "Please provide a date with the following format MM/DD/YYYY" : "");
                    }}
                    onChange={(date: any) => {
                        setReimbursement({ ...reimbursement, coverageStartDate: date })
                        if (!isMomentValid(date)) {
                            setValidation("coverageStartDate", "Please provide a date with the following format MM/DD/YYYY")
                        }
                    }}
                ></DatePicker>
            </Box>
            {validation.coverageStartDate && <Typography color={"error"}>{validation.coverageStartDate}</Typography>}
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "center",
            }}>
                <DatePicker
                    sx={{
                        width: { xs: "100%", sm: "50%" },
                        whiteSpace: 'normal'
                    }}
                    data-testid="coverage-end"
                    label="Coverage period end date"
                    format="MM/DD/YYYY"
                    value={reimbursement.coverageEndDate}
                    minDate={reimbursement?.coverageStartDate}
                    onError={(error) => {
                        setValidation("coverageEndDate", error ? "Please provide a date with the following format MM/DD/YYYY" : "");
                    }}
                    onChange={(date: any) => {
                        setReimbursement({ ...reimbursement, coverageEndDate: date })
                        if (!isMomentValid(date)) {
                            setValidation("coverageEndDate", "Please provide a date with the following format MM/DD/YYYY")
                        }
                    }}
                ></DatePicker>
            </Box>
            {validation.coverageEndDate && <Typography color={"error"}>{validation.coverageEndDate}</Typography>}
            {reimbursement.files.length > 0 &&
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0.5
                }}>
                    <Typography variant="h3" sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }}>Files</Typography>
                    {reimbursement.files.map((file) => {
                        return <Typography key={file.name} sx={{ display: "flex", alignItems: "center", fontSize: { xs: "0.7rem", sm: "1rem" } }} variant="body1">{file?.name}<DownloadFile key={file.name} file={file} /><IconButton onClick={() => deleteFile(file?.name)}><DeleteForever /></IconButton></Typography>
                    })}
                </Box>
            }
            {!photo &&
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                }}>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<CloudUpload />}
                        href="#file-upload"
                        sx={{ fontSize: { xs: "0.675rem", sm: "0.875rem" } }}
                    >
                        Upload a file
                        <VisuallyHiddenInput onChange={uploadFiles} type="file" data-testid="file-upload-input" />
                    </Button>
                    <Button sx={{ fontSize: { xs: "0.675rem", sm: "0.875rem" } }} startIcon={<CameraAlt />} variant="contained" color="primary" onClick={() => { setPhoto(true) }}>Take Photo</Button>
                </Box>
            }
            {validation.files && <Typography color={"error"}>{validation.files}</Typography>}
            {photo &&
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                }}>
                    <Box sx={{
                        maxWidth: "400px",
                        maxHeight: "400px",
                    }}>
                        <Webcam videoConstraints={{ facingMode: facingMode ? "environment" : "user" }} ref={webcamRef} width={"100%"} height={"100%"} forceScreenshotSourceSize={true} screenshotQuality={1} screenshotFormat='image/png' />
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                    }}>
                        <Button onClick={takePhoto}>Take Photo</Button>
                        <Button onClick={() => { setPhoto(false) }}>Cancel Photo</Button>
                        <Button onClick={() => { setFacingMode(!facingMode) }}><Cameraswitch /></Button>
                    </Box>
                </Box>
            }
            {uploading && <Box>
                <Typography>Uploading {uploadFile.name}.</Typography>
            </Box>}
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox data-testid="confirmation-checkbox" checked={confirmationChecked} onChange={(e) => { setConfirmationChecked(e.target.checked); if (e.target.checked) setValidation("confirmation", "") }} />}
                        label="I confirm that the information I have provided is true and correct and that the uploaded attachments include the policy holder name, dates of coverage, 
                        and a verification of payment/receipt that displays the amount paid."
                    />
                    {validation["confirmation"] && <Typography data-testid="confirmationError" variant="body2" sx={{ color: "error.main" }}>{validation["confirmation"]}</Typography>}
                </FormGroup>
            </Grid>
            {!hasBankAccount && (
                <Grid item xs={12}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox data-testid="understanding-checkbox" checked={understandingChecked} onChange={(e) => { setUnderstandingChecked(e.target.checked); if (e.target.checked) setValidation("understanding", "") }} />}
                            label="I understand that if I do not add a bank account to My Account,
                             When will mail a check to the address on record."
                        />
                        {validation["understanding"] && <Typography data-testid="bankAccountCheckboxError" variant="body2" sx={{ color: "error.main" }}>{validation["understanding"]}</Typography>}
                    </FormGroup>
                </Grid>
            )}
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
                alignItems: "center"
            }}>
                <Button sx={{ fontSize: { xs: "0.675rem", sm: "0.875rem" } }} variant="contained" color="secondary" onClick={submit} data-testid="submitReimbursementButton" >Submit Reimbursement Request</Button>
                <Button sx={{ fontSize: { xs: "0.675rem", sm: "0.875rem" } }} variant="contained" color="secondary" onClick={(event) => onCancel(event)}>Cancel</Button>
            </Box>
        </Box>
    )
}

export default SubmitReimbursementRequest;
