import { FormattedNumber } from "react-intl";

function FormattedCurrency({
  value,
  maximumFractionDigits = 2,
  currency = "USD",
}: {
  value: number;
  maximumFractionDigits?: number;
  currency?: string;
}) {
  return (
    <FormattedNumber
      style={`currency`}
      maximumFractionDigits={maximumFractionDigits}
      currency={currency}
      value={value}
    />
  );
}

export default FormattedCurrency;
