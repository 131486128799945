/** @jsxImportSource @emotion/react */
import { AppBar, Toolbar, Box, Menu, MenuItem, ListItemIcon, Container, IconButton, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { HealthAndSafety, Home, Person, School, Security, Storefront, Work } from "@mui/icons-material";
import { keyboardToClick } from "services/Aria";
import { useAuth } from "contexts/User";
import { useRemoteConfigFull } from "contexts/RemoteConfig";
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useCollection } from "react-firebase-hooks/firestore";
import { collectionGroup, query } from "firebase/firestore";
import { ServiceMarketPlaceType } from "services/Interfaces";
import { ServiceMarketPlaceConverter } from "services/Firestore/Service";
import { useFirebase } from "contexts/Firebase";

function linkStyle() {
  return {
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "400"
  };
}

function Navbar() {
  const navigate = useNavigate();
  const flags = useRemoteConfigFull()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorNavMenuEl, setAnchorNavMenuEl] = React.useState(null);
  const { userData, userClaims } = useAuth();
  const { firestore } = useFirebase();
  const [services, setServices] = useState<ServiceMarketPlaceType[] | undefined>([]);

  const open = Boolean(anchorEl);
  const handleOpenNavMenu = (event: any) => {
    setAnchorNavMenuEl(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorNavMenuEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const goto = (uri: any) => {
    navigate(uri);
    setAnchorEl(null);
    setAnchorNavMenuEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [serviceData] = useCollection<ServiceMarketPlaceType>(
    query(collectionGroup(firestore, "serviceMarketplace")).withConverter(
      ServiceMarketPlaceConverter
    ), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() => {
    const serviceValue = serviceData?.docs.map((data) => data.data());
    setServices(serviceValue);
  }, [serviceData]);

  return (
    <AppBar position="sticky" elevation={0} sx={{
      pt: 2,
      insetBlockStart: 0,
      zIndex: 1000,
    }} color="primary">
      <Toolbar variant="dense" sx={{
      }}>
        <Container maxWidth="xl" sx={{ paddingX: 0 }}>
          <Box sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: { ms: 0, sm: 1.5, xs: 1.5 }
          }}>
            <Button variant="text" onClick={() => goto("/account/dashboard")} onKeyDown={keyboardToClick} >
              <img id="main-logo" src="/when-logo-reversed.png" width="120px" alt="When" />
            </Button>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center"
            }}>
              {/* <IconButton>
                <Notifications sx={{ color: "gray.light" }} />
              </IconButton> */}
              <IconButton
                color="primary"
                data-testid="navbar-my-account"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={{
                  backgroundColor: "white.main",
                  ":hover": {
                    color: "white.main",
                  }
                }}
                style={linkStyle()}
                onClick={handleClick}
              >
                <Person />
              </IconButton>
              <IconButton aria-label="Menu" sx={{
                display: { ms: "none", sm: "flex" },
                color: "white.main",
              }}
                onClick={handleOpenNavMenu}
                id="nav-open"
              >
                {anchorNavMenuEl === null ?
                  <>
                    <MenuIcon />
                  </> :
                  <>
                    <CloseIcon />
                  </>}
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem sx={{ display: "none" }} onClick={() => { goto('account/dashboard'); }}>Dashboard</MenuItem>
                {userData?.get("companies")?.length === 1 && <MenuItem data-testid="nav-company" onClick={() => { goto('company'); }}>Company</MenuItem>}

                {(userClaims?.admin === true || userClaims?.roles?.admin?.viewer === true) &&
                  <MenuItem data-testid="adminItem" onClick={() => { goto('admin'); }}>
                    Admin
                  </MenuItem>}
                <MenuItem data-testid="myAccountMenuItem" onClick={() => { goto('account/My-Account'); }}>
                  {/* <ListItemIcon>
                    <Settings fontSize="small" />
                  </ListItemIcon> */}
                  My Account
                </MenuItem>
                <MenuItem onClick={() => { goto('/logout'); }}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
          <Menu
            id="nav-menu"
            anchorEl={anchorNavMenuEl}
            open={Boolean(anchorNavMenuEl)}
            onClose={handleCloseNavMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            disableScrollLock={true}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            sx={{
              display: { ms: "none", sm: "flex" },
              justifyContent: "right",
              marginTop: 1,
              padding: 1
            }}
          >
            <MenuItem onClick={() => { goto('account/dashboard'); }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Home sx={{ paddingRight: 1 }} />
                Dashboard
              </Box>
              <KeyboardArrowRightIcon />
            </MenuItem>
            <MenuItem onClick={() => { goto('account/insurance-plans'); }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', marginRight: 2 }}>
                <HealthAndSafety sx={{ paddingRight: 1 }} />
                Health Insurance
              </Box>
              <KeyboardArrowRightIcon />
            </MenuItem>
            <MenuItem onClick={() => { goto('account/when-benefit'); }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Security sx={{ paddingRight: 1 }} />
                When Benefit
              </Box>
              <KeyboardArrowRightIcon />
            </MenuItem>
            <MenuItem onClick={() => { goto('account/services'); }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Storefront sx={{ paddingRight: 1 }} />
                Services
              </Box>
              <KeyboardArrowRightIcon />
            </MenuItem>
            <MenuItem onClick={() => { goto('account/employments'); }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Work sx={{ paddingRight: 1 }} />
                Past Employment
              </Box>
              <KeyboardArrowRightIcon />
            </MenuItem>
            <MenuItem onClick={() => { goto('account/resources'); }} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <School sx={{ paddingRight: 1 }} />
                Resources
              </Box>
              <KeyboardArrowRightIcon />
            </MenuItem>
            <MenuItem onClick={handleCloseNavMenu}>
              <Button variant="outlined" sx={{ width: "100%", borderRadius: 0, paddingX: 0, color: "gray.main" }}>Close</Button>
            </MenuItem>
          </Menu>
        </Container>
      </Toolbar>
      <Toolbar sx={{
        display: { ms: "flex", sm: "none", xs: "none" },
        "&.MuiToolbar-root": {
          padding: { ms: 1, lg: 2 }
        }
      }}>
        <Container maxWidth="xl" sx={{ display: "flex", flexDirection: "row", gap: { xs: 1, sm: 2 } }}>
          <NavigationButton
            path="/account/dashboard"
            label="Dashboard"
            icon={<Home sx={{ height: 16 }} />}
            isActive={window.location.pathname === "/account/dashboard"}
            onClick={() => navigate("/account/dashboard")}
          />
          <NavigationButton
            path="/account/insurance-plans"
            label="Health Insurance"
            icon={<HealthAndSafety sx={{ height: 16 }} />}
            isActive={window.location.pathname.startsWith("/account/insurance-plans")}
            onClick={() => navigate("/account/insurance-plans")}
          />
          {flags.whenBenefit && (
            <NavigationButton
              path="/account/when-benefit"
              label="When Benefit"
              icon={<Security sx={{ height: 16 }} />}
              isActive={window.location.pathname.startsWith("/account/when-benefit")}
              onClick={() => navigate("/account/when-benefit")}
            />
          )}
          {flags.services && (services?.length ?? 0) > 0 && (
            <NavigationButton
              path="/account/services"
              label="Services"
              icon={<Storefront sx={{ height: 16 }} />}
              isActive={window.location.pathname.startsWith("/account/services")}
              onClick={() => navigate("/account/services")}
            />
          )}
          {flags.documents && (
            <NavigationButton
              path="/account/employments"
              label="Past Employment"
              icon={<Work sx={{ height: 16 }} />}
              isActive={window.location.pathname.startsWith("/account/employments")}
              onClick={() => navigate("/account/employments")}
            />
          )}
          {flags.resources && (
            <NavigationButton
              path="/account/resources"
              label="Resources"
              icon={<School sx={{ height: 16 }} />}
              isActive={window.location.pathname.startsWith("/account/resources")}
              onClick={() => navigate("/account/resources")}
            />
          )}
        </Container>
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;




interface NavigationButtonProps {
  path: string;
  label: string;
  icon: JSX.Element;
  isActive: boolean;
  onClick: () => void;
}

const NavigationButton: React.FC<NavigationButtonProps> = ({ path, label, icon, isActive, onClick }) => (
  <Button
    startIcon={icon}
    color="white"
    variant={isActive ? "contained" : undefined}
    sx={{
      justifyContent: "start",
      background: isActive ? `linear-gradient(-72.47deg,rgb(255,145,0) 22.16%,rgba(255,145,0,.7) 88.47%)!important` : undefined,
      color: "white.main",
      borderRadius: { xs: 10, sm: 20, md: 40 },
      paddingX: { ms: 1, md: 1.5, lg: 2 },
      '& .MuiButton-startIcon': {
        marginRight: { ms: 0, md: 1, lg: 2 },
      },
      fontSize: { ms: "0.7rem", md: "0.8rem", lg: "1rem" }, // Change the font size of the label
      // '& .MuiButton-label': {
      //   marginLeft: '8px', // Change the margin to the right of the icon
      // },
    }}
    size="large"
    onClick={onClick}
  >
    {label}
  </Button>
);
