import { useCallback } from "react";
import { collection, addDoc } from "firebase/firestore";
import { useFirebase } from "contexts/Firebase";
import { useAuth } from "contexts/User";
import mixpanel from "mixpanel-browser";

export interface ServiceMarketplaceActivity {
  serviceId: string;
  activity: string;
  pageUrl: string;
  timestamp: Date;
  type: "user";
}

export function useServiceMarketplaceActivity() {
  const { firestore } = useFirebase();
  const { user } = useAuth();

  const addServiceMarketplaceActivity = useCallback(
    async (activity: ServiceMarketplaceActivity) => {
      if (!user?.uid) return;

      const userActivityCollectionRef = collection(
        firestore,
        `users/${user.uid}/activity`
      );
      await addDoc(userActivityCollectionRef, activity);
      mixpanel.track(activity.activity, {
        serviceId: activity.serviceId,
        pageUrl: activity.pageUrl,
        userId: user.uid,
        timestamp: new Date(),
      });
    },
    [firestore, user?.uid]
  );

  return { addServiceMarketplaceActivity };
}
