import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface MfaIntroductionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleClick: () => void;
}

const MfaIntroductionDialog: React.FC<MfaIntroductionDialogProps> = ({ isOpen, onClose, handleClick }) => {

  return (
    <Dialog open={isOpen} onClose={onClose} >
      <DialogContent sx={{ textAlign: 'center', p: { xs: 2, sm: 4 }, width: { sm: 500, xs: 'auto' }, }} >
        <Typography sx={{ fontSize: { xs: '1.3rem', sm: '1.8rem' }, mb: { xs: 1, sm: 4 } }} fontWeight={600}>
          {"Setup MFA to Secure your Account"}
        </Typography>

        <Typography sx={{ fontSize: { xs: '1rem', sm: '1.2rem' } }} fontWeight={200} mb={2}>
          Multi-Factor Authentication (MFA) helps to secure your account and protects access to your data and the services we offer
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          sx={{ m: 1, fontSize: 16 }}
          onClick={handleClick}
        >
          Get Started
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default MfaIntroductionDialog;
