import React, { useEffect } from "react"
import { DocumentData, DocumentSnapshot, QueryDocumentSnapshot, collection, doc, query, where, documentId } from 'firebase/firestore';

import { useUserData } from "contexts/User/data";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { Company, CompanyUser } from "services/Interfaces";
import { CompanyConverter, CompanyUserConverter } from "services/Firestore";
import { Box, Typography } from "@mui/material";
import { useFirebase } from "contexts/Firebase";

export interface CompanyState {
  loading: boolean,
  name: string,
  company?: DocumentSnapshot<Company>,
  error?: Error,
  users?: QueryDocumentSnapshot<CompanyUser>[],
  companyId?: string,
  companyRoles?: string[],
  checkCompanyRole: (role: string) => boolean,
  setCompanyId: (companyId: string) => void,
  companies?: QueryDocumentSnapshot<Company>[],
}
export const CompanyContext = React.createContext<CompanyState>({
  name: "Company",
  users: undefined,
  loading: true,
  checkCompanyRole: () => false,
  setCompanyId: () => { }
})
export function useCompany() {
  const {
    name,
    company,
    loading,
    error,
    companyId,
    companyRoles,
    users,
    checkCompanyRole,
    setCompanyId,
    companies
  } = React.useContext(CompanyContext);
  return {
    name,
    company,
    loading,
    error,
    users,
    companyId,
    companyRoles,
    checkCompanyRole,
    setCompanyId,
    companies
  };
}

export const CompanyProvider = ({ children }: { children: any }) => {
  //load company based on user
  const { firestore } = useFirebase();
  const { userData, userLoading, loaded: userLoaded } = useUserData();
  const [companyId, setCompanyId] = React.useState<string>("");
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  useEffect(() => {
    if (userData && userData.get("companies") && companyId === "") {
      setCompanyId(userData.get("companies")[0]);
    }
  }, [userData, companyId])

  const checkCompanyRole = (role: string) => {
    return companyRoles?.includes(role) || companyRoles?.includes("admin") || false;
  }
  const [companies, ,] = useCollection<Company>(
    userData && userData.data()?.companies ?
      query(
        collection(firestore, "companies").withConverter<Company>(CompanyConverter),
        where(documentId(), "in", userData.data()?.companies)
      ) :
      null
  );
  useEffect(() => {
    if (!userData?.data()?.admin && companies && companies.docs.length > 0 && !companies.docs.map((d) => d.id).includes(companyId)) {
      setCompanyId(companies.docs[0].id);
    }
  }, [companies, companyId, userData])

  // console.log("company provider", userData, userData?.get("companyId"));
  const [companyRoles, setCompanyRoles] = React.useState<string[]>([]);
  const [companyUser, ,] = useDocument<DocumentData>(companyId && userData ? doc(firestore, "companies", companyId, "users", userData.id) : null);
  const [companyData, companyLoading, companyError] = useDocument<Company>(companyId ? doc(firestore, "companies", companyId).withConverter(CompanyConverter) : null);
  const [users, ,] = useCollection<CompanyUser>(
    companyId ?
      collection(firestore, "companies", companyId, "users").withConverter<CompanyUser>(CompanyUserConverter) :
      null
  );
  useEffect(() => {
    if (companyUser && companyUser.exists()) {
      console.log("set companyUser roles")
      setCompanyRoles(companyUser.get("roles"));
    }
  }, [companyUser])
  useEffect(() => {
    if (userLoading || !userLoaded) {
      console.log("companyProvider: user loading")
      setLoading(true);
      return;
    } else {
      if (!userData) {
        console.log("companyProvider, no user data")
        setLoading(false);
        setLoaded(true);
        return;
      } else if (!userData.get("companies")) {
        console.log("companyProvider, no companies")
        setLoading(false);
        setLoaded(true);
        return;
      } else if (userData.get("companies").length === 0) {
        console.log("companyProvider, no companies length")
        setLoading(false);
        setLoaded(true);
        return;
      } else if (companyUser && companyRoles && companyRoles.length > 0) {
        console.log("companyProvider, companyUser and companyRoles")
        setLoading(false);
        setLoaded(true);
        return;
      }
    }
  }, [userLoading, userData, companyUser, companyRoles, userLoaded])
  console.log("companyProvider: loading", { userLoaded, loading, userLoading, userData, companyUser, companyRoles });

  return (
    <CompanyContext.Provider value={{ name: companyData?.get('name'), users: users?.docs, checkCompanyRole, companyRoles, companyId, company: companyData, loading: companyLoading, error: companyError, setCompanyId, companies: companies?.docs }}>
      {(loading || !userLoaded) && <Typography sx={{ display: loading ? "block" : "none" }}>Loading company</Typography>}
      {loaded &&
        <Box sx={{ display: !loading && userLoaded ? "block" : "none" }}>
          {children}
        </Box>
      }
    </CompanyContext.Provider>
  )
}
export default function CompanyConsumer() {
  return React.useContext(CompanyContext);
}