/** @jsxImportSource @emotion/react */
import { AppBar, Toolbar, Box, Menu, MenuItem, ListItemIcon, IconButton, Dialog, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React, { useContext, useState } from "react";
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import { Notifications, Person, Search } from "@mui/icons-material";
import { useAuth } from "contexts/User";
import { ModalContext } from "contexts/Modal";
import { useRemoteConfig } from "contexts/RemoteConfig";
import SearchDialog from "components/Company/SearchDialog";
import { useSettings } from "contexts/Settings";

function linkStyle() {
  return {
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "400"
  };
}

function Navbar({ sx }: { sx?: any }) {
  const { settings } = useSettings();
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorNavMenuEl, setAnchorNavMenuEl] = useState(null);
  const { userData } = useAuth();
  const { modalDispatch } = useContext(ModalContext);
  const algoliaSearch = useRemoteConfig("algoliaSearch").asBoolean()


  const open = Boolean(anchorEl);
  const handleOpenNavMenu = (event: any) => {
    // setAnchorNavMenuEl(event.currentTarget);
    modalDispatch({ type: "navigation" });
  };
  const handleCloseNavMenu = () => {
    setAnchorNavMenuEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const goto = (uri: any) => {
    navigate(uri);
    setAnchorEl(null);
    setAnchorNavMenuEl(null);
  };
  const gotoUrl = (uri: string) => {
    if (!uri.includes("http")) {
      window.location.href = settings.PUBLIC_URL + uri;
    } else {
      window.location.href = uri;
    }
    return null;
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="sticky" elevation={0} sx={{
      py: 2,
      pr: 4,
      pl: 4,
      insetBlockStart: 0,
      zIndex: 1000,
      ...sx
    }} color="transparent">

      <Toolbar variant="dense" disableGutters sx={{
        display: "flex",
        justifyContent: "space-between"
      }}>
        <IconButton aria-label="Menu" sx={{
          display: { lg: "none", xs: "flex" },
          mr: 4,
        }}
          onClick={handleOpenNavMenu}
          data-testid="nav-open"
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{
          width: "100%",
          display: { sm: "flex", xs: "none" },
          flexDirection: "row",
          justifyContent: "space-between"
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            gap: 2,
            maxWidth: "300px",
            position: "relative"
          }}>

            {algoliaSearch &&
              <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                gap: 2,
                maxWidth: "300px",
                cursor: "pointer"
              }}
                onClick={() => { setSearchOpen(true) }}
              >
                <Search />
                <Typography variant="body1" color={"primary"}>Search</Typography>
              </Box>
            }

          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center"
          }}>
            <IconButton>
              <Notifications />
            </IconButton>
            <IconButton
              color="primary"
              data-testid="navbar-my-account"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              size="large"
              sx={{
                backgroundColor: "white.main",
              }}
              style={linkStyle()}
              onClick={handleClick}
            >
              <Person />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => { goto('account/dashboard'); }}>Dashboard</MenuItem>
              {userData?.get("companies")?.length === 1 && <MenuItem data-testid="nav-company" onClick={() => { goto('company'); }}>Company</MenuItem>}

              <MenuItem onClick={() => { goto('account/dashboard'); }}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={() => { goto('/logout'); }}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>

          </Box>
        </Box>
        <Menu
          id="nav-menu"
          anchorEl={anchorNavMenuEl}
          open={Boolean(anchorNavMenuEl)}
          onClose={handleCloseNavMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          disableScrollLock={true}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          <MenuItem onClick={() => { goto('account/dashboard'); }}>Dashboard</MenuItem>
          {/* <MenuItem onClick={() => { goto('account/claims'); }}>Claims</MenuItem> */}
          <MenuItem onClick={() => { gotoUrl(`${settings.REACT_APP_WHEN_URL}/learn`) }}>Learn About Insurance</MenuItem>
          <MenuItem onClick={() => { goto('account/insurance-plans'); }}>Find Health Insurance</MenuItem>
          <MenuItem onClick={() => { goto('jamie'); }}>Jamie</MenuItem>
          <MenuItem onClick={() => { goto('account/dashboard'); }}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={() => { goto('/logout'); }}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
      <Dialog
        fullWidth
        maxWidth="md"
        open={searchOpen}
        onClose={() => { setSearchOpen(false) }}
      >
        <Box sx={{
          p: 4,
          height: "80vh",
        }}>
          <SearchDialog onClose={() => { setSearchOpen(false) }} />
        </Box>
      </Dialog>
    </AppBar >
  );
}
export default Navbar;
