import React, { useEffect, useReducer, useState } from 'react'
import { AdminCaseActivity, COBRAElectionReasonType, Case, CaseActivityChange, CaseActivityTypes, HealthInsuranceDecisionType } from 'services/Interfaces';
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { AdminCaseActivityConverter, CaseConverter } from 'services/Firestore';
import { useNotifications } from 'contexts/Notification';
import { useFirebase } from 'contexts/Firebase';
import moment from 'moment';
import { useAuth } from 'contexts/User';
import { Button, Dialog, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridCloseIcon } from '@mui/x-data-grid';

function addActivityChange(
    changes: CaseActivityChange[],
    field: string,
    oldValue: any,
    newValue: any
) {
    if (oldValue !== newValue) {
        changes.push({
            field,
            oldValue,
            newValue,
        });
    }
}

interface FormState {
    healthInsuranceDecision?: HealthInsuranceDecisionType;
    cobraElectionReason?: COBRAElectionReasonType;
}

interface Validation {
    healthInsuranceDecision?: string
    cobraElectionReason?: string
}

type FormAction =
    | { type: "SET_HEALTH_INSURANCE_DECISION"; payload?: HealthInsuranceDecisionType }
    | { type: "SET_COBRA_ELECTION_REASON"; payload?: COBRAElectionReasonType }

const initialState: FormState = {
    healthInsuranceDecision: undefined,
    cobraElectionReason: undefined
};

function formReducer(state: FormState, action: FormAction): FormState {
    switch (action.type) {
        case "SET_HEALTH_INSURANCE_DECISION":
            return { ...state, healthInsuranceDecision: action.payload };
        case "SET_COBRA_ELECTION_REASON":
            return { ...state, cobraElectionReason: action.payload };
        default:
            return state;
    }
}


const HealthInsuranceDecisionDialog = ({ theCase, open, onClose }: { theCase: Case, open: boolean, onClose: Function }) => {
    const { firestore } = useFirebase();
    const { userData } = useAuth();
    const { notifications } = useNotifications();
    const [formState, dispatch] = useReducer(formReducer, initialState);
    const [validation, setValidation] = useState<Validation>({});

    // when the case loads set the values
    useEffect(() => {
        if (theCase) {
            dispatch({
                type: "SET_HEALTH_INSURANCE_DECISION",
                payload: theCase.healthInsuranceDecision,
            });
            dispatch({
                type: "SET_COBRA_ELECTION_REASON",
                payload: theCase.cobraElectionReason,
            });
        }
    }, [theCase, open]);

    const handleCancel = () => {
        onClose();
        setValidation({});
    }

    const saveHealthInsuranceDecision = async (details: any) => {
        let validateObj: Validation = {}
        if (!formState.healthInsuranceDecision) {
            validateObj["healthInsuranceDecision"] = "Health Insurance Decision required"
        }
        if (
            formState?.healthInsuranceDecision === HealthInsuranceDecisionType.ELECTED_COBRA &&
            !formState?.cobraElectionReason
        ) {
            validateObj["cobraElectionReason"] = "Cobra Election Reason required"
        }
        if (Object.keys(validateObj).length) {
            setValidation(validateObj);
            return;
        }
        const changes: CaseActivityChange[] = [];
        addActivityChange(
            changes,
            "Health Insurance Decision",
            theCase?.healthInsuranceDecision,
            formState.healthInsuranceDecision
        );
        addActivityChange(
            changes,
            "COBRA Election Reason",
            theCase?.cobraElectionReason,
            formState?.healthInsuranceDecision === HealthInsuranceDecisionType.ELECTED_COBRA
                ? formState?.cobraElectionReason : COBRAElectionReasonType.NONE
        );
        console.log("::: changes :::", changes);

        if (changes?.length === 0) {
            handleCancel();
            return;
        }

        if (!theCase || !theCase?.id || !theCase?.companyId) {
            return;
        }
        await setDoc(
            doc(
                firestore,
                `companies/${theCase?.companyId}/cases/${theCase?.id}`
            ).withConverter(CaseConverter),
            {
                healthInsuranceDecision: formState?.healthInsuranceDecision,
                cobraElectionReason: formState?.healthInsuranceDecision === HealthInsuranceDecisionType.ELECTED_COBRA
                    ? formState?.cobraElectionReason : COBRAElectionReasonType.NONE
            },
            { merge: true }
        );

        await addDoc<AdminCaseActivity>(
            collection(
                firestore,
                "companies",
                theCase.companyId,
                "cases",
                theCase.id,
                "activity"
            ).withConverter(AdminCaseActivityConverter),
            {
                activity: CaseActivityTypes.CASE_UPDATED,
                type: "admin_action",
                userId: userData?.id || "",
                caseId: theCase?.id || "",
                companyId: theCase?.companyId,
                displayName: userData?.data()?.name?.display || "Name Missing",
                message: "Health Insurance Decision Updated",
                accessedBy: userData?.id || "",
                changes,
                timestamp: moment(),
            }
        );
        handleCancel();
        notifications.success("Health Insurance Decision updated successfully");
    }
    return (
        <Dialog
            open={open}
            onClose={handleCancel}
        >
            <Box
                sx={{
                    width: 500,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItem: "center",
                    justifyContent: "space-between",
                }}>
                    <Typography variant="h3">Edit Health Insurance Decision</Typography>
                    <IconButton
                        sx={{ position: 'absolute', p: 1, right: 0, top: 0, color: 'primary' }}
                        onClick={() => onClose()}>
                        <GridCloseIcon />
                    </IconButton>
                </Box>
                <FormControl fullWidth sx={{ display: "flex", gap: 2 }}>
                    <FormControl>
                        <InputLabel id="health-insurance-decision">Health Insurance Decision</InputLabel>
                        <Select
                            value={formState.healthInsuranceDecision || ""}
                            onChange={(e) => {
                                dispatch({
                                    type: "SET_HEALTH_INSURANCE_DECISION",
                                    payload: e.target.value as HealthInsuranceDecisionType,
                                });
                            }}
                            label={"Health Insurance Decision"}
                        >
                            {Object.values(HealthInsuranceDecisionType)
                                .map((key) => {
                                    return (
                                        <MenuItem key={key} value={key}>
                                            {key}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        {validation['healthInsuranceDecision'] && <Typography color="error">{validation['healthInsuranceDecision']}</Typography>}
                    </FormControl>
                    {
                        formState.healthInsuranceDecision === HealthInsuranceDecisionType.ELECTED_COBRA &&
                        <FormControl>
                            <InputLabel id="cobra-election-reason">Cobra Election Reason</InputLabel>
                            <Select
                                value={formState.cobraElectionReason || ""}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SET_COBRA_ELECTION_REASON",
                                        payload: e.target.value as COBRAElectionReasonType,
                                    });
                                }}
                                label={"Cobra Election Reason"}
                            >
                                {Object.values(COBRAElectionReasonType)
                                    .map((key) => {
                                        return (
                                            <MenuItem key={key} value={key}>
                                                {key}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                            {validation['cobraElectionReason'] && <Typography color="error">{validation['cobraElectionReason']}</Typography>}
                        </FormControl>
                    }
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={saveHealthInsuranceDecision}
                >
                    Save
                </Button>
            </Box>
        </Dialog>
    )
}

export default HealthInsuranceDecisionDialog