import React from 'react';
import { Button, Dialog, DialogContent, DialogActions, Typography } from "@mui/material";

interface DisableMfaDialogProps {
  open: boolean;
  onClose: () => void;
  handleConfirm: () => void;
}

const DisableMfaDialog: React.FC<DisableMfaDialogProps> = ({ open, onClose, handleConfirm }) => {

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogContent sx={{ textAlign: 'center', p: { xs: 2, sm: 4 } }}>
          <Typography sx={{ fontSize: { xs: '1.3rem', sm: '1.8rem' }, mb: { xs: 1, sm: 4 } }} fontWeight={600}>
            Are you sure you want to disable MFA?
          </Typography>
          <Typography sx={{ fontSize: { xs: '1rem', sm: '1.2rem' }, mb: { sm: 4, xs: 2 } }} fontWeight={200}>
            Disabling MFA reduces the security of your account. Are you sure you want to proceed?
          </Typography>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              sx={{ m: 1, fontSize: { xs: 12, sm: 16 } }}
              onClick={onClose}>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ m: 1, fontSize: { xs: 14, sm: 16 } }} onClick={handleConfirm}>Disable MFA</Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DisableMfaDialog;
