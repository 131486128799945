import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as JamieImage } from 'images/Jamie.svg';
import moment from "moment";
import { useNavigate } from "react-router";


function JamieCard() {
    const navigate = useNavigate()
    return (
        <Card sx={{
            backgroundColor: "beige.dark",
            borderRadius: "12px",
            border: 0,
            height: "100%"
        }}>
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={2} sx={{
                        justifyContent: "left",
                        display: "flex"
                    }}>
                        <JamieImage width={"80px"} />
                    </Grid>
                    <Grid item xs={12} sm={10} sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2
                    }}>
                        <Typography variant="h6" fontWeight={600}>Jamie here, hope you&apos;re having a nice {moment().format('dddd')}!</Typography>
                        <Button variant="contained"  onClick={()=>{navigate("/jamie")}}>Continue chat</Button>
                    </Grid>
                    <Grid item xs={12} sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}>
                        <Typography variant="body2" fontStyle="italic">If you&apos;re still looking for a health insurance policy, check out my recommendations below.</Typography>
                        {/* <Box sx={{
                            display: "flex",
                            gap: 3
                        }}>
                            <Button variant="contained">Yes</Button>
                            <Button variant="outlined" sx={{ backgroundColor: "beige.main" }}>No</Button>
                        </Box> */}

                    </Grid>
                </Grid>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 4
                }}>
                </Box>
                <Box></Box>
            </CardContent>
        </Card>
    );
}
export default JamieCard;