import { DocumentData, SnapshotOptions, FirestoreDataConverter, QueryDocumentSnapshot } from "firebase/firestore";
import moment, { Moment } from "moment";
import { AdminCaseActivity, Case, CaseActivity, CaseDocument, CaseSeveranceBenefit, Company, CompanyPrivateSetting, CompanyUser, ExitLetter, SeveranceBenefit, SeverancePackage, Task, WhenBenefitSetting } from "services/Interfaces";


export const CompanyConverter: FirestoreDataConverter<Company> = {
  toFirestore(company: Company): DocumentData {
    return {
      ...company,
      hris: company.hris ? {
        ...company.hris,
        lastImport: company.hris.lastImport ? company.hris.lastImport.toDate() : undefined
      } : undefined,
      createdOn: company.createdOn ? company.createdOn.toDate() : moment().toDate(),
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<Company>,
    options: SnapshotOptions
  ): Company {
    const data = snapshot.data(options);
    const hris = data.hris;
    const branding = data?.branding;
    if (hris?.lastImport) {
      hris.lastImport = moment(hris.lastImport.toDate());
    }
    return {
      name: data.name,
      logo: data.logo,
      code: data.code,
      hrisSetup: data.hrisSetup,
      hris: hris,
      branding: branding,
      landingPages: data.landingPages,
      verificationStep: Boolean(data.verificationStep),
      emailDomain: data.emailDomain,
      createdOn: data.createdOn ? moment(data.createdOn.toDate()) : moment(),
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};
export const CompanyUserConverter: FirestoreDataConverter<CompanyUser> = {
  toFirestore(companyUser: CompanyUser): DocumentData {
    console.log("update companyUser", {
      ...companyUser,
      name: {
        first: companyUser.name.first,
        last: companyUser.name.last,
        lowercase: `${companyUser.name.first.toLowerCase()} ${companyUser.name.last.toLowerCase()}`,
        display: `${companyUser.name.first} ${companyUser.name.last}`
      },
      createdAt: companyUser.createdAt ? companyUser.createdAt?.toDate() : moment().toDate(),
      updatedAt: moment().toDate(),
    })
    return {
      ...companyUser,
      name: {
        first: companyUser.name.first,
        last: companyUser.name.last,
        lowercase: `${companyUser.name.first.toLowerCase()} ${companyUser.name.last.toLowerCase()}`,
        display: `${companyUser.name.first} ${companyUser.name.last}`
      },
      createdAt: companyUser.createdAt ? companyUser.createdAt?.toDate() : moment().toDate(),
      updatedAt: moment().toDate(),
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<CompanyUser>,
    options: SnapshotOptions
  ): CompanyUser {
    const data = snapshot.data(options);
    return {
      name: data.name,
      email: data.email,
      roles: data.roles,
      active: data.active,
      inviteEmail: data.inviteEmail,
      userId: data.userId,
      createdAt: data.createdAt ? moment(data.createdAt.toDate()) : undefined,
      updatedAt: data.updatedAt ? moment(data.updatedAt.toDate()) : undefined,
      updatedBy: data.updatedBy,
      updatedByUid: data.updatedByUid,
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};

// function to parse Firestore dates
const parseDate = (date: any): Moment | undefined => {
  if (date?.toDate) {
    return moment(date.toDate());
  }
  return date ? moment(date, "MM/DD/YYYY") : undefined;
};

//  function to format dates into MM/DD/YYYY string
const formatDate = (date: any): string | undefined => {
  const parsedDate = parseDate(date);
  return parsedDate ? parsedDate.format("MM/DD/YYYY") : undefined;
};

export const CaseConverter: FirestoreDataConverter<Case> = {
  toFirestore(theCase: Case): DocumentData {
    const merge: any = {};
    if (theCase.createdAt) {
      merge['createdAt'] = theCase.createdAt.toDate();
    }
    if (theCase.syncUser?.date) {
      merge['syncUser.date'] = theCase.syncUser.date.toDate();
    }
    return {
      ...theCase,
      ...merge
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<Case>,
    options: SnapshotOptions
  ): Case {
    const data = snapshot.data(options);
    if (data?.employee?.dateOfBirth && (data?.employee.dateOfBirth as any).toDate) {
      data.employee.dateOfBirth = moment((data.employee.dateOfBirth as any).toDate()).format("MM/DD/YYYY");
    }
    if (data.syncUser?.date) {
      data.syncUser.date = moment(data.syncUser.date.toDate());
    }
    return {
      ...data,
      contact: {
        ...data.contact,
        dateOfBirth: formatDate(data.contact?.dateOfBirth) ?? "",
      },
      event: {
        ...data.event,
        date: formatDate(data?.event?.date) ?? "",
      },
      employee: data.employee ?? undefined,
      syncUser: data.syncUser
        ? {
            ...data.syncUser,
            date: parseDate(data.syncUser.date) ?? moment(),
          }
        : undefined,
      createdAt: parseDate(data.createdAt) ?? moment(),
      updatedAt: parseDate(data.updatedAt) ?? moment(),
      closeDate: parseDate(data.closeDate),
      shared: formatDate(data.shared),
      released: parseDate(data.released),
      id: snapshot.id,
      ref: snapshot.ref,
      benefitTier: data.benefitTier,
      healthInsuranceDecision: data.healthInsuranceDecision,
      cobraElectionReason: data.cobraElectionReason,
      terminationDate: formatDate(data.terminationDate),
      lastDay: formatDate(data.lastDay),
      exitMeetingDate: formatDate(data.exitMeetingDate),
      dataVersion: data.dataVersion ?? undefined,
    };
  },
};
function createActivityConverter<
  T extends CaseActivity
>(): FirestoreDataConverter<T> {
  return {
    toFirestore(activity: T): DocumentData {
      return {
        ...activity,
        timestamp: activity.timestamp.toISOString(),
      };
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot<T>,
      options: SnapshotOptions
    ): T {
      const data = snapshot.data(options);
      return {
        activity: data.activity,
        type: data.type,
        userId: data.userId,
        caseId: data.caseId,
        companyId: data.companyId,
        displayName: data.displayName,
        message: data.message,
        timestamp: moment(data.timestamp),
        changes: data.changes,
        id: snapshot.id,
        ref: snapshot.ref,
      } as T;
    },
  };
}

export const CaseActivityConverter = createActivityConverter<CaseActivity>();
export const AdminCaseActivityConverter =
  createActivityConverter<AdminCaseActivity>();

export const CaseSeveranceBenefitConverter: FirestoreDataConverter<CaseSeveranceBenefit> = {
  toFirestore(benefit: CaseSeveranceBenefit): DocumentData {
    return {
      ...benefit,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<CaseSeveranceBenefit>,
    options: SnapshotOptions
  ): CaseSeveranceBenefit {
    const data = snapshot.data(options);
    return {
      type: data.type,
      name: data.name,
      amountOrCoverage: data.amountOrCoverage,
      serviceId: data.serviceId,
      conditions: data.conditions,
      amount: data.amount,
      value: data.value,
      active: data.active,
      id: snapshot.id,
      ref: snapshot.ref,
      calculation: data.calculation,
      structure: data.structure,
      term: data.term,
    };
  },
};
export const CaseDocumentConverter: FirestoreDataConverter<CaseDocument> = {
  toFirestore(doc: CaseDocument): DocumentData {
    return {
      ...doc,
      updatedAt: doc.updatedAt?.toISOString(),
      createdAt: doc.createdAt?.toISOString()
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<CaseDocument>,
    options: SnapshotOptions
  ): CaseDocument {
    const data = snapshot.data(options);
    return {
      name: data.name,
      body: data.body,
      active: data.active,
      status: data.status,
      templateId: data.templateId,
      visibility: data.visibility,
      signatureRequired: data.signatureRequired,
      createdAt: data.createdAt ? moment(data.createdAt) : undefined,
      createdBy: data.createdBy,
      updatedAt: data.updatedAt ? moment(data.updatedAt) : undefined,
      updatedBy: data.updatedBy,
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};

export const TaskConverter: FirestoreDataConverter<Task> = {
  toFirestore(task: Task): DocumentData {
    return {
      ...task,
      deadline: task.deadline ? {
        ...task.deadline,
        date: task.deadline?.date?.toDate()
      } : undefined,
      createdAt: task.createdAt?.toDate(),
      updatedAt: task.updatedAt?.toDate(),
      completedOn: task.completedOn?.toISOString()
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<Task>,
    options: SnapshotOptions
  ): Task {
    const data = snapshot.data(options);
    return {
      name: data.name,
      caseId: data.caseId,
      type: data.type,
      companyId: data.companyId,
      caseName: data.caseName,
      deadline: data.deadline ? {
        beforeOrAfter: data.deadline?.beforeOrAfter,
        days: data.deadline?.days,
        date: data.deadline?.date ? moment(data.deadline?.date?.toDate()) : undefined
      } : undefined,
      assignedTo: data.assignedTo,
      assignedToUsers: data.assignedToUsers,
      status: data.status,
      notes: data.notes,
      createdAt: moment(data.createdAt?.toDate()) || undefined,
      createdBy: data.createdBy,
      updatedAt: moment(data.updatedAt?.toDate()) || undefined,
      updatedBy: data.updatedBy,
      completedBy: data.completedBy,
      completedOn: data.completedOn ? moment(data.completedOn) : undefined,
      completedByUid: data.completedByUid,
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};

export const ExitLetterConverter: FirestoreDataConverter<ExitLetter> = {
  toFirestore(exitLetter: ExitLetter): DocumentData {
    return {
      ...exitLetter,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): ExitLetter {
    const data = snapshot.data(options);
    return {
      name: data.name,
      notes: data.notes,
      body: data.body,
      active: data.active,
      deletedAt: data.delatedAt,
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};

export const SeverancePackageConverter: FirestoreDataConverter<SeverancePackage> = {
  toFirestore(severancePackage: SeverancePackage): DocumentData {
    return {
      ...severancePackage,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): SeverancePackage {
    const data = snapshot.data(options);
    return {
      name: data.name,
      description: data.description,
      active: data.active,
      deletedAt: data.delatedAt,
      id: snapshot.id,
      ref: snapshot.ref
    };
  },
};

export const SeveranceBenefitConverter: FirestoreDataConverter<SeveranceBenefit> = {
  toFirestore(severanceBenefit: SeveranceBenefit): DocumentData {
    return {
      ...severanceBenefit,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): SeveranceBenefit {
    const data = snapshot.data(options);
    return {
      active: data.active,
      type: data.type,
      name: data.name,
      structure: data.structure,
      term: data.term,
      amountOrCoverage: data.amountOrCoverage,
      serviceId: data.serviceId,
      conditions: data.conditions,
      id: snapshot.id,
      ref: snapshot.ref,
      calculation: data.calculation
    };
  },
};
export const CompanyPrivateSettingConverter: FirestoreDataConverter<CompanyPrivateSetting> = {
  toFirestore(privateSettings: CompanyPrivateSetting): DocumentData {
    return {
      ...privateSettings,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): CompanyPrivateSetting {
    const data = snapshot.data(options);
    return {
      billCustomerId: data.billCustomerId,
      invoice: data.invoice,
      fees: data.fees,
      automation: data.automation
    };
  },
};
export const CompanyWhenSettingConverter: FirestoreDataConverter<WhenBenefitSetting> = {
  toFirestore(whenBenefitSettings: WhenBenefitSetting): DocumentData {
    return {
      ...whenBenefitSettings,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): WhenBenefitSetting {
    const data = snapshot.data(options);
    return {
      timing: data.timing
    };
  },
};
