import { Button, Dialog, Typography } from "@mui/material"
import { Box } from "@mui/system"

const ReleaseBenefitDialog = ({ releaseOpen, setReleaseOpen, setReleaseBenefit }: { releaseOpen: boolean, setReleaseOpen: Function, setReleaseBenefit: Function }) => {
  return (
    <>
      <Dialog open={releaseOpen} onClose={() => { setReleaseOpen(false) }}>
        <Box sx={{
          width: 500,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2

        }}>
          <Typography variant="h2">Release Benefit</Typography>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            alignItems: "center"
          }}>
            <Typography>Releasing the When Benefit confirms that the employee has completed all requirements in order to claim the When benefit and enables When to process reimbursement requests.  Do you want to release the benefit?</Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => { setReleaseBenefit(true) }}>Continue</Button>
          <Button onClick={() => { setReleaseOpen(false) }}>Cancel</Button>
        </Box>
      </Dialog>
    </>
  )
}
export default ReleaseBenefitDialog