/** @jsxImportSource @emotion/react */
import { Box, TextField, Button, Typography, Card, CardContent, Backdrop, CircularProgress, List, ListItem } from "@mui/material";
import { useEffect } from "react";
import React from "react";
import { useAuth } from "../../contexts/User";
import { doc, updateDoc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useFirebase } from "contexts/Firebase";

function PersonalInfoModal(props: { close: Function }) {
  const { firestore } = useFirebase();

  const { user } = useAuth();
  const userAnswersDoc = user ? doc(firestore, 'users', user?.uid!, "answers", user?.uid!) : null;
  const [answers, answersLoading, firestoreError] = useDocument(
    userAnswersDoc,
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  async function savePreference(variable: string, value: any) {
    if (userAnswersDoc) {
      console.log('updateDoc')
      await updateDoc(userAnswersDoc, {
        [variable]: value
      });
    }
  }
  useEffect(() => {
    if (firestoreError) {
      console.error(firestoreError);
    }

  })
  return (
    <Box sx={{

    }}>
      <Card sx={{
        backgroundColor: "white.main",
        height: { xs: "100vh", sm: "auto" },
        p: 4,
      }}>
        <CardContent sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: "33px",
          padding: 0
        }}>

          <Box>
            <Typography variant="h2" fontWeight={600} align="center" color={"primary.main"}>Personal Information</Typography>
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: 4
          }}>
            <Box width={"100%"} minHeight={"300px"} position={"relative"}>
              <Backdrop open={answersLoading} sx={{ position: "absolute" }}>
                <CircularProgress color="brightOrange"></CircularProgress>
              </Backdrop>
              <List>
                <ListItem>
                  <TextField label="First Name" value={answers?.get('personalInfo.firstName')} onChange={(e) => { savePreference('personalInfo.firstName', e.target.value) }}></TextField>
                </ListItem>
                <ListItem>
                  <TextField label="Last Name" value={answers?.get('personalInfo.lastName')} onChange={(e) => { savePreference('personalInfo.lastName', e.target.value) }}></TextField>
                </ListItem>
              </List>
            </Box>
          </Box>
          <Box sx={{ mx: "auto" }}>
            <Button variant="outlined" onClick={() => { props.close() }}>Close</Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
export default PersonalInfoModal;
