import { DocumentData, SnapshotOptions, FirestoreDataConverter, QueryDocumentSnapshot } from "firebase/firestore";
import moment from "moment";
import { Application } from "services/Interfaces";

// Implement the FirestoreDataConverter for Application
export const applicationConverter: FirestoreDataConverter<Application> = {
  toFirestore(application: Application): DocumentData {
    return {
      ...application
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<Application>,
    options: SnapshotOptions
  ): Application {
    const data = snapshot.data()!;
    return {
      ...data,
      plan: {
        ...data.plan,
        effectiveDate: data.plan?.effectiveDate?.toDate ? moment(data.plan?.effectiveDate.toDate()) : data.plan?.effectiveDate ? moment(data.plan?.effectiveDate) : ""
      },
      consent: data?.consent?.map((consent: any) => ({
        ...consent,
        consentedOn: consent?.consentedOn?.toDate ? moment(consent?.consentedOn?.toDate()) : null,
      })),
      review: {
        ...data?.review,
        reviewedOn: data?.review?.reviewedOn?.toDate ? moment(data?.review?.reviewedOn?.toDate()) : null,
      },
      submission: {
        ...data?.submission,
        submittedOn: data?.submission?.submittedOn?.toDate ? moment(data?.submission?.submittedOn?.toDate()) : null,
      },
      requests: data?.requests?.map((request: any) => ({
        ...request,
        requiredBy: request?.requiredBy?.toDate ? moment(request?.requiredBy?.toDate()) : null,
      })),
      id: snapshot.id,
      ref: snapshot.ref,
      createdAt: moment(data?.createdAt?.toDate()),
      updatedAt: moment(data?.createdAt?.toDate()),
    }

  },
};