/** @jsxImportSource @emotion/react */
import { Box, TextField, Button, Typography, Grid, Container, IconButton, Link } from "@mui/material";
import { useState, useCallback } from "react";
import React from "react";
import { useAuth } from "../../contexts/User";
import { CloseOutlined } from "@mui/icons-material";

function ForgotPassword({ close }: { close: () => void }) {
  const { sendPasswordResetEmail } = useAuth();
  const [email, setEmail] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loginLoading, setLoading] = useState<boolean>(false);
  const [isAttemptUnsuccessful, setAttemptUnsuccessful] = useState<boolean>(false);

  const validateEmailFormat = useCallback((email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }, []);

  const onEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setError(validateEmailFormat(newEmail) ? "" : "Please enter a valid email address");
  }, [validateEmailFormat]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!email) {
      setError("Please enter your email");
      setLoading(false);
      return;
    }

    if (!validateEmailFormat(email)) {
      setError("Please enter a valid email address");
      setLoading(false);
      return;
    }

    const res = await sendPasswordResetEmail(email);
    setLoading(false);

    if (res) {
      setEmailSent(true);
    } else {
      setAttemptUnsuccessful(true);
    }
  };

  const renderContent = () => {
    if (emailSent) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Typography data-testid="receiveEmailText">If your account exists you will receive an email momentarily.</Typography>
          <Button onClick={close}>Close</Button>
        </Box>
      );
    }

    if (isAttemptUnsuccessful) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' , padding: '0px 0px' }}>
          <Typography color="error" data-testid="receiveEmailText">
          You have exceeded the maximum number of password reset attempts. Please try again in 24 hours. If you need assistance, please contact{' '}
          <Link href="mailto:support@forwhen.com">support@forwhen.com</Link>.
          </Typography>
          <Button onClick={close}>Close</Button>
        </Box>
      );
    }

    return (
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Typography data-testid="emailTextMessage">
            Please enter your email and we will send you an email to reset your password.
          </Typography>
          <TextField
            data-testid="forgotEmailTextfield"
            color="secondary"
            sx={{ backgroundColor: 'secondary.light', borderRadius: '10px' }}
            label="Email Address"
            onChange={onEmailChange}
            value={email}
            required
          />
          {error && (
            <Typography variant="caption" color="error">
              {error}
            </Typography>
          )}
          <Box sx={{ display: 'flex', gap: 4 }}>
            <Button
              variant="contained"
              type="submit"
              data-testid="resetPasswordButton"
              sx={{ maxWidth: { sm: '220px' } }}
              color="secondary"
              disabled={loginLoading}
            >
              Reset Password
            </Button>
            <Button data-testid="cancelForgotPopupButton" onClick={close}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    );
  };

  return (
    <Container
      id="forgotPassword"
      maxWidth="md"
      sx={{
        textAlign: 'center',
        justifyContent: 'center',
        py: 4,
        px: { xs: 2, sm: 4 },
        position: 'relative',
      }}
    >
      <IconButton
        data-testid="closeIconButton"
        onClick={close}
        sx={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      >
        <CloseOutlined />
      </IconButton>
      <Typography
        variant="h4"
        sx={{
          paddingBottom: '2rem',
        }}
      >
        {isAttemptUnsuccessful ? 'Daily limit exceeded' : 'Reset your Password'}
      </Typography>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12}>
          {renderContent()}
        </Grid>
      </Grid>
    </Container>
  );
}

export default ForgotPassword;
