import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  useLazyQuery,
  useMutation,
  createHttpLink,
  useApolloClient,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { Mutations } from "./GraphQL/Mutations";
import { Queries } from "./GraphQL/Queries";
import * as Interfaces from "./Interfaces";
import { onError } from '@apollo/client/link/error';

function createClient(settings: any, user: any , logout: any) {
  try {
    const httpLink = createHttpLink({
      uri: settings.REACT_APP_GRAPHQL_ENDPOINT,
    });
    const authLink = setContext(async (_, { headers }) => {

      // console.log("add auth to graphql");
      // get the authentication token from local storage if it exists
      const token = await user?.getIdToken();
      // console.log("token", token);
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        }
      }
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(async ({ message }) => {
          console.error(`[GraphQL error]: Message: ${message}`);
          if (message === 'Authentication error' || message === 'Context creation failed: Authentication error') {
            logout().then(() => window.location.href = "/login");
          }
        });
      }
    });

    return new ApolloClient({
      link: errorLink.concat(authLink.concat(httpLink)),
      cache: new InMemoryCache()
    });
  } catch (e) {
    console.log("createClient error", e);
  }
}
export { createClient, ApolloProvider, useMutation, useQuery, useLazyQuery, useApolloClient, Queries, Mutations, Interfaces };