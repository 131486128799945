import React from "react"
import { reducer, initialState } from "./reducer"

export const DisclaimerContext = React.createContext<any>({
  disclaimerState: initialState,
  disclaimerDispatch: () => { },
})
export function useDisclaimers() {
  const { disclaimerState, disclaimerDispatch } = React.useContext(DisclaimerContext);

  return {
    disclaimers: Object.keys(disclaimerState).map((key: string) => {
      return disclaimerState[key];
    }),
    addDisclaimer: (carrierId: string, disclaimer: string) => {
      return disclaimerDispatch({ type: "addDisclaimer", carrierId, disclaimer })
    },
    getDisclaimers: () => {
      return disclaimerDispatch({ type: "getDisclaimers" });
    },
    clearDisclaimers: () => {
      return disclaimerDispatch({ type: "clearDisclaimers" })
    }
  }

}
export const DisclaimerProvider = ({ children }: { children: any }) => {
  const [disclaimerState, disclaimerDispatch] = React.useReducer(reducer, initialState)
  return (
    <DisclaimerContext.Provider value={{ disclaimerState, disclaimerDispatch }}>
      {children}
    </DisclaimerContext.Provider>
  )
}