/** @jsxImportSource @emotion/react */
import { AppBar, Toolbar, Box, Container, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { keyboardToClick } from "services/Aria";

function Navbar() {
  const navigate = useNavigate();

  const goto = (uri: any) => {
    navigate(uri);
  };
  return (
    <AppBar position="sticky" elevation={0} sx={{
      py: 2,
      insetBlockStart: 0,
      zIndex: 1000,
    }} color="primary">
      <Toolbar variant="dense" sx={{
      }}>
        <Container maxWidth="xl">
          <Box sx={{
            width: "100%",
            display: { sm: "flex", xs: "none" },
            flexDirection: "row",
            justifyContent: "space-between"
          }}>
            <Button variant="text" onClick={() => goto("/")} onKeyDown={keyboardToClick} >
              <img id="main-logo" src="/when-logo-reversed.png" width="120px" alt="When" />
            </Button>

          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
