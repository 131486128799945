import React, { useEffect, useRef } from 'react'
/** @jsxImportSource @emotion/react */
import { Typography, Box, Button, TextField } from "@mui/material";
import { useCompany } from "contexts/Company";
import { useState } from "react";
// import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useNavigate } from "react-router-dom";
import { useLazyQuery } from '@apollo/client';
import algoliasearch from 'algoliasearch';
import { useHits, useInstantSearch, InstantSearch, Configure, Index } from 'react-instantsearch';
import { Queries } from 'services/apollo';
import { useSettings } from 'contexts/Settings';


let timerId: any = undefined;
const timeout = 250;

function CaseResults({ onClose }: { onClose: any }) {
    const { hits } = useHits();
    const navigate = useNavigate();
    return (
        <Box>
            {hits.map((hit: any) => (
                <Box key={hit.objectID}>
                    <Button onClick={() => { console.log("NAV"); navigate(`/company/cases/${hit.objectID}`); onClose(); }} variant="text">{hit.caseName}</Button>
                </Box>
            ))}
        </Box>
    );
}
function NoResultsBoundary({ children, fallback, refreshSearchToken }: { children: any, fallback: any, refreshSearchToken: any }) {
    const { results, error, addMiddlewares } = useInstantSearch();
    useEffect(() => {
        if (error) {
            console.error("algoliaError", error)
        }
    })
    useEffect(() => {
        const middleware = ({ instantSearchInstance }: { instantSearchInstance: any }) => {
            function handleError(searchError: any) {
                if (searchError.message.includes("validUntil")) {
                    // refresh token
                    console.log("refresh token")
                    // setRefreshing(true)
                    refreshSearchToken();
                } else {
                    console.error("algolia error", searchError.message, ",-,", searchError.name, ",-,", searchError.stack);
                }
            }

            return {
                subscribe() {
                    instantSearchInstance.addListener('error', handleError);
                },
                unsubscribe() {
                    instantSearchInstance.removeListener('error', handleError);
                },
            };
        };

        return addMiddlewares(middleware);
    }, [addMiddlewares, refreshSearchToken]);
    // The `__isArtificial` flag makes sure not to display the No Results message
    // when no hits have been returned.
    if (!results.__isArtificial && results.nbHits === 0) {
        return (
            <>
                {fallback}
                <div hidden>{children}</div>
            </>
        );
    }
    if (error) {

        return (
            <>
                {fallback}
                <div hidden>{children}</div>
            </>
        );
    }

    return children;
}

function SearchDialog({ onClose }: { onClose: any }) {

    const { settings } = useSettings();
    const [searchClient, setSearchClient] = useState<any>(null);
    const [search, setSearch] = useState<string>("");
    const [searchDebounce, setSearchDebounce] = useState<string>("");
    const { company } = useCompany();

    const [getSearchToken, searchToken] = useLazyQuery(Queries.getSearchToken, {
        fetchPolicy: "network-only",
    })
    const searchFieldRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        if (company && (!searchToken || !searchToken.called)) {
            getSearchToken({
                variables: {
                    companyId: company?.id!
                }
            });
        }
    }, [company, searchToken, getSearchToken])
    useEffect(() => {
        if (searchToken?.data?.getSearchToken) {
            console.log("algoliaId", settings.REACT_APP_ALGOLIA_APP_ID, settings.REACT_APP_ALGOLIA_CASES_INDEX, searchToken?.data?.getSearchToken)
            setSearchClient(
                algoliasearch(
                    settings.REACT_APP_ALGOLIA_APP_ID || '',
                    searchToken?.data?.getSearchToken,
                    {

                    }
                )
            )
        }
    }, [searchToken, settings.REACT_APP_ALGOLIA_APP_ID, settings.REACT_APP_ALGOLIA_CASES_INDEX])
    useEffect(() => {
        if (search) {
            if (timerId) {
                clearTimeout(timerId);
            }

            timerId = setTimeout(() => setSearchDebounce(search.trim()), timeout);
        }
    })
    const refreshSearchToken = () => {
        getSearchToken({
            variables: {
                companyId: company?.id!
            }
        });
    }

    return (
        <Box>
            <TextField
                inputRef={searchFieldRef}
                inputProps={{
                    style: {
                        cursor: "pointer"
                    }
                }}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus // autofocus when dialog opens
                InputProps={{ disableUnderline: true }}
                variant="standard"
                fullWidth
                placeholder="Search"
                value={search}
                onChange={(e) => { setSearch(e.target.value) }}
            />
            {searchClient &&
                <InstantSearch searchClient={searchClient} indexName={settings.REACT_APP_ALGOLIA_CASES_INDEX}>
                    <Configure hitsPerPage={5} query={searchDebounce} />
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        p: 2,
                    }}>
                        <Typography variant="h3">Cases</Typography>
                        <NoResultsBoundary refreshSearchToken={refreshSearchToken} fallback={<>No Results</>}>

                            <Index indexName={settings.REACT_APP_ALGOLIA_CASES_INDEX || ""}>
                                <Configure facetFilters={["status:open"]} />
                                <CaseResults onClose={onClose} />
                            </Index>
                        </NoResultsBoundary>

                    </Box>
                </InstantSearch>
            }
        </Box>

    )
}
export default SearchDialog;