import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'firebase/auth';
import { Auth, applyActionCode, checkActionCode, sendPasswordResetEmail } from 'firebase/auth';
import { Box, Button, Container, Typography } from '@mui/material';
import { useFirebase } from 'contexts/Firebase';

const AuthActionPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get('mode');
  const oobCode = queryParams.get('oobCode');
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const { auth } = useFirebase()

  const handleVerifyEmail = async (auth: Auth, oobCode: string | null) => {
    if (!oobCode)
      return;
    applyActionCode(auth, oobCode).then((resp) => {
      setStatus('success')
    }).catch((error: any) => {
      setStatus('failure');
      navigate('/');
    });
  }

  useEffect(() => {
    // Handle different modes
    switch (mode) {
      case 'verifyEmail':
        handleVerifyEmail(auth, oobCode);
        break;
      case 'resetPassword':
        navigate(`/password-reset?oobCode=${oobCode}`)
        break;
      case 'recoverEmail':
        handleRecoverEmail(auth, oobCode);
        break;
      default:
        setStatus('error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, oobCode, navigate]);

  function handleRecoverEmail(auth: Auth, oobCode: string | null) {
    let restoredEmail: any = null;
    if (!oobCode)
      return;

    // Confirm the action code is valid.
    checkActionCode(auth, oobCode).then((info) => {
      restoredEmail = info['data']['email'];
      // Revert to the old email.
      return applyActionCode(auth, oobCode);
    }).then((res) => {
      sendPasswordResetEmail(auth, restoredEmail).then((res) => {
        console.log("response", res);
      }).catch((error) => {
        console.log("response", error);
      });
    }).catch((error) => {
      // Invalid code.
      console.log("response", error);
    });
  }

  return (
    <>
      <Container maxWidth="md" sx={{
        textAlign: "center",
        justifyContent: "center",
        pb: { xs: 4 },
        py: 2
      }}>
        {
          !mode ?
            <>
              <Typography variant="body2">Protecting Your Access to Care</Typography>
              <Typography variant="h4" sx={{
                paddingBottom: "2rem",
              }}>Create Your When Account</Typography>
              <Typography variant="h5">
                Thank you for creating an account, a verification email has been sent to your inbox.
              </Typography></> : mode === "verifyEmail" ? <>
                <Box>
                  {status === 'success' &&
                    <>
                      <Typography variant="body2">Protecting Your Access to Care</Typography>
                      <Typography variant="h4" sx={{
                        paddingBottom: "2rem",
                      }}>Create Your When Account</Typography>
                      <Typography variant="h5"> Thank you for verifying your email </Typography>
                      <Button variant="contained" onClick={() => window.location.reload()} type="submit" sx={{ maxWidth: { sm: "220px" }, mt: 2 }} color="secondary">Continue</Button>
                    </>}
                  {status === "error" && <Typography variant="h5"> Something Went Wrong. Please Try Again.</Typography>}
                </Box>
              </> :
              mode === "recoverEmail" ? <>
                <Typography variant="body2">Protecting Your Access to Care</Typography>
                <Typography variant="h3">Please Check Your Email.</Typography>
              </> :
                <>
                </>
        }
      </Container>
    </>
  );
};

export default AuthActionPage;
