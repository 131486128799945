import { Button, Dialog, Divider, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { FormattedNumber } from "react-intl"
import { ReimbursementRequest } from "services/Interfaces"
import CloseIcon from '@mui/icons-material/Close';
import { Mutations } from "services/apollo"
import { useMutation } from '@apollo/client';
import { useNotifications } from "contexts/Notification";

const ApproveRequest = ({ openApproveDialog, setOpenApproveDialog, reimbursementRequest, onClose }: { openApproveDialog: boolean, setOpenApproveDialog: Function, reimbursementRequest: ReimbursementRequest, onClose: Function }) => {
  const [approvedAmount, setApprovedAmount] = useState<number>()
  const [error, setError] = useState<string>()
  const [approveReimbursementRequest] = useMutation(Mutations.approveReimbursementRequest);
  const { notifications } = useNotifications();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const approvedRequest = async () => {
    if (!approvedAmount) {
      setError("Please enter an amount no more than the amount requested.");
      return
    }
    if (error || isSubmitting) {
      return
    }
    try {
      setIsSubmitting(true)
      let status = "";
      if (reimbursementRequest.amount === approvedAmount) {
        status = 'Approved';
      } else {
        status = 'Partially Approved';
      }

      await approveReimbursementRequest({
        variables: {
          requestId: reimbursementRequest?.id || "",
          requestUserId: reimbursementRequest?.userId || "",
          requestBenefitId: reimbursementRequest?.whenBenefitId || "",
          approvedAmount: approvedAmount.toString() || "",
          notes: reimbursementRequest?.notes || "",
          status: status,
        },
      });

      notifications.success('Reimbursement request approved successfully.');
    } catch (error) {
      console.error('Error approving reimbursement request:', error);
      notifications.error('An error occurred while approving the request. Please try again.');
    } finally {
      setOpenApproveDialog(false)
      setIsSubmitting(false);
      onClose()
    }
  }

  return (
    <>
      <Dialog open={openApproveDialog} onClose={() => { setOpenApproveDialog(false) }} >
        <Box sx={{
          width: 500,
          p: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: 'center'
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "column",
          }}>
            <Typography variant="h2" sx={{ pb: 2 }} >Approve Request</Typography>
            <IconButton sx={{ position: 'absolute', top: '15px', right: '30px', color: 'primary' }} onClick={() => { setOpenApproveDialog(false) }}>
              <CloseIcon />
            </IconButton>
            <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>
          </Box>
          <Typography>Amount Requested : <FormattedNumber style={`currency`} maximumFractionDigits={2} currency="usd" value={reimbursementRequest.amount} /> </Typography>
          <TextField
            label="Approved Amount"
            variant="outlined"
            sx={{
              width: "100%",
              marginTop: "10px",
              '& input[type=number]': {
                '-moz-appearance': 'textfield'
              },
              '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
              },
              '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
              },
            }}
            type="number"
            value={approvedAmount ? approvedAmount : ""}
            onChange={(e) => {
              const parsedValue = parseFloat(e.target.value);
              const amountValue = parseFloat(parsedValue.toFixed(2));
              setApprovedAmount(amountValue)
              if (isNaN(amountValue) || amountValue === 0 || amountValue > reimbursementRequest.amount) {
                setError("Please enter an amount no more than the amount requested.");
              } else {
                setError("");
              }
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              inputProps: {
                step: '0.01',
                pattern: '\\d*\\.?\\d{0,2}',
              },
            }}
          />
          {error && (
            <Typography color={"error"}>{error}</Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={() => { approvedRequest() }}
          >Continue</Button>
          <Button onClick={() => { setOpenApproveDialog(false) }}>Cancel</Button>
        </Box>
      </Dialog>
    </>
  )
}

export default ApproveRequest