import { useEffect, useRef, useState } from 'react';
import { Typography, Box, Switch } from '@mui/material';
import EmailVerificationDialog from './EmailVerificationDialog';
import MfaEnrolledDialog from './MfaEnrolledDialog';
import MfaIntroductionDialog from './MfaIntroductionDialog';
import OtpVerificationDialog from './OtpVerificationDialog';
import PhoneNumberDialog from './PhoneNumberDialog';
import {
  multiFactor,
  RecaptchaVerifier,
  PhoneMultiFactorGenerator, sendEmailVerification, PhoneAuthProvider
} from 'firebase/auth';
import { useNotifications } from 'contexts/Notification';
import DisableMfaDialog from './DisableMfaDialog';
import { useRemoteConfig } from 'contexts/RemoteConfig';
import { useFirebase } from 'contexts/Firebase';
import ReAuthenticateDialog from './ReAuthenticateDialog';

const MultiFactorAuthentication: React.FC = () => {
  const [mfaEnable, setMfaEnable] = useState<boolean>(false);
  const [isMfaIntroductionDialogOpen, setIsMfaIntroductionDialogOpen] = useState(false);
  const [isEmailVerificationDialogOpen, setIsEmailVerificationDialogOpen] = useState(false);
  const [isPhoneNumberDialogOpen, setIsPhoneNumberDialogOpen] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(''));
  const [isOtpVerificationDialogOpen, setIsOtpVerificationDialogOpen] = useState(false);
  const [isMfaEnrolledDialogOpen, setIsMfaEnrolledDialogOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('1');
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const { notifications } = useNotifications();
  const [resendCount, setResendCount] = useState(0);
  const [canResend, setCanResend] = useState(true);
  const [invalidOtp, setInvalidOtp] = useState("");
  const [invalidPhone, setInvalidPhone] = useState("");
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [openReAuthenticateDialog, setOpenReAuthenticateDialog] = useState(false);
  const { auth } = useFirebase()
  const user = auth.currentUser;
  const mfaSMSRestriction = useRemoteConfig('mfaSMS').asBoolean() || false;
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);

  useEffect(() => {
    const checkMFAStatus = async () => {
      if (user) {
        try {
          const enrolledFactors = await multiFactor(user);
          if (enrolledFactors.enrolledFactors.length > 0) {
            setMfaEnable(true);
          }
        } catch (error) {
          console.error('Error checking MFA status:', error);
        }
      }
    };
    checkMFAStatus();
  }, [user]);

  useEffect(() => {
    setInvalidPhone("")
  }, [isPhoneNumberDialogOpen])


  const handleStartPhoneVerification = async () => {
    if (!phoneNumber || phoneNumber === '') {
      setInvalidPhone("Invalid phone number. Please enter a valid phone number.");
      return;
    }
    if (!recaptchaVerifierRef.current) {
      console.log(recaptchaVerifierRef.current, "recaptchaVerifierRef");
      recaptchaVerifierRef.current = new RecaptchaVerifier("phoneVerificationButton", { "size": "invisible" }, auth);
    }
    const recaptchaVerifier = recaptchaVerifierRef.current;
    if (user && user.emailVerified) {
      try {
        const multiFactorSession = await multiFactor(user).getSession();
        const validPhoneNumber = `+${selectedCountryCode}${phoneNumber}`
        const phoneInfoOptions = {
          phoneNumber: validPhoneNumber,
          session: multiFactorSession
        };
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
        setVerificationId(verificationId);
        setIsPhoneNumberDialogOpen(false);
        setIsOtpVerificationDialogOpen(true);
        console.log('Verification code sent to phone number:', phoneNumber);
      } catch (error: any) {
        if (error.code === 'auth/invalid-phone-number') {
          setInvalidPhone("Invalid phone number. Please enter a valid phone number.");
        } else {
          console.log('An error occurred during phone verification:', error);
        }
      }
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!canResend) {
      timer = setTimeout(() => {
        setCanResend(true);
        setResendCount(0);
      }, 30000);
    }
    return () => clearTimeout(timer);
  }, [canResend]);

  const handleResendVerification = () => {
    if (canResend) {
      setCanResend(false);
      setResendCount(resendCount + 1);
      handleStartPhoneVerification();
    }
  };

  const handleSavePhoneNumberToAuth = async (verificationCode: string) => {
    try {
      if (!user) {
        console.log('No user logged in.');
        return;
      }
      const cred = PhoneAuthProvider.credential(verificationId!, verificationCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const multiFactorResult = await multiFactor(user).enroll(multiFactorAssertion);
      console.log(multiFactorResult, " multiFactorResult");
      setMfaEnable(true);
      setIsOtpVerificationDialogOpen(false);
      setIsMfaEnrolledDialogOpen(true)
    } catch (error: any) {
      if (error.code === 'auth/invalid-verification-code') {
        setInvalidOtp("Invalid code provided")
      } else if (error.code === 'auth/missing-verification-code' || error.code === 'auth/missing-code') {
        setInvalidOtp("Please enter a valid code")
      } else {
        notifications.error('An error occurred while saving the phone number.');
      }
      setOtp(Array(6).fill(''));
    }
  };

  const handleMfaEnable = async (check: boolean) => {
    const user = auth.currentUser;

    if (!user) return;

    if (mfaSMSRestriction) {
      if (!check) {
        // MFA is being disabled
        setIsConfirmationDialogOpen(true);
      } else {
        // MFA is being enabled
        if (!user.emailVerified) {
          try {
            // Send the email verification and wait for it to complete
            setIsEmailVerificationDialogOpen(true);
            await sendEmailVerification(user);
            if (user.emailVerified) {
              setIsEmailVerificationDialogOpen(false);
              setIsMfaIntroductionDialogOpen(true);
            }
          } catch (error) {
            console.error('Error sending verification email:', error);
            return;
          }
        } else {
          setIsMfaIntroductionDialogOpen(true);
        }
      }
    }
    else {
      notifications.error("You do not have access to this feature.")
    }

  };

  const handleClick = () => {
    setIsMfaIntroductionDialogOpen(false);
    setIsPhoneNumberDialogOpen(true);
  };

  const handleOtpChange = (index: number, value: string) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleOtpVerificationChanges = async () => {
    await handleSavePhoneNumberToAuth(otp.join(''));
  }
  const handleDisableMfaConfirmation = async () => {
    setIsConfirmationDialogOpen(false);
    try {
      if (!user) return;
      const enrolledFactors = await multiFactor(user);
      if (enrolledFactors.enrolledFactors.length > 0) {
        const smsFactor = enrolledFactors.enrolledFactors.find(
          (factor) => factor.factorId === 'phone'
        );

        if (smsFactor) {
          await multiFactor(user).unenroll(smsFactor);
          notifications.success('SMS MFA disabled successfully');
          setMfaEnable(false);
        }
      }
    } catch (error) {
      console.error('Error disabling SMS MFA:', error);
    }
  };
  if (!mfaSMSRestriction) {
    //if MFA is not enabled return nothing
    return (
      <></>
    )
  }
  return (
    <Box sx={{ px: { xs: 2, sm: 4 } }}>
      <Typography sx={{ pt: 4 }} variant="h3" color="primary" fontWeight={600}>
        Enable MFA
        <Switch
          checked={mfaEnable}
          onChange={(e) => {
            if (mfaSMSRestriction) {
              if (e.target.checked) {
                setOpenReAuthenticateDialog(e.target.checked)
              } else {
                handleMfaEnable(e.target.checked)
              }
            } else {
              notifications.error("You do not have access to this feature.")
            }
          }}
        />
      </Typography>
      <EmailVerificationDialog isOpen={isEmailVerificationDialogOpen} />
      <MfaIntroductionDialog isOpen={isMfaIntroductionDialogOpen} onClose={() => { setIsMfaIntroductionDialogOpen(false) }} handleClick={handleClick} />
      <PhoneNumberDialog setInvalidPhone={setInvalidPhone} InvalidPhone={invalidPhone} isOpen={isPhoneNumberDialogOpen} onClose={() => { setIsPhoneNumberDialogOpen(false) }} handleClick={handleStartPhoneVerification} phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} selectedCountryCode={selectedCountryCode} setSelectedCountryCode={setSelectedCountryCode} />
      <OtpVerificationDialog InvalidOtp={invalidOtp} handleResendVerification={handleResendVerification} isOpen={isOtpVerificationDialogOpen} onClose={() => setIsOtpVerificationDialogOpen(false)} handleClick={handleOtpVerificationChanges} otp={otp} setOtp={setOtp} handleOtpChange={handleOtpChange} />
      <MfaEnrolledDialog isOpen={isMfaEnrolledDialogOpen} onClose={() => { setIsMfaEnrolledDialogOpen(false) }} handleClick={() => { setIsMfaEnrolledDialogOpen(false); setPhoneNumber(''); setOtp(Array(6).fill('')); }} />
      <DisableMfaDialog open={isConfirmationDialogOpen} onClose={() => { setIsConfirmationDialogOpen(false) }} handleConfirm={handleDisableMfaConfirmation} />
      {openReAuthenticateDialog && <ReAuthenticateDialog openReAuthenticateDialog={openReAuthenticateDialog} setOpenReAuthenticateDialog={setOpenReAuthenticateDialog} handleMfaEnable={handleMfaEnable} />}
    </Box>
  );
};

export default MultiFactorAuthentication;

