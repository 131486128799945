import { Box, Typography, Card, CardContent, Button, Divider, Link, ListItem, List, useTheme, useMediaQuery, Dialog } from "@mui/material";
import ScheduleWithAgentModal from "components/Modals/ScheduleWithAgentModal";
import { useSettings } from "contexts/Settings";
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { Interfaces } from 'services/apollo';

function SelectPlanModal(props: { plan: Interfaces.HealthPlanDetails, close: Function }) {
  const { settings } = useSettings();
  const [scheduleWithAgent, setScheduleWithAgent] = useState<boolean>(false);
  const plan = props.plan;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  useEffect(() => {
    mixpanel.track("SelectHealthPlan", {
      plan: {
        name: props.plan.name,
        carrier: props.plan.provider,
        id: props.plan.id,
        type: props.plan.type
      }
    });
  })
  return (
    <Card sx={{
      backgroundColor: "white.main",
      height: { xs: "100vh", sm: "auto" },
      minWidth: "50vw",
      overflowY: "scroll",
      p: 4,
    }}>
      <CardContent sx={{
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        gap: "33px",
        padding: 0
      }}>

        <Box>
          <Typography variant="h2" fontWeight={600} align="center" color={"primary.main"}>How to Enroll</Typography>
          <Typography align="center">{plan.provider} - {plan.name}</Typography>
          <Typography align="center">You selected plan ID: <Typography component={"span"} fontWeight={600}>{plan.id}</Typography></Typography>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: 4
        }}>
          <Box width={"100%"}>
            <Typography variant="h2" align="center">Do It Yourself</Typography>
            <List>
              <ListItem>
                <Typography color={"primary"} fontWeight={600} px={2}>1.</Typography>
                <Link href="https://www.healthcare.gov/see-plans/#/" target="_blank">Go to Healthcare.gov</Link>
              </ListItem>
              <ListItem>
                <Typography color={"primary"} fontWeight={600} px={2}>2.</Typography>
                <Typography color={"primary"}>Enter your zipcode</Typography>
              </ListItem>
              <ListItem>
                <Typography color={"primary"} fontWeight={600} px={2}>3.</Typography>
                <Typography color={"primary"}>Click &quot;See Full Price Plans&quot;</Typography>
              </ListItem>
              <ListItem>
                <Typography color={"primary"} fontWeight={600} px={2}>4.</Typography>
                <Typography color={"primary"}>Close the &quot;Compare Plans&quot; popup</Typography>
              </ListItem>
              <ListItem>
                <Typography color={"primary"} fontWeight={600} px={2}>5.</Typography>
                <Typography color={"primary"}>Click &quot;Add filters&quot; button</Typography>
              </ListItem>
              <ListItem>
                <Typography color={"primary"} fontWeight={600} px={2}>6.</Typography>
                <Typography color={"primary"}>Enter your Plan ID into the &quot;Search for Plan Id&quot; field: <Typography component={"span"} fontWeight={600}>{plan.id}</Typography></Typography>
              </ListItem>
            </List>
          </Box>
          <Divider orientation={isSm ? "vertical" : "horizontal"} flexItem variant="middle" sx={{ backgroundColor: "brightOrange.main" }}></Divider>
          <Box width={"100%"} sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2
          }}>
            <Typography variant="h2" align="center">Get Expert Help</Typography>
            <Typography>Our team of When Licensed Insurance Agents are here to help you find a plan, enroll, and assist with other health insurance-related questions.</Typography>
            <Link href={"tel:" + settings.REACT_APP_CONCIERGE_PHONE} color="primary">Call: {settings.REACT_APP_CONCIERGE_PHONE}</Link>
          </Box>
        </Box>
        <Box sx={{ mx: "auto" }}>
          <Button variant="outlined" onClick={() => { props.close() }}>Close</Button>
        </Box>

        <Dialog
          open={scheduleWithAgent}
          onClose={() => { setScheduleWithAgent(false); }}
          fullScreen={isSm ? false : true}

          maxWidth="lg"
        >
          <ScheduleWithAgentModal title="Schedule a Call with an Agent About This Policy" close={() => { setScheduleWithAgent(false); }}></ScheduleWithAgentModal>
        </Dialog>
      </CardContent>
    </Card>
  );
}
export default SelectPlanModal;