/** @jsxImportSource @emotion/react */
import { AppBar, Toolbar, Box, Menu, MenuItem, ListItemIcon, IconButton, TextField, Typography, Button, Dialog } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import Logout from '@mui/icons-material/Logout';
import Settings from '@mui/icons-material/Settings';
import MenuIcon from '@mui/icons-material/Menu';
import { Notifications, Person, Search } from "@mui/icons-material";
import { useAuth } from "contexts/User";
import algoliasearch from 'algoliasearch/lite';
import { Configure, Index, InstantSearch, useHits, useInstantSearch } from 'react-instantsearch';
import { useLazyQuery } from "@apollo/client";
import { Queries } from "services/apollo";
import { useCompany } from "contexts/Company";
import { ModalContext } from "contexts/Modal";
import { useSettings } from "contexts/Settings";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";

function linkStyle() {
  return {
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "400"
  };
}
function NoResultsBoundary({ children, fallback, refreshSearchToken }: { children: any, fallback: any, refreshSearchToken: any }) {
  const { results, error, addMiddlewares } = useInstantSearch();
  useEffect(() => {
    if (error) {
      console.error("algoliaError", error)
    }
  })
  useEffect(() => {
    const middleware = ({ instantSearchInstance }: { instantSearchInstance: any }) => {
      function handleError(searchError: any) {
        if (searchError.message.includes("validUntil")) {
          // refresh token
          console.log("refresh token")
          // setRefreshing(true)
          refreshSearchToken();
        } else {
          console.error("algolia error", searchError.message, ",-,", searchError.name, ",-,", searchError.stack);
        }
      }

      return {
        subscribe() {
          instantSearchInstance.addListener('error', handleError);
        },
        unsubscribe() {
          instantSearchInstance.removeListener('error', handleError);
        },
      };
    };

    return addMiddlewares(middleware);
  }, [addMiddlewares, refreshSearchToken]);
  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned.
  if (!results.__isArtificial && results.nbHits === 0) {
    return children;
  }
  if (error) {

    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
}
function CaseResults({ close }: { close: any }) {
  const caseColumns = [
    { field: 'objectID', headerName: 'Case ID', width: 200 },
    { field: 'companyId', headerName: 'Company', width: 200 },
    { field: 'shared', headerName: 'Shared', width: 200, valueGetter: (params: any) => { return moment(params.row.shared)?.format("MM/DD/YYYY") || "" } },
    {
      field: "actions",
      headerName: "Actions",
      width: 200,
      renderCell: (params: any) => {
        return (
          <Button variant="contained" color="primary" onClick={() => {
            navigate(`/admin/cases/${params.row.companyId}-${params.row.objectID}`)
            close();
          }}>View</Button>
        )
      }
    }
  ]
  const { hits } = useHits();
  const navigate = useNavigate();
  console.log(hits);

  return (
    <Box sx={{ minHeight: "200px" }}>
      <DataGrid
        getRowId={(row) => row.objectID}
        rows={hits || []}
        columns={caseColumns}
      />
    </Box>
  );
}

let timerId: any = undefined;
const timeout = 250;
function Navbar({ sx }: { sx?: any }) {
  const { settings } = useSettings();
  const [searchClient, setSearchClient] = React.useState<any>(null);
  const [search, setSearch] = React.useState<string>("");
  const [searchDebounce, setSearchDebounce] = React.useState<string>("");
  const [searchFocus, setSearchFocus] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorNavMenuEl, setAnchorNavMenuEl] = React.useState(null);
  const { userData } = useAuth();
  const { company } = useCompany();
  const { modalDispatch } = useContext(ModalContext);
  const location = useLocation();

  const [getSearchToken, searchToken] = useLazyQuery(Queries.getSearchToken, {
    fetchPolicy: "network-only",
  })

  const open = Boolean(anchorEl);
  const handleOpenNavMenu = (event: any) => {
    // setAnchorNavMenuEl(event.currentTarget);
    modalDispatch({ type: "navigation" });
  };
  const handleCloseNavMenu = () => {
    setAnchorNavMenuEl(null);
  };
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const goto = (uri: any) => {
    navigate(uri);
    setAnchorEl(null);
    setAnchorNavMenuEl(null);
  };
  const gotoUrl = (uri: string) => {
    if (!uri.includes("http")) {
      window.location.href = settings.PUBLIC_URL + uri;
    } else {
      window.location.href = uri;
    }
    return null;
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const refreshSearchToken = () => {
    getSearchToken({
      variables: {
        companyId: company?.id!,
        adminSearch: location.pathname.startsWith('/admin') ? true : false
      }
    });
  }
  useEffect(() => {
    if (!searchToken || !searchToken.called) {
      getSearchToken({
        variables: {
          companyId: company?.id,
          adminSearch: location.pathname.startsWith('/admin') ? true : false
        }
      });
    }
  }, [company, searchToken, getSearchToken, location?.pathname])
  useEffect(() => {
    if (searchToken?.data?.getSearchToken) {
      console.log("algoliaId", settings.REACT_APP_ALGOLIA_APP_ID, settings.REACT_APP_ALGOLIA_INDICES)
      setSearchClient(
        algoliasearch(
          settings.REACT_APP_ALGOLIA_APP_ID || '',
          searchToken?.data?.getSearchToken,
          {

          }
        )
      )
    }
  }, [searchToken, settings.REACT_APP_ALGOLIA_APP_ID, settings.REACT_APP_ALGOLIA_INDICES])
  useEffect(() => {
    if (search) {
      if (timerId) {
        clearTimeout(timerId);
      }

      timerId = setTimeout(() => setSearchDebounce(search.trim()), timeout);
    }
  })
  if (location.pathname.includes('zendesk/ticket')) {
    return (<></>)
  }
  return (
    <AppBar position="sticky" elevation={0} sx={{
      py: 2,
      pr: 4,
      pl: 4,
      insetBlockStart: 0,
      zIndex: 1000,
      ...sx
    }} color="transparent">

      <Toolbar variant="dense" disableGutters sx={{
        display: "flex",
        justifyContent: "space-between"
      }}>
        <IconButton aria-label="Menu" sx={{
          display: { lg: "none", xs: "flex" },
          mr: 4,
        }}
          onClick={handleOpenNavMenu}
          id="nav-open"
        >
          <MenuIcon />
        </IconButton>

        <Box sx={{
          width: "100%",
          display: { sm: "flex", xs: "none" },
          flexDirection: "row",
          justifyContent: "space-between"
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            gap: 2,
            maxWidth: "300px",
            position: "relative"
          }}>

            <Box sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              gap: 2,
              maxWidth: "300px",
              cursor: "pointer"
            }}
              onClick={() => { setSearchFocus(true) }}
            >
              <Search />
              <Typography variant="h4" color="grey">
                Search
              </Typography>
            </Box>
            <Dialog fullWidth maxWidth="lg" sx={{ height: "100%" }} open={searchFocus} onClose={() => { setSearchFocus(false) }}>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                height: "60vh",
              }}>
                <Box sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                  p: 2,
                  borderRadius: "0 0 10px 10px",
                  boxShadow: "4px 10px 10px rgba(0, 0, 0, 0.25)",

                }}>
                  <Search />
                  <TextField
                    inputProps={{
                      style: {
                        cursor: "pointer"
                      }
                    }}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus={true}
                    InputProps={{ disableUnderline: true }}
                    variant="standard"
                    fullWidth
                    placeholder="Search"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                  />
                </Box>
                {searchClient &&
                  <InstantSearch searchClient={searchClient} indexName={settings.REACT_APP_ALGOLIA_CASES_INDEX}>
                    <Configure hitsPerPage={5} query={searchDebounce} />
                    <Box sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}>

                      <Typography variant="h3">Cases</Typography>
                      <Index indexName={settings.REACT_APP_ALGOLIA_CASES_INDEX}>
                        <Configure facetFilters={["status:open"]} />
                        <NoResultsBoundary
                          fallback={<Typography variant="body1">No Results</Typography>}
                          refreshSearchToken={refreshSearchToken}
                        >
                          <CaseResults close={() => { setSearchFocus(false) }} />
                        </NoResultsBoundary>
                      </Index>

                    </Box>
                  </InstantSearch>
                }

              </Box>
            </Dialog>
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            alignItems: "center"
          }}>
            <IconButton>
              <Notifications />
            </IconButton>
            <IconButton
              color="primary"
              data-testid="navbar-my-account"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              size="large"
              sx={{
                backgroundColor: "white.main",
              }}
              style={linkStyle()}
              onClick={handleClick}
            >
              <Person />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => { goto('account/dashboard'); }}>Dashboard</MenuItem>
              {userData?.get("companies")?.length === 1 && <MenuItem data-testid="nav-company" onClick={() => { goto('company'); }}>Company</MenuItem>}

              <MenuItem onClick={() => { goto('account/dashboard'); }}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={() => { goto('/logout'); }}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>

          </Box>
        </Box>
        <Menu
          id="nav-menu"
          anchorEl={anchorNavMenuEl}
          open={Boolean(anchorNavMenuEl)}
          onClose={handleCloseNavMenu}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          disableScrollLock={true}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
          }}
        >
          <MenuItem onClick={() => { goto('account/dashboard'); }}>Dashboard</MenuItem>
          {/* <MenuItem onClick={() => { goto('account/claims'); }}>Claims</MenuItem> */}
          <MenuItem onClick={() => { gotoUrl(`${settings.REACT_APP_WHEN_URL}/learn`) }}>Learn About Insurance</MenuItem>
          <MenuItem onClick={() => { goto('account/insurance-plans'); }}>Find Health Insurance</MenuItem>
          <MenuItem onClick={() => { goto('jamie'); }}>Jamie</MenuItem>
          <MenuItem onClick={() => { goto('account/dashboard'); }}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={() => { goto('/logout'); }}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar >
  );
}
export default Navbar;
