import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

interface MfaEnrolledDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleClick: () => void;
}

const MfaEnrolledDialog: React.FC<MfaEnrolledDialogProps> = ({ isOpen, onClose, handleClick }) => (
  <Dialog open={isOpen} onClose={onClose} >
    <DialogContent sx={{ textAlign: 'center', p: { xs: 2, sm: 4 } }}>
      <Typography sx={{ fontSize: { xs: '1.3rem', sm: '1.8rem' }, mb: { xs: 1, sm: 4 } }} fontWeight={600}>
        Setup MFA To Secure Your Account
      </Typography>
      <Typography sx={{ fontSize: { xs: '1rem', sm: '1.2rem'}, mb: { xs: 1, sm: 4 }  }} fontWeight={200} >
        You are now enrolled in MFA
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        sx={{ m: 1, fontSize: 16 }}
        onClick={handleClick}
      >
        Close
      </Button>
    </DialogContent>
  </Dialog>
);

export default MfaEnrolledDialog;
