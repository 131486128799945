import { Box, Typography, Card, CardContent, Button, Divider, Link, ListItem, List, Grid } from "@mui/material";
import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import { Interfaces } from 'services/apollo';

function ViewDetailsModal(props: { plan: Interfaces.HealthPlanDetails, close: Function }) {
  const plan = props.plan;
  useEffect(() => {
    mixpanel.track("ViewHealthPlanDetails", {
      plan: {
        name: props.plan.name,
        carrier: props.plan.provider,
        id: props.plan.id,
        type: props.plan.type
      }
    });
  })
  return (
    <Box sx={{

    }}>
      <Card sx={{
        backgroundColor: "white.main",
        p: 4,
      }}>
        <CardContent sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: "33px",
          padding: 0
        }}>

          <Box>
            <Typography variant="h2" fontWeight={600} align="center" color={"primary.main"}>Plan Details</Typography>
            <Typography variant="h2" fontWeight={600} align="center" color={"primary.main"}>{plan.name} from {plan.provider}</Typography>
            <Typography align="center">Plan ID: <Typography component={"span"} fontWeight={600}>{plan.id}</Typography></Typography>
          </Box>
          <Box sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 4
          }}>
            <Box width={"100%"}>
              <Typography variant="h2" align="center">Plan Overview</Typography>
              <List>
                <ListItem>
                  <Typography color={"primary"} fontWeight={600}>Premium</Typography>
                  <Typography px={2}>${plan.premium}</Typography>
                </ListItem>
                <ListItem>
                  <Typography color={"primary"} fontWeight={600}>Deductible</Typography>
                  <Typography px={2}>${plan.deductible}</Typography>
                </ListItem>
                <ListItem>
                  <Typography color={"primary"} fontWeight={600}>Out of Pocket Max</Typography>
                  <Typography px={2}>${plan.maxOutOfPocket}</Typography>
                </ListItem>
                <ListItem>
                  <Typography color={"primary"} fontWeight={600}>Provider Links:</Typography>
                </ListItem>
                {plan.benefitUrl &&
                  <ListItem>
                    <Link target="_blank" href={plan.benefitUrl} color={"primary"} fontWeight={600} px={2}>Plan Benefits</Link>
                  </ListItem>
                }
                {plan.brochureUrl &&
                  <ListItem>
                    <Link target="_blank" href={plan.brochureUrl} color={"primary"} fontWeight={600} px={2}>Plan Brochure</Link>
                  </ListItem>
                }
                {plan.formularyUrl &&
                  <ListItem>
                    <Link target="_blank" href={plan.formularyUrl} color={"primary"} fontWeight={600} px={2}>Covered Precscriptions</Link>
                  </ListItem>
                }
                {plan.networkUrl &&
                  <ListItem>
                    <Link target="_blank" href={plan.networkUrl} color={"primary"} fontWeight={600} px={2}>Plan Network</Link>
                  </ListItem>
                }
              </List>
            </Box>
            <Divider orientation="vertical" flexItem variant="middle" sx={{ backgroundColor: "brightOrange.main" }}></Divider>
            <Box width={"100%"} sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2
            }}>
              <Typography variant="h2" align="center">Benefits</Typography>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={4}>
                  <Typography color={"primary"} fontWeight={600} px={2}>In-Network</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography color={"primary"} fontWeight={600} px={2}>Out-of-Network</Typography>
                </Grid>
              </Grid>
              {plan.benefits.map((benefit) => {
                return (
                  <Grid container spacing={2} key={benefit.name}>
                    <Grid item xs={4}>
                      <Typography color={"primary"} fontWeight={600}>{benefit.name}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>{benefit.inNetwork}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>{benefit.outOfNetwork}</Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Box>
          <Box sx={{ mx: "auto" }}>
            <Button variant="outlined" onClick={() => { props.close() }}>Close</Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}
export default ViewDetailsModal;