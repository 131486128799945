
export enum KEYS{
    space="32",
    enter="13"
}
export function keyboardToClick(event: React.KeyboardEvent<any>) {
    if (event.key === KEYS.space || event.key === KEYS.enter) {
        event.preventDefault();
        event.currentTarget.click();
    }
}