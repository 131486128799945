/** @jsxImportSource @emotion/react */
import { useMutation, useLazyQuery } from "@apollo/client";
import { Typography, Box, Snackbar, Button, Container, Dialog, Modal, Paper, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CreateAccount from "components/User/CreateAccount";
import { useFirebase } from "contexts/Firebase";
import { useSettings } from "contexts/Settings";
import { useAuth } from "contexts/User";
import { doc } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import ReCAPTCHA from "react-google-recaptcha";
import { useParams, useNavigate } from "react-router";
import { Mutations, Queries } from "services/apollo";



function EmployeeExit() {
  const { settings } = useSettings();
  const { firestore } = useFirebase();
  const { user } = useAuth();
  const { companyId, exitCode } = useParams();
  const [error, setError] = useState<string>("");
  const [helpForm, setHelpForm] = useState<any>({});
  const [infoConfirmed, setInfoConfirmed] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<any>(null);
  const [confirmExitCodeIdentity] = useLazyQuery(Queries.confirmExitCodeIdentity, {
    fetchPolicy: "network-only"
  });
  const [syncDataForExitCode] = useMutation(Mutations.syncDataForExitCode);
  const confirmIdentity = async () => {
    if (!lastName || !dateOfBirth) {
      setError("Please enter your last name and date of birth");
      return;
    }
    if (!companyId || !exitCode) {
      setError("Invalid URL");
      return;
    }
    try {
      const result = await confirmExitCodeIdentity({
        variables: {
          companyId,
          exitCode,
          lastName,
          birthDate: dateOfBirth.format("MM/DD/YYYY"),
          zipCode: "",
          workEmail: "",
          terminationDate: ""
        },
        fetchPolicy: "network-only"
      });
      if (result.error) {
        console.error(result.error);
        setError(result.error.message);
        return;
      }
      if (result.data?.confirmExitCodeIdentity) {
        setInfoConfirmed(true);
      } else {
        setError("Information provided did not match our records");
      }
    } catch (e: any) {
      console.error(e);
      setError(e.message);
    }
  }
  const [syncingData, setSyncingData] = useState<boolean>(false);
  const [dataSynced, setDataSynced] = useState<boolean>(false);
  useEffect(() => {
    if (!companyId || !exitCode) {
      return;
    }
    if (infoConfirmed && !dataSynced && !syncingData && user && user.uid) {
      console.log("Syncing data for", user.uid)
      setSyncingData(true);
      setTimeout(async () => {
        try {
          const result = await syncDataForExitCode({
            variables: {
              companyId,
              exitCode,
              lastName,
              birthDate: dateOfBirth.format("MM/DD/YYYY"),
              zipCode: ""
            }
          });
          if (result.data?.syncDataForExitCode) {
            setSyncingData(false);
            setDataSynced(true);
          } else {
            setError("Error syncing data");
          }
        } catch (e) {
          console.error(e);
          setError("Something went wrong syncing data")
        }
      }, 500);
    }
  }, [infoConfirmed, dataSynced, syncDataForExitCode, companyId, exitCode, syncingData, lastName, dateOfBirth, user]);

  const [company, ,] = useDocument(
    companyId ? doc(firestore, 'companies', companyId) : null,
  )
  const [submitContactUs] = useMutation(Mutations.contactUs);
  const recaptchaRef = React.createRef<any>();
  // const jamieBox = useRef<any>(null)
  const [askForHelpModal, setAskForHelpModal] = useState<boolean>(false);
  const [contactSubmitted, setContactSubmitted] = useState<boolean>(false);
  const navigate = useNavigate();
  async function submitHelp(e: any) {
    e.preventDefault();
    const result = await submitContactUs({
      variables: {
        name: helpForm.name,
        email: helpForm.email,
        phone: helpForm.phone,
        subject: helpForm.subject,
        description: helpForm.description
      }
    })
    if (result.errors) {
      console.error(result.errors);
    }
    setContactSubmitted(true);
    setHelpForm({});
    handleClose();
  }
  function handleClose() {
    setAskForHelpModal(false);
  }
  return (
    <Box>
      <Box sx={{
        backgroundColor: "beige.main",
        py: 4
      }}>
        <Container>
          <Box sx={{
            py: 4
          }}>
            <Typography variant="h1">Welcome to When</Typography>
            <Typography variant="h2">Your former employer{company?.get("name") ? `, ${company?.get("name")},` : ""} has partnered with When to provide you with resources to ease your transition out of the company.</Typography>
          </Box>
        </Container>
        <Container>
          <Box sx={{
            py: 4
          }}>
            <Typography variant="h2">Revolutionizing Health Insurance Simplicity</Typography>
            <Typography>
              When is an approved partner with HealthCare.gov and appointed by multiple health insurance carriers. We&apos;re changing how people find, understand, and purchase their coverage, providing access to ACA and private medical plans in a one-stop, user-friendly platform. We deliver a uniquely personalized, transparent, and seamless experience - and it&apos;s absolutely free!
            </Typography>
          </Box>
        </Container>

      </Box>
      <Box sx={{
        py: 4
      }}>
        {!infoConfirmed && <Container>
          <Typography variant="h2">Let&apos;s confirm your information</Typography>
          <Typography>Before we get started, we&apos;ll need to confirm your identity. Please enter your last name and birth date below.</Typography>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
            py: 2
          }}>
            <TextField label="Last Name" value={lastName} onChange={(e) => { setLastName(e.target.value) }}></TextField>
            <DatePicker label="Date of Birth" value={dateOfBirth} onChange={(e) => { setDateOfBirth(e) }}></DatePicker>
            <Button variant="contained" onClick={confirmIdentity}>Confirm</Button>
          </Box>
        </Container>}
        {infoConfirmed && !user && <Container>
          <Typography variant="h2">Let&apos;s create your account</Typography>
          <CreateAccount></CreateAccount>
        </Container>
        }
        {infoConfirmed && user && syncingData && <Container>
          <Typography variant="h2">Please wait while we synchronize your information</Typography>
          <Typography>We are pulling in the information from your former employer.</Typography>
        </Container>}
        {infoConfirmed && user && dataSynced && <Container>
          <Typography variant="h2">Let&apos;s get started</Typography>
          <Typography>Now that you&apos;ve created your account, let&apos;s get started with your exit process.</Typography>
          <Button onClick={() => {
            navigate("/exit/jamie")
          }}>Let&apos;s Go!</Button>
        </Container>}
      </Box>

      <Modal
        open={askForHelpModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Paper sx={{
          backgroundColor: "white.main",
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: "400px",
          transform: "translate(-50%, -50%)",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Contact Us
          </Typography>
          <TextField label="Name" value={helpForm.name} onChange={(e) => { setHelpForm({ ...helpForm, name: e.target.value }) }}></TextField>
          <TextField label="Email" value={helpForm.email} onChange={(e) => { setHelpForm({ ...helpForm, email: e.target.value }) }}></TextField>
          <TextField label="Phone" value={helpForm.phone} onChange={(e) => { setHelpForm({ ...helpForm, phone: e.target.value }) }}></TextField>
          <TextField label="Subject" value={helpForm.subject} onChange={(e) => { setHelpForm({ ...helpForm, subject: e.target.value }) }}></TextField>
          <TextField rows={3} multiline label="Description" value={helpForm.description} onChange={(e) => { setHelpForm({ ...helpForm, description: e.target.value }) }}></TextField>
          <div className="g-recaptcha" data-sitekey=""></div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={settings.REACT_APP_RECAPTCHA || ""}
          />
          <Button onClick={submitHelp} variant="contained">Submit</Button>
        </Paper>
      </Modal>
      <Dialog open={error !== ""} onClose={() => { setError("") }}>
        <Box sx={{
          p: 4
        }}>
          <Typography color="error">Error: {error}</Typography>
          <Button onClick={() => { setError("") }}>Close</Button>
        </Box>
      </Dialog>
      <Snackbar
        open={contactSubmitted}
        autoHideDuration={6000}
        onClose={() => { setContactSubmitted(false) }}
        message="Thank you for contacting us, someone will reach out soon."
      />
    </Box >
  );
}
export default EmployeeExit;
