import React, { useState } from 'react'
/** @jsxImportSource @emotion/react */
import { Typography, Box, Button, TextField, IconButton, Divider } from "@mui/material";
import { ReimbursementRequest } from 'services/Interfaces';
import { useDownloadURL } from 'react-firebase-hooks/storage';
import { ref as storageRef } from 'firebase/storage';
import { Link } from 'react-router-dom';
import ApproveRequest from './ApproveRequest';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import DenialRequest from './DenialRequest';
import { useFirebase } from 'contexts/Firebase';
import AdditionalInfoRequest from './AdditionalInfoRequest';
import FormattedCurrency from './FormattedCurrency';

function DownloadFile({ file }: { file: { name: string, type: string, path: string } }) {
    const { storage } = useFirebase();
    const [downloadURL, loading, error] = useDownloadURL(storageRef(storage, file.path));
    if (loading) {
        return <Typography variant="body1">{file.name}</Typography>
    }
    if (error) {
        return <Typography variant="body1">Error: {file.name}: {error.message}</Typography>
    }
    if (!downloadURL) return <Typography variant="body1">{file.name}</Typography>
    return (
        <Link to={downloadURL} target="_blank" download>{file.name}</Link>
    );
}
function AdjudicateRequest({ modifyToOID, reimbursementRequest, onClose, firstName }: { modifyToOID: Function, reimbursementRequest: ReimbursementRequest, onClose: Function, firstName?: string }) {
    const [notes, setNotes] = useState(reimbursementRequest?.notes);
    const [openApproveDialog, setOpenApproveDialog] = useState(false)
    const [openDenialDialog, setOpenDenialDialog] = useState(false)
    const [openAdditionalInfoDialog, setOpenAdditionalInfoDialog] = useState<Boolean>(false);
    console.log("reimbursementRequest", reimbursementRequest);

    if (!reimbursementRequest) {
        return (
            <></>
        )
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
            }}>
                <Typography variant="h2" sx={{ pb: 1 }}>Reimbursement Request Details<IconButton onClick={() => onClose()} sx={{ color: 'primary' }} >
                    <CloseIcon />
                </IconButton></Typography>

                <Divider sx={{ backgroundColor: "brightOrange.main" }}></Divider>

            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
            }}>
                <Typography variant="h4">User Name:</Typography>
                <Typography variant="body1" sx={{ padding: "1px" }}>{reimbursementRequest?.name}</Typography>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
            }}>
                <Typography variant="h4">Benefit OID:</Typography>
                <Typography variant="body1" sx={{ padding: "1px" }}>{modifyToOID('WB', reimbursementRequest?.whenBenefitId)}</Typography>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
            }}>
                <Typography variant="h4">Date Submitted:</Typography>
                <Typography variant="body1" sx={{ padding: "1px" }}>{reimbursementRequest?.createdAt.toDate().toLocaleDateString()}</Typography>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
            }}>
                <Typography variant="h4">Status:</Typography>
                <Typography variant="body1" sx={{ padding: "1px" }}>{reimbursementRequest?.status}</Typography>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
            }}>
                <Typography variant="h4">Date Resolved:</Typography>
                <Typography variant="body1" sx={{ padding: "1px" }}></Typography>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
            }}>
                <Typography variant="h4">Amount Requested:</Typography>
                <Typography variant="body1" sx={{ padding: "1px" }}>
                    <FormattedCurrency value={reimbursementRequest?.amount || 0} />
                </Typography>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
            }}>
                <Typography variant="h4">Amount Approved:</Typography>
                <Typography variant="body1" sx={{ padding: "1px" }}>
                    <FormattedCurrency value={reimbursementRequest?.approvedAmount || 0} />
                </Typography>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
            }}>
                <Typography variant="h4">Coverage Period Start Date:</Typography>
                <Typography variant="body1" sx={{ padding: "1px" }}>{moment(reimbursementRequest?.coverageStartDate).format('MM/DD/YYYY')}</Typography>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 2,
            }}>
                <Typography variant="h4">Coverage Period End Date:</Typography>
                <Typography variant="body1" sx={{ padding: "1px" }}>{moment(reimbursementRequest?.coverageEndDate).format('MM/DD/YYYY')}</Typography>
            </Box>
            <Typography variant="h4">Files</Typography>
            {reimbursementRequest?.files.map((file) => {
                return <DownloadFile key={file.name} file={file} />
            })}
            <Typography variant="h4">Notes</Typography>
            <TextField multiline rows={3} value={notes} onChange={(e) => { setNotes(e.target.value) }}></TextField>
            {
                reimbursementRequest?.status === "Pending" || reimbursementRequest?.status === "Additional info needed" ? (
                    <>
                        <Button variant="contained" onClick={() => { setOpenApproveDialog(true) }}>Approve</Button>
                        {/* {
                            reimbursementRequest?.status !== "Additional Information Requested" &&
                            <Button variant="outlined" color='secondary' onClick={moreInfo}>Request More Information</Button>
                        } */}
                        <Button variant="contained" onClick={() => { setOpenAdditionalInfoDialog(true) }}>Additional info needed</Button>
                        <Button variant="contained" color='secondary' onClick={() => {
                            if (reimbursementRequest?.status === "Pending" || reimbursementRequest?.status === "Additional info needed") setOpenDenialDialog(true)
                        }} >Reject</Button>
                        <Button variant="outlined" onClick={() => onClose()}>Cancel</Button>
                    </>
                ) : <Typography align='center' variant="body1" color="error">No Actions are available</Typography>
            }
            {(openApproveDialog && (reimbursementRequest?.status === "Pending" || reimbursementRequest?.status === "Additional info needed"))
                && (<ApproveRequest openApproveDialog={openApproveDialog} setOpenApproveDialog={setOpenApproveDialog} reimbursementRequest={reimbursementRequest} onClose={onClose} />)}
            {openDenialDialog && <DenialRequest openDenialDialog={openDenialDialog} setOpenDenialDialog={setOpenDenialDialog} reimbursementRequest={reimbursementRequest} onClose={onClose} />}
            {openAdditionalInfoDialog && <AdditionalInfoRequest openAdditionalInfoDialog={openAdditionalInfoDialog} reimbursementRequest={reimbursementRequest} setOpenAdditionalInfoDialog={setOpenAdditionalInfoDialog} firstName={firstName} />}
        </Box>
    )
}

export default AdjudicateRequest;