import { Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

const Banner = ({ bodyText, btnText, onSave }: { bodyText: any, btnText: string, onSave: Function }) => {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { sm: "center" },
            justifyContent: "space-between",
            backgroundColor: "white.main",
            borderRadius: "8px",
            p: 1,
            gap: 1,
            borderLeft: theme => `6px solid ${theme.palette.brightOrange.main}`,
        }}
            data-testid="test-draft-application-banner"
        >
            <Typography>{bodyText}</Typography>
            <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button data-testid={`test-${btnText}-btn`} color={"primary"} onClick={() => onSave()} variant="contained" sx={{ paddingX: 3, paddingY: 1, borderRadius: 0, mr: { sm: 4 } }}>{btnText}</Button>
            </Box>
        </Box >
    )
}

export default Banner