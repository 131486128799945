/** @jsxImportSource @emotion/react */

import { Box } from "@mui/system";
import PersonalInformation from "./PersonalInformation";
import { Card, Grid } from "@mui/material";
import MailingAddress from "./MailingAddress";
import ContactInformation from "./ContactInformation";
import DirectDeposit from "./DirectDeposit";
import MultiFactorAuthentication from "./MultiFactorAuthentication";

function MyAccount() {
  return (
    <Box>
      <Box sx={{ p: { xs: 2, sm: 4 } }}>
        <Grid item xs={12} sx={{ px: { xs: 1, sm: 4 } }}>
          <Card sx={{ p: { xs: 2, sm: 4 }, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <PersonalInformation />
            <MailingAddress />
            <ContactInformation />
            <DirectDeposit />
            <MultiFactorAuthentication />
          </Card>
        </Grid>
      </Box>
    </Box>
  );
}

export default MyAccount;
