import { DocumentData, DocumentReference, DocumentSnapshot, FirestoreError, doc } from "@firebase/firestore";
import { UserContext } from ".";
import { useCollection } from "react-firebase-hooks/firestore";
import { ReimbursementRequest, UserData, WhenBenefit } from "services/Interfaces";
import { WhenBenefitConverter, WhenReimbursementRequestConverter } from "services/Firestore/User";
import { QuerySnapshot, collection, collectionGroup, orderBy, query, where } from "firebase/firestore";
import { useContext, useEffect } from "react";
import { useFirebase } from "contexts/Firebase";


export function useUserData(): {
    userDoc?: DocumentReference<DocumentData>,
    answersDoc?: DocumentReference<DocumentData>,
    whenBenefits?: QuerySnapshot<WhenBenefit> | undefined,
    reimbursementRequests?: QuerySnapshot<ReimbursementRequest> | undefined,
    answers: DocumentSnapshot<DocumentData> | undefined,
    answersLoading: boolean,
    answersError: FirestoreError | undefined,
    userData: DocumentSnapshot<UserData> | undefined,
    userLoading: boolean,
    userError: FirestoreError | undefined,
    loaded: boolean
} {
    const { firestore } = useFirebase();
    const { user, userData, userDataLoading, userDataError, answers, answersLoading, answersError, loaded } = useContext(UserContext);
    const userDoc = user ? doc(firestore, 'users', user?.uid) : undefined;
    const answersDoc = user ? doc(firestore, 'users', user?.uid, 'answers', user?.uid) : undefined;
    const [whenBenefits] = useCollection<WhenBenefit>(
        user ? query(collection(firestore, 'users', user?.uid, 'whenBenefits'),
            orderBy('createdAt', 'desc'))
            .withConverter(WhenBenefitConverter) :
            undefined,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [reimbursementRequests] = useCollection<ReimbursementRequest>(
        user ? query(
            collectionGroup(firestore, 'reimbursementRequests'),
            where('userId', '==', user?.uid),
            orderBy('createdAt', 'desc')
        ).withConverter(WhenReimbursementRequestConverter) :
            undefined,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    useEffect(() => {
        console.log("UserContext Data: ", {
            loaded,
            userDoc,
            answersDoc,
            answers,
            answersLoading,
            answersError,
            userData,
            userLoading: userDataLoading,
            userError: userDataError,
            whenBenefits,
            reimbursementRequests
        })
    })
    return {
        loaded,
        userDoc,
        answersDoc,
        answers,
        answersLoading,
        answersError,
        userData,
        userLoading: userDataLoading,
        userError: userDataError,
        whenBenefits,
        reimbursementRequests
    }
}