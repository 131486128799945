/* eslint-disable @typescript-eslint/no-unused-vars */
/** @jsxImportSource @emotion/react */
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery, Queries, Interfaces } from "services/apollo";
import useScript from 'react-script-hook';
import Case from "pages/Admin/Case";
import { Box } from "@mui/system";

declare global {
  var ZAFClient: any;
}

function ZendeskTicket() {
  const [zendeskLoading, zendeskError] = useScript({ src: 'https://static.zdassets.com/zendesk_app_framework_sdk/2.0/zaf_sdk.min.js' });
  const [ticketDetails, setTicketDetails] = useState<any>(null);
  const [userData, setUserData] = useState<any>(null);
  const [email,] = useState("");
  const [sessionExpired, setSessionExpired] = useState(false);

  const userResults = useQuery<{ listUsers: Interfaces.UserSearchResults }, Interfaces.UserSearchVariables>(Queries.listUsers, {
    nextFetchPolicy: "network-only",
  });

  useEffect(() => {
    if (!zendeskLoading && !zendeskError && !ticketDetails) {
      const initClient = async () => {
        try {
          const client = await ZAFClient.init();
          client.on('app.registered', async () => {
            // Set iframe size
            if (typeof client.invoke === 'function') {
              client.invoke('resize', { width: '100%', height: '700px' });
            } else {
              console.error("client.invoke is not a function");
            }

            try {
              const ticketData = await client.get('ticket');
              setTicketDetails(ticketData.ticket);

              // Look up the user in Zendesk
              try {
                const data = await client.request(`/api/v2/users/${ticketData.ticket.requester.id}.json`);
                setUserData(data.user);
              } catch (error: any) {
                if (error instanceof Error && 'status' in error) {
                  const statusError = error as { status: number };
                  if (statusError.status === 401 || statusError.status === 403) {
                    setSessionExpired(true);
                  }
                } else {
                  console.error("Unexpected error:", error);
                }
              }
            } catch (error: any) {
              if (error instanceof Error && 'status' in error) {
                const statusError = error as { status: number };
                if (statusError.status === 401 || statusError.status === 403) {
                  setSessionExpired(true);
                }
              } else {
                console.error("Unexpected error:", error);
              }
            }
          });
        } catch (error) {
          console.error("Failed to initialize ZAFClient:", error);
        }
      };

      initClient();
    }
  }, [zendeskLoading, zendeskError, ticketDetails]);

  useEffect(() => {
    if (window.zE) {
      window.zE('messenger', 'hide');
    }
  });

  useEffect(() => {
    console.log("userData", userData);
    console.log("ticketDetails", ticketDetails);
  }, [userData, ticketDetails]);

  if (sessionExpired) {
    return (
      <Box sx={{ textAlign: "center", pt: "20px" }}>
        <Typography variant="h4" color="error">Session Expired</Typography>
        <Typography variant="body1">Please log in again to continue.</Typography>
        <button onClick={() => window.location.reload()}>Login</button>
      </Box>
    );
  }

  if (userData?.user_fields?.case_id) {
    return (
      <Case caseIdIn={userData?.user_fields?.case_id} companyIdIn={userData?.user_fields?.company_id}></Case>
    );
  }

  return (
    <Box sx={{
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      pt: "20px",
    }}>
      {zendeskError?.message}
      <Typography variant="h3">When Case Details</Typography>
      <Typography>Ticket Requester: {ticketDetails?.requester?.name}</Typography>
      <Typography>Case Id: {userData?.user_fields?.case_id}</Typography>
    </Box>
  );
}

export default ZendeskTicket;
