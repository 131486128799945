import { Lock, VerifiedUser } from "@mui/icons-material";
import { Box, Typography, Card, CardContent, CardActions, Button, Tooltip, Dialog, useMediaQuery, useTheme } from "@mui/material";
import { FormattedNumber } from 'react-intl'
import { Interfaces } from 'services/apollo';
import SelectPlanModal from "./SelectPlanModal";
import { useContext, useState } from "react";
import ViewDetailsModal from "./ViewDetailsModal";
import { useAuth } from "contexts/User";
import { ModalContext } from "contexts/Modal";

function PlanSummaryCard(props: { plan: Interfaces.HealthPlanDetails, title?: string, variant?: string }) {
  let metalColor = "primary";
  const { modalDispatch } = useContext(ModalContext);
  const { user } = useAuth();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [selectPlanOpen, setSelectPlanOpen] = useState<boolean>(false);
  const [viewDetailsOpen, setViewDetailsOpen] = useState<boolean>(false);
  switch (props.plan.metal.toLowerCase()) {
    case "bronze":
      metalColor = "#CD7F32";
      break;
    case "silver":
      metalColor = "#C0C0C0";
      break;
    case "gold":
      metalColor = "#FFD700";
      break;
    case "platinum":
      metalColor = "#E5E4E2";
      break;
  }
  console.log("metal", props.plan.metal, metalColor)
  return (
    <Box sx={{
      height: "100%"
    }}>
      <Card sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        border: 0,
        backgroundColor: props.variant === "primary" ? "primary.main" : "white.main",
        width: props.variant === "primary" ? "309px" : "299px",
        py: props.variant === "primary" ? "50px" : "30px",
        px: props.variant === "primary" ? "32px" : "27px",
        minWidth: "240px"
      }}>
        <CardContent sx={{
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          gap: "33px",
          padding: 0
        }}>

          <Box>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              alignItems: "center",
              pb: 1
            }}>
              <Typography sx={{
                backgroundColor: "brightOrange.main",
                borderRadius: "16px",
                padding: "2px 7px"
              }} color="#0F0F0F" fontSize={11} fontWeight={600}>{props.plan.type}</Typography>
              <Tooltip title={`${props.plan.metal} - Qualified Health Plan`}>
                <VerifiedUser style={{ color: metalColor }} />
              </Tooltip>
            </Box>
            <Typography variant="h2" fontWeight={600} color={props.variant === "primary" ? "white.main" : "primary.main"}>{props?.title}</Typography>
          </Box>
          <Box>
            <Typography color={props.variant === "primary" ? "white.main" : ""} fontSize={13}>Monthly Premium</Typography>
            <Typography variant="body1-bold" fontSize={42} color={props.variant === "primary" ? "brightOrange.main" : "primary"}><FormattedNumber value={props.plan.premium} style={`currency`} currency="USD" /></Typography>
          </Box>
          <Box>
            <Typography color={props.variant === "primary" ? "white.main" : ""} fontSize={13}>Provider</Typography>
            <Typography variant="body1-bold" fontSize={"24px"} lineHeight={"30px"} letterSpacing={"-1%"} color={props.variant === "primary" ? "brightOrange.main" : "primary"}>{props.plan.provider}</Typography>
          </Box>
          <Box>
            <Typography color={props.variant === "primary" ? "white.main" : ""} fontSize={13}>Plan Name</Typography>
            <Typography variant="body1-bold" fontSize={24} color={props.variant === "primary" ? "brightOrange.main" : "primary"}>{props.plan.name}</Typography>
          </Box>
          <Box>
            <Typography color={props.variant === "primary" ? "white.main" : ""} fontSize={13}>Deductible</Typography>
            <Typography variant="body1-bold" fontSize={24} color={props.variant === "primary" ? "brightOrange.main" : "primary"}><FormattedNumber value={props.plan.deductible} style={`currency`} currency="USD" /></Typography>
          </Box>
          <Box>
            <Typography textAlign="left" color={props.variant === "primary" ? "white.main" : ""} fontSize={13}>Max Out of Pocket</Typography>
            <Typography variant="body1-bold" fontSize={24} color={props.variant === "primary" ? "brightOrange.main" : "primary"}><FormattedNumber value={props.plan.maxOutOfPocket} style={`currency`} currency="USD" /></Typography>
          </Box>
        </CardContent>
        <CardActions disableSpacing sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          flexBasis: 0,
          pt: 4,
          mt: "auto"
        }}>
          <Button color="secondary" variant="contained" fullWidth onClick={() => {
            if (user?.isAnonymous) {
              modalDispatch({ type: "createAccount" });
            } else {
              setSelectPlanOpen(true)
            }
          }}
          >
            Select This Plan {user?.isAnonymous && <Lock sx={{ height: "16px", mt: "-4px" }} />}
          </Button>
          <Button sx={{ color: props.variant === "primary" ? "white.main" : "primary" }} variant="text" onClick={() => { setViewDetailsOpen(true) }}>View Additional Plan Details</Button>
        </CardActions>
      </Card>
      <Dialog
        open={selectPlanOpen}
        onClose={() => { setSelectPlanOpen(false) }}
        onBackdropClick={() => { setSelectPlanOpen(false) }}
        fullScreen={isSm ? false : true}
        fullWidth
        maxWidth="lg"
        sx={{
        }}
      >
        <SelectPlanModal plan={props.plan} close={() => { setSelectPlanOpen(false) }}></SelectPlanModal>
      </Dialog>
      <Dialog
        open={viewDetailsOpen}
        onClose={() => { setViewDetailsOpen(false) }}
        onBackdropClick={() => { setViewDetailsOpen(false) }}
        fullScreen={isSm ? false : true}
        fullWidth
        maxWidth="lg"
      >
        <ViewDetailsModal plan={props.plan} close={() => { setViewDetailsOpen(false) }}></ViewDetailsModal>
      </Dialog>
    </Box >
  );
}
export default PlanSummaryCard