/** @jsxImportSource @emotion/react */
import { Box, Button, Typography, Card, CardContent, Link } from "@mui/material";
import { useSettings } from "contexts/Settings";
import React from "react";
// import { InlineWidget } from "react-calendly";

function ScheduleWithAgentModal(props: { title?: string, close: Function }) {

  const { settings } = useSettings();
  return (
    <Box sx={{
      maxWidth: "400px",
    }}>
      <Card sx={{
        backgroundColor: "white.main",
        height: { xs: "100vh", sm: "auto" },
        p: 4,
      }}>
        <CardContent sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          padding: 0,
          gap: 3
        }}>
          <Typography variant="h2" fontWeight={600} align="center" color={"primary.main"}>Speak with a When Licensed Insurance Agent</Typography>
          <Typography>We have a team of agents ready to help you find a plan, enroll, and answer any health insurance related questions you may have.</Typography>
          <Link href={"tel:" + settings.REACT_APP_CONCIERGE_PHONE}>Call: {settings.REACT_APP_CONCIERGE_PHONE}</Link>
          <Box sx={{ mx: "auto" }}>
            <Button variant="outlined" onClick={() => { props.close() }}>Close</Button>
          </Box>
          {/* <Box>
            <Typography variant="h2" fontWeight={600} align="center" color={"primary.main"}>{props.title ? props.title : "Schedule a call with an Agent"}</Typography>
          </Box>
          <Box sx={{
            marginTop: "-40px",
            marginBottom: "20px"
          }}>
            <InlineWidget url="https://calendly.com/when-insurance/health-insurance" />
          </Box>
          <Box sx={{ mx: "auto" }}>
            <Button variant="outlined" onClick={() => { props.close() }}>Close</Button>
          </Box> */}
        </CardContent>
      </Card>
    </Box>
  );
}
export default ScheduleWithAgentModal;
