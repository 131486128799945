/** @jsxImportSource @emotion/react */
import { Box, Typography, Tabs, Tab, Collapse, Switch, IconButton, Button } from "@mui/material";
import { useAuth } from "../../contexts/User";
import { SetStateAction, useEffect, useState } from "react";
import moment from "moment";
import { ArrowDownward, ArrowUpward, Delete } from "@mui/icons-material";
import { useRemoteConfig } from "contexts/RemoteConfig";
import { Container } from "@mui/system";


// TODO disallow deploying this to production
function Debug() {
  const { user, userClaims } = useAuth();
  const [value, setValue] = useState("features");
  const [token, setToken] = useState("token");
  const [expanded, setExpanded] = useState<boolean>(false);
  const { overrides, defaults, setOverride, deleteOverride } = useRemoteConfig("overrides");
  console.log("Debug", overrides)

  const handleChange = (_event: any, newValue: SetStateAction<string>) => {
    setValue(newValue);
  };
  useEffect(() => {
    user?.getIdToken().then(token => {
      setToken(token);
    })
  }, [token, user]);
  return (
    <Box sx={{
    }}>
      <Box sx={{
        background: "red",
        color: "white.main",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: 2,
        alignItems: "center",
        padding: 1,
        cursor: "pointer"

      }}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded ? <ArrowUpward /> : <ArrowDownward />}<Typography color="white.main">Debug Tools</Typography>{expanded ? <ArrowUpward /> : <ArrowDownward />}
      </Box>
      <Collapse in={expanded}>
        <Container maxWidth="xl">
          <Box sx={{ borderBottom: 1, borderColor: 'divider', minHeight: 500 }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="User" value="user" />
              <Tab label="Features" value="features" />

            </Tabs>
            <Box sx={{
              display: value === "user" ? "block" : "none"
            }}>
              <Box>
                Actions:
              </Box>
              <Box>
                <Typography>Email: {user?.email}</Typography>
                <Typography>Uid: {user?.uid}</Typography>
                <Typography>Is Anonymous: {user?.isAnonymous ? "true" : "false"}</Typography>
                <Typography>lastLoginAt: {moment(parseInt((user?.toJSON() as any)?.lastLoginAt)).format("YYYY-MM-DD HH:mm:ss")} </Typography>
                <Typography>expiration: {moment((user?.toJSON() as any)?.stsTokenManager?.expirationTime).format("YYYY-MM-DD HH:mm:ss")} </Typography>
                <Typography>Token: </Typography><Typography variant="caption">{token}</Typography>
                <Typography>Claims: </Typography><Typography variant="caption">{JSON.stringify(userClaims)}</Typography>
              </Box>
            </Box>
            <Box sx={{
              display: value === "features" ? "flex" : "none",
              flexDirection: "row",
              justifyContent: "space-between"
            }}>
              <Box sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                maxWidth: 500
              }}>
                <Typography variant="h2">
                  Overrides:
                </Typography>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  maxWidth: 500
                }}>
                  {overrides &&
                    Object.keys(overrides).map(key => {
                      return (
                        <Box key={key} sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 1,
                          alignItems: "center",
                        }}>

                          <Typography sx={{
                            flexGrow: 1
                          }}>
                            {key}
                          </Typography>
                          <Typography>
                            Value: {overrides[key].asString()}
                          </Typography>
                          <Switch
                            checked={overrides[key].asBoolean()}
                            onChange={(e) => {
                              setOverride(key, (!overrides[key].asBoolean()).toString());
                            }}
                          />
                          <IconButton onClick={() => {
                            deleteOverride(key);
                          }}>
                            <Delete />
                          </IconButton>
                        </Box>
                      );
                    })
                  }
                </Box>
              </Box>
              <Box sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                gap: 1,
                maxWidth: 500
              }}>
                <Typography variant="h2">Defaults:</Typography>
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  maxWidth: 500
                }}>
                  {
                    defaults &&
                    Object.keys(defaults).map(key => {
                      return (
                        <Box key={
                          key
                        } sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 1,
                          alignItems: "center",
                        }}>
                          <Typography sx={{
                            flexGrow: 1
                          }}>
                            {key}
                          </Typography>
                          <Typography>
                            Default:
                            {defaults[key] === true && "true"}
                            {defaults[key] === false && "false"}
                            {typeof defaults[key] !== "boolean" && defaults[key]}
                          </Typography>
                          <Button onClick={() => {
                            setOverride(key, defaults[key].toString());
                          }}
                            disabled={Object.hasOwn(overrides, key)}
                          >
                            Override
                          </Button>
                        </Box>
                      );
                    })
                  }
                </Box>
              </Box>
            </Box>
          </Box>

        </Container>

      </Collapse >

    </Box >
  );
}
export default Debug;
