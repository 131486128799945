export const reducer = (state: any, action: any) => {
  console.log("modal context", action.type);
  switch (action.type) {
    case "navigation":
      return {
        ...state,
        navigation: !state.navigation
      }
    case "login":
      return {
        ...state,
        login: !state.login
      }
    case "createAccount":
      return {
        ...state,
        createAccount: !state.createAccount
      }
    case "newVersion":
      return {
        ...state,
        newVersion: !state.newVersion
      }
    default:
      return state
  }
}

export const initialState = {
  navigation: false,
  login: false,
  newVersion: false,
  createAccount: false
}