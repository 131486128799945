import { useLazyQuery, useMutation } from "@apollo/client";
import { Check } from "@mui/icons-material";
import { Backdrop, Box, Button, Card, CircularProgress, Dialog, Paper, Rating, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DataGrid, GridRenderCellParams } from "@mui/x-data-grid";
import HealthInsuranceDecisionDialog from "components/Admin/HealthInsuranceDecisionDialog";
import Household, { CoveredPerson } from "components/HealthPlan/Household";
import InsuranceDetails from "components/HealthPlan/InsuranceDetails";
import PreferencesModal from "components/HealthPlan/PreferencesModal";
import SelectPlanModalQuotit from "components/HealthPlan/SelectPlanModalQuotit";
import ViewDetailsModalQuotit from "components/HealthPlan/ViewDetailsModalQuotit";
import { useFirebase } from "contexts/Firebase";
import { useNotifications } from "contexts/Notification";
import { useRemoteConfig } from "contexts/RemoteConfig";
import { collection, doc, query, QueryDocumentSnapshot } from "firebase/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { FormattedNumber } from "react-intl";
import { Mutations, Queries } from "services/apollo";
import { QuotitPlanConverter } from "services/Firestore/Plan";
import { Case, HealthInsuranceDecisionType, QuotitHealthPlanDetails } from "services/Interfaces";
// import { UserDataConverter } from "services/Firestore/User";
// import { User } from "services/Interfaces";

function InsuranceAdmin({ userId, theCase }: { userId: string, theCase: Case }) {
    const { firestore } = useFirebase();
    const theme = useTheme();
    const { notifications } = useNotifications();
    const isSm = useMediaQuery(theme.breakpoints.up('sm'));
    const ideon = useRemoteConfig("ideon").asBoolean();

    const [updateHousehold, setUpdateHousehold] = useState(false);
    const [userAnswers, ,] = useDocument<any>(doc(firestore, `users/${userId}/answers/${userId}`));
    const [planDetailsOpen, setPlanDetailsOpen] = useState<boolean>(false);
    const [insuranceDecisionOpen, setInsuranceDecisionOpen] = useState<boolean>(false);
    const [selectPlanOpen, setSelectPlanOpen] = useState<boolean>(false);
    const [plan, setPlan] = useState<any>();
    const [, setPlans] = useState<Array<QuotitHealthPlanDetails>>([]);
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
    const [openPreferences, setOpenPreferences] = useState<boolean>(false);

    const [filteredPlans, setFilteredPlans] = useState<Array<QuotitHealthPlanDetails>>([]);
    const [tabValue, setTabValue] = useState<any>(0);
    const [setHousehold] = useMutation(Mutations.setHousehold, {
    })
    const [setInsuranceDetails] = useMutation(Mutations.setInsuranceDetails, {
    })

    const [setPreferences] = useMutation(Mutations.setPreferences, {
    })


    const [loadRates, rateQuery] = useLazyQuery<{ total: number }, { effectiveDate?: string, uid: string, source?: string }>(Queries.getRates, {
        notifyOnNetworkStatusChange: true,
    });

    const [loadSubsidy] = useLazyQuery<{ federalSubsidyAmount: number }, { uid: string }>(Queries.subsidyCheck, {
        notifyOnNetworkStatusChange: true,
    });


    const filingStatuses = [
        { value: "Single", text: "Single" },
        { value: "HeadOfHousehold", text: "Head of Household" },
        { value: "QualifyingWidower", text: "Qualifying Widower" },
        { value: "MarriedFilingJointly", text: "Married filing jointly" },
        { value: "MarriedFilingSeparately", text: "Married filing separately" },
    ];

    const saveHousehold = async (members: { [key: string]: CoveredPerson }) => {
        // call admin graphql to save
        // convert members to array
        const household = Object.entries(members).map(([key, value]) => {
            return {
                ...value as any
            }
        });
        const result = await setHousehold({
            variables: {
                uid: userId,
                members: household
            }
        })
        if (result.data?.setHousehold === true) {
            notifications.success("Household saved")
            setUpdateHousehold(false);
        } else {
            console.error("error saving household", result.errors);
            notifications.error("Error saving household");
        }
    }
    const saveInsuranceDetails = async (details: any) => {
        // call admin graphql to save
        const result = await setInsuranceDetails({
            variables: {
                uid: userId,
                details: {
                    zipcode: details.zipcode,
                    county: {
                        name: details.county.name,
                        fips: details.county.fips,
                        state: details.county.state,
                        zipcode: details.county.zipcode
                    },
                    income: details.income,
                    type: details.type,
                    effectiveDate: details.effectiveDate,
                    isNativeAmarican: details.isNativeAmarican,
                    taxFilingStatus: details.taxFilingStatus,
                }
            }
        })
        if (result.data?.setInsuranceDetails === true) {
            notifications.success("Insurance details saved")
            setDetailsOpen(false);
            loadSubsidy({
                variables: {
                    uid: userId,
                },
                fetchPolicy: "network-only"
            });
        } else {
            console.error("error saving insurance details", result.errors);
            notifications.error("Error saving insurance details");
        }
    }
    const savePreferences = async (preferences: any) => {
        // call admin graphql to save
        const result = await setPreferences({
            variables: {
                uid: userId,
                preferences: {
                    hmo: preferences.planType.hmo,
                    ppo: preferences.planType.ppo,
                    shortterm: preferences.planType.shortterm,
                    lowDeductible: preferences.lowDeductible,
                    lowPremium: preferences.lowPremium,
                    comprehensiveCoverage: preferences.comprehensiveCoverage,
                    networkSize: preferences.networkSize,
                    mentalHealth: preferences.mentalHealth,
                    dental: preferences.dental,
                    vision: preferences.vision
                }
            }
        })
        if (result.data?.setPreferences === true) {
            notifications.success("Preference updated")
            setOpenPreferences(false);
        } else {
            console.error("error saving preference", result.errors);
            notifications.error("Error saving preference");
        }
    }
    const [allPlans, plansLoading,] = useCollection<QuotitHealthPlanDetails>(
        query<QuotitHealthPlanDetails>(
            collection(firestore, `users/${userId}/effectiveDate/${userAnswers?.get("insuranceDetails.effectiveDate") || moment().add(1, 'month').startOf('month').format("YYYY-MM-DD")}/plans`).withConverter(QuotitPlanConverter),
            // where("contractStatus", "==", "Contracted"),
            // limit(3)
        )
        , {
            snapshotListenOptions: { includeMetadataChanges: true },
        });
    useEffect(() => {
        if (allPlans && allPlans.docs.length > 0) {
            const planList = allPlans.docs.map(plan => plan.data());
            //foreach plan check disclaimers
            // planList.forEach((plan: QuotitHealthPlanDetails) => {
            //   if (plan.disclaimer) {
            //     addDisclaimer(plan.carrierId, plan.disclaimer);
            //   }
            // });
            //loop over allPlans and remove any plan that has an id that is another id with _1 at the end
            let plans = planList;
            setPlans(plans)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPlans])
    const [filters, setFilters] = useState<any>({
        metal_level: '',
        type: '',
        insuranceType: "HealthOnExchange"
    });
    const columns = [
        { field: 'planType', headerName: 'Plan Type', width: 90, sortable: true },
        {
            field: 'rate', headerName: "Premium", width: 140, sortable: true, renderCell: (params: GridRenderCellParams<any>) => {
                return <FormattedNumber value={params.row.rate} style={`currency`} currency="USD" />
            },
        },
        {
            field: 'provider', valueGetter: (params: any) => {
                return params.row.carrier?.name
            }, headerName: 'Provider', minWidth: 150, flex: 0.5, sortable: true
        },
        {
            field: 'name', headerName: 'Plan Name', minWidth: 200, flex: 1, sortable: false, renderCell: (params: GridRenderCellParams<any>) => {
                return <Box sx={{
                    overflow: 'hidden',
                }}><Button variant="text" color="primary" aria-haspopup="dialog" onClick={() => {
                    setPlan(params.row);
                    setPlanDetailsOpen(true);
                }}>{params.row?.name}</Button>
                </Box>
            }
        }, {
            field: 'deductible',
            sortComparator: (v1: string, v2: string) => {
                return parseInt(v1.slice(1)) < parseInt(v2.slice(1)) ? -1 : 1;
            },
            valueGetter: (params: any) => {
                const benefit = params.row?.benefits?.filter((ben: any) => ben.enum === "ACA1MedicalDeductible" || ben.enum === "ACA1MedicalDrugDeductible" || ben.enum === "AnnualDeductible");
                return benefit[0].inNetwork
            },
            headerName: 'Deductible',
            width: 100,
            sortable: true
        },
        {
            field: 'maxOutOfPocket', sortComparator: (v1: string, v2: string) => {
                return parseInt(v1.slice(1)) < parseInt(v2.slice(1)) ? -1 : 1;
            },
            valueGetter: (params: any) => params.row?.benefits?.filter((ben: any) => ben.enum === "ACA1EHBMedicalOOPMax" || ben.enum === "ACA1EHBDrugOOPMax" || ben.enum === "MaximumAnnualCopay")[0]?.inNetwork || "Unknown",
            headerName: 'Max Out of Pocket',
            width: 150,
            sortable: true
        },
        { field: 'score', valueGetter: (params: any) => params.row.recommendation?.internalScore, headerName: 'Score', width: 100, sortable: true },
        {
            field: 'Plan Rating', valueGetter: (params: any) => params.row.rating?.overall, headerName: 'Star Rating', width: 100, sortable: true, sortComparator: (v1: string, v2: string) => {
                if (v1 === "Not Rated") v1 = "0";
                if (v2 === "Not Rated") v2 = "0";
                return parseInt(v1) <= parseInt(v2) ? -1 : 1;
            },
            renderCell: (params: GridRenderCellParams<any>) => {
                return (<Rating size="small" value={parseInt(params.value)} readOnly />)
            }
        },
        {
            field: 'id', headerName: 'Select', width: 150, sortable: false, renderCell: (params: GridRenderCellParams<any>) => {
                return <>
                    <Button variant="text" color="primary" aria-haspopup="dialog" onClick={() => {
                        setPlan(params.row);
                        setPlanDetailsOpen(true);
                    }}>details</Button>
                    <Button variant="contained" color="secondary" aria-haspopup="dialog" onClick={() => {
                        setPlan(params.row);
                        setSelectPlanOpen(true);
                    }}>Select</Button>
                </>
            }
        },
    ];

    useEffect(() => {
        if (allPlans) {
            const filteredPlans = allPlans.docs.filter((plan: QueryDocumentSnapshot<QuotitHealthPlanDetails>) => {
                if (filters.metal_level && filters.metal_level !== plan.get("benefitLevel")) {
                    return false;
                }
                if (filters.carrier && filters.carrier !== plan.get("carrierId")) {
                    return false;
                }
                if (filters.type && filters.type !== plan.get("planType")) {
                    return false;
                }
                if (filters.insuranceType && filters.insuranceType !== plan.get("insuranceType")) {
                    return false;
                }
                return true;
            });
            console.log("process plan filter", allPlans.docs.length, filters, filteredPlans.length)

            setFilteredPlans(filteredPlans.map((plan: QueryDocumentSnapshot<QuotitHealthPlanDetails>) => plan.data()));
        }
    }, [filters, allPlans])
    // const [user, ,] = useDocument<User>(doc(firestore, `users/${userId}`).withConverter(UserDataConverter));
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4

        }}>
            <Card sx={{
                p: 4
            }}>
                <Box>
                    <Typography variant="h4">Cobra Details</Typography>
                    <Typography>Estimate ${userAnswers?.data().cobra.estimate}</Typography>
                </Box>
            </Card>
            <Card sx={{
                p: 4
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center"
                }}>
                    <Typography variant="h4">Health Insurance Decision</Typography>
                    <Button variant="outlined" onClick={() => { setInsuranceDecisionOpen(true) }}>Update Details</Button>
                </Box>
                <Box>
                    <Typography>Health Insurance Decision: {theCase?.healthInsuranceDecision || ""}</Typography>
                    {
                        theCase?.healthInsuranceDecision === HealthInsuranceDecisionType.ELECTED_COBRA &&
                        <Typography>COBRA Election Reason: {theCase?.cobraElectionReason}</Typography>
                    }
                </Box >
            </Card >
            <Card sx={{
                p: 4
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center"
                }}>
                    <Typography variant="h4">Insurance Details</Typography>
                    <Button variant="outlined" onClick={() => { setDetailsOpen(true) }}>Update Details</Button>
                    <Button variant="outlined" onClick={() => { setOpenPreferences(true) }}>Update Preferences</Button>
                </Box>
                {
                    userAnswers?.data().insuranceDetails ? (
                        <Box>
                            <Typography>Effective Date: {userAnswers?.data().insuranceDetails.effectiveDate || "Not set"}</Typography>
                            <Typography>Income: {userAnswers?.data().insuranceDetails.income}</Typography>
                            <Typography>Zipcode: {userAnswers?.data().insuranceDetails.zipcode}</Typography>
                            <Typography>DOB: {userAnswers?.data().personalInfo.dateOfBirth}</Typography>
                            <Typography>Email: {userAnswers?.data().personalInfo.email}</Typography>
                            <Typography>
                                Tax Filing status: {filingStatuses.find((status) => status.value === userAnswers?.data().insuranceDetails.taxFilingStatus)?.text}
                            </Typography>
                            <Typography>Native American Tribe: {userAnswers?.data().insuranceDetails.isNativeAmarican ? "True" : "False"}</Typography>
                            {userAnswers?.data().insuranceDetails.federalSubsidyAmount !== undefined ? (
                                <>
                                    <Typography>
                                        Federal Subsidy Amount:
                                        <FormattedNumber
                                            value={userAnswers?.data().insuranceDetails.federalSubsidyAmount}
                                            style={`currency`}
                                            currency="USD"
                                        />
                                    </Typography>
                                </>
                            ) : null}

                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                                alignItems: "center"
                            }}>
                                <Typography>Household Members</Typography>
                                <Button variant="outlined" onClick={() => { setUpdateHousehold(true) }}>Update Household</Button>
                            </Box>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell align="center">Date of Birth</TableCell>
                                            <TableCell align="center">Gender</TableCell>
                                            <TableCell align="center">Pregnant?</TableCell>
                                            <TableCell align="center">Tobacco?</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.entries(userAnswers?.data().insuranceDetails.household?.members || {}).map(([key, member]: any, index) => (
                                            <TableRow
                                                key={key}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {member.name}
                                                </TableCell>
                                                <TableCell align="center">{member.dob}</TableCell>
                                                <TableCell align="center">{member.gender}</TableCell>
                                                <TableCell align="center">{member.pregnant === true && <Check />}</TableCell>
                                                <TableCell align="center">{member.tobacco === true && <Check />}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box >
                    ) : (
                        <Typography>No insurance details found</Typography>
                    )
                }
            </Card >
            <Card sx={{
                p: 4
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center"
                }}>
                    <Typography variant="h4">Insurance Plans</Typography>
                    <Button variant="contained" onClick={() => {
                        loadRates({
                            variables: {
                                uid: userId,
                                source: ideon ? "ideon" : "quotit"
                            },
                            fetchPolicy: "network-only"
                        });
                    }}>
                        Update Plans
                    </Button>
                    <Typography variant="caption">Required after changes to household or details.</Typography>
                </Box>
                <Tabs value={tabValue} onChange={(event: React.SyntheticEvent, newValue: number) => {
                    setTabValue(newValue)
                    switch (newValue) {
                        case 0:
                            setFilters({ ...filters, insuranceType: "HealthOnExchange" });
                            break;
                        case 1:
                            setFilters({ ...filters, insuranceType: "HealthOffExchange" });
                            break;
                        case 2:
                            setFilters({ insuranceType: "ShortTerm" });
                            break;
                    }
                }} aria-label="Insurance Type Options">
                    <Tab label="Federal Exchange Plans" value={0} />
                    <Tab label="Private Medical" value={1} />
                    <Tab label="Short Term Medical" value={2} />
                </Tabs>

                <Box sx={{ minHeight: "600px", width: '100%', position: 'relative' }}>
                    <Backdrop open={plansLoading || rateQuery.loading} sx={{ position: "absolute", zIndex: 1000 }}>
                        <CircularProgress color="brightOrange"></CircularProgress>
                    </Backdrop>
                    <DataGrid
                        rows={filteredPlans || []}
                        columns={columns}
                        rowCount={filteredPlans?.length}
                        pageSizeOptions={[10, 25]}
                        disableColumnFilter
                        disableColumnMenu
                        autoHeight
                        paginationMode="client"
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,

                                }
                            },
                            sorting: {
                                sortModel: [{ field: 'score', sort: 'desc' }],
                            },
                        }}

                    />
                </Box>
            </Card>
            <Dialog
                open={updateHousehold}
                onClose={() => { setUpdateHousehold(false) }}
                fullWidth
                maxWidth="lg"
            >
                <Box sx={{
                    p: 4,
                    width: "100%"
                }}>
                    <Household
                        saveOverride={saveHousehold}
                        userId={userId}
                        cancel={() => { setUpdateHousehold(false) }}
                        saved={() => { setUpdateHousehold(false) }} />
                </Box>
            </Dialog>
            <Dialog
                open={selectPlanOpen}
                onClose={() => { setSelectPlanOpen(false) }}
                onBackdropClick={() => { setSelectPlanOpen(false) }}
                fullScreen={isSm ? false : true}
                fullWidth
                maxWidth="lg"
                sx={{
                }}
                aria-labelledby="select-plan-modal-title"
            >

                <Box sx={{
                    p: 4,
                    width: "100%"
                }}>
                    <SelectPlanModalQuotit userId={userId} plan={plan} close={() => { setSelectPlanOpen(false) }}></SelectPlanModalQuotit>
                </Box>
            </Dialog>
            <Dialog
                open={planDetailsOpen}
                onClose={() => { setPlanDetailsOpen(false) }}
                onBackdropClick={() => { setPlanDetailsOpen(false) }}
                fullScreen={isSm ? false : true}
                fullWidth
                maxWidth="lg"
                sx={{
                }}
                aria-labelledby="view-details-modal-title"
            >

                <Box sx={{
                    p: 4,
                    width: "100%"
                }}>
                    <ViewDetailsModalQuotit plan={plan} close={() => { setPlanDetailsOpen(false) }}></ViewDetailsModalQuotit>
                </Box>
            </Dialog>
            <Dialog
                open={openPreferences}
                onClose={() => { setOpenPreferences(false); }}
                fullScreen={isSm ? false : true}
                fullWidth
                maxWidth="lg"
                aria-labelledby="preferences-modal-title"
            >

                <Box sx={{
                    p: 4,
                    width: "100%"
                }}>
                    <PreferencesModal userId={userId} saveOverride={savePreferences} close={() => { setOpenPreferences(false); }}></PreferencesModal>
                </Box>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={detailsOpen}
                onClose={() => { setDetailsOpen(false) }}
            >

                <Box sx={{
                    p: 4,
                    width: "100%"
                }}>
                    <InsuranceDetails userId={userId} saveOverride={saveInsuranceDetails} saved={() => { setDetailsOpen(false) }} cancel={() => { setDetailsOpen(false) }} updateInfo={true}></InsuranceDetails>
                </Box>

            </Dialog>
            <HealthInsuranceDecisionDialog theCase={theCase} open={insuranceDecisionOpen} onClose={() => setInsuranceDecisionOpen(false)} />
        </Box >
    );
}
export default InsuranceAdmin;