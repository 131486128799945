import ETradeBaseTile from "./ETradeBaseTile";

function ETrade401kRolloverTile() {
    return (
      <ETradeBaseTile
        title="E*TRADE can help you understand your choices."
        type="401kRollover"
        imageUrl="/etrade-401k.svg"
        body="When leaving a job, you may still have money in your old workplace retirement plan. You have several options you can consider, including rolling over your retirement plan assets to an E*TRADE Rollover IRA"
        buttonText="Explore options"
        buttonUrl="https://us.etrade.com/knowledge/library/retirement-planning/four-things-you-should-consider-before-rolling-over-your-401k?cid=pa_employercampaign_401krollover_when"
      />
    );
}

export default ETrade401kRolloverTile;