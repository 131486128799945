import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import { useServiceMarketplaceActivity } from "hooks/useServiceMarketplaceActivity";

interface ETradeBaseTileProps {
  title: string;
  imageUrl: string;
  body: string;
  buttonText: string;
  buttonUrl: string;
  type: "401kRollover" | "FinancialPlanning";
}

function ETradeBaseTile({
  title,
  imageUrl,
  body,
  buttonText,
  buttonUrl,
  type,
}: ETradeBaseTileProps) {
  const { addServiceMarketplaceActivity } = useServiceMarketplaceActivity();

  return (
    <Grid item xs={12}>
      <Card
        sx={{
          width: "100%",
          backgroundColor: "white",
          p: 0,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          overflow: "hidden",
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <Box
          sx={{
            flex: { xs: "1 1 auto", md: "0 0 40%" },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 2,
            minHeight: { xs: "200px", md: "300px" },
          }}
        >
          <Box
            component="img"
            src={imageUrl}
            alt="E*TRADE Promotion"
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              maxHeight: { xs: "200px", md: "300px" },
            }}
          />
        </Box>
        <Box
          sx={{
            flex: { xs: "1 1 auto", md: "1 1 60%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: 4,
          }}
        >
          <Box>
            <Typography
              variant="h5"
              color="black"
              fontWeight="bold"
              gutterBottom
            >
              {title}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="body1" color="black">
              {body}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              mt: 2,
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              component="a"
              href={buttonUrl}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                addServiceMarketplaceActivity({
                  serviceId: "etrade",
                  activity: `clickedServiceCTAButton-${type}`,
                  pageUrl: window.location.href,
                  timestamp: new Date(),
                  type: "user",
                });
              }}
              sx={{
                backgroundColor: "white",
                color: "black",
                borderColor: "black",
                borderRadius: 20,
                fontWeight: "bold",
                textTransform: "none",
                padding: "6px 16px",
                "&:hover": {
                  backgroundColor: "white",
                  borderColor: "black",
                },
              }}
            >
              {buttonText}
            </Button>
            <Box
              component="img"
              src="/etrade-logo.svg"
              alt="E*TRADE from Morgan Stanley"
              sx={{ height: "30px" }}
            />
          </Box>
        </Box>
      </Card>
    </Grid>
  );
}

export default ETradeBaseTile;
