import { Box, Button, TextField, Typography, Select, MenuItem, InputLabel, FormControl, IconButton, FormHelperText } from "@mui/material";
import { useState, useEffect } from "react";
import { Queries, useLazyQuery } from "../../services/apollo";
import { doc, updateDoc } from "firebase/firestore";
import { useDocument } from "react-firebase-hooks/firestore";
import { useAuth } from "contexts/User";
import moment from "moment";
import { useFirebase } from "contexts/Firebase";
import CloseIcon from '@mui/icons-material/Close';

function InsuranceDetails(props: { saved: Function, cancel?: Function, saveOverride?: (details: any) => any, userId?: string, updateInfo?: boolean }) {
    const { firestore } = useFirebase();
    const { user } = useAuth();
    const shortTermLengths = [
        { value: 1, text: "1 Month" },
        { value: 2, text: "2 Months" },
        { value: 3, text: "3 Months" },
        { value: 4, text: "4 Months" },
        { value: 5, text: "5 Months" },
        { value: 6, text: "6 Months" },

    ]

    const filingStatuses = [
        { value: "Single", text: "Single" },
        { value: "HeadOfHousehold", text: "Head of Household" },
        { value: "QualifyingWidower", text: "Qualifying Widower" },
        { value: "MarriedFilingJointly", text: "Married filing jointly" },
        { value: "MarriedFilingSeparately", text: "Married filing separately" },
    ];

    const [step, setStep] = useState<string>("overview");
    const [zipcodeHelper, setZipcodeHelper] = useState<string>("");
    const [countyHelper, setCountyHelper] = useState<string>("");

    const userAnswersDoc = user ? doc(firestore, 'users', props.userId || user?.uid!, "answers", props.userId || user?.uid!) : null;
    const [answers, answersLoading, firestoreError] = useDocument(
        userAnswersDoc,
        {
            snapshotListenOptions: { includeMetadataChanges: true },
        }
    );
    const [effectiveDates] = useState<Array<{ value: string, text: string }>>(
        Array.from(Array(4).keys()).map((i) => {
            const date = moment().add(i + 1, "months").startOf("month");
            return { value: date.format("YYYY-MM-DD"), text: date.format("MMMM D") }
        })
    )
    const [insuranceDetails, setInsuranceDetails] = useState<{ county?: any, effectiveDate?: string, shortTermLength?: number, income?: number, type?: string, zipcode?: string, isNativeAmarican?: boolean, taxFilingStatus?: string }>({});
    useEffect(() => {
        if (!answersLoading) {
            const details = answers?.get('insuranceDetails');
            if (details === undefined) {
                console.log("details is undefined")
                // create members
                updateDoc(answers?.ref!, {
                    "insuranceDetails": {}
                });
            } else {
                console.log("set details")
                setInsuranceDetails(details);
            }
            console.log(answers?.data())
        }
    }, [answers, answersLoading])
    useEffect(() => {
        if (firestoreError) {
            console.error(firestoreError);
        }
    })
    const [counties, { data: countiesData }] = useLazyQuery(Queries.GET_GEOGRAPHY, {

    });
    const getCounty = async () => {
        await counties({
            variables: {
                zipcode: insuranceDetails.zipcode
            }
        })
    }
    const [loadSubsidy] = useLazyQuery<{ federalSubsidyAmount: number }, { uid: string }>(Queries.subsidyCheck, {
        notifyOnNetworkStatusChange: true,
    });
    async function nextStep() {
        if (!insuranceDetails.zipcode?.match("(^\\d{5}$)|(^\\d{9}$)|(^\\d{5}-\\d{4}$)")) {
            return setZipcodeHelper("Please enter a valid zip code.");
        }
        await getCounty();
        setStep("county");
    }
    async function save() {
        if (!insuranceDetails.county) {
            return setCountyHelper("Please select a county.");
        }
        if (typeof props.saveOverride === "function") {
            props.saveOverride(insuranceDetails);
            return true;
        } else {
            await updateDoc(userAnswersDoc!, {
                "insuranceDetails.county": insuranceDetails.county,
                "insuranceDetails.income": insuranceDetails.income,
                "insuranceDetails.type": insuranceDetails.type,
                "insuranceDetails.zipcode": insuranceDetails.zipcode,
                "insuranceDetails.effectiveDate": insuranceDetails.effectiveDate,
                "insuranceDetails.taxFilingStatus": insuranceDetails.taxFilingStatus,
                "insuranceDetails.isNativeAmarican": insuranceDetails.isNativeAmarican,
            });
            if (user?.uid) {
                loadSubsidy({
                    variables: {
                        uid: user?.uid,
                    },
                    fetchPolicy: "network-only"
                });
            }
            if (typeof props.saved === "function") {
                props.saved();
            }
            return true;
        }
    }
    if (insuranceDetails === undefined) {
        return (
            <Typography>Loading</Typography>
        )
    }

    if (step === "county") {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                gap: "1rem",
            }} >
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItem: "center",
                    width: "400px"
                }}>
                    <Typography variant="subtitle2">Please verify your county</Typography>
                    <IconButton
                    sx={{ position: 'absolute', p: 1, right: 0, top: 0, color: 'primary' }}
                        onClick={() => props && props.cancel && props.cancel(false)}>
                    <CloseIcon />
                </IconButton>
                </Box>
                {
                    countiesData?.geographyByZip &&
                    <FormControl fullWidth>
                        <InputLabel id="county-select-label">County</InputLabel>
                        <Select
                            data-testid="select-anyCountry"
                            labelId="county-select-label"
                            value={insuranceDetails.county?.name || ""}
                            label="County"
                            onChange={e => {
                                const selectedCounty = countiesData?.geographyByZip.find((county: { name: any; }) => county.name === e.target.value);
                                setInsuranceDetails({ ...insuranceDetails, county: selectedCounty });
                            }}
                        >
                            {countiesData?.geographyByZip.map((county: any, index: number) => (
                                <MenuItem data-testid={`county-option-${index}`} key={index} value={county.name}>
                                    {county.name}
                                </MenuItem>
                            ))}
                            {countiesData?.geographyByZip.length === 0 && (
                                <MenuItem value={0}>No county found</MenuItem>
                            )}
                        </Select>
                        {!insuranceDetails.county && <FormHelperText>{countyHelper}</FormHelperText>}
                    </FormControl>
                }

                <Button data-testid="continue-button" variant="contained" onClick={save} color="primary">Continue</Button>
            </Box>
        );
    }
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            gap: 2,
        }} >
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                alignItem: "center",
                justifyContent: "space-between",
            }}>
                <Typography variant="h4">Lets collect some data about your household</Typography>
                <IconButton
                    sx={{ position: 'absolute', p: 1, right: 0, top: 0, color: 'primary', cursor: 'pointer' }}
                    onClick={() => props && props.cancel && props.cancel(false)} 
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                gap: 2,
            }} >
                <TextField data-testid="annual-income-input" label="Annual Income" value={insuranceDetails.income || ""} onChange={e => setInsuranceDetails({ ...insuranceDetails, income: parseInt(e.target.value) })} />
                <TextField
                    data-testid="zipcode-input"
                    label="Zipcode"
                    error={zipcodeHelper !== ""}
                    helperText={zipcodeHelper}
                    value={insuranceDetails.zipcode || ""}
                    onChange={(e) => {
                        const value = e.target.value;
                        console.log(value);
                        setInsuranceDetails({ ...insuranceDetails, zipcode: value });
                        if (!value || !value.match(/^\d{5}(-\d{4})?$/)) {
                            setZipcodeHelper("Please enter a valid zip code.");
                        } else {
                            setZipcodeHelper("");
                        }
                    }}
                />
                <FormControl >
                    <InputLabel id="county-select-label">Plan Type</InputLabel>
                    <Select data-testid="plan-type-select" label="Plan Type" value={insuranceDetails.type || ""} onChange={e => { setInsuranceDetails({ ...insuranceDetails, type: e.target.value }); }}>
                        <MenuItem data-testid="individual-option" value="individual">Individual</MenuItem>
                        <MenuItem data-testid="family-option" value="family">Family</MenuItem>
                    </Select>
                </FormControl>
                <FormControl >
                    <InputLabel id="county-select-label">When do you need your plan to start?</InputLabel>
                    <Select data-testid="effective-date-select" label="When do you need your plan to start?" value={insuranceDetails.effectiveDate || ""} onChange={e => { setInsuranceDetails({ ...insuranceDetails, effectiveDate: e.target.value }); }}>
                        {
                            effectiveDates.map(({ value, text }, i) => {
                                return (
                                    <MenuItem data-testid={`effective-date-option-${i}`} key={i} value={value}>{text}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl >
                    <InputLabel id="county-select-label">For Short Term Medical, how many months of coverage do you anticipate needing coverage?</InputLabel>
                    <Select data-testid="short-term-length-select" label="For Short Term Medical, how many months of coverage do you anticipate needing coverage?" value={insuranceDetails.shortTermLength || 3} onChange={e => { setInsuranceDetails({ ...insuranceDetails, shortTermLength: parseInt(e.target.value.toString()) }); }}>
                        {
                            shortTermLengths.map(({ value, text }, i) => {
                                return (
                                    <MenuItem data-testid={`short-term-length-option-${i}`} key={i} value={value}>{text}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
                {props.updateInfo &&
                    <>
                        <FormControl >
                            <InputLabel id="filing-status-label">Tax Filing Status</InputLabel>
                            <Select data-testid="tax-filing-status-select" label="Tax Filing Status" value={insuranceDetails.taxFilingStatus || 3} onChange={e => { setInsuranceDetails({ ...insuranceDetails, taxFilingStatus: e.target.value as string }); }}>
                                {filingStatuses.map(({ value, text }, i) => (
                                    <MenuItem data-testid={`tax-filing-status-option-${i}`} key={i} value={value}>{text}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="native-american-label">Native American</InputLabel>
                            <Select
                                data-testid="native-american-select"
                                labelId="native-american-label"
                                value={insuranceDetails.isNativeAmarican ? "true" : "false"}
                                onChange={e => { setInsuranceDetails({ ...insuranceDetails, isNativeAmarican: e.target.value === "true" }); }}
                                label="Native American"
                            >
                                <MenuItem data-testid="native-american-option-0" value="true">Yes</MenuItem>
                                <MenuItem data-testid="native-american-option-1" value="false">No</MenuItem>
                            </Select>
                        </FormControl>

                    </>
                }
            </Box>
            <Button data-testid="continue-button" variant="contained" onClick={e => { nextStep() }} color="primary" >Continue</Button>
        </Box>
    )
}
export default InsuranceDetails;
